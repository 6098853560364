import PageHeader from "../../patterns/PageHeader";
import { DrawerComponentProps } from "..";
import { MachineStatusChoices } from "../../__generated__/graphql";
import Alert from "../../patterns/Alert";
import { isAttrCriteria, isEventCriteria } from "../../campaign/lib";
import EventCompletionCriteriaEditor from "../event-completion-criteria-editor";
import AttCompletionCriteriaEditor from "../attr-completion-criteria-editor";
import { emptyFilterDoc } from "../../lib/filters";
import SectionHeader from "../../patterns/SectionHeader";
import SubSectionHeader from "../../patterns/SubSectionHeader";

export default function CompletionCriteriaEditor({
  dispatch,
  campaignConfig,
  targetMemberType,
  campaignStatus,
  entityName,
}: DrawerComponentProps) {
  const completionCriteria = campaignConfig.completion_criteria;
  const isEditable = campaignStatus === MachineStatusChoices.Draft;
  return (
    <>
      <PageHeader
        header="Goal"
        subhead="The end goal, or actions you want a member of the organization to take"
      />
      <div className="mx-8">
        {!isEditable ? (
          <div className="mb-2">
            <Alert
              message={`You can't edit the target behavior after the ${entityName} has gone live`}
              type="warning"
              size="small"
            />
          </div>
        ) : (
          <>
            <div className="mb-2">
              <SectionHeader title=" What would you like to influence?" />
              <div className="grid grid-cols-2 gap-4">
                <label className="col-span-1 px-4 py-4 text-xs flex justify-between rounded-md bg-white">
                  <SubSectionHeader
                    label="An event"
                    description="Incentivize an event being performed"
                    icon="Event"
                  />
                  <input
                    type="radio"
                    className="text-body-text active:ring-1 focus:ring-white animate"
                    checked={isEventCriteria(completionCriteria)}
                    onChange={() => {
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: {
                          version: "1",
                          type: "event",
                          target: "",
                        },
                      });
                    }}
                  />{" "}
                </label>
                <label className="col-span-1 px-4 py-4 text-xs flex justify-between rounded-md bg-white">
                  <SubSectionHeader
                    label="An attribute change"
                    description="Incentivize an attribute being changed"
                    icon="CRM"
                  />
                  <input
                    type="radio"
                    className="text-body-text active:ring-1 focus:ring-white animate"
                    checked={isAttrCriteria(completionCriteria)}
                    onChange={() => {
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: {
                          version: "1",
                          type: "attribute",
                          target: null,
                          condition: {
                            type: "attribute_filter",
                            filter: emptyFilterDoc,
                          },
                        },
                      });
                    }}
                  />{" "}
                </label>
              </div>
            </div>
            {completionCriteria && (
              <>
                {isEventCriteria(completionCriteria) && (
                  <EventCompletionCriteriaEditor
                    entityName={entityName}
                    targetMemberType={targetMemberType}
                    criteria={completionCriteria}
                    onChange={(c) =>
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: c,
                      })
                    }
                  />
                )}
                {isAttrCriteria(completionCriteria) && (
                  <AttCompletionCriteriaEditor
                    entityName={entityName}
                    criteria={completionCriteria}
                    targetMemberType={targetMemberType}
                    onChange={(c) =>
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: c,
                      })
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
