import { useCallback, useState, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Flags,
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import PageHeader from "../patterns/PageHeader";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";
import { GET_CAMPAIGNS, GET_BEHAVIORS } from "../graphql/queries";
import { SEED_CAMPAIGN_OR_BEHAVIOR } from "../graphql/mutations";
import SkeletonScreen from "../patterns/Skeleton";
import SelectInput from "../patterns/forms/SelectInput";
import SettingsBlock from "../campaign_form/SettingsBlock";
import { CampaignDefinition } from "../types/BackendTypes";
import FilterBuilder from "../filter_builder";
import useFilteredAudience from "../hooks/useFilteredAudience";
import AudienceTargeting from "../campaign_form/drawer/AudienceTargeting";
import { emptyFilterDoc } from "../lib/filters";
import AttributeUpdateDrawer from "./AttributeUpdateDrawer";
import TextInput from "../patterns/forms/TextInput";
import Alert from "../patterns/Alert";
import IconButton from "../patterns/atoms/IconButton";
import SectionHeader from "../patterns/SectionHeader";
import {
  isAttrCriteria,
  hasValidCriteria,
  completionCriteriaDescription,
} from "../campaign/lib";
import { uuidv7 } from "@kripod/uuidv7";

export default function SeedCampaignOrBehavior() {
  const canUseDemoData = useIsFeatureEnabled(Flags.SelfServiceSeed);
  const { data: campaignsData, loading: campaignsLoading } =
    useQuery(GET_CAMPAIGNS);
  const { data: behaviorsData, loading: behaviorsLoading } =
    useQuery(GET_BEHAVIORS);
  const [seedCampaignOrBehaviorMutation] = useMutation(
    SEED_CAMPAIGN_OR_BEHAVIOR,
  );
  const [seeding, setSeeding] = useState(false);
  const [forceReloadHack, setForceReloadHack] = useState(uuidv7());
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null,
  );
  const [selectedBehaviorId, setSelectedBehaviorId] = useState<string | null>(
    null,
  );
  const [enterOverDays, setEnterOverDays] = useState<number | string>(14);
  const [completionRate, setCompletionRate] = useState<number | string>(0.1);
  const [avgDaysToComplete, setAvgDaysToComplete] = useState<number | string>(
    3.5,
  );

  const setSelected = useCallback(
    (id) => {
      if (campaignsData?.allCampaigns.find((c) => c.id === id)) {
        setSelectedCampaignId(id);
        setSelectedBehaviorId(null);
      } else if (behaviorsData?.allBehaviors.find((c) => c.id === id)) {
        setSelectedBehaviorId(id);
        setSelectedCampaignId(null);
      }
    },
    [
      campaignsData,
      behaviorsData,
      setSelectedBehaviorId,
      setSelectedCampaignId,
    ],
  );

  const seedCampaignOrBehavior = useCallback(async () => {
    setSeeding(true);
    const variables = {
      objectId: "",
      objectType: "",
      enterOverDays: parseInt(`${enterOverDays}`),
      completionRate: parseFloat(`${completionRate}`),
      avgDaysToComplete: parseFloat(`${avgDaysToComplete}`),
    };
    if (selectedCampaignId) {
      variables.objectId = selectedCampaignId;
      variables.objectType = "campaign";
    } else if (selectedBehaviorId) {
      variables.objectId = selectedBehaviorId;
      variables.objectType = "behavior";
    }
    await seedCampaignOrBehaviorMutation({
      variables,
    });
    setSeeding(false);
  }, [
    seedCampaignOrBehaviorMutation,
    selectedCampaignId,
    selectedBehaviorId,
    enterOverDays,
    completionRate,
    avgDaysToComplete,
  ]);

  const optionGroups = useMemo(() => {
    if (!campaignsData?.allCampaigns || !behaviorsData?.allBehaviors) {
      return [];
    }

    return [
      {
        label: "Campaigns",
        options: campaignsData.allCampaigns
          .filter((c) => c.status === MachineStatusChoices.Draft)
          .map((c) => ({
            label: c.name,
            value: c.id,
          })),
      },
      {
        label: "Behaviors",
        options: behaviorsData.allBehaviors
          .filter((c) => c.status === MachineStatusChoices.Draft)
          .map((c) => ({
            label: c.name,
            value: c.id,
          })),
      },
    ];
  }, [campaignsData, behaviorsData]);

  const selectedCampaign = useMemo(() => {
    return campaignsData?.allCampaigns.find((c) => c.id === selectedCampaignId);
  }, [campaignsData, selectedCampaignId]);

  const selectedBehavior = useMemo(() => {
    return behaviorsData?.allBehaviors.find((c) => c.id === selectedBehaviorId);
  }, [behaviorsData, selectedBehaviorId]);

  const selected = useMemo(() => {
    const selected = selectedCampaign ?? selectedBehavior;
    return selected;
  }, [selectedCampaign, selectedBehavior]);

  const selectedEntityType = useMemo(() => {
    if (selectedCampaign) {
      return "campaign";
    } else if (selectedBehavior) {
      return "behavior";
    }
  }, [selectedCampaign, selectedBehavior]);

  const targetsOrgs = useMemo(
    () => selected?.targetMemberType === TargetMemberTypeChoices.Org,
    [selected],
  );
  const audienceCriteria = useMemo(() => {
    if (selectedCampaign) {
      const definition: CampaignDefinition = JSON.parse(
        selectedCampaign.definition,
      );
      return definition.entry_criteria.audience;
    } else if (selectedBehavior) {
      const audience = JSON.parse(selectedBehavior.audience);
      return audience.criteria[0];
    } else {
      return {
        filter: emptyFilterDoc,
      };
    }
  }, [selectedCampaign, selectedBehavior]);

  const completionCriteria = useMemo(() => {
    if (selectedCampaign) {
      const definition: CampaignDefinition = JSON.parse(
        selectedCampaign.definition,
      );
      return definition.completion_criteria;
    } else if (selectedBehavior) {
      const completion = JSON.parse(selectedBehavior.observing);
      return completion.criteria[0];
    } else {
      return null;
    }
  }, [selectedCampaign, selectedBehavior]);

  const { audience, loading: audienceLoading } = useFilteredAudience(
    audienceCriteria?.filter,
    selected?.targetMemberType,
    forceReloadHack,
  );

  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
    setForceReloadHack(uuidv7());
  }, [setDrawerIsVisible]);

  const canSeed = useMemo(() => {
    return true;
  }, []);

  if (!canUseDemoData) {
    return <>You don't have access to this page</>;
  }

  if (campaignsLoading || behaviorsLoading || seeding) {
    return <SkeletonScreen />;
  }

  return (
    <>
      <PageHeader
        header="Populate a campaign or behavior with data"
        subhead=""
        buttons={[
          {
            type: "primary",
            label: "Seed",
            action: seedCampaignOrBehavior,
            isDisabled: !canSeed,
          },
        ]}
      />
      {drawerIsVisible && (
        <AttributeUpdateDrawer
          close={closeDrawer}
          audienceCriteria={audienceCriteria}
        />
      )}
      <div className="flex grow flex-col mx-8 mb-32 gap-y-6">
        <div className="bg-white rounded-lg">
          <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
            <SelectInput
              label="Campaign"
              placeholder="Select a campaign or Behavior"
              groups={optionGroups}
              required
              onChange={(id) => setSelected(id)}
            />
          </div>
        </div>
        {(selectedBehaviorId || selectedCampaignId) && (
          <>
            <SectionHeader
              title={`Seeding the ${selectedEntityType}: ${selected.name}`}
            ></SectionHeader>
            <div className="bg-white rounded-lg">
              <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
                <div className="flex flex-row gap-4 items-center mb-2">
                  <label className="mb-0">Entry:</label>
                  <AudienceTargeting
                    filter={audienceCriteria.filter}
                    audience={audience}
                    loading={audienceLoading}
                    objTargetsOrgs={targetsOrgs}
                    entityName={selectedEntityType}
                  />
                  <IconButton
                    icon="pencil"
                    onClick={() => setDrawerIsVisible(true)}
                  />
                </div>
                <Alert type="info" message="">
                  Click the pencil icon to bulk set attributes. This lets you
                  shrink to expand the audience to the desired size.
                </Alert>
              </div>
              <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
                <div className="flex flex-row gap-4 items-center mb-2">
                  <label className="mb-0">Completion:</label>
                  <div className="flex gap-2 flex-wrap ml-7">
                    {isAttrCriteria(completionCriteria) ? (
                      <div className="mt-2">
                        <FilterBuilder
                          filterDoc={completionCriteria.condition.filter}
                          readonly={true}
                          onChange={() => {}}
                        />
                      </div>
                    ) : (
                      hasValidCriteria(completionCriteria) && (
                        <SettingsBlock
                          title={completionCriteriaDescription(
                            completionCriteria,
                          )}
                          section="events"
                          onClick={() => {}}
                        />
                      )
                    )}
                  </div>
                </div>
                <Alert type="warning" message="">
                  The completion criteria for a seeded campaign can be any event
                  criteria. If the completion criteria are based on attributes,
                  all the attributes must be either strings or integers, they
                  must be AND-ed together, and all the conditions must be
                  equality conditions. Seeding will not work correctly for any
                  other type of completion criteria.
                </Alert>
              </div>
              <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
                <div className="mb-4">
                  <label>Seed settings</label>
                  <Alert type="info" message="">
                    Use the below parameters for entering and completing a
                    campaign or behavior. Note that the entire audience will be
                    entered.
                  </Alert>
                </div>
                <TextInput
                  label="Number of days over which members should enter"
                  placeholder=""
                  required={true}
                  value={`${enterOverDays}`}
                  onChange={(v) => setEnterOverDays(v)}
                />
                <TextInput
                  label="Completion rate (0 - 1)"
                  placeholder="0.1"
                  required={true}
                  value={`${completionRate}`}
                  onChange={(v) => setCompletionRate(v)}
                />
                <TextInput
                  label="Average days to completion"
                  placeholder="1"
                  required={true}
                  value={`${avgDaysToComplete}`}
                  onChange={(v) => setAvgDaysToComplete(v)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
