import { ButtonProps } from "./types";
import { cx } from "../../lib/cx";
import {
  CheckCircleIcon,
  CursorArrowRippleIcon,
  PlayCircleIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import { PauseCircleIcon } from "@heroicons/react/24/outline";

const iconMapping = {
  "add a behavior": {
    icon: (
      <CursorArrowRippleIcon
        className="GrowIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className: "BehaviorButton",
  },
  "set behavior live": {
    icon: (
      <PlayCircleIcon
        className="StaticIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className: "BehaviorButton",
  },
  "pause behavior": {
    icon: (
      <PauseCircleIcon
        className="StaticIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className: "BehaviorPauseButton",
  },
  "complete behavior": {
    icon: (
      <CheckCircleIcon
        className="StaticIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className: "text-body-text bg-white animate hover:bg-grey-200",
  },
  "create behavior": {
    icon: (
      <StarIcon
        className="StaticIcon"
        style={{ width: "0px", height: "0px" }}
      />
    ),
  },
};

export default function BehaviorButton({
  label,
  onClick,
  isDisabled = false,
  fullWidth = false,
}: ButtonProps) {
  const selectedIcon =
    iconMapping[
      Object.keys(iconMapping).find((key) => label.toLowerCase().includes(key))
    ];

  return (
    <button
      onClick={onClick}
      type="button"
      style={{ pointerEvents: isDisabled ? "none" : "auto" }}
      className={cx(
        isDisabled && "opacity-30",
        fullWidth && "w-full",
        "btn-md",
        selectedIcon?.className || "BehaviorButton",
      )}
    >
      <div className="">
        {selectedIcon?.icon || (
          <StarIcon
            className="StaticIcon"
            style={{ width: "16px", height: "16px" }}
          />
        )}
      </div>
      {label}
    </button>
  );
}
