import { useParams } from "react-router-dom";
import PersonProfile from "./PersonProfile";
import CampaignsIcon from "../patterns/symbols/CampaignsIcon";
import TopNav from "../patterns/TopNav";

export default function PersonMain() {
  const params = useParams();
  const topNavOptions = {
    title: "Audience",
    breadcrumb: <CampaignsIcon strokeColor={"white"} strokeWidth={1.5} />,
    backLink: "/audience/people",
    buttonAction: () => {},
  };
  return (
    <>
      <TopNav {...topNavOptions} />
      <div className="max-w-8xl mx-auto mt-0 2xl:mt-8">
        <PersonProfile id={params.id} />
      </div>
    </>
  );
}
