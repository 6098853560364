import { CompletionCriteria, Filter } from "../../types/BackendTypes";
import {
  CohortType,
  TargetMemberTypeChoices,
} from "../../__generated__/graphql";

export const NULL_COHORT = {
  id: "",
  name: "",
  status: "DRAFT",
  targetMemberType: TargetMemberTypeChoices.Org,
  audience: {},
  observing: {},
};

interface InitAction {
  type: "init";
  data: CohortType;
}

interface UpdateAudience {
  type: "UpdateAudience";
  data: Filter;
}

interface UpdateCompletionCriteria {
  type: "UpdateCompletionCriteria";
  criteria: CompletionCriteria;
}

export type CohortUpdate = UpdateAudience | UpdateCompletionCriteria;

export function updateCohortReducer(
  prev: CohortType,
  reducerAction: InitAction | CohortUpdate,
): CohortType {
  switch (reducerAction.type) {
    case "init":
      return {
        ...NULL_COHORT,
        id: reducerAction.data.id,
        name: reducerAction.data.name,
        status: reducerAction.data.status,
        targetMemberType: reducerAction.data.targetMemberType,
        audience: JSON.parse(reducerAction.data.audience),
        observing: JSON.parse(reducerAction.data.observing),
      };
    case "UpdateAudience":
      return {
        ...prev,
        audience: {
          criteria: [
            {
              type: "filter",
              version: "1",
              filter: reducerAction.data,
            },
          ],
        },
      };

    case "UpdateCompletionCriteria":
      return { ...prev, observing: { criteria: [reducerAction.criteria] } };
    default:
      const exhaustiveCheck: never = reducerAction;
      throw new Error(`Unknown action type ${exhaustiveCheck}`);
  }
}
