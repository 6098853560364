import { useCallback, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";

import { Flags } from "../__generated__/graphql";
import { IncentiveTypes } from "../__generated__/graphql";
import { CREATE_INCENTIVE } from "../graphql/mutations";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";

import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import SelectInput from "../patterns/forms/SelectInput";
import SecondaryButton from "../patterns/atoms/SecondaryButton";

interface Props {
  closeModal: () => void;
}

export default function NewIncentive({ closeModal }: Props) {
  const [createIncentiveMutation] = useMutation(CREATE_INCENTIVE);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState("");
  const [cost, setCost] = useState("");
  const [type, setType] = useState<IncentiveTypes>();
  const [incentiveConfig] = useState({});

  const formRef = useRef<HTMLFormElement>();

  const incentiveNames: { [key in keyof typeof IncentiveTypes]: string } = {
    DiscountSample: "Discount Sample",
    ShopifyGift: "Gift",
    Trees: "Trees",
    Webhook: "Webhook",
  };

  const canUseShopifyGiftIncentive = useIsFeatureEnabled(
    Flags.ShopifyGiftIncentive,
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const options = useMemo(() => {
    return Object.keys(IncentiveTypes)
      .filter(
        (e) =>
          IncentiveTypes[e] !== IncentiveTypes.ShopifyGift ||
          canUseShopifyGiftIncentive,
      )
      .map((e) => ({
        label: incentiveNames[e],
        value: IncentiveTypes[e],
      }));
  }, []);

  const createIncentive = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        await createIncentiveMutation({
          variables: {
            name: name,
            type: type,
            config: JSON.stringify(incentiveConfig),
            cost: parseInt(cost),
          },
          refetchQueries: ["GetIncentives"],
        });
        toast.success("Incentive created");
        closeModal();
      } catch (e) {
        if (e.message.startsWith("duplicate")) {
          toast.error(`There is already an incentive called ${name}`);
        } else {
          toast.error("Something went wrong");
        }
      } finally {
        setIsSaving(false);
      }
    },
    [createIncentiveMutation, closeModal, name, type, incentiveConfig, cost],
  );

  return (
    <Modal close={closeModal} title="Add an Incentive">
      <form ref={formRef} className="" action="#" onSubmit={createIncentive}>
        <SelectInput
          label="Incentive type"
          placeholder="Choose a type"
          options={options}
          required={true}
          onChange={(s) => setType(s as IncentiveTypes)}
        />
        <TextInput
          label="Incentive name"
          placeholder="Incentive name"
          value={name}
          onChange={setName}
          required={true}
        />
        <TextInput
          type="number"
          label="Incentive cost"
          placeholder="Incentive cost"
          value={cost}
          onChange={setCost}
          required={true}
          min="0"
          step="1"
        />
        <SecondaryButton
          label="Add Incentive"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
