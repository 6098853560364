import React from "react";
import { TargetMemberTypeChoices } from "../../__generated__/graphql";
import BuilderSectionHeader from "./builder-section-header";
import FilterBuilder from "../../filter_builder";
import AudienceTargeting from "../drawer/AudienceTargeting";
import useFilteredAudience from "../../hooks/useFilteredAudience";
import SectionProps from "./section_props";

export default function AudienceTargetingSection({
  data,
  campaignDefinition,
  openDrawer,
}: SectionProps) {
  const entityName = data.campaignById.isBehavior ? "behavior" : "campaign";
  const targetMemberType = data.campaignById.targetMemberType;

  const { audience, loading: audienceLoading } = useFilteredAudience(
    campaignDefinition.entry_criteria.audience.filter,
    targetMemberType,
  );
  const hasAudience =
    campaignDefinition.entry_criteria.audience.filter.filters.conditions
      .length > 0;

  const isOrgCampaign = targetMemberType === TargetMemberTypeChoices.Org;

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Audience Targeting"
        description={`${isOrgCampaign ? "Which organizations" : "Who"} should be entered into this ${entityName}`}
        isValid={hasAudience}
      />
      <div
        className={`${hasAudience ? "flex gap-2 flex-wrap ml-7 cursor-pointer  " : ""}`}
      >
        <FilterBuilder
          filterDoc={campaignDefinition.entry_criteria.audience.filter}
          readonly={true}
          onChange={() => {}}
        />
      </div>
      <div className="ml-6 max-w-md">
        <AudienceTargeting
          filter={campaignDefinition.entry_criteria.audience.filter}
          audience={audience}
          loading={audienceLoading}
          objTargetsOrgs={isOrgCampaign}
          entityName={entityName}
        />
      </div>
    </div>
  );
}
