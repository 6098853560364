import { cx } from "../lib/cx";

export default function TimeInput({
  hours,
  minutes,
  onHoursChange,
  onMinutesChange,
  validTime,
}: {
  hours: number;
  minutes: number;
  onHoursChange: (hours: number) => void;
  onMinutesChange: (minutes: number) => void;
  validTime: boolean;
}) {
  const noArrowsCss =
    "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none";

  return (
    <div className="flex gap-2 items-center">
      <div className="flex items-center">
        <div
          className={cx(
            `rounded-lg border border-rule-color bg-white px-2 py-1 pl-3 text-body-text`,
            !validTime ? "border-red-400" : "border-rule-color",
          )}
        >
          <input
            type="number"
            value={hours?.toString().padStart(2, "0")}
            onChange={(e) => {
              onHoursChange(Number(e.target.value));
            }}
            placeholder="HH"
            required
            className={`${noArrowsCss} w-8 text-center p-1 border-0 rounded-lg text-right focus:border-transparent focus:ring-0 text-xs font-normal leading-6 tracking-wide`}
          />
          <span>:</span>
          <input
            type="text"
            value={minutes?.toString().padStart(2, "0")}
            onChange={(e) => {
              onMinutesChange(Number(e.target.value));
            }}
            placeholder="MM"
            required
            className={`${noArrowsCss} w-8 text-center p-1 border-0 rounded-lg text-right focus:border-transparent focus:ring-0 text-xs font-normal leading-6 tracking-wide`}
          />
        </div>
      </div>
      {!validTime && (
        <span className="text-red-400 text-xs mt-1 italic">Invalid time</span>
      )}
    </div>
  );
}
