import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";

interface Props {
  value: string;
  placeholder: string;
  autoFocus?: boolean;
  onChange: (s: string) => void;
  keyDown?: (e: React.KeyboardEvent) => void;
}

export default function SearchInput({
  value,
  placeholder,
  autoFocus = false,
  onChange,
  keyDown,
}: Props) {
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );
  return (
    <div className="relative">
      <MagnifyingGlassIcon className="absolute left-4 top-[11px] h-4 w-4" />
      <input
        type="text"
        className="w-full rounded-full border-rule-color pl-10 text-xs py-2.5 outline-none placeholder:text-xs placeholder:tracking-wide placeholder:text-body-text-lighter"
        placeholder={placeholder}
        onKeyDown={keyDown}
        value={value}
        onChange={onInputChange}
        autoFocus={autoFocus}
      />
    </div>
  );
}
