import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { formatDistance } from "date-fns";
import {
  BehaviorType,
  PersonType,
  OrgType,
  TargetMemberTypeChoices,
  BehaviorStateNames,
} from "../../__generated__/graphql";
import { GET_BEHAVIOR_MEMBERS } from "../../graphql/queries";
import { Tabs } from "../lib";
import { formatLong } from "../../lib/date";
import Table, { TableProps } from "../../patterns/tables/table";
import SkeletonScreen from "../../patterns/Skeleton";
import EngagementPill from "../../patterns/EngagementPill";

interface Props {
  behavior: BehaviorType;
  activeTab: Tabs;
  showMember: (m: PersonType | OrgType) => void;
}

interface PersonNode {
  id: string;
  completed: string;
  entered: string;
  observing: string;
  member: PersonType;
}

interface OrgNode {
  id: string;
  completed: string;
  entered: string;
  observing: string;
  member: PersonType;
}

interface CellData {
  content: string | React.ReactNode;
}

const HEADERS = {
  [TargetMemberTypeChoices.Person]: {
    [Tabs.Completed]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Completed" },
    ],
    [Tabs.Eligible]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Entered" },
    ],
    [Tabs.TimeToCompletion]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Time to Completion" },
    ],
    [Tabs.Observing]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Observing" },
    ],
  },
  [TargetMemberTypeChoices.Org]: {
    [Tabs.Completed]: [
      { label: "Name" },
      { label: "Engagement" },
      { label: "Completed" },
    ],
    [Tabs.Eligible]: [
      { label: "Name" },
      { label: "Engagement" },
      { label: "Entered" },
    ],
    [Tabs.TimeToCompletion]: [
      { label: "Name" },
      { label: "Engagement" },
      { label: "Time to Completion" },
    ],
    [Tabs.Observing]: [
      { label: "Name" },
      { label: "Engagement" },
      { label: "Entered" },
    ],
  },
};

const CELLS = {
  [TargetMemberTypeChoices.Person]: {
    [Tabs.Completed]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.completed) },
    ],
    [Tabs.Eligible]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.entered) },
    ],
    [Tabs.TimeToCompletion]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatDistance(new Date(n.completed), new Date(n.entered)) },
    ],
    [Tabs.Observing]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.observing) },
    ],
  },
  [TargetMemberTypeChoices.Org]: {
    [Tabs.Completed]: (n: OrgNode, stageName: string): CellData[] => [
      { content: n.member.name },
      { content: stageRagStatus(n, stageName) },
      { content: formatLong(n.completed) },
    ],
    [Tabs.Eligible]: (n: OrgNode, stageName: string): CellData[] => [
      { content: n.member.name },
      { content: stageRagStatus(n, stageName) },
      { content: formatLong(n.entered) },
    ],
    [Tabs.TimeToCompletion]: (n: OrgNode, stageName: string): CellData[] => [
      { content: n.member.name },
      { content: stageRagStatus(n, stageName) },
      { content: formatDistance(new Date(n.completed), new Date(n.entered)) },
    ],
    [Tabs.Observing]: (n: OrgNode, stageName: string): CellData[] => [
      { content: n.member.name },
      { content: stageRagStatus(n, stageName) },
      { content: formatLong(n.entered) },
    ],
  },
};

const QUERY_MAP = {
  [Tabs.Eligible]: {
    query: GET_BEHAVIOR_MEMBERS,
    variables: (behavior) => ({ behaviorId: behavior.id, limit: 15 }),
  },
  [Tabs.Completed]: {
    query: GET_BEHAVIOR_MEMBERS,
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Completed,
      limit: 15,
    }),
  },
  [Tabs.TimeToCompletion]: {
    query: GET_BEHAVIOR_MEMBERS,
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Completed,
      limit: 15,
    }),
  },
  [Tabs.Observing]: {
    query: GET_BEHAVIOR_MEMBERS,
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Observing,
      limit: 15,
    }),
  },
};

function stageRagStatus(node: any, stageName: string) {
  const attrName = `${stageName}_rag_status`;
  const rag_value =
    node.attributes
      ?.find((attr) => attr.name === attrName)
      ?.value?.toLowerCase()
      .replace(/"/g, "") || "N/A";

  return EngagementPill({ status: rag_value });
}

const PAGE_SIZE = 15;

function toTable(
  targetMemberType: TargetMemberTypeChoices,
  stageName: string,
  data: any,
  activeTab: Tabs,
  showMember: (p: PersonType) => void,
): Omit<TableProps, "filterBar"> {
  return {
    header: HEADERS[targetMemberType][activeTab],
    rows: data.edges.map((p) => {
      return {
        id: p.node.id,
        onClick: (e: React.MouseEvent, id: string) => {
          showMember(p.node.member);
          e.stopPropagation();
        },
        cells: CELLS[targetMemberType][activeTab](p.node, stageName),
      };
    }),
  };
}

export const MemberTable = ({ behavior, activeTab, showMember }: Props) => {
  const query = QUERY_MAP[activeTab];
  const [isPaginating, setIsPaginating] = useState(false);
  const { data, loading, fetchMore, refetch } = useQuery(query.query, {
    variables: query.variables(behavior),
  });

  useEffect(() => {
    refetch({ behaviorId: behavior.id });
  }, [behavior, refetch]);

  const loadNext = useCallback(async () => {
    setIsPaginating(true);
    try {
      const key = Object.keys(data)[0];
      fetchMore({
        variables: {
          cursor: data[key].edges[data[key].edges.length - 1].node.id,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [data, fetchMore]);

  if (loading) {
    return <SkeletonScreen />;
  }

  const key = Object.keys(data)[0];
  const hasMore = data[key].total > data[key].edges.length;

  const tableData = toTable(
    behavior.targetMemberType,
    behavior.name,
    data[key],
    activeTab,
    showMember,
  );

  return (
    <>
      {tableData.rows.length !== 0 && !loading && (
        <>
          <Table header={tableData.header} rows={tableData.rows} />
          {isPaginating ? (
            <div className="w-full flex justify-center">
              <div className="spinner"></div>
            </div>
          ) : (
            <>
              {hasMore && (
                <div
                  className="text-sm text-center cursor-pointer text-blue-600 -pb-2 pb-6"
                  onClick={loadNext}
                >
                  Show More
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
