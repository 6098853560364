import moment from "moment";
import { BehaviorType, Timeseries } from "../../__generated__/graphql";
import { binDataBy } from "../../patterns/charts/utils";
import Bar from "../../patterns/charts/Bar";

export default function EnteredCompletedBarChart({
  behavior,
}: {
  behavior: BehaviorType;
}) {
  const { entered, completed } = behavior.stats;
  const weeklyAggregator = (datum: Timeseries) => {
    const date = new Date(datum.x);
    return `${date.getFullYear()}-${date.getMonth()}-${moment(date).startOf("isoWeek").date()}`;
  };
  const binnedData = {
    entered: binDataBy(entered.timeSeries, weeklyAggregator),
    completed: binDataBy(completed.timeSeries, weeklyAggregator),
  };
  const data = Array.from(
    new Set([
      ...Object.keys(binnedData.entered),
      ...Object.keys(binnedData.completed),
    ]),
  ).map((date) => ({
    date,
    entered: binnedData.entered[date] || 0,
    completed: binnedData.completed[date] || 0,
  }));

  return (
    <div className="w-full h-full min-h-56">
      <Bar data={data} keys={["entered", "completed"]} indexBy="date" />
    </div>
  );
}
