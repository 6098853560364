import { Menu } from "@headlessui/react";
import { cx } from "../lib/cx";

interface Props {
  readonly: boolean;
  currentlySelected: string;
  isOperatorSelectorHovered: boolean;
  setIsOperatorSelectorHovered: (b: boolean) => void;
  onClick: (i: "AND" | "OR") => void;
}

export function FilterGroupOperatorSelector({
  readonly,
  currentlySelected,
  isOperatorSelectorHovered,
  setIsOperatorSelectorHovered,
  onClick,
}: Props) {
  return (
    <Menu
      as="div"
      className="flex h-10 w-12 items-center justify-center bg-white bg-opacity-40"
      onMouseEnter={() => {
        if (!readonly) setIsOperatorSelectorHovered(true);
      }}
      onMouseLeave={() => setIsOperatorSelectorHovered(false)}
    >
      <Menu.Button
        className={cx(
          "text-xs font-semibold leading-6",
          readonly && "pointer-events-none opacity-50",
          isOperatorSelectorHovered && "text-active-blue",
        )}
      >
        {currentlySelected}
      </Menu.Button>
      <Menu.Items className="bg-white rounded-lg shadow-md p-4 absolute w-60 ml-48 mt-48 z-50">
        <Menu.Item
          as="div"
          className={
            "animate block px-4 py-4 text-sm rounded-lg hover:bg-slate-10 hover:text-active-blue cursor-pointer whitespace-no-wrap border-none"
          }
          onClick={() => {
            onClick("AND");
            setIsOperatorSelectorHovered(false);
          }}
        >
          and
        </Menu.Item>
        <Menu.Item
          as="div"
          className={
            "animate block px-4 py-4 text-sm rounded-lg hover:bg-slate-10 hover:text-active-blue cursor-pointer whitespace-no-wrap border-none"
          }
          onClick={() => {
            onClick("OR");
            setIsOperatorSelectorHovered(false);
          }}
        >
          or
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}
