import React from "react";

export default function PavlovLogoWhite() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 110 44">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.144 30.5753H83.8784V13.0161H80.144V30.5753ZM46.835 21.0912H44.5747V16.702H46.835C48.3097 16.702 49.3166 17.5744 49.3166 18.9085C49.3166 20.2426 48.3091 21.0912 46.835 21.0912ZM44.5502 24.39H47.228V24.3894H47.2287C50.7675 24.3894 53.1994 22.0848 53.1994 18.8841C53.1994 15.6835 50.7675 13.3789 47.2287 13.3789H40.668V30.5745H44.5502V24.39ZM59.2036 27.7379C57.6553 27.7379 56.4025 26.5244 56.4025 24.536C56.4025 22.4502 57.6798 21.3591 59.2036 21.3591C60.7274 21.3591 61.9802 22.4983 61.9802 24.536C61.9802 26.5738 60.752 27.7379 59.2036 27.7379ZM62.0054 29.0227V29.1439C62.0054 29.6526 62.0544 30.3075 62.1035 30.5743H65.6913L65.6898 30.5575C65.6639 30.276 65.5932 29.5089 65.5932 28.4159V18.5209H61.9808V19.9038C61.7349 19.3451 60.7771 18.1572 58.5898 18.1572C54.9774 18.1572 52.6436 20.8011 52.6436 24.5117C52.6436 28.2222 55.1246 30.9142 58.7124 30.9142C60.2363 30.9142 61.4645 30.1869 62.0054 29.0227ZM79.4701 18.5208L74.8263 30.5741H71.0674L66.0544 18.5208H70.1083L72.934 26.015L75.5382 18.5208H79.4701ZM91.055 27.543C92.4801 27.543 93.807 26.5732 93.807 24.536C93.807 22.4989 92.4801 21.5528 91.055 21.5528C89.6299 21.5528 88.3029 22.4989 88.3029 24.536C88.3029 26.5732 89.6544 27.543 91.055 27.543ZM91.055 18.1572C94.7164 18.1572 97.5421 20.8011 97.5421 24.536C97.5421 28.271 94.7164 30.9386 91.055 30.9386C87.3935 30.9386 84.5679 28.271 84.5679 24.536C84.5679 20.8011 87.418 18.1572 91.055 18.1572ZM105.356 30.5741L110 18.5208H106.068L103.464 26.015L100.638 18.5208H96.5842L101.597 30.5741H105.356Z"
        fill="white"
      />
      <path
        d="M23.4337 9.1564C23.4337 8.94431 23.321 8.74853 23.1371 8.64249L8.30563 0.0795338C8.12172 -0.0265113 7.89554 -0.0265113 7.71237 0.0795338L0.29663 4.36138C0.273641 4.37473 0.252135 4.38956 0.231371 4.40514C0.0867642 4.51637 0 4.6899 0 4.87529V13.4382C0 13.6503 0.112719 13.8461 0.29663 13.9522L6.82248 17.7201L0.297371 21.4866C0.113461 21.5933 0 21.7891 0 22.0005V30.5627V39.1256C0 39.3377 0.113461 39.5335 0.29663 39.6395L7.71237 43.9206C7.71237 43.9206 7.71311 43.9206 7.71385 43.9214C7.73017 43.931 7.74797 43.9384 7.76502 43.9466C7.7717 43.9496 7.77763 43.9533 7.7843 43.9555C7.80062 43.9622 7.81767 43.9674 7.83399 43.9726C7.84215 43.9748 7.84956 43.9785 7.85698 43.98C7.87922 43.9859 7.90221 43.9904 7.9252 43.9933C7.92743 43.9933 7.92965 43.9941 7.93188 43.9948C7.95709 43.9978 7.98305 44 8.009 44C8.08464 44 8.16102 43.9852 8.23444 43.9555C8.25891 43.9459 8.2819 43.934 8.30489 43.9206L15.7206 39.6395C15.9045 39.5335 16.0173 39.3377 16.0173 39.1256V30.9053L23.1364 26.7947C23.3203 26.6887 23.433 26.4929 23.433 26.2808V17.7179V9.15566L23.4337 9.1564ZM16.018 13.7801L22.2472 10.1835V17.376L16.0662 20.9445L16.018 20.9726V13.7801ZM7.41574 26.6249V33.8174L1.18652 30.2208V23.0283L7.41574 26.6249ZM8.009 1.27866L21.654 9.1564L21.1638 9.43894L15.424 12.7523L1.77978 4.87455L8.009 1.27866ZM1.18652 5.90237L14.8315 13.7801V20.9726L1.18652 13.0956V5.90237ZM14.2382 22.0012L8.009 25.5978L6.45169 24.699L1.77978 22.0019L8.009 18.4053L14.2382 22.0019V22.0012ZM7.41574 42.3804L1.18652 38.7838V31.5905L7.41574 35.1871V42.3797V42.3804ZM14.8315 38.7845L8.60226 42.3811V35.1886L14.8315 31.592V38.7852V38.7845ZM22.2472 25.9397L15.1281 30.0495L14.3472 30.5004L8.60226 33.8174V26.6249L19.7667 20.1792L22.2472 18.7472V25.9397Z"
        fill="url(#paint0_linear_1284_8800)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1284_8800"
          x1="8.3243"
          y1="15.7062"
          x2="18.4419"
          y2="41.5643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B4F6FF" />
          <stop offset="0.51" stopColor="#FFBB56" />
          <stop offset="1" stopColor="#FC54F5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
