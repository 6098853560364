import React, { useRef, useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { LoopsIntegrationType } from "../__generated__/graphql";
import Drawer from "../patterns/Drawer";
import PageHeader from "../patterns/PageHeader";
import toast from "react-hot-toast";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import {
  UPDATE_LOOPS_INTEGRATION,
  DELETE_LOOPS_INTEGRATION,
} from "../graphql/mutations";
import SlackConfig from "./config/SlackConfig";

interface Props {
  close: () => void;
  activeIntegration: LoopsIntegrationType;
}

export default function IntegrationDetail({ close, activeIntegration }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [deleteMutation] = useMutation(DELETE_LOOPS_INTEGRATION);
  const [updateMutation] = useMutation(UPDATE_LOOPS_INTEGRATION);
  const [editingProps, setEditingProps] = useState(
    JSON.parse(activeIntegration.config).properties,
  );

  const handleDelete = useCallback(async () => {
    try {
      await deleteMutation({
        variables: { loopsIntegrationId: activeIntegration.id },
        refetchQueries: ["GetLoopsIntegrations"],
      });
      toast.success("Integration deleted");
      close();
    } catch (e) {
      toast.error(e.message);
    }
  }, [activeIntegration, close, deleteMutation]);

  const handleUpdate = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        updateMutation({
          variables: {
            id: activeIntegration.id,
            props: JSON.stringify(editingProps),
          },
        });
        toast.success("Updated");
      } catch (e) {
        toast.error(e.message);
      }
    },
    [activeIntegration, editingProps, updateMutation],
  );

  return (
    <Drawer close={close}>
      <PageHeader
        header={activeIntegration.loopsType}
        subhead=""
        buttons={[]}
      />
      <div className="p-8">
        <form ref={formRef} onSubmit={handleUpdate}>
          <div className="mb-4">
            <SlackConfig props={editingProps} setProps={setEditingProps} />
          </div>
          <div className="flex flex-row items-center justify-between">
            <PrimaryButton
              label="Save"
              onClick={() => formRef.current.requestSubmit()}
            />
            <SecondaryButton
              label="Delete Integration"
              onClick={handleDelete}
            />
          </div>
        </form>
      </div>
    </Drawer>
  );
}
