export default function Copy() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83317 11.0833H10.4998V4.66667H5.83317V11.0833ZM4.6665 4.662C4.6665 4.02033 5.19267 3.5 5.83842 3.5H10.4946C11.1421 3.5 11.6665 4.02092 11.6665 4.662V11.088C11.6665 11.7297 11.1403 12.25 10.4946 12.25H5.839C5.68572 12.2507 5.5338 12.2212 5.39192 12.1632C5.25003 12.1051 5.12097 12.0197 5.01209 11.9118C4.90322 11.8039 4.81666 11.6756 4.75736 11.5343C4.69807 11.3929 4.66719 11.2413 4.6665 11.088V4.662Z"
        fill="#44546F"
      />
      <path
        d="M2.9165 9.33333V2.912C2.9165 2.27033 3.44267 1.75 4.08842 1.75H8.74984V9.33333H2.9165ZM4.08317 9.33333H8.74984V2.91667H4.08317V9.33333Z"
        fill="#44546F"
      />
    </svg>
  );
}
