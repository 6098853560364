import React, { useEffect, useMemo, useState } from "react";
import Table, { TableProps } from "../../patterns/tables/table";
import SearchInput from "../../patterns/forms/SearchInput";
import AttributesEmpty from "../../patterns/illustrations/AttributesEmpty";
import EmptyState from "../../patterns/EmptyState";
import { AttributeType, attrIcon } from "../../filter_builder/lib";

function toTable(attrs: AttributeType[]): TableProps {
  return {
    header: [
      { label: "", width: "w-12" },
      { label: "Name" },
      { label: "Type" },
    ],
    rows: attrs.map((a) => {
      const IconComponent = attrIcon(a.source, a.aType);
      return {
        id: a.name,
        cells: [
          {
            content: (
              <div className="h-4 w-4 mr-1.5 text-body-text">
                <IconComponent />
              </div>
            ),
          },
          {
            content: a.name,
          },
          {
            content: a.aType,
          },
        ],
        onClick: () => {},
      };
    }),
  };
}

interface Props {
  attributes: AttributeType[];
  showNewAttributeModal: () => void;
}

export default function PersonAttributes({
  attributes,
  showNewAttributeModal,
}: Props) {
  const [filter, setFilter] = useState("");
  const [filteredAttributes, setFilteredAttributes] = useState<AttributeType[]>(
    [],
  );

  useEffect(() => {
    setFilteredAttributes(
      (attributes || []).filter((a) =>
        a.name.toLowerCase().includes(filter.toLowerCase()),
      ),
    );
  }, [filter, setFilteredAttributes, attributes]);
  const tableData = useMemo(
    () => toTable(filteredAttributes),
    [filteredAttributes],
  );

  return (
    <div>
      {attributes.length === 0 ? (
        <div className="h-full mt-8">
          <EmptyState
            title="This is where you can add and manage attributes"
            icon={<AttributesEmpty />}
            description={
              "Use these to target campaigns, and build insights over time around collective behaviours"
            }
            buttonLabel={"Add an attribute"}
            onClick={() => showNewAttributeModal()}
          />
        </div>
      ) : (
        <>
          <Table
            header={tableData.header}
            rows={tableData.rows}
            filterBar={
              <div className="w-1/3">
                <SearchInput
                  value={filter}
                  placeholder="Search attributes…"
                  onChange={setFilter}
                />
              </div>
            }
          />
        </>
      )}
    </div>
  );
}
