import React from "react";
import { cx } from "../../lib/cx";
import { ButtonProps } from "./types";

export default function SecondaryButton({
  label,
  onClick,
  isDisabled,
  fullWidth = false,
}: ButtonProps) {
  const isDeleteButton = label.toLowerCase() === "delete";

  return (
    <button
      type="button"
      onClick={onClick}
      style={{ pointerEvents: isDisabled ? "none" : "auto" }}
      className={cx(
        isDisabled && "opacity-30",
        fullWidth && "w-full",
        "btn-md animate text-body-text",
        isDeleteButton
          ? "bg-grey-100 hover:bg-red-100 hover:text-red hover:border-red-10 focus:outline-none focus:ring-2 focus:ring-grey-300"
          : "bg-grey-100 hover:bg-blue-100 hover:text-blue-600 hover:border-blue-600 focus:outline-none focus:ring-2 focus:ring-grey-300",
      )}
    >
      {label}
    </button>
  );
}
