import { useQuery } from "@apollo/client";
import { GET_TEAMMATES } from "../graphql/queries";
import { TeammateType } from "../__generated__/graphql";
import Table, { TableProps } from "../patterns/tables/table";

function toTable(teammates: TeammateType[]): Omit<TableProps, "filterBar"> {
  return {
    header: [{ label: "Name" }, { label: "Email" }],
    rows: teammates.map((t) => {
      return {
        id: t.email,
        onClick: () => {},
        cells: [{ content: t.name }, { content: t.email }],
      };
    }),
  };
}

export default function Teammates() {
  const { data, loading } = useQuery(GET_TEAMMATES);
  if (loading) {
    return <></>;
  }
  const tableDefinition = toTable(data.teammates);
  return <Table header={tableDefinition.header} rows={tableDefinition.rows} />;
}
