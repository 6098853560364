import React from "react";
import TextInput from "../../patterns/forms/TextInput";
import { SegmentProps, SegmentAuth } from "../../types/BackendTypes";

interface Props {
  props: SegmentProps;
  setProps: (s: SegmentProps) => void;
  auth: SegmentAuth;
  setAuth: (s: SegmentAuth) => void;
}

export default function SegmentConfig({
  props,
  setProps,
  auth,
  setAuth,
}: Props) {
  return (
    <>
      <TextInput
        placeholder="Destination name"
        label="Destination name"
        value={props?.destination_name || ""}
        required={true}
        onChange={(s) => {
          setProps({ ...props, destination_name: s });
        }}
      />
      <TextInput
        placeholder="Shared Secret"
        label="Shared secret"
        value={auth?.shared_secret || ""}
        required={true}
        onChange={(s) => {
          setAuth({ ...auth, shared_secret: s });
        }}
      />
      <TextInput
        type="password"
        placeholder="Segment access token"
        label="Segment access token"
        value={auth?.access_token || ""}
        required={true}
        onChange={(s) => {
          setAuth({ ...auth, access_token: s });
        }}
      />
    </>
  );
}
