import Modal from "../patterns/Modal";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import { DeleteCampaignIllustration } from "../patterns/illustrations/DeleteCampaignIllustration";
import { DELETE_BEHAVIOR } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { BehaviorType } from "../__generated__/graphql";

interface Props {
  behavior: BehaviorType;
  close: () => void;
}

export default function DeleteModal({ behavior, close }: Props) {
  const navigate = useNavigate();
  const [deleteMutation] = useMutation(DELETE_BEHAVIOR);
  return (
    <Modal
      title={`Are you sure you want to delete this behavior?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <DeleteCampaignIllustration />
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <PrimaryButton
          label="Delete"
          onClick={() => {
            deleteMutation({
              variables: { id: behavior.id },
              update(cache) {
                const normalizedId = cache.identify({
                  id: behavior.id,
                  __typename: "BehaviorType",
                });
                cache.modify({
                  id: normalizedId,
                  fields: {
                    deleted() {
                      return true;
                    },
                  },
                });
                cache.gc();
              },
            }).then((response) => {
              if (response.data.deleteBehavior.ok) {
                navigate("/dashboard");
                toast.success(`Behavior deleted`);
              } else {
                toast.error(`Failed to delete behavior`);
                close();
              }
            });
          }}
        />
      </div>
    </Modal>
  );
}
