import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NetworkStatus, useQuery, useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import PageHeader from "../patterns/PageHeader";
import { GET_EVENT_STATS, GET_PEOPLE } from "../graphql/queries";
import { FILTER_DOC_VERSION, buildNewCondition } from "../filter_builder/lib";
import { pluralize } from "../lib/string";
import PeopleTable from "../people/table";
import useQueryAndFilter from "../hooks/useQueryAndFilter";
import SkeletonScreen from "../patterns/Skeleton";
import EventsIcon from "../patterns/symbols/EventsIcon";
import Line from "../patterns/charts/Line";
import TopNav from "../patterns/TopNav";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { filterDocIsComplete } from "../filter_builder/lib";
import Drawer from "../patterns/Drawer";
import PersonEventHistory from "./person_event_history";

const PAGE_SIZE = 50;

const SEARCH_DEBOUNCE_TIMEOUT = 1000;

export default function EventDetail() {
  const { eventName } = useParams();
  const [isPaginating, setIsPaginating] = useState(false);
  const [drawerPersonId, setDrawerPersonId] = useState(null);
  const { data: eventData } = useQuery(GET_EVENT_STATS, {
    variables: { eventName },
  });

  const eventFilterDoc = {
    version: FILTER_DOC_VERSION,
    filters: {
      operator: "AND",
      conditions: [buildNewCondition(eventName)],
    },
  };
  const { query, updateQuery, filter, updateFilter } = useQueryAndFilter(
    JSON.stringify(eventFilterDoc),
  );
  const [
    getPeople,
    { data: peopleData, fetchMore, previousData, networkStatus, refetch },
  ] = useLazyQuery(GET_PEOPLE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  // Initial fetch is not debounced
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      getPeople({ variables: { filter: JSON.stringify(filter), query } });
    }
  }, []);

  const debouncedRefetch = useCallback(
    debounce(refetch, SEARCH_DEBOUNCE_TIMEOUT),
    [refetch],
  );
  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      debouncedRefetch({ filter: JSON.stringify(filter), query });
    }
  }, [filter, query, debouncedRefetch]);

  const loadNext = useCallback(async () => {
    setIsPaginating(true);
    try {
      await fetchMore({
        variables: {
          cursor:
            peopleData.allPeople.edges[peopleData.allPeople.edges.length - 1]
              .node.id,
          filter: JSON.stringify(filter),
          query,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [filter, query, peopleData, fetchMore]);

  const nextPageTrigger = useInfiniteScroll(loadNext);

  const closeDrawer = useCallback(() => {
    setDrawerPersonId(null);
  }, [setDrawerPersonId]);

  if (!eventData || (!peopleData && !previousData)) {
    return <SkeletonScreen />;
  }

  const currentData = peopleData || previousData;

  const loading = networkStatus === NetworkStatus.setVariables;

  const hasMore =
    currentData.allPeople.total > currentData.allPeople.edges.length;

  const topNavOptions = {
    title: "Events",
    breadcrumb: <EventsIcon />,
    backLink: "/events",
    buttonAction: () => {},
  };

  return (
    <>
      <TopNav {...topNavOptions} />
      <PageHeader
        header={`Activity for ${eventName}`}
        subhead={
          loading
            ? "Loading..."
            : pluralize(currentData.allPeople.total, "person")
        }
      />
      <div className="flex flex-col rounded-xl mx-8 mb-6">
        <div className="h-96 w-auto bg-white px-4 py-4 rounded-b-lg">
          <span className="font-bold tracking-tight text-body-text">
            {eventData.eventStats.count}
          </span>
          <span className="inline-flex text-xs text-body-text-lightest ml-2">
            {" "}
            total events recorded for{" "}
            {pluralize(eventData.eventStats.peopleCount, "person")}
          </span>
          <Line data={[{ id: 1, data: eventData.eventStats.timeSeries }]} />
        </div>
      </div>
      {drawerPersonId && (
        <Drawer close={closeDrawer}>
          <div className="mt-10">
            <PersonEventHistory
              personId={drawerPersonId}
              eventName={eventName}
            />
          </div>
        </Drawer>
      )}
      <PeopleTable
        people={currentData.allPeople.edges}
        filter={filter}
        query={query}
        updateQuery={updateQuery}
        updateFilter={updateFilter}
        showNewBehaviorModal={() => {}}
        isLoading={loading}
        readOnlyFilters={true}
        onRowClick={(personId) => setDrawerPersonId(personId)}
      />
      {hasMore && <div ref={nextPageTrigger}></div>}
      {isPaginating ? (
        <SkeletonScreen />
      ) : (
        <div className="w-full min-h-96"></div>
      )}
    </>
  );
}
