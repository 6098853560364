import { formatRelative } from "date-fns";
import { OrgUpdateType, PersonUpdateType } from "../__generated__/graphql";
import { sourceIcon } from "../filter_builder/lib";
import { titleCase } from "../lib/string";
import Card from "../patterns/Card";
import SectionHeader from "../patterns/SectionHeader";

export default function DataSources({
  updates,
}: {
  updates: Array<PersonUpdateType | OrgUpdateType>;
}) {
  return (
    <>
      <SectionHeader title="Data sources"></SectionHeader>
      <Card>
        {updates.map((update) => {
          const Icon = sourceIcon(update.updateSource);
          return (
            <div key={update.updateSource} className="flex items-center mb-2">
              <div className="h-3 w-3 mr-2">
                <Icon />
              </div>
              <div className="text-xs">
                <span>{titleCase(update.updateSource)}</span>
                <span className="text-body-text-lightest">
                  {" "}
                  updated {formatRelative(update.updatedAt, new Date())}
                </span>
              </div>
            </div>
          );
        })}
      </Card>
    </>
  );
}
