import { useQuery } from "@apollo/client";
import {
  BehaviorType,
  PersonType,
  OrgType,
  TargetMemberTypeChoices,
  BehaviorStateNames,
} from "../__generated__/graphql";
import { formatLong } from "../lib/date";
import { useCallback, useEffect } from "react";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { GET_BEHAVIOR_MEMBERS } from "../graphql/queries";
import Table, { TableProps } from "../patterns/tables/table";
import { formatDistance } from "date-fns";
import SkeletonScreen from "../patterns/Skeleton";
import { Tabs } from "../campaign/lib";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import { downloadFile } from "../lib/file";

interface Props {
  behavior: BehaviorType;
  activeTab: Tabs;
  showMember: (m: PersonType | OrgType) => void;
}

interface PersonNode {
  id: string;
  completed: string;
  entered: string;
  exited: string;
  member: PersonType;
}

interface OrgNode {
  id: string;
  completed: string;
  entered: string;
  exited: string;
  member: PersonType;
}

interface CellData {
  content: string;
}

const HEADERS = {
  [TargetMemberTypeChoices.Person]: {
    [Tabs.Completed]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Completed" },
    ],
    [Tabs.Eligible]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Entered" },
    ],
    [Tabs.TimeToCompletion]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Time to Completion" },
    ],
    [Tabs.Exited]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Exited" },
    ],
  },
  [TargetMemberTypeChoices.Org]: {
    [Tabs.Completed]: [{ label: "Name" }, { label: "Completed" }],
    [Tabs.Eligible]: [{ label: "Name" }, { label: "Entered" }],
    [Tabs.TimeToCompletion]: [
      { label: "Name" },
      { label: "Time to Completion" },
    ],
    [Tabs.Exited]: [{ label: "Name" }, { label: "Exited" }],
  },
};

const CELLS = {
  [TargetMemberTypeChoices.Person]: {
    [Tabs.Completed]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.completed) },
    ],
    [Tabs.Eligible]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.entered) },
    ],
    [Tabs.TimeToCompletion]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatDistance(new Date(n.completed), new Date(n.entered)) },
    ],
    [Tabs.Exited]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.exited) },
    ],
  },
  [TargetMemberTypeChoices.Org]: {
    [Tabs.Completed]: (n: OrgNode): CellData[] => [
      { content: n.member.name },
      { content: formatLong(n.completed) },
    ],
    [Tabs.Eligible]: (n: OrgNode): CellData[] => [
      { content: n.member.name },
      { content: formatLong(n.entered) },
    ],
    [Tabs.TimeToCompletion]: (n: OrgNode): CellData[] => [
      { content: n.member.name },
      { content: formatDistance(new Date(n.completed), new Date(n.entered)) },
    ],
    [Tabs.Exited]: (n: OrgNode): CellData[] => [
      { content: n.member.name },
      { content: formatDistance(new Date(n.exited), new Date(n.entered)) },
    ],
  },
};

const QUERY_MAP = {
  [Tabs.Eligible]: {
    query: GET_BEHAVIOR_MEMBERS,
    variables: (behavior) => ({ behaviorId: behavior.id }),
  },
  [Tabs.Completed]: {
    query: GET_BEHAVIOR_MEMBERS,
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Completed,
    }),
  },
  [Tabs.TimeToCompletion]: {
    query: GET_BEHAVIOR_MEMBERS,
    variables: (behavior) => ({
      behaviorId: behavior.id,
      stateFilter: BehaviorStateNames.Completed,
    }),
  },
};

const PAGE_SIZE = 50;

function toTable(
  targetMemberType: TargetMemberTypeChoices,
  data: any,
  activeTab: Tabs,
  showMember: (p: PersonType) => void,
): Omit<TableProps, "filterBar"> {
  return {
    header: HEADERS[targetMemberType][activeTab],
    rows: data.edges.map((p) => {
      return {
        id: p.node.id,
        onClick: (e: React.MouseEvent, id: string) => {
          showMember(p.node.member);
          e.stopPropagation();
        },
        cells: CELLS[targetMemberType][activeTab](p.node),
      };
    }),
  };
}

export const MemberTable = ({ behavior, activeTab, showMember }: Props) => {
  const query = QUERY_MAP[activeTab];
  const { data, loading, fetchMore, refetch } = useQuery(query.query, {
    variables: query.variables(behavior),
  });

  useEffect(() => {
    refetch({ behaviorId: behavior.id });
  }, [behavior, refetch]);

  const loadNext = useCallback(() => {
    const key = Object.keys(data)[0];
    fetchMore({
      variables: {
        cursor: data[key].edges[data[key].edges.length - 1].node.id,
        limit: PAGE_SIZE,
      },
    });
  }, [data, fetchMore]);

  const nextPageTrigger = useInfiniteScroll(loadNext);

  if (loading) {
    return <SkeletonScreen />;
  }

  const key = Object.keys(data)[0];
  const hasMore = data[key].total > data[key].edges.length;

  const tableData = toTable(
    behavior.targetMemberType,
    data[key],
    activeTab,
    showMember,
  );

  return (
    <>
      {tableData.rows.length !== 0 && !loading && (
        <>
          <div className="flex flex-col items-end mx-8 mb-4">
            <SecondaryButton
              label="Download CSV"
              onClick={() => {
                downloadFile(
                  `/reporting/download/membership?object_id=${behavior.id}`,
                  behavior.name,
                );
              }}
            />
          </div>
          <Table header={tableData.header} rows={tableData.rows} />
          {hasMore && <div ref={nextPageTrigger}></div>}
        </>
      )}
    </>
  );
};
