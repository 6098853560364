import React, { useCallback, useState, useMemo, useContext } from "react";
import { SessionContext } from "../SessionContext";
import { useMutation } from "@apollo/client";
import {
  AttributeTarget,
  PersonAttributeType,
  OrgAttributeType,
} from "../__generated__/graphql";
import PageHeader from "../patterns/PageHeader";
import { BULK_SET_ATTRIBUTES_AND_EVENTS } from "../graphql/mutations";
import { Filter } from "../types/BackendTypes";
import FilterBuilder from "../filter_builder";
import { emptyFilterDoc } from "../lib/filters";
import useDocumentClick from "../hooks/useDocumentClick";
import Drawer from "../patterns/Drawer";
import { findAttribute, configForTarget } from "../filter_builder/lib";
import { XMarkIcon } from "@heroicons/react/24/solid";
import AttributePicker from "../filter_builder/AttributePicker";
import SubSectionHeader from "../patterns/SubSectionHeader";
import TextInput from "../patterns/forms/TextInput";

type AttributeForUpdate = {
  source: string;
  name: string;
  target: AttributeTarget;
  value: any;
};

interface AttributeAndEventUpdaterProps {
  attrsSelected: AttributeForUpdate[];
  eventsSelected: string[];
  updateAttrsSelected: (updates: AttributeForUpdate[]) => void;
  updateEventsSelected: (events: string[]) => void;
}

function AttributeAndEventUpdater({
  attrsSelected,
  eventsSelected,
  updateAttrsSelected,
  updateEventsSelected,
}: AttributeAndEventUpdaterProps) {
  const session = useContext(SessionContext);
  const [attributePickerOpen, setAttributePickerOpen] = useState(false);
  const [eventPickerOpen, setEventPickerOpen] = useState(false);
  const availableAttributes = useMemo(() => {
    const alreadyUsed = attrsSelected.map((u) => u.name);
    return {
      person: session.attributes.person.filter(
        (a) => a.source === "api" && !alreadyUsed.includes(a.name),
      ),
      org: session.attributes.org.filter(
        (a) => a.source === "api" && !alreadyUsed.includes(a.name),
      ),
    };
  }, [session, attrsSelected]);

  const closeAttributePicker = useCallback(
    () => setAttributePickerOpen(false),
    [setAttributePickerOpen],
  );

  const closeEventPicker = useCallback(
    () => setEventPickerOpen(false),
    [setEventPickerOpen],
  );

  useDocumentClick(closeAttributePicker);
  useDocumentClick(closeEventPicker);
  return (
    <>
      <div className="">
        <span className="text-sm mb-2">Select attributes to set</span>
        {attrsSelected.map((u) => {
          const attribute = findAttribute(
            u.name,
            u.source,
            u.target,
            session.attributes,
          );
          const config = configForTarget(attribute);
          return (
            <div
              key={`${u.name}${u.source}`}
              className="grid grid-cols-flexibleLeftColumn items-center align-middle"
            >
              <>
                <div className="mb-1 text-sm font-semibold min-w-44">
                  {u.name}
                </div>
                <div className="flex items-center">
                  <config.valueComponent
                    disabled={false}
                    onChange={(s: any) => {
                      const newUpdates = JSON.parse(
                        JSON.stringify(attrsSelected),
                      );
                      newUpdates.forEach((update) => {
                        if (
                          u.name === update.name &&
                          u.source === update.source
                        ) {
                          update.value = s;
                        }
                      });
                      updateAttrsSelected(newUpdates);
                    }}
                    value={u.value}
                  ></config.valueComponent>
                  <div className="mb-2">
                    <button
                      className="ml-2 bg-slate-10 w-8 h-8 rounded-full hover:bg-red-10 hover:text-red animate"
                      disabled={false}
                      onClick={() => {
                        const newUpdates = JSON.parse(
                          JSON.stringify(attrsSelected),
                        );
                        const filteredUpdates = newUpdates.filter((update) => {
                          return (
                            u.name !== update.name || u.source !== update.source
                          );
                        });
                        updateAttrsSelected(filteredUpdates);
                      }}
                    >
                      <XMarkIcon className="w-5 inline-block" />
                    </button>
                  </div>
                </div>
              </>
            </div>
          );
        })}
        <AttributePicker
          openerStyle="root"
          openerLabel="Select an attribute"
          attributePickerOpen={attributePickerOpen}
          setAttributePickerOpen={setAttributePickerOpen}
          availableAttributes={availableAttributes}
          availableEventNames={session.eventNames}
          onAttributePicked={(
            attribute: PersonAttributeType | OrgAttributeType,
          ) => {
            const config = configForTarget(attribute);
            updateAttrsSelected([
              ...attrsSelected,
              {
                source: attribute.source,
                name: attribute.name,
                target: attribute.target,
                value: config.defaultValue,
              },
            ]);
          }}
          onPickerOpened={() => {}}
        />
      </div>
      <div className="mt-4">
        <span className="text-sm mb-2">Select events to set</span>
        <div className="">
          {eventsSelected.map((eventName) => {
            return (
              <div
                key={`bulk-set-${eventName}`}
                className="grid grid-cols-flexibleLeftColumn items-center align-middle"
              >
                <>
                  <div className="mb-1 text-sm font-semibold min-w-44">
                    {eventName}
                  </div>
                  <div className="flex items-center">
                    <div className="mb-2">
                      <button
                        className="ml-2 bg-slate-10 w-8 h-8 rounded-full hover:bg-red-10 hover:text-red animate"
                        disabled={false}
                        onClick={() => {
                          const events = Array.from(eventsSelected);
                          const filteredUpdates = events.filter(
                            (e) => e !== eventName,
                          );
                          updateEventsSelected(filteredUpdates);
                        }}
                      >
                        <XMarkIcon className="w-5 inline-block" />
                      </button>
                    </div>
                  </div>
                </>
              </div>
            );
          })}
        </div>
        <AttributePicker
          openerStyle="root"
          openerLabel="Select an event"
          attributePickerOpen={eventPickerOpen}
          setAttributePickerOpen={setEventPickerOpen}
          availableAttributes={{ person: [], org: [] }}
          availableEventNames={session.eventNames}
          onAttributePicked={(event: string) => {
            updateEventsSelected([...eventsSelected, event]);
          }}
          onPickerOpened={() => {}}
        />
      </div>
    </>
  );
}

export default function AttributeUpdateDrawer({ close, audienceCriteria }) {
  const [matchingMethod, setMatchingMethod] = useState<
    "filter" | "number" | "proportion"
  >("filter");
  const [filter, setFilter] = useState<Filter>(emptyFilterDoc);
  const [numberToSet, setNumberToSet] = useState<number>(0);
  const [proportionToSet, setProportionToSet] = useState<number>(0);
  const [bulkSetAttributeMutation] = useMutation(
    BULK_SET_ATTRIBUTES_AND_EVENTS,
  );
  const [attrUpdates, setAttrUpdates] = useState<AttributeForUpdate[]>([]);
  const [events, setEvents] = useState<string[]>([]);

  const [updating, setUpdating] = useState(false);

  const bulkSetAttribute = useCallback(async () => {
    if (updating) {
      return;
    }
    setUpdating(true);
    const variables = {};

    if (attrUpdates.length > 0) {
      variables["attrUpdates"] = attrUpdates.map((update) => ({
        name: update.name,
        source: update.source,
        target: update.target,
        value: JSON.stringify(update.value),
      }));
    }

    if (events.length > 0) {
      variables["events"] = events;
    }

    if (matchingMethod === "filter") {
      variables["filter"] = JSON.stringify(filter);
    } else if (matchingMethod === "number") {
      variables["numberToSet"] = numberToSet;
    } else if (matchingMethod === "proportion") {
      variables["proportionToSet"] = proportionToSet;
    }

    await bulkSetAttributeMutation({
      variables,
    });
    setUpdating(false);
    close();
  }, [
    bulkSetAttributeMutation,
    attrUpdates,
    events,
    filter,
    close,
    numberToSet,
    proportionToSet,
    matchingMethod,
    updating,
    setUpdating,
  ]);

  const canUpdate = useMemo(() => {
    return (attrUpdates.length > 0 || events.length > 0) && !updating;
  }, [updating, attrUpdates, events]);

  return (
    <Drawer close={close}>
      <div className="mt-10">
        <PageHeader
          header="Bulk set attributes to refine audience"
          subhead=""
          buttons={[
            {
              type: "primary",
              label: updating ? "updating..." : "Update attribute",
              action: () => bulkSetAttribute(),
              isDisabled: !canUpdate,
            },
          ]}
        />
        <div className="px-8 mb-8 flex flex-col gap-4">
          <div>
            <span className="text-sm">Existing audience criteria</span>
            <FilterBuilder
              filterDoc={audienceCriteria.filter}
              readonly={true}
              onChange={() => {}}
            />
          </div>
          <div>
            <span className="text-sm mb-2">
              Choose how to select the people/orgs to update:
            </span>
            <div className="flex flex-col gap-y-2 mb-4">
              <label className="px-2 text-xs flex">
                <input
                  type="radio"
                  className="mr-3 text-body-text active:ring-1 focus:ring-white animate"
                  checked={matchingMethod === "filter"}
                  onChange={() => {
                    setMatchingMethod("filter");
                  }}
                />{" "}
                <SubSectionHeader
                  label="A filter"
                  description="Target people/orgs based on a filter of other attributes"
                />
              </label>
              <label className="px-2 text-xs flex">
                <input
                  type="radio"
                  className="mr-3 text-body-text active:ring-1 focus:ring-white animate"
                  checked={matchingMethod === "number"}
                  onChange={() => {
                    setMatchingMethod("number");
                  }}
                />{" "}
                <SubSectionHeader
                  label="Number"
                  description="N people/orgs, randomly sampled"
                />
              </label>
              <label className="px-2 text-xs flex">
                <input
                  type="radio"
                  className="mr-3 text-body-text active:ring-1 focus:ring-white animate"
                  checked={matchingMethod === "proportion"}
                  onChange={() => {
                    setMatchingMethod("proportion");
                  }}
                />{" "}
                <SubSectionHeader
                  label="Proportion"
                  description="A proportion al all people/orgs, randomly sampled"
                />
              </label>
            </div>
            {matchingMethod === "filter" && (
              <>
                <span className="text-sm mb-2">
                  Set a filter for the bulk update
                </span>
                <FilterBuilder
                  filterDoc={filter}
                  readonly={false}
                  onChange={(d) => setFilter(d)}
                />
              </>
            )}
            {matchingMethod === "number" && (
              <>
                <span className="text-sm mb-2">
                  Set a number of people/orgs to update, randomly sampled
                </span>
                <TextInput
                  label="Number to set"
                  type="number"
                  placeholder="Number to set"
                  value={`${numberToSet}`}
                  required={true}
                  onChange={(v) => setNumberToSet(parseInt(v))}
                />
              </>
            )}
            {matchingMethod === "proportion" && (
              <>
                <span className="text-sm mb-2">
                  Set a proportion (0 - 1) of all people/orgs to update,
                  randomly sampled
                </span>
                <TextInput
                  label="Proportion to set"
                  type="number"
                  placeholder="Proportion to set"
                  value={`${proportionToSet}`}
                  required={true}
                  onChange={(v) => setProportionToSet(parseFloat(v))}
                />
              </>
            )}
          </div>
          <div>
            <AttributeAndEventUpdater
              attrsSelected={attrUpdates}
              eventsSelected={events}
              updateAttrsSelected={setAttrUpdates}
              updateEventsSelected={setEvents}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
}
