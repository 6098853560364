import { BehaviorType } from "../__generated__/graphql";

export function isBehaviorDirty(a: BehaviorType, b: BehaviorType): boolean {
  if (!a || !b) return false;
  if (JSON.stringify(a.audience) !== JSON.stringify(JSON.parse(b.audience)))
    return true;
  else if (
    JSON.stringify(a.observing) !== JSON.stringify(JSON.parse(b.observing))
  )
    return true;
  else if (
    JSON.stringify(a.actionsOnCompletion) !==
    JSON.stringify(JSON.parse(b.actionsOnCompletion))
  )
    return true;
  else if (
    JSON.stringify(a.actionsOnEnter) !==
    JSON.stringify(JSON.parse(b.actionsOnEnter))
  )
    return true;
  else return false;
}

export function canBehaviorBeSetLive(behavior: BehaviorType): boolean {
  return (
    behavior &&
    behavior.targetMemberType &&
    behavior.audience.criteria.length > 0 &&
    behavior.observing.criteria.length > 0
  );
}

export const contextMap = {
  objective: "Objective",
  lifecycleStage: "Lifecycle Stage",
  behavior: "Behavior",
} as const;

export type ContextType = keyof typeof contextMap;
