export default function IncentivesIcon() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 4.50532V13.5019L17 19.4984V10.5018L7 4.50532Z" fill="white" />
      <path d="M17 10.5018L21 8.50374V17.5004L17 19.4984" fill="white" />
      <path
        d="M21 8.49699L17 10.5085L7 4.49857L11 2.5L21 8.49699Z"
        fill="white"
      />
      <path
        d="M17 19.4984L7 13.5019V4.50532L17 10.5018M17 19.4984V10.5018M17 19.4984L21 17.5004V8.50374L17 10.5018M21 8.49699L17 10.5085L7 4.49857L11 2.5L21 8.49699Z"
        stroke="#1E293B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 6.50679V15.5034L13 21.4999V12.5033L3 6.50679Z" fill="white" />
      <path d="M13 12.5033L17 10.5052V19.5018L13 21.4999" fill="white" />
      <path
        d="M17 10.4985L13 12.51L3 6.50004L7 4.50146L17 10.4985Z"
        fill="white"
      />
      <path
        d="M13 21.4999L3 15.5034V6.50679L13 12.5033M13 21.4999V12.5033M13 21.4999L17 19.5018V10.5052L13 12.5033M17 10.4985L13 12.51L3 6.50004L7 4.50146L17 10.4985Z"
        stroke="#1E293B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 15.5L13 21.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14.5L13 20.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 13.5L13 19.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12.5L13 18.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 11.5L13 17.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10.5L13 16.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.5L13 15.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8.5L13 14.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.5L13 13.5"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
