import { useCallback, useRef, useState } from "react";
import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { useMutation } from "@apollo/client";
import { CREATE_ORG } from "../graphql/mutations";
import toast from "react-hot-toast";

interface Props {
  closeModal: () => void;
}

export default function NewOrgModal({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [createOrgMutation] = useMutation(CREATE_ORG);
  const [name, setName] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const createOrg = useCallback(
    async (e: React.FormEvent) => {
      setIsSaving(true);
      e.preventDefault();
      try {
        await createOrgMutation({
          variables: {
            name,
          },
          refetchQueries: ["GetOrgs"],
        });
        toast.success("Organization created");
        closeModal();
      } catch (error) {
        if (error.message.includes("already exists")) {
          toast.error("An organization with this name already exists");
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSaving(false);
      }
    },
    [name, closeModal, createOrgMutation],
  );
  return (
    <Modal close={closeModal} title="Add an organization">
      <form ref={formRef} className="" action="#" onSubmit={createOrg}>
        <TextInput
          label="Name"
          value={name}
          placeholder="Name"
          required={true}
          onChange={setName}
        />

        <PrimaryButton
          label="Create organization"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
