import { AttributeTarget } from "../../__generated__/graphql";
import { cohortToFilter } from "../../cohort/lib";
import FilterBuilder from "../../filter_builder";
import CampaignsIcon from "../../patterns/symbols/CampaignsIcon";

export default function CohortAction({ cohortId }: { cohortId: string }) {
  const filterDoc = {
    version: "1" as "1",
    filters: {
      operator: "AND" as "AND",
      conditions: [cohortToFilter(cohortId, AttributeTarget.Org)],
    },
  };

  return (
    <div className="bg-white rounded-lg px-4 py-2 flex flex-row items-center gap-4">
      <div className="w-5 h-5">
        <CampaignsIcon strokeColor={"white"} />
      </div>
      <div className="flex flex-row items-center gap-2">
        Targeting:
        <div className="-mb-2">
          <FilterBuilder
            filterDoc={filterDoc}
            readonly={true}
            onChange={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
