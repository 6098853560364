export default function SettingsIcon() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.50532L12 13.5019L17 16.5V10.5034L12 7.50532Z"
        fill="white"
      />
      <path d="M17 10.5034L22 7.50532V13.5019L17 16.5" fill="white" />
      <path
        d="M22 7.49857L17 10.5101L12 7.49857L17 4.5L22 7.49857Z"
        fill="white"
      />
      <path
        d="M17 16.5L12 13.5019L12 7.50532L17 10.5034M17 16.5V10.5034M17 16.5L22 13.5019V7.50532L17 10.5034M22 7.49857L17 10.5101L12 7.49857L17 4.5L22 7.49857Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 10.5053L7 16.5019L12 19.5V13.5034L7 10.5053Z" fill="white" />
      <path d="M12 13.5034L17 10.5053V16.5019L12 19.5" fill="white" />
      <path
        d="M17 10.4986L12 13.5101L7 10.4986L12 7.5L17 10.4986Z"
        fill="white"
      />
      <path
        d="M12 19.5L7 16.5019L7 10.5053L12 13.5034M12 19.5V13.5034M12 19.5L17 16.5019V10.5053L12 13.5034M17 10.4986L12 13.5101L7 10.4986L12 7.5L17 10.4986Z"
        stroke="#1E293B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 2"
      />
      <path d="M7 4.50532L7 10.5019L12 13.5V7.50338L7 4.50532Z" fill="white" />
      <path d="M12 7.50338L17 4.50532V10.5019L12 13.5" fill="white" />
      <path
        d="M17 4.49857L12 7.51013L7 4.49857L12 1.5L17 4.49857Z"
        fill="white"
      />
      <path
        d="M12 13.5L7 10.5019L7 4.50532L12 7.50338M12 13.5V7.50338M12 13.5L17 10.5019V4.50532L12 7.50338M17 4.49857L12 7.51013L7 4.49857L12 1.5L17 4.49857Z"
        stroke="#1E293B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 2"
      />
      <path d="M2 13.5053L2 19.5019L7 22.5V16.5034L2 13.5053Z" fill="white" />
      <path d="M7 16.5034L12 13.5053L12 19.5019L7 22.5" fill="white" />
      <path
        d="M12 13.4986L7 16.5101L2 13.4986L7 10.5L12 13.4986Z"
        fill="white"
      />
      <path
        d="M7 22.5L2 19.5019L2 13.5053L7 16.5034M7 22.5V16.5034M7 22.5L12 19.5019L12 13.5053L7 16.5034M12 13.4986L7 16.5101L2 13.4986L7 10.5L12 13.4986Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 19.5068L7 22.5048M12 19.5L7 22.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 18.5068L7 21.5048M12 18.5L7 21.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 17.5068L7 20.5048M12 17.5L7 20.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 16.5068L7 19.5048M12 16.5L7 19.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 15.5068L7 18.5048M12 15.5L7 18.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 14.5068L7 17.5048M12 14.5L7 17.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 13.5L17 16.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 12.5L17 15.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 11.5L17 14.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 10.5L17 13.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 9.5L17 12.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 8.5L17 11.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
