import { useCallback, useEffect, useRef, useState } from "react";
import TextInput from "../patterns/forms/TextInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { fetchCSRFHeader } from "../lib/csrf";
import PavlovWhiteLogo from "../patterns/symbols/PavlovLogoWhite";
import GoogleSSOButton from "../patterns/atoms/GoogleSSOButton";

const INVITE_URL = "/app/fetch_invite";
const SIGNUP_URL = "/app/claim_invite";
const SSO_LOGIN_URL = "/app/sso_login";

function NoTokenError() {
  return (
    <div className="absolute w-full grid h-full place-items-center bg-gradient-to-r from-violet-500 to-fuchsia-500">
      <div className="w-128">
        <div className="pb-8 flex place-content-center my-auto h-20">
          <PavlovWhiteLogo />
        </div>
        <div className="rounded-lg bg-white p-6 flex flex-col items-center">
          <div>You must be invited to sign up first.</div>
          <div>
            Head to{" "}
            <a
              className="underline text-blue-500"
              href="https://www.trypavlov.com"
            >
              trypavlov.com
            </a>{" "}
            to join the waiting list.
          </div>
        </div>
      </div>
    </div>
  );
}
export default function ClaimInvite() {
  let [searchParams] = useSearchParams();
  let token = searchParams.get("token");
  const navigate = useNavigate();

  const [isLoadingInvite, setIsLoadingInvite] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const formRef = useRef<HTMLFormElement>();

  const submitForm = useCallback(() => {
    formRef.current.requestSubmit();
  }, [formRef]);

  const submitOnEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        submitForm();
      }
    },
    [submitForm],
  );

  useEffect(() => {
    fetchCSRFHeader().then((csrfToken) => {
      let formData = new FormData();
      formData.append("token", token);
      fetch(INVITE_URL, {
        method: "POST",
        body: formData,
        headers: new Headers([["X-CSRFToken", csrfToken]]),
      }).then((response) => {
        if (response.status === 200) {
          response.text().then((t) => {
            const data = JSON.parse(t);
            setEmail(data.email);
            setIsLoadingInvite(false);
          });
        } else if (response.status === 403) {
          toast.error("Already logged in");
          navigate("/");
        } else if (response.status === 400) {
          response.text().then((t) => {
            toast.error(t);
          });
        } else {
          toast.error("Invite not found");
        }
      });
    });
  }, [token, navigate]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (password !== confirmPassword) {
        toast.error("Passwords do not match");
        return;
      }
      let formData = new FormData();
      formData.append("token", token);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("password", password);
      const csrfToken = await fetchCSRFHeader();
      const response = await fetch(SIGNUP_URL, {
        method: "POST",
        body: formData,
        headers: new Headers([["X-CSRFToken", csrfToken]]),
      });
      if (response.status === 200) {
        navigate("/");
        window.location.reload();
      } else {
        const text = await response.text();
        toast.error(text);
      }
    },
    [name, email, password, confirmPassword, navigate, token],
  );

  const ssoSignup = useCallback(
    async (e: any) => {
      e.preventDefault();
      const csrfToken = await fetchCSRFHeader();
      const body = new FormData();
      body.append("invite_token", token);
      body.append("provider", "google");
      const response = await fetch(SSO_LOGIN_URL, {
        method: "POST",
        body,
        headers: new Headers([["X-CSRFToken", csrfToken]]),
      });
      if (response.status === 200) {
        const url = await response.text();
        window.location.replace(url);
      } else {
        toast("Signup failed");
      }
    },
    [token],
  );

  if (!token) {
    return <NoTokenError />;
  }

  if (isLoadingInvite) {
    return <></>;
  }

  return (
    <div className="absolute w-full grid h-full place-items-center bg-gradient-to-r from-violet-500 to-fuchsia-500">
      <div className="w-96">
        <div className="pb-8 flex place-content-center my-auto h-20">
          <PavlovWhiteLogo />
        </div>
        <div className="rounded-lg bg-white p-6">
          <form className="pb-4" ref={formRef} onSubmit={onSubmit}>
            <h1 className="mb-4">Signup for Pavlov</h1>
            <TextInput
              label="Email"
              placeholder="email"
              value={email}
              required={true}
              onChange={setEmail}
              onKeyDown={submitOnEnter}
            />
            <TextInput
              label="Name"
              placeholder="Your name"
              value={name}
              required={true}
              onChange={setName}
              onKeyDown={submitOnEnter}
            />
            <TextInput
              type="password"
              label="Password"
              placeholder="password"
              value={password}
              required={true}
              onChange={setPassword}
              onKeyDown={submitOnEnter}
            />
            <TextInput
              type="password"
              label="Confirm Password"
              placeholder="confirm password"
              value={confirmPassword}
              required={true}
              onChange={setConfirmPassword}
              onKeyDown={submitOnEnter}
            />
            <PrimaryButton
              label="Create account"
              onClick={submitForm}
              fullWidth={true}
            />
          </form>
          <div className="flex flex-row justify-center pb-4">or</div>
          <GoogleSSOButton onClick={ssoSignup} label={"Sign up with Google"} />
        </div>
      </div>
    </div>
  );
}
