import { useMutation, useQuery } from "@apollo/client";
import { GET_MESSAGING_SENDERS } from "../graphql/queries";
import Drawer from "../patterns/Drawer";
import PageHeader from "../patterns/PageHeader";
import Card from "../patterns/Card";
import SectionHeader from "../patterns/SectionHeader";
import EmptyState from "../patterns/EmptyState";
import NoSender from "../patterns/illustrations/NoSender";
import {
  MessagingIntegrationType,
  MessagingTypeChoices,
} from "../__generated__/graphql";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import {
  CREATE_MESSAGING_SENDER,
  DELETE_MESSAGING_INTEGRATION,
  DELETE_MESSAGING_SENDER,
  UPDATE_MESSAGING_INTEGRATION,
} from "../graphql/mutations";
import { FormEvent, useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";
import MailgunConfig from "./config/MailgunConfig";
import { MailgunProps, PavlovProps } from "../types/BackendTypes";
import TextInput from "../patterns/forms/TextInput";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import PavlovConfig from "./config/PavlovConfig";
import IconButton from "../patterns/atoms/IconButton";

interface Props {
  close: () => void;
  activeIntegration: MessagingIntegrationType;
}

export default function IntegrationDetail({ close, activeIntegration }: Props) {
  const { data, loading, refetch } = useQuery(GET_MESSAGING_SENDERS, {
    variables: { integrationId: activeIntegration.id },
  });
  const [addingSender, setAddingSender] = useState(false);
  // TODO - Could we make this visible to GQL type system instead?
  const [editingConfig, setEditingConfig] = useState(
    JSON.parse(activeIntegration.config).properties,
  );
  const formRef = useRef<HTMLFormElement>();

  const [deleteMutation] = useMutation(DELETE_MESSAGING_INTEGRATION);
  const [updateMutation] = useMutation(UPDATE_MESSAGING_INTEGRATION);
  const [deleteMessagingSender] = useMutation(DELETE_MESSAGING_SENDER);

  const handleDelete = useCallback(async () => {
    try {
      await deleteMutation({
        variables: { messagingIntegrationId: activeIntegration.id },
        refetchQueries: ["GetMessagingIntegrations"],
      });
      toast.success("Integration deleted");
      close();
    } catch (e) {
      toast.error(e.message);
    }
  }, [activeIntegration, deleteMutation, close]);

  const handleUpdateConfig = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      try {
        await updateMutation({
          variables: {
            id: activeIntegration.id,
            config: JSON.stringify(editingConfig),
          },
        });
        toast.success("Updated");
      } catch (e) {
        toast.error(e.message);
      }
    },
    [editingConfig, updateMutation, activeIntegration],
  );

  const handleDeleteMessagingSender = useCallback(
    async (id: string) => {
      try {
        await deleteMessagingSender({
          variables: { messagingSenderId: id },
          update(cache) {
            const normalizedId = cache.identify({
              id: id,
              __typename: "MessagingSenderType",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
      } catch (e) {
        toast.error(e.message);
      }
    },
    [deleteMessagingSender],
  );

  if (loading) {
    return <></>;
  }

  const canEditConfig = [
    MessagingTypeChoices.Mailgun,
    MessagingTypeChoices.Pavlov,
  ].includes(activeIntegration.messagingType);

  const canModifySenders = [
    MessagingTypeChoices.Mailgun,
    MessagingTypeChoices.Pavlov,
  ].includes(activeIntegration.messagingType);

  const isDirty =
    JSON.stringify(editingConfig) !==
    JSON.stringify(JSON.parse(activeIntegration.config).properties);

  return (
    <Drawer close={close}>
      <PageHeader
        header={activeIntegration.messagingType}
        subhead=""
        buttons={[]}
      />

      <div className="px-8">
        <div className="mb-4">
          <Card>
            {canEditConfig && (
              <form
                ref={formRef}
                className=""
                action="#"
                onSubmit={handleUpdateConfig}
              >
                <SectionHeader title="Settings" />
                <div>
                  {activeIntegration.messagingType ===
                    MessagingTypeChoices.Mailgun && (
                    <MailgunConfig
                      config={editingConfig as MailgunProps}
                      setConfig={setEditingConfig}
                    />
                  )}
                  {activeIntegration.messagingType ===
                    MessagingTypeChoices.Pavlov && (
                    <PavlovConfig
                      config={editingConfig as PavlovProps}
                      setConfig={setEditingConfig}
                    />
                  )}
                  <PrimaryButton
                    label="Save"
                    onClick={() => formRef.current.requestSubmit()}
                    isDisabled={!isDirty}
                  />
                </div>
              </form>
            )}
          </Card>
        </div>
        <div className="mb-4">
          <Card>
            <SectionHeader title="Senders" />
            {data.messagingSenders.length > 0 ? (
              data.messagingSenders.map((s) => {
                return (
                  <div className="flex pb-2" key={s.id}>
                    <div className="text-xs leading-5 text-body-text-lighter w-2/5">
                      {s.displayName}
                      {canModifySenders && (
                        <IconButton
                          icon="trash"
                          onClick={() => handleDeleteMessagingSender(s.id)}
                        />
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyState
                title="No Senders have been added"
                icon={<NoSender />}
                description={
                  "You'll need to add a sender before you can send an email."
                }
              />
            )}
            {canModifySenders && (
              <div className="mt-3">
                {addingSender ? (
                  <div>
                    <AddSender
                      activeIntegration={activeIntegration}
                      refetch={refetch}
                      close={() => setAddingSender(false)}
                    />
                  </div>
                ) : (
                  <PrimaryButton
                    label="Add sender"
                    onClick={() => setAddingSender(true)}
                    fullWidth={false}
                  />
                )}
              </div>
            )}
          </Card>
        </div>
        <SecondaryButton label="Remove integration" onClick={handleDelete} />
      </div>
    </Drawer>
  );
}

function AddSender({
  close,
  refetch,
  activeIntegration,
}: {
  close: () => void;
  refetch: (variables: { integrationId: string }) => void;
  activeIntegration: MessagingIntegrationType;
}) {
  const form = useRef<HTMLFormElement>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [createSenderMutation] = useMutation(CREATE_MESSAGING_SENDER);
  const saveSender = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      try {
        await createSenderMutation({
          variables: {
            messagingIntegrationId: activeIntegration.id,
            displayName: name,
            externalId: email,
            replyTo: replyTo,
          },
        });
        await refetch({ integrationId: activeIntegration.id });
        toast.success("Sender added");
        close();
      } catch (e) {
        toast.error(e.message);
      }
    },
    [
      name,
      email,
      replyTo,
      activeIntegration,
      createSenderMutation,
      close,
      refetch,
    ],
  );
  return (
    <form ref={form} onSubmit={saveSender}>
      <TextInput
        placeholder="Sender name"
        label="Display name"
        value={name}
        required={true}
        onChange={setName}
      />
      <TextInput
        placeholder="test@example.com"
        label="Email address"
        value={email}
        required={true}
        onChange={setEmail}
      />
      <TextInput
        placeholder="test@example.com"
        label="Reply To"
        value={replyTo}
        required={true}
        onChange={setReplyTo}
      />
      <div className="flex flex-row gap-x-2">
        <PrimaryButton
          label="Save"
          onClick={() => form.current.requestSubmit()}
        />
        <SecondaryButton label="Cancel" onClick={close} />
      </div>
    </form>
  );
}
