import {
  ReferralType,
  TargetMemberTypeChoices,
} from "../../__generated__/graphql";
import { uuidv7 } from "@kripod/uuidv7";
import {
  Action,
  updatedActionList,
  removeActionOfType,
} from "../../lib/reducer_utils";
import { ActionListNames } from "../../actions/action_list_names";

const NULL_REFERRAL = {
  id: "",
  name: "",
  status: "DRAFT",
  targetMemberType: TargetMemberTypeChoices.Person,
  actionsOnReferred: { actions: [] },
};

interface InitAction {
  type: "init";
  data: ReferralType;
}

interface EnableNotifyCompletedAction {
  type: "EnableNotifyCompletedAction";
}

interface EnableEmailReferrerAction {
  type: "EnableEmailReferrerAction";
  referrerAttributeIds: {
    email: string;
    name: string;
  };
}

interface EnableWebhookAction {
  type: "EnableWebhookAction";
}

interface EnableUpdateCrmAction {
  type: "EnableUpdateCrmAction";
}

interface RemoveActionOfType {
  type: "RemoveActionOfType";
  actionType: Action["type"];
  actionList: ActionListNames;
}

interface UpdateActionOfType {
  type: "UpdateActionOfType";
  actionType: Action["type"];
  actionList: ActionListNames;
  action: Action;
}

export type ReferralUpdate =
  | EnableNotifyCompletedAction
  | EnableEmailReferrerAction
  | EnableWebhookAction
  | EnableUpdateCrmAction
  | RemoveActionOfType
  | UpdateActionOfType;

export function updateReferralReducer(
  prev: ReferralType,
  reducerAction: InitAction | ReferralUpdate,
): ReferralType {
  switch (reducerAction.type) {
    case "init":
      return {
        ...NULL_REFERRAL,
        id: reducerAction.data.id,
        name: reducerAction.data.name,
        status: reducerAction.data.status,
        targetMemberType: reducerAction.data.targetMemberType,
        actionsOnReferred: JSON.parse(reducerAction.data.actionsOnReferred),
      };

    case "EnableNotifyCompletedAction":
      return {
        ...prev,
        actionsOnReferred: {
          actions: [
            ...prev.actionsOnReferred.actions,
            {
              type: "notify_completed",
              id: uuidv7(),
              message: {
                template: "",
              },
              enabled: true,
            },
          ],
        },
      };
    case "EnableEmailReferrerAction":
      return {
        ...prev,
        actionsOnReferred: {
          actions: [
            ...prev.actionsOnReferred.actions,
            {
              id: uuidv7(),
              type: "email",
              delivery: { mode: "single" },
              messages: [{ subject: "", body: "", sender: "" }],
              enabled: true,
              email_address_source: {
                source: "attribute_model",
                attr: reducerAction.referrerAttributeIds.email,
              },
              name_source: {
                source: "attribute_model",
                attr: reducerAction.referrerAttributeIds.name,
              },
            },
          ],
        },
      };
    case "EnableWebhookAction":
      return {
        ...prev,
        actionsOnReferred: {
          actions: [
            ...prev.actionsOnReferred.actions,
            {
              id: uuidv7(),
              type: "webhook",
              url: "",
              method: "POST",
              headers: [],
              triggering_step_name: "referred",
              enabled: true,
            },
          ],
        },
      };
    case "EnableUpdateCrmAction":
      return {
        ...prev,
        actionsOnReferred: {
          actions: [
            ...prev.actionsOnReferred.actions,
            {
              id: uuidv7(),
              type: "update_crm",
              updates: [],
            },
          ],
        },
      };
    case "RemoveActionOfType":
      return {
        ...prev,
        [reducerAction.actionList]: {
          actions: [
            ...removeActionOfType(
              prev[reducerAction.actionList],
              reducerAction.actionType,
            ),
          ],
        },
      };
    case "UpdateActionOfType":
      return {
        ...prev,
        [reducerAction.actionList]: {
          actions: [
            ...updatedActionList(
              prev[reducerAction.actionList],
              reducerAction.action,
            ),
          ],
        },
      };
    default:
      const exhaustiveCheck: never = reducerAction;
      throw new Error(`Unknown action type ${exhaustiveCheck}`);
  }
}
