import { Timeseries } from "../../__generated__/graphql";

export function binDataBy(
  data: Timeseries[],
  aggregator: <T>(v: T) => string,
): Timeseries[] {
  const result = data.reduce((acc, curr) => {
    const key = aggregator(curr);

    if (!acc[key]) {
      acc[key] = 0;
    }
    acc[key] += curr.y;
    return acc;
  }, []);
  return result;
}
