import { useQuery } from "@apollo/client";
import { GET_MESSAGING_INTEGRATIONS } from "../graphql/queries";
import PageHeader from "../patterns/PageHeader";
import { MessagingIntegrationType } from "../__generated__/graphql";
import EmptyState from "../patterns/EmptyState";
import Integrations from "../patterns/illustrations/Integrations";
import { useState } from "react";
import IntegrationDetail from "./IntegrationDetail";
import { Outlet, useNavigate } from "react-router-dom";
import TestEmailModal from "./TestEmailModal";
import CardMenu from "../patterns/CardMenu";
import SectionHeader from "../patterns/SectionHeader";
import SkeletonScreen from "../patterns/Skeleton";

export default function MessagingMain() {
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_MESSAGING_INTEGRATIONS);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [testModalVisible, setTestModalVisible] = useState(false);
  const [activeIntegration, setActiveIntegration] =
    useState<MessagingIntegrationType>();

  if (loading) {
    return (
      <>
        <SkeletonScreen />
      </>
    );
  }

  return (
    <div>
      {drawerIsVisible && activeIntegration && (
        <IntegrationDetail
          close={() => setDrawerIsVisible(false)}
          activeIntegration={activeIntegration}
        />
      )}
      {testModalVisible && (
        <TestEmailModal closeModal={() => setTestModalVisible(false)} />
      )}
      <div className="flex flex-col max-w-8xl mx-auto mt-0 2xl:mt-8">
        {data.messagingIntegrations.length > 0 ? (
          <>
            <PageHeader
              header="Messaging Integrations"
              subhead=""
              buttons={[
                {
                  type: "secondary",
                  label: "Send test email",
                  action: () => {
                    setTestModalVisible(true);
                  },
                },
                {
                  type: "primary",
                  label: "Add a messaging integration",
                  action: () => {
                    navigate("./new");
                  },
                },
              ]}
            />
            {data.messagingIntegrations.map((integration) => (
              <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-8">
                <CardMenu
                  key={integration.id}
                  onClick={() => {
                    setDrawerIsVisible(true);
                    setActiveIntegration(integration);
                  }}
                >
                  <div className="w-auto h-40 rounded-t-lg bg-gradient-to-br from-[#E5F5F2] to-[#E4ECF6] p-6 flex items-center justify-center">
                    <Integrations />
                  </div>
                  <div className="px-4 pt-4 pb-2">
                    <SectionHeader
                      title={integration.messagingType}
                      description="Messaging"
                    />
                  </div>
                </CardMenu>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-8">
            <EmptyState
              title="No messaging integrations configured yet"
              description="You haven't configured a messaging integration yet."
              icon={<Integrations />}
              buttonLabel={"Add a messaging integration"}
              onClick={() => navigate("./new")}
            />
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
}
