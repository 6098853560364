import { NavigateFunction, useNavigate } from "react-router-dom";
import { OrgEdge, PersonEdge } from "../__generated__/graphql";
import Table, { TableProps } from "../patterns/tables/table";
import SearchInput from "../patterns/forms/SearchInput";
import { Filter } from "../types/BackendTypes";
import FilterBuilder from "../filter_builder";
import BehaviorButton from "../patterns/atoms/BehaviorButton";

interface Props {
  orgs: Omit<OrgEdge, "cursor">[] | any;
  query: string;
  filter: Filter;
  isLoading: boolean;
  updateQuery: (s: string) => void;
  updateFilter: (f: Filter) => void;
  showNewBehaviorModal: (b: boolean) => void;
}

function toTable(
  people: Omit<PersonEdge, "cursor">[],
  navigate: NavigateFunction,
): Omit<TableProps, "filterBar"> {
  return {
    header: [
      { label: "Name" },
      { label: "Active Campaigns" },
      { label: "Campaigns Entered" },
      { label: "Completion Rate" },
    ],
    rows: people.map((p) => {
      const totalCampaignsEntered =
        p.node.completedCampaigns.length +
        p.node.activeCampaigns.length +
        p.node.exitedCampaigns.length;
      const completedCampaigns = p.node.completedCampaigns.length;
      const successRate =
        totalCampaignsEntered > 0
          ? ((completedCampaigns / totalCampaignsEntered) * 100).toFixed(0) +
            "%"
          : "—";

      return {
        id: p.node.id,
        onClick: (e: any, id: string) =>
          navigate(`/audience/organizations/${p.node.id}`),
        cells: [
          { content: p.node.name },
          { content: p.node.activeCampaigns.length },
          { content: totalCampaignsEntered },
          { content: successRate },
        ],
      };
    }),
  };
}

export default function OrgsTable({
  orgs,
  query,
  filter,
  isLoading,
  updateQuery,
  updateFilter,
  showNewBehaviorModal,
}: Props) {
  const navigate = useNavigate();
  const tableDefinition = toTable(orgs, navigate);
  const hasActiveFilters = filter.filters.conditions.length > 0;
  return (
    <Table
      header={tableDefinition.header}
      rows={tableDefinition.rows}
      isLoading={isLoading}
      emptyMessage="No organizations found matching these filters"
      filterBar={
        <div className="flex space-x-2">
          <div className="grow">
            <FilterBuilder
              showOrgAttrs={true}
              showPersonAttrs={false}
              showEvents={true}
              filterDoc={filter}
              onChange={updateFilter}
            />
          </div>
          <div className="min-w-64">
            {hasActiveFilters ? (
              <BehaviorButton
                label="Add a Behavior using these filters"
                onClick={() => {
                  showNewBehaviorModal(true);
                }}
              />
            ) : (
              <SearchInput
                value={query}
                placeholder="Search organizations"
                onChange={updateQuery}
              />
            )}
          </div>
        </div>
      }
    />
  );
}
