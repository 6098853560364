import React, { Dispatch } from "react";
import { CampaignDefinitionEditAction } from "../reducer";
import { useQuery } from "@apollo/client";
import {
  GET_LOOPS_INTEGRATIONS,
  GET_SLACK_CHANNELS,
} from "../../graphql/queries";
import Card from "../../patterns/Card";
import SelectInput from "../../patterns/forms/SelectInput";
import { Link } from "react-router-dom";
import {
  NotifyEnteredAction,
  NotifyCompletedAction,
  NotifyExitedAction,
} from "../../types/BackendTypes";
import Editor from "../editor";
import OptionsAndDetail from "../options-and-details";

export default function SlackAction({
  dispatch,
  action,
  actionSource,
}: {
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  action: NotifyEnteredAction | NotifyCompletedAction | NotifyExitedAction;
  actionSource: "before_actions" | "after_actions" | "exit_actions";
}) {
  const {
    data: loopsIntegrationData,
    loading: loopsIntegrationLoading,
    error: loopsIntegrationError,
  } = useQuery(GET_LOOPS_INTEGRATIONS);
  const {
    data: slackData,
    loading: slackChannelsLoading,
    error: slackChannelsError,
  } = useQuery(GET_SLACK_CHANNELS);

  if (loopsIntegrationLoading || slackChannelsLoading) {
    return (
      <Card>
        <div className="p-4">Loading...</div>
      </Card>
    );
  }

  if (loopsIntegrationError || slackChannelsError) {
    return (
      <Card>
        <div className="p-4">
          Something went wrong. Please check the{" "}
          <Link
            to={"/settings/loops"}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer underline"
          >
            Internal Messaging
          </Link>{" "}
          integration settings
        </div>
      </Card>
    );
  }

  if (loopsIntegrationData?.loopsIntegrations.length === 0) {
    return (
      <Card>
        <div className="p-4 text-xs tracking-wide">
          You'll need to set up an{" "}
          <Link
            to={"/settings/loops"}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer underline"
          >
            Internal Messaging
          </Link>{" "}
          integration to use this follow up action.
        </div>
      </Card>
    );
  }

  let trigger;
  if (actionSource === "before_actions") {
    trigger = "entry";
  } else if (actionSource === "after_actions") {
    trigger = "completion";
  } else if (actionSource === "exit_actions") {
    trigger = "exit without completion";
  }

  return (
    <OptionsAndDetail
      left={
        <div>
          <SelectInput
            label={"Send to"}
            description={"Notify a slack channel with this message"}
            placeholder="Pick a channel"
            required={true}
            value={action.channel || ""}
            options={slackData.slackChannels.map((s) => ({
              label: s.name,
              value: s.id,
            }))}
            disabled={false}
            onChange={(s) =>
              dispatch({
                type: "UpdateActionOfType",
                actionList: actionSource,
                action: {
                  ...action,
                  channel: s,
                },
              })
            }
          />
          <div className="text-xs font-normal leading-5 tracking-wide text-body-text-lighter mb-2">
            To notify a private channel, you must first invite the app to that
            channel in your slack workspace.
          </div>
        </div>
      }
      right={
        <div className="p-4">
          <Editor
            label={`Message to send on ${trigger}`}
            placeholder={"Notify your team and celebrate customer success!"}
            value={action.message.template || ""}
            editable={true}
            mentionUsers={slackData.slackUsers}
            onChange={(s) =>
              dispatch({
                type: "UpdateActionOfType",
                actionList: actionSource,
                action: {
                  ...action,
                  message: {
                    template: s,
                  },
                },
              })
            }
          />
        </div>
      }
    />
  );
}
