import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import CampaignsIcon from "./symbols/CampaignsIcon";
import PeopleIcon from "./symbols/PeopleIcon";
import EventsIcon from "./symbols/EventsIcon";
import DashboardIcon from "./symbols/DashboardIcon";
import OrganizationIcon from "./symbols/OrganizationIcon";

interface Props {
  title: string;
  breadcrumb: ReactNode;
  buttonTitle?: string;
  backLink?: string;
  buttonAction?: () => void;
}

const getBreadcrumbIcon = (title: string): ReactNode => {
  const iconMap: { [key: string]: ReactNode } = {
    Campaigns: <CampaignsIcon strokeColor={"white"} strokeWidth={1.5} />,
    Dashboard: <DashboardIcon strokeColor={"white"} strokeWidth={1.5} />,
    Lifecycle: <DashboardIcon strokeColor={"white"} strokeWidth={1.5} />,
    Audience: <PeopleIcon />,
    "Audience > People": <PeopleIcon />,
    "Audience > Organizations": <OrganizationIcon />,
    Events: <EventsIcon />,
    Default: (
      <svg
        className="mx-auto mt-1 h-3 w-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      ></svg>
    ),
  };

  return iconMap[title] || iconMap.Default;
};

export default function TopNav(topNavOptions: Props) {
  return (
    <div className="top-0 z-[9001] flex h-12 items-center gap-x-3 border-b-1 border-rule-color align-middle sm:px-6 lg:px-8">
      {topNavOptions.breadcrumb ? (
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="inline-flex items-center">
            <li className="inline-flex items-center align-middle text-sm font-medium text-grey-700 hover:text-blue-600">
              <div className="mr-1.5 h-6 w-6 items-center rounded-md bg-grey-200 align-middle">
                <div className="w-4 h-4 mx-auto mt-1">
                  {getBreadcrumbIcon(topNavOptions.title)}
                </div>
              </div>

              <div className="flex font-semibold text-xs leading-5 text-body-text">
                {topNavOptions.backLink ? (
                  <div className="flex">
                    <Link
                      to={topNavOptions.backLink}
                      className="hover:text-active-blue animate"
                    >
                      {topNavOptions.title}
                    </Link>
                  </div>
                ) : (
                  <>{topNavOptions.title}</>
                )}
              </div>
            </li>
          </ol>
        </nav>
      ) : null}

      <div className="flex flex-1 flex-row-reverse gap-x-4 self-stretch lg:gap-x-6">
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {topNavOptions.buttonTitle ? (
            <button
              type="button"
              className="hover:bg-grey-700 focus:ring-2 inline-flex items-center rounded-full bg-active-blue py-1 pl-4 pr-1 text-center text-sm font-semibold text-white focus:outline-none"
              onClick={() => topNavOptions.buttonAction()}
            >
              {topNavOptions.buttonTitle}
              <span className="ml-2 inline-flex h-6 w-6 items-center justify-center">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="white" />
                  <path
                    d="M15.7129 7.87297L13.3392 11.4004H15.5089C15.606 11.4004 15.6936 11.4592 15.7312 11.5497C15.7688 11.6402 15.749 11.7447 15.6811 11.8148L10.3831 17.1714C10.3088 17.253 10.1893 17.2735 10.0925 17.2214C9.99568 17.1692 9.94589 17.0575 9.97138 16.9496L10.9285 12.863H9.24092C9.164 12.8629 9.09172 12.8258 9.04637 12.763C9.00102 12.7001 8.98803 12.6192 9.01141 12.5451L10.3981 7.67649C10.4276 7.57261 10.5212 7.50077 10.6281 7.5H15.5089C15.5965 7.50018 15.6771 7.54834 15.7194 7.62581C15.7618 7.70329 15.7593 7.7979 15.7129 7.87297Z"
                    fill="#16588E"
                  />
                </svg>
              </span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
