import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import Modal from "../patterns/Modal";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import { SetCampaignLive } from "../patterns/illustrations/SetCampaignLiveIllustration";
import CohortButton from "../patterns/atoms/BehaviorButton";
import { CohortType, MachineStatusChoices } from "../__generated__/graphql";
import { UPDATE_COHORT } from "../graphql/mutations";

interface Props {
  cohort: CohortType;
  close: () => void;
}

export default function SetLiveModal({ cohort, close }: Props) {
  const [updateCohortMutation] = useMutation(UPDATE_COHORT);

  const setCohortLive = useCallback(async () => {
    try {
      const response = await updateCohortMutation({
        variables: {
          id: cohort.id,
          name: cohort.name,
          status: MachineStatusChoices.Live,
          audience: JSON.stringify(cohort.audience),
          observing: JSON.stringify(cohort.observing),
        },
      });

      if (response.data.updateCohort.ok) {
        toast.success(`Cohort set live`);
      } else {
        toast.error(() => <p>Could not set Cohort live</p>);
      }
    } catch (e) {
      toast.error(e.message);
    }
    close();
  }, [cohort, close, updateCohortMutation]);

  return (
    <Modal
      title={`Are you sure you want to set this cohort live?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <SetCampaignLive />
      </div>
      <div className="text-center text-xs mt-2">
        <p>
          {`You won't be able to edit the completion criteria after you put
          the cohort live.`}
        </p>
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <CohortButton label={`Set Cohort Live`} onClick={setCohortLive} />
      </div>
    </Modal>
  );
}
