import {
  AttrTypeEnum,
  OrgAttribute,
  PersonAttribute,
} from "../__generated__/graphql";
import { parsedAttributeValue } from "../lib/attributes";
import { cx } from "../lib/cx";
import { formatRelative } from "date-fns";

interface Props {
  attr: PersonAttribute | OrgAttribute;
  allowEditing?: boolean;
}

function renderedValue(attr: PersonAttribute | OrgAttribute) {
  const parsedValue = parsedAttributeValue(attr);
  switch (attr.aType) {
    case AttrTypeEnum.Boolean:
      return parsedValue === false
        ? "False"
        : !parsedValue
          ? "undefined"
          : "True";
    case AttrTypeEnum.Int:
      return parsedValue ? Number(parsedValue).toLocaleString() : "undefined";
    case AttrTypeEnum.String:
    case AttrTypeEnum.Time:
    case AttrTypeEnum.Date:
    case AttrTypeEnum.Unknown:
      return parsedValue ?? "undefined";
    case AttrTypeEnum.Datetime:
      return parsedValue
        ? formatRelative(parsedValue, new Date())
        : "undefined";
    default:
      const exhaustiveCheck: never = attr.aType;
      throw new Error(`Unhandled color case: ${exhaustiveCheck}`);
  }
}

export function AttributeValue({ attr }: Props) {
  return (
    <div className={cx("", attr.value === false ? "" : !attr.value ? "" : "")}>
      {renderedValue(attr)}
    </div>
  );
}
