import { COMPLETION_RATE_THRESHOLD } from "../ai_agent/AiAgentDrawer";

export default function EngagementCircle({
  completionPercentage,
  showText = false,
}: {
  completionPercentage: number;
  showText?: boolean;
}) {
  const strokeColor =
    completionPercentage >= COMPLETION_RATE_THRESHOLD
      ? "stroke-green-400"
      : "stroke-red-400";
  return (
    <svg viewBox="0 -1 38 38" className={strokeColor}>
      <path
        className="fill-none stroke-[4] stroke-[#F2F5F2]"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        className="fill-none stroke-[4] engagement-circle-icon"
        stroke-dasharray={`${completionPercentage}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      {showText && (
        <text
          x="50%"
          y="50%"
          text-anchor="middle"
          dominant-baseline="middle"
          className="text-xxs stroke-none fill-grey-700"
        >
          {completionPercentage}%
        </text>
      )}
    </svg>
  );
}
