import React from "react";
import Input from "./Input";

interface Props {
  autoComplete?: string;
  type?: React.HTMLInputTypeAttribute;
  label: string;
  description?: string;
  placeholder: string;
  value: string;
  required: boolean;
  onChange: (s: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  min?: string;
  step?: string;
  disabled?: boolean;
  size?: "default" | "large";
  error?: string;
  autoFocus?: boolean;
}

export default function TextInput({
  autoComplete,
  type = "text",
  label,
  description,
  placeholder,
  value,
  required,
  onChange,
  onKeyDown = () => undefined,
  min,
  step,
  disabled = false,
  autoFocus = false,
  size,
  error,
}: Props) {
  return (
    <div className="border-none pb-4">
      <label>
        {label}
        {description && (
          <div className="text-xs font-normal leading-5 tracking-wide text-body-text-lighter mb-2">
            {description}
          </div>
        )}
        <Input
          autoComplete={autoComplete}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          onKeyDown={onKeyDown}
          min={min}
          step={step}
          size={size}
          error={error}
          autoFocus={autoFocus}
        />
      </label>
    </div>
  );
}
