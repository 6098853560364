import { Popover } from "@headlessui/react";
import { useCallback, useEffect, useState } from "react";
import { cx } from "../lib/cx";
import SearchInput from "../patterns/forms/SearchInput";
import { AttributeType, attrIcon, sourceIcon } from "./lib";
import { PlusIcon } from "@heroicons/react/24/outline";
import { AttributeGroup } from "../SessionContext";

interface Props {
  openerStyle: "root" | "group" | "toolbar";
  attributePickerOpen: boolean;
  availableAttributes: AttributeGroup;
  availableEventNames: string[];
  openerLabel?: string;
  setAttributePickerOpen: (s: boolean) => void;
  onAttributePicked: (a: AttributeType | string) => void;
  onPickerOpened: (s: string | undefined) => void;
}

export default function AttributePicker({
  openerStyle,
  attributePickerOpen,
  openerLabel = "Add a filter group",
  availableAttributes,
  availableEventNames,
  setAttributePickerOpen,
  onAttributePicked,
  onPickerOpened,
}: Props) {
  const [filteredPersonAttrs, setFilteredPersonAttrs] = useState(
    availableAttributes.person,
  );
  const [filteredOrgAttrs, setFilteredOrgAttrs] = useState(
    availableAttributes.org,
  );
  const [filteredEventNames, setFilteredEventNames] =
    useState(availableEventNames);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState<
    "person" | "org" | "events"
  >("person");
  const [searchString, setSearchString] = useState("");

  const keyDown = useCallback(
    (e: React.KeyboardEvent) => {
      const activeGroup =
        selectedGroup === "person"
          ? filteredPersonAttrs
          : selectedGroup === "org"
            ? filteredEventNames
            : filteredOrgAttrs;
      switch (e.key) {
        case "ArrowDown":
          if (selectedIndex < activeGroup.length - 1) {
            setSelectedIndex(selectedIndex + 1);
          } else {
            setSelectedIndex(0);
            if (selectedGroup === "person") {
              setSelectedGroup("org");
            }
            if (selectedGroup === "org") {
              setSelectedGroup("events");
            }
          }
          break;
        case "ArrowUp":
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
          } else {
            if (selectedGroup === "org") {
              setSelectedIndex(filteredPersonAttrs.length - 1);
              setSelectedGroup("person");
            }
            if (selectedGroup === "events") {
              setSelectedIndex(filteredOrgAttrs.length - 1);
              setSelectedGroup("org");
            }
          }
          break;
        case "Enter":
          const attr = activeGroup[selectedIndex];
          onAttributePicked(attr);
          setSearchString("");
          setAttributePickerOpen(false);
          break;
      }
    },
    [
      filteredPersonAttrs,
      filteredOrgAttrs,
      filteredEventNames,
      selectedIndex,
      selectedGroup,
      onAttributePicked,
      setSelectedIndex,
      setAttributePickerOpen,
    ],
  );

  useEffect(() => {
    const lowerCaseSearchString = searchString.toLowerCase();
    setFilteredPersonAttrs(
      availableAttributes.person.filter((a) => {
        return a.name.toLowerCase().includes(lowerCaseSearchString);
      }),
    );
    setFilteredOrgAttrs(
      availableAttributes.org.filter((a) => {
        return a.name.toLowerCase().includes(lowerCaseSearchString);
      }),
    );
    setFilteredEventNames(
      availableEventNames.filter((a) => {
        return a.toLowerCase().includes(lowerCaseSearchString);
      }),
    );
    setSelectedIndex(0);
  }, [
    searchString,
    availableAttributes,
    availableEventNames,
    setFilteredPersonAttrs,
    setFilteredOrgAttrs,
    setSelectedIndex,
  ]);

  return (
    <Popover
      className="relative"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover.Button
        onClick={(e) => {
          onPickerOpened(undefined);
          setAttributePickerOpen(!attributePickerOpen);
          e.stopPropagation();
        }}
      >
        {openerStyle === "root" && (
          <div className="pl-3 pr-4 h-10 text-xs text-center font-medium inline-flex items-center border border-rule-color bg-white text-body-text rounded-lg hover:bg-grey-100 hover:border-grey-100 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 animate">
            <PlusIcon className="w-4 h-4 text-body-text me-2 rounded-full" />
            {openerLabel}
          </div>
        )}

        {openerStyle === "group" && (
          <div className="flex py-2 px-2 text-xs leading-6 tracking-wide w-10 h-10 border-l-1 border-white hover:text-active-blue bg-grey-100 hover:bg-blue-10 animate cursor-pointer rounded-r-lg">
            <PlusIcon className="w-4 h-4 m-auto" />
          </div>
        )}

        {openerStyle === "toolbar" && (
          <button
            title="Add a variable"
            className="cursor-pointer rounded p-1.5 hover:bg-grey-200 active:bg-grey-400 animate"
          >
            <PlusIcon className="w-4 h-4" />
          </button>
        )}
      </Popover.Button>

      <Popover.Panel
        static
        className="absolute z-10 rounded-lg bg-white shadow-md mt-1"
      >
        {attributePickerOpen && (
          <div className="w-80 h-auto overflow-y-auto p-2">
            <div className="mb-3 text-xs">
              <SearchInput
                value={searchString}
                placeholder="Search…"
                keyDown={keyDown}
                onChange={(s) => setSearchString(s)}
              />
            </div>
            <div className="h-auto max-h-96 overflow-auto rounded-sm flex flex-col gap-4">
              {filteredPersonAttrs.length > 0 && (
                <div>
                  <div className="p-2 text-[10px] leading-5 uppercase font-normal tracking-widest text-body-text">
                    Person Attributes
                  </div>

                  {filteredPersonAttrs.map((attr, index) => (
                    <AttrRow
                      isSelected={
                        selectedIndex === index && selectedGroup === "person"
                      }
                      attr={attr}
                      onClick={() => {
                        onAttributePicked(attr);
                        setSearchString("");
                        setAttributePickerOpen(false);
                      }}
                      onMouseEnter={() => {
                        setSelectedIndex(index);
                        setSelectedGroup("person");
                      }}
                    />
                  ))}
                </div>
              )}
              {filteredOrgAttrs.length > 0 && (
                <div>
                  <div className="p-2 text-[10px] leading-5 uppercase font-normal tracking-widest text-body-text">
                    Organization Attributes
                  </div>

                  {filteredOrgAttrs.map((attr, index) => (
                    <AttrRow
                      isSelected={
                        selectedIndex === index && selectedGroup === "org"
                      }
                      attr={attr}
                      onClick={() => {
                        onAttributePicked(attr);
                        setSearchString("");
                        setAttributePickerOpen(false);
                      }}
                      onMouseEnter={() => {
                        setSelectedIndex(index);
                        setSelectedGroup("org");
                      }}
                    />
                  ))}
                </div>
              )}
              {filteredEventNames.length > 0 && (
                <div>
                  <div className="p-2 text-[10px] leading-5 uppercase font-normal tracking-widest text-body-text">
                    Events
                  </div>

                  {filteredEventNames.map((eventName, index) => (
                    <EventRow
                      isSelected={
                        selectedIndex === index && selectedGroup === "events"
                      }
                      eventName={eventName}
                      onClick={() => {
                        onAttributePicked(eventName);
                        setSearchString("");
                        setAttributePickerOpen(false);
                      }}
                      onMouseEnter={() => {
                        setSelectedIndex(index);
                        setSelectedGroup("events");
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}

interface AttrRowProps {
  isSelected: boolean;
  attr: AttributeType;
  onClick: () => void;
  onMouseEnter: () => void;
}

function AttrRow({ isSelected, attr, onClick, onMouseEnter }: AttrRowProps) {
  const IconComponent = attrIcon(attr.source, attr.aType);
  return (
    <div
      key={`${attr.name}${attr.source}`}
      className={cx(
        "flex cursor-pointer px-2 py-2 text-sm leading-6 text-body-text-lighter align-middle items-center",
        isSelected && "bg-slate-10 rounded-md",
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <div className="flex h-4 w-4 mr-3 rounded-lg">
        <IconComponent className="text-body-text-lightest" />
      </div>
      <div className="flex-1 overflow-hidden overflow-ellipsis">
        {attr.displayName ?? attr.name}
      </div>
    </div>
  );
}

interface EventRowProps {
  isSelected: boolean;
  eventName: string;
  onClick: () => void;
  onMouseEnter: () => void;
}

function EventRow({
  isSelected,
  eventName,
  onClick,
  onMouseEnter,
}: EventRowProps) {
  const IconComponent = sourceIcon(eventName);
  return (
    <div
      key={eventName}
      className={cx(
        "flex cursor-pointer px-2 py-2 text-sm leading-6 text-body-text-lighter align-middle items-center",
        isSelected && "bg-slate-10 rounded-md",
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <div className="flex h-4 w-4 mr-3 rounded-lg">
        <IconComponent className="text-body-text-lightest p-.5" />
      </div>
      <div className="flex-1 overflow-hidden overflow-ellipsis">
        {eventName}
      </div>
    </div>
  );
}
