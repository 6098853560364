import React, { useState, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useQuery, useMutation } from "@apollo/client";
import SelectInput from "../../patterns/forms/SelectInput";
import { GET_SEGMENT_SOURCES } from "../../graphql/queries";
import SkeletonScreen from "../../patterns/Skeleton";
import { CREATE_SEGMENT_RESOURCES_FOR_INTEGRATION } from "../../graphql/mutations";

export default function SegmentExtraDetails() {
  const { data, loading, error } = useQuery(GET_SEGMENT_SOURCES);
  const [source, setSource] = useState("-1");
  const [createSegmentResourcesForIntegration, { loading: mutationLoading }] =
    useMutation(CREATE_SEGMENT_RESOURCES_FOR_INTEGRATION);

  const sourceOptions = useMemo(
    () =>
      (data?.segmentSources || []).map((s) => ({
        label: s.name,
        value: s.id,
      })),
    [data],
  );

  const onClick = async () => {
    try {
      await createSegmentResourcesForIntegration({
        variables: { sourceId: source },
      });
      toast.success("Segment resources successfully created");
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <div className="text-sm my-8">
      {!error && loading && <SkeletonScreen />}
      {error && (
        <p>
          Error loading Segment sources. Please check your access_token and try
          again.
        </p>
      )}
      {!error && !loading && (
        <SelectInput
          label="Choose the source of segment events"
          placeholder="Choose a source"
          options={sourceOptions}
          required={true}
          onChange={setSource}
        />
      )}
      {source && source !== "-1" && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={onClick}
          disabled={mutationLoading}
        >
          {mutationLoading
            ? "Processing..."
            : "Configure Destination in Segment"}
        </button>
      )}
    </div>
  );
}
