import { Dispatch } from "react";
import SelectInput from "../patterns/forms/SelectInput";
import OptionsAndDetail from "../campaign_form/options-and-details";
import { MessagingSenderType } from "../__generated__/graphql";
import { EmailAction } from "../types/BackendTypes";
import TextInput from "../patterns/forms/TextInput";
import Editor from "../campaign_form/editor";
import { ReferralUpdate } from "../referral/referral_form/reducer";

interface Props {
  dispatch: Dispatch<ReferralUpdate>;
  message: EmailAction;
  isEditable: boolean;
  senders?: MessagingSenderType[];
  actionList: "actionsOnReferred";
}

export default function MessageEditor({
  dispatch,
  message,
  isEditable,
  senders,
  actionList,
}: Props) {
  return (
    <OptionsAndDetail
      left={
        <>
          <div className="pb-4">
            <SelectInput
              label={"Send from"}
              description={"Senders can be managed in Integrations/Messaging"}
              placeholder="Pick a sender"
              required={true}
              value={message.messages[0].sender || ""}
              options={senders.map((s) => ({
                label: s.displayName,
                value: s.id,
              }))}
              disabled={!isEditable}
              onChange={(s) =>
                dispatch({
                  type: "UpdateActionOfType",
                  actionType: "email",
                  actionList: actionList,
                  action: {
                    ...message,
                    messages: [
                      {
                        ...message.messages[0],
                        sender: s,
                      },
                    ],
                    enabled: true,
                  },
                })
              }
            />
          </div>
        </>
      }
      right={
        <>
          <TextInput
            placeholder="Subject"
            label="Enter E-mail subject"
            value={message.messages[0].subject || ""}
            required={true}
            disabled={!isEditable}
            onChange={(s) =>
              dispatch({
                type: "UpdateActionOfType",
                actionType: "email",
                actionList: actionList,
                action: {
                  ...message,
                  messages: [
                    {
                      ...message.messages[0],
                      subject: s,
                    },
                  ],
                  enabled: true,
                },
              })
            }
          />
          <Editor
            label="Message to send"
            value={message.messages[0].body || ""}
            editable={isEditable}
            onChange={(s) =>
              dispatch({
                type: "UpdateActionOfType",
                actionType: "email",
                actionList: actionList,
                action: {
                  ...message,
                  messages: [
                    {
                      ...message.messages[0],
                      body: s,
                    },
                  ],
                  enabled: true,
                },
              })
            }
          />
        </>
      }
    />
  );
}
