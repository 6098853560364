import React from "react";

export default function HubspotLogo() {
  return (
    <svg viewBox="0 0 48 50" width="100%" height="100%" className="opacity-60">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.0482 36.0306C31.3111 36.0306 28.2816 33.0345 28.2816 29.3393C28.2816 25.6434 31.3111 22.6474 35.0482 22.6474C38.7853 22.6474 41.8147 25.6434 41.8147 29.3393C41.8147 33.0345 38.7853 36.0306 35.0482 36.0306ZM37.0739 16.4563V10.5034C38.6451 9.76949 39.7455 8.19764 39.7455 6.37345V6.23604C39.7455 3.71838 37.6627 1.65854 35.117 1.65854H34.9787C32.433 1.65854 30.3502 3.71838 30.3502 6.23604V6.37345C30.3502 8.19764 31.4506 9.77013 33.0218 10.504V16.4563C30.6826 16.8139 28.5452 17.7681 26.7825 19.1723L10.2557 6.4582C10.3647 6.04405 10.4413 5.6177 10.442 5.16952C10.4446 2.31861 8.11117 0.00385592 5.22717 3.34484e-06C2.34447 -0.00320713 0.00324967 2.30513 3.38308e-06 5.15667C-0.0032429 8.00822 2.33019 10.323 5.21418 10.3262C6.15366 10.3275 7.02366 10.0636 7.78589 9.63465L24.0426 22.142C22.6604 24.2057 21.8501 26.6778 21.8501 29.3393C21.8501 32.1253 22.7402 34.7027 24.2439 36.8223L19.3005 41.7118C18.9096 41.5956 18.5045 41.5147 18.0747 41.5147C15.7055 41.5147 13.7844 43.414 13.7844 45.757C13.7844 48.1007 15.7055 50 18.0747 50C20.4444 50 22.3649 48.1007 22.3649 45.757C22.3649 45.3332 22.2831 44.9319 22.1656 44.5454L27.0558 39.7091C29.2756 41.3844 32.0402 42.3912 35.0482 42.3912C42.3374 42.3912 48.2456 36.5475 48.2456 29.3393C48.2456 22.8137 43.3983 17.4226 37.0739 16.4563Z"
        fill="#ff5c35"
      />
    </svg>
  );
}
