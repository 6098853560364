import { Dispatch } from "react";
import { IncentiveAction } from "../../../../types/BackendTypes";
import { CampaignDefinitionEditAction } from "../../../reducer";
import SelectInput from "../../../../patterns/forms/SelectInput";

interface Props {
  action: IncentiveAction;
  dispatch: Dispatch<CampaignDefinitionEditAction>;
}

export default function AutoClaimToggle({ action, dispatch }: Props) {
  const incentive = action.incentives[0];
  return (
    <SelectInput
      label="Auto apply this incentive on event completion"
      value={String(incentive.config.auto_claim)}
      placeholder="--"
      options={[
        { label: "Yes, apply it", value: "true" },
        { label: "I'll do it manually", value: "false" },
      ]}
      required={true}
      onChange={(s) => {
        dispatch({
          type: "UpdateAfterAction",
          action: {
            ...action,
            incentives: [
              {
                ...incentive,
                config: {
                  ...incentive.config,
                  auto_claim: s === "true" ? true : false,
                },
              },
            ],
          } as IncentiveAction,
        });
      }}
    />
  );
}
