import CloseButton from "../patterns/atoms/CloseButton";
import SkeletonScreen from "../patterns/Skeleton";
import CardMenu from "../patterns/CardMenu";
import MixPanelLogo from "../patterns/symbols/MixPanelLogo";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import SectionHeader from "../patterns/SectionHeader";
import "../buttons.css";

export default function StoryBook() {
  return (
    <div className="p-8">
      <AttributesEmpty />
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4">
        <CardMenu>
          <div className="w-auto h-32 rounded-t-lg bg-grey-30 p-4">
            <MixPanelLogo />
          </div>
          <div className="px-4 pt-4 pb-3">
            {" "}
            <SectionHeader
              title="Title"
              description="Reply to this message and let us know how you've been using these features."
            />
          </div>
        </CardMenu>
        <CardMenu>
          <div className="w-auto h-32 rounded-t-lg bg-grey-30 p-4">
            <MixPanelLogo />
          </div>
          <div className="px-4 pt-4 pb-3">
            {" "}
            <SectionHeader
              title="Title"
              description="Reply to this message and let us know how you've been using these features."
            />
          </div>
        </CardMenu>
        <CardMenu>
          <div className="w-auto h-32 rounded-t-lg bg-grey-30 p-4">
            <MixPanelLogo />
          </div>
          <div className="px-4 pt-4 pb-3">
            {" "}
            <SectionHeader
              title="Title"
              description="Reply to this message and let us know how you've been using these features."
            />
          </div>
        </CardMenu>
      </div>

      <CloseButton />
      <SkeletonScreen />
    </div>
  );
}
