import { format } from "date-fns";

export const DAY_IN_SECONDS = 24 * 60 * 60;

function coerceToDate(s: string | Date) {
  return typeof s === "string" ? new Date(s) : s;
}

export function formatLong(s: string | Date) {
  return format(coerceToDate(s), "d MMMM, yyyy");
}
