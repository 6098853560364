import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BehaviorType } from "../../__generated__/graphql";
import {
  TrophyIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";

import { formatStats } from "../../lib/behaviors";
import { SessionContext } from "../../SessionContext";
import FilterBuilder from "../../filter_builder";
import EmptyState from "../../patterns/EmptyState";
import BehaviorsEmpty from "../../patterns/illustrations/BehaviorsEmpty";
import EnteredCompletedBarChart from "../graphs/EnteredCompletedBarChart";

export default function Objectives({
  behavior,
  subBehaviors,
  onCreateCampaign,
}: {
  behavior: BehaviorType;
  subBehaviors?: BehaviorType[];
  onCreateCampaign?: (b: BehaviorType) => void;
}) {
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const behaviorStats = formatStats(
    behavior,
    session.orgCount,
    session.peopleCount,
  );
  const hasData = behaviorStats.entered.count > 0;

  return (
    <>
      {hasData ? (
        <div className="mb-8">
          <div className="flex w-full overflow-hidden rounded-xl mb-2 shadow-sm">
            <div className="flex flex-col flex-[3] bg-grey-80">
              <div className="pl-6 pt-5 text-md font-bold flex flex-row gap-x-2 items-center">
                <div>{behavior.name}</div>
                <ArrowTopRightOnSquareIcon
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => {
                    navigate(`/behaviors/${behavior.id}?view=overview`);
                  }}
                />
                {onCreateCampaign && (
                  <TrophyIcon
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => onCreateCampaign(behavior)}
                  />
                )}
              </div>
              <div className="flex-1 p-6 h-56">
                <EnteredCompletedBarChart behavior={behavior} />
              </div>
            </div>
            <div className="flex flex-col flex-1 bg-grey-50 py-2">
              <div className="h-full border-b-1 border-grey-200 border-dashed py-4 mx-4">
                <StatBlock
                  title="Total Entered:"
                  value={behaviorStats.entered.count.toString()}
                  subtext={`(${behaviorStats.entered.percentage})`}
                />
              </div>
              <div className="h-full border-b-1 border-rule-color border-dashed py-4 mx-4">
                <StatBlock
                  title="Total Completed:"
                  value={behaviorStats.completed.count.toString()}
                  subtext={`(${behaviorStats.completed.percentage})`}
                />
              </div>
              <div className="h-full py-4 mx-4">
                <StatBlock
                  title="Avg Time to Completion:"
                  value={behaviorStats.averageTimeToCompletion}
                  subtext="days"
                />
              </div>
            </div>
          </div>

          {subBehaviors && (
            <div className="flex flex-col gap-2">
              {subBehaviors.map((behavior) => {
                return <SubBehaviorBlock behavior={behavior} />;
              })}
            </div>
          )}
        </div>
      ) : (
        <div className="bg-grey-50 p-6 mb-6 rounded-lg">
          <div className="text-md font-bold mb-4">{behavior.name}</div>
          <EmptyState
            title="No Data"
            description="No data available for this Lifecycle Stage"
            icon={<BehaviorsEmpty />}
            fullHeight={false}
          ></EmptyState>
        </div>
      )}
    </>
  );
}

function SubBehaviorBlock({ behavior }: { behavior: BehaviorType }) {
  const session = useContext(SessionContext);
  const subBehaviorStats = formatStats(
    behavior,
    session.orgCount,
    session.peopleCount,
  );
  return (
    <>
      <FilterBuilder
        filterDoc={JSON.parse(behavior.observing).criteria[0].condition.filter}
        readonly={true}
        onChange={() => {}}
      />
      <div className="flex flex-wrap gap-2 -mt-2">
        <div className="flex-1 p-4 bg-grey-80 rounded-lg shadow-sm w-12">
          <StatBlock
            title="Total Entered:"
            value={subBehaviorStats.entered.count.toString()}
            subtext={`(${subBehaviorStats.entered.percentage})`}
          />
        </div>
        <div className="flex-1 p-4 bg-grey-80 rounded-lg shadow-sm w-12">
          <StatBlock
            title="Total Completed:"
            value={subBehaviorStats.completed.count.toString()}
            subtext={`(${subBehaviorStats.completed.percentage})`}
          />
        </div>
        <div className="flex-1 p-4 bg-grey-80 rounded-lg shadow-sm w-12">
          <StatBlock
            title="Avg Time to Completion:"
            value={subBehaviorStats.averageTimeToCompletion}
            subtext="days"
          />
        </div>
      </div>
    </>
  );
}

function StatBlock({
  title,
  value,
  subtext,
}: {
  title: string;
  value: string;
  subtext: string;
}) {
  return (
    <div className="text-body-text-lightest">
      <div className="text-xs">{title}</div>
      <div className="text-2xl font-bold my-2 text-body-text">{value}</div>
      <div className="text-xs">{subtext}</div>
    </div>
  );
}
