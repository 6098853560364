import React from "react";
import { TargetMemberTypeChoices } from "../../__generated__/graphql";
import SettingsBlock from "../SettingsBlock";
import BuilderSectionHeader from "./builder-section-header";
import FilterBuilder from "../../filter_builder";
import {
  hasValidCriteria,
  completionCriteriaDescription,
  isAttrCriteria,
  isEventCriteria,
} from "../../campaign/lib";
import SectionProps from "./section_props";

export default function CompletionCriteriaSection({
  data,
  campaignDefinition,
  openDrawer,
}: SectionProps) {
  const entityName = data.campaignById.isBehavior ? "behavior" : "campaign";
  const hasEventCriteria = isEventCriteria(
    campaignDefinition.completion_criteria,
  );
  const isOrgCampaign =
    data.campaignById.targetMemberType === TargetMemberTypeChoices.Org;

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Goal"
        description={
          hasEventCriteria
            ? `The end goal, or actions you want ${isOrgCampaign ? "a member of the organization" : "customers"} to take`
            : `Complete the ${entityName} when the ${isOrgCampaign ? "organization" : "person"} matches this filter`
        }
        isValid={hasValidCriteria(campaignDefinition.completion_criteria)}
        onClick={() => {}}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {isAttrCriteria(campaignDefinition.completion_criteria) ? (
          <div className="">
            <FilterBuilder
              filterDoc={
                campaignDefinition.completion_criteria.condition.filter
              }
              readonly={true}
              onChange={() => {}}
            />
          </div>
        ) : (
          hasValidCriteria(campaignDefinition.completion_criteria) && (
            <SettingsBlock
              title={completionCriteriaDescription(
                campaignDefinition.completion_criteria,
              )}
              section="events"
              onClick={() => {}}
            />
          )
        )}
      </div>
    </div>
  );
}
