export default function Success() {
  return (
    <div className="items-center mx-auto block">
      <svg className="items-center mx-auto">
        <mask
          id="mask0_2587_202279"
          maskUnits="userSpaceOnUse"
          x="0"
          y="1"
          width="180"
          height="560"
        >
          <path
            d="M167.143 1H12.8571C5.75634 1 0 6.08477 0 12.3571V148.643C0 154.915 5.75634 160 12.8571 160H167.143C174.244 160 180 154.915 180 148.643V12.3571C180 6.08477 174.244 1 167.143 1Z"
            fill="url(#paint0_linear_2587_202279)"
          />
        </mask>
        <g mask="url(#mask0_2587_202279)">
          <ellipse
            cx="90.2032"
            cy="109.5"
            rx="88.9944"
            ry="88.5"
            fill="#E7ECEB"
          />
          <mask
            id="mask1_2587_202279"
            maskUnits="userSpaceOnUse"
            x="2"
            y="21"
            width="178"
            height="177"
          >
            <ellipse
              cx="91.0056"
              cy="109.5"
              rx="88.9944"
              ry="88.5"
              fill="#E7ECEB"
            />
          </mask>
          <g mask="url(#mask1_2587_202279)">
            <path
              d="M92.4302 140.06H92.5744L97.9219 120.006H105.963V117H-20.7835V120.006H-11.5621L-26.6146 178.865H-30.1667V181H16.2569V178.864H12.1315C7.22537 170.511 5.80101 160.798 8.12571 151.544L11.0166 140.06H64.7238L54.799 178.864H51.2504V180.998H97.6739V178.864H93.545C88.6389 170.511 87.2146 160.798 89.5393 151.544L92.4302 140.06Z"
              fill="#F3F3F3"
            />
            <g opacity="0.6">
              <path
                d="M87.2046 151.545L90.0955 140.062H64.7226L54.796 178.861H91.2086C86.2427 170.484 84.8641 160.847 87.2046 151.545Z"
                fill="#030F1C"
              />
            </g>
            <g opacity="0.6">
              <path
                d="M5.79122 151.544L13.8169 119.651H-11.4717L-26.6508 178.997H9.87087C4.90143 170.615 3.44894 160.846 5.79122 151.544Z"
                fill="#030F1C"
              />
            </g>
            <g opacity="0.6">
              <path
                d="M97.3357 122.211H-12.1219L-11.554 120.012H97.9195L97.3357 122.211V122.211Z"
                fill="#030F1C"
              />
            </g>
            <g opacity="0.6">
              <rect
                x="-20.7844"
                y="117"
                width="39.4231"
                height="3.00619"
                fill="#030F1C"
              />
            </g>
          </g>
          <path
            d="M135.931 171.519C135.569 169.927 135.299 168.38 135.206 167.029C134.799 161.194 134.211 146.901 134.205 146.762C134.192 145.901 134.876 145.187 135.748 145.152C136.619 145.117 137.359 145.774 137.417 146.633C137.423 146.776 138.008 161.024 138.411 166.815C138.507 168.191 138.817 169.831 139.228 171.522H139.843C139.577 167.926 139.367 164.19 139.332 161.145C139.209 152.381 138.634 129.887 138.627 129.66C138.613 129.093 138.906 128.562 139.395 128.266C139.885 127.97 140.498 127.955 141.002 128.226C141.506 128.497 141.826 129.013 141.84 129.58C141.84 129.806 142.429 152.319 142.546 161.103C142.578 163.53 142.725 166.414 142.914 169.308V117.708C142.898 117.131 143.201 116.591 143.705 116.297C144.209 116.004 144.834 116.004 145.338 116.297C145.842 116.591 146.144 117.131 146.128 117.708V164.131C146.295 161.515 146.417 158.946 146.447 156.745C146.569 147.961 147.146 125.446 147.152 125.221C147.159 124.799 147.337 124.398 147.646 124.108C147.955 123.817 148.369 123.662 148.795 123.676C149.221 123.686 149.626 123.863 149.919 124.168C150.213 124.473 150.372 124.881 150.361 125.302C150.361 125.528 149.774 148.022 149.657 156.786C149.599 161.108 149.191 166.803 148.788 171.519H148.824C149.41 167.866 149.953 163.815 150.11 160.755C150.479 153.429 151.167 135.443 151.175 135.262C151.209 134.386 151.955 133.703 152.842 133.737C153.729 133.77 154.42 134.507 154.386 135.383C154.379 135.564 153.697 153.571 153.321 160.917C153.162 163.992 152.643 167.924 152.082 171.522H153.773C153.877 171.523 153.98 171.536 154.08 171.562H134.027C134.127 171.536 134.231 171.523 134.334 171.522L135.931 171.519Z"
            fill="#85CEB4"
          />
          <g opacity="0.6">
            <path
              d="M148.795 171.519H145.565C145.972 166.812 146.388 161.067 146.445 156.745C146.568 147.961 147.145 125.446 147.151 125.221C147.159 124.8 147.338 124.399 147.647 124.109C147.955 123.819 148.369 123.662 148.795 123.675C149.684 123.696 150.386 124.425 150.364 125.302C150.364 125.528 149.776 148.022 149.659 156.786C149.602 161.108 149.194 166.802 148.795 171.519Z"
              fill="#030F1C"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M139.848 171.519C139.577 167.925 139.367 164.19 139.332 161.145C139.209 152.38 138.634 129.887 138.627 129.66C138.605 128.784 139.305 128.055 140.193 128.033C141.08 128.01 141.817 128.702 141.84 129.578C141.84 129.805 142.429 152.319 142.546 161.103C142.586 164.155 142.806 167.918 143.074 171.524L139.848 171.519Z"
              fill="#030F1C"
            />
          </g>
          <path
            d="M111.695 41.8857C111.134 39.0754 110.236 36.9825 106.588 39.4939C106.588 39.4939 106.555 36.71 105.264 35.6108C103.973 34.5115 95.8169 29.6139 95.5049 37.6633C95.5049 37.6633 95.3552 44.1298 95.3552 45.7443C95.3552 47.3587 97.7187 52.8281 99.6833 52.8281C100.15 52.8202 100.613 52.7487 101.059 52.6156C100.775 54.8488 100.064 57.0083 98.9646 58.9793L111.033 56.6505C111.033 56.6505 108.947 53.4375 108.512 47.1276C110.352 45.5094 112.106 43.9431 111.695 41.8857Z"
            fill="#CAAB8E"
          />
          <path
            fillRule="evenodd"
            clip-rule="evenodd"
            d="M117.076 39.7456C118.82 41.8761 116.653 43.9462 116.653 43.9462C116.653 43.9462 119.883 45.9885 117.868 49.4556C116.495 51.8198 114.011 51.1668 114.011 51.1668C113.125 54.4317 109.97 54.3489 109.97 54.3489C108.908 52.0476 108.512 47.1272 108.512 47.1272C111.246 44.7987 111.105 42.1286 110.736 40.4568C110.366 38.7849 107.83 39.2551 106.984 40.3C106.546 40.841 106.505 41.802 106.472 42.5774C106.441 43.2997 106.417 43.861 106.086 43.7714C105.815 43.6982 105.717 43.0548 105.593 42.2433C105.402 40.9949 105.15 39.3488 104.117 38.7695C102.412 37.8138 98.4775 34.4225 98.4775 34.4225C95.5716 34.7622 95.6319 38.7695 95.6319 38.7695C95.6319 38.7695 93.4823 38.7696 92.7182 36.2249C92.2658 34.7184 93.4823 30.5304 95.6242 28.6496C96.9375 27.4963 98.8172 28.3884 98.8172 28.3884C98.8172 28.3884 99.8123 27.1591 102.589 26.6121C109.485 25.2537 117.076 32.2022 115.491 35.807C114.866 37.228 115.36 37.7851 116.34 38.8904C116.563 39.1416 116.81 39.4212 117.076 39.7456ZM95.3717 44.2122C95.3614 44.8637 95.3549 45.4091 95.3549 45.7441C95.3549 47.3585 97.7184 52.8279 99.683 52.8279C100.149 52.8199 100.612 52.7484 101.059 52.6154C100.999 53.087 100.921 53.5415 100.829 53.9762C99.1045 54.5304 95.1746 55.3667 93.3305 52.2737C91.6392 49.437 94.3562 45.5133 95.3717 44.2122Z"
            fill="#161B36"
          />
          <g opacity="0.6">
            <path
              d="M101.083 52.4367C101.083 52.4367 104.646 50.628 105.778 49.4561C105.841 51.4007 105.164 53.298 103.88 54.772C102.869 55.8979 101.286 56.2249 100.176 56.2994C100.626 55.0502 100.93 53.7542 101.083 52.4367V52.4367Z"
              fill="#030F1C"
            />
          </g>
          <path
            d="M100.053 38.6127C100.214 38.4329 100.437 38.2931 101.103 38.333C101.77 38.373 102.78 38.9922 102.558 39.152C102.336 39.3118 101.669 38.7725 101.144 38.9523C100.618 39.1321 100.317 39.2799 100.14 39.182C99.9181 39.0588 99.9751 38.6991 100.053 38.6127Z"
            fill="#161B36"
          />
          <path
            d="M97.1637 38.4293C96.952 38.3107 96.6961 38.2493 96.0782 38.5C95.4602 38.7506 94.7064 39.6592 94.9689 39.7395C95.2314 39.8198 95.6863 39.0965 96.2421 39.099C96.7979 39.1014 97.1316 39.1451 97.2668 38.9961C97.4369 38.8085 97.2655 38.4863 97.1637 38.4293Z"
            fill="#161B36"
          />
          <g opacity="0.6">
            <path
              d="M98.3144 46.5235C98.0454 46.5278 97.7802 46.4605 97.5465 46.3288C97.3788 46.2405 97.2595 46.083 97.2211 45.899C97.1652 45.5523 97.4799 44.8729 97.8069 44.2069C97.8597 44.0993 97.9029 44.0115 97.928 43.9544C98.3502 42.9955 98.1585 41.1683 98.1565 41.1499C98.152 41.108 98.1703 41.067 98.2047 41.0422C98.2391 41.0174 98.2843 41.0126 98.3232 41.0296C98.3621 41.0467 98.3889 41.0829 98.3934 41.1248C98.4018 41.2021 98.594 43.0322 98.1466 44.0486C98.1205 44.1076 98.0759 44.1985 98.0214 44.3098C97.8221 44.7154 97.4077 45.5594 97.4564 45.8619C97.4867 45.9767 97.5647 46.0734 97.6712 46.1281C97.9876 46.2968 98.359 46.334 98.7032 46.2313C98.7437 46.2184 98.7881 46.2279 98.8197 46.2562C98.8512 46.2845 98.8651 46.3272 98.8561 46.3684C98.8471 46.4095 98.8166 46.4428 98.776 46.4557C98.6266 46.5016 98.4709 46.5245 98.3144 46.5235V46.5235Z"
              fill="#030F1C"
            />
          </g>
          <path
            d="M97.8554 48.8406C97.8348 48.7696 97.8437 48.6935 97.8801 48.6291C97.9165 48.5647 97.9774 48.5173 98.0492 48.4974C98.4067 48.3951 99.0658 48.2026 99.5678 48.0336C100.091 47.8285 100.6 47.5891 101.091 47.3171C101.177 47.2711 101.28 47.272 101.365 47.3194C101.45 47.3669 101.504 47.4542 101.508 47.5504C101.542 48.2899 101.452 49.7343 100.276 50.0287C98.846 50.3867 98.0971 49.6216 97.8554 48.8406Z"
            fill="#161B36"
          />
          <path
            d="M98.4773 49.7628C98.4773 49.7628 99.7081 48.0629 101.466 48.4512C101.466 48.4512 101.469 49.7788 100.336 50.078C99.1151 50.4001 98.4773 49.7628 98.4773 49.7628Z"
            fill="#EC5A5A"
          />
          <path
            d="M101.688 40.913C101.459 40.8627 101.226 40.8349 100.992 40.8301C100.741 40.8301 100.538 41.161 100.538 41.5692C100.538 41.9774 100.741 42.3083 100.992 42.3083C101.243 42.3083 101.447 41.9774 101.447 41.5692C101.45 41.39 101.406 41.2131 101.319 41.0561C101.501 41.062 101.682 41.0893 101.858 41.1372C102.029 41.1861 102.04 40.9944 101.688 40.913Z"
            fill="#161B36"
          />
          <path
            d="M97.1451 41.2196C96.934 41.1731 96.7189 41.1457 96.5028 41.1381C96.5 41.138 96.4974 41.1367 96.4946 41.1367C96.4926 41.1367 96.4909 41.1376 96.4889 41.1377C96.4656 41.137 96.4495 41.1367 96.4495 41.1367L96.4474 41.1445C96.2188 41.1833 96.04 41.4939 96.04 41.8758C96.04 42.284 96.2435 42.6149 96.4946 42.6149C96.7456 42.6149 96.9491 42.284 96.9491 41.8758C96.9524 41.6974 96.9085 41.5212 96.8218 41.3647C96.9888 41.3734 97.1543 41.3999 97.3155 41.4438C97.486 41.4927 97.4977 41.301 97.1451 41.2196Z"
            fill="#161B36"
          />
          <path
            d="M109.658 41.2391C109.084 41.0471 108.53 41.5617 108.11 42.1442L108.107 42.1487C107.818 42.5617 107.571 43.0017 107.368 43.4624C107.343 43.5224 107.371 43.5914 107.432 43.6167C107.493 43.6419 107.562 43.6137 107.588 43.5537C107.777 43.1202 108.008 42.706 108.278 42.3168C108.524 42.2746 108.777 42.3205 108.992 42.4462C109.206 42.6033 109.342 42.8423 109.367 43.1042C109.377 43.161 109.426 43.2027 109.485 43.2028C109.491 43.2028 109.498 43.2023 109.504 43.2012C109.535 43.1961 109.563 43.179 109.582 43.1536C109.6 43.1282 109.608 43.0966 109.602 43.0657C109.568 42.7384 109.393 42.4413 109.123 42.2492C108.927 42.1265 108.699 42.0636 108.468 42.0681C108.815 41.642 109.212 41.3378 109.581 41.4624C109.622 41.4761 109.666 41.4674 109.698 41.4396C109.73 41.4118 109.745 41.3691 109.737 41.3277C109.728 41.2864 109.698 41.2526 109.658 41.2391L109.658 41.2391Z"
            fill="#0B122D"
          />
          <path
            d="M100.882 52.6454C100.825 52.6454 100.777 52.6065 100.765 52.552C100.753 52.4976 100.782 52.4425 100.833 52.4199C100.873 52.4023 104.867 50.636 106.552 48.5007C106.578 48.4674 106.62 48.4504 106.663 48.4562C106.705 48.462 106.741 48.4897 106.757 48.5288C106.773 48.5679 106.766 48.6125 106.74 48.6457C105.017 50.8278 101.096 52.5626 100.93 52.6353C100.915 52.642 100.898 52.6454 100.882 52.6454V52.6454Z"
            fill="#0B122D"
          />
          <path
            d="M121.26 161.212C121.186 150.976 121.53 144.255 121.341 138.702C121.05 130.168 122.106 114.84 121.519 101.107L90.794 101.162C90.794 101.162 87.9623 136.477 88.109 141.882C88.2403 146.719 88.7146 175.339 88.786 180.687C89.7475 181.902 95.1267 181.904 96.4463 180.824C96.9222 180.434 98.6405 160.821 99.5358 155.471C100.563 149.335 100.281 143.991 100.929 140.139C101.518 136.642 105.125 126.397 105.125 126.397L114.414 180.979C114.51 181.912 120.845 181.95 121.885 180.354C122.221 179.84 121.315 168.882 121.26 161.212Z"
            fill="#91A8A3"
          />
          <path
            d="M101.146 142.709C101.093 142.708 101.048 142.671 101.038 142.619C100.315 138.774 98.6619 136.821 98.6453 136.801C98.6057 136.755 98.611 136.686 98.6573 136.647C98.7035 136.607 98.7731 136.612 98.8127 136.658C98.8297 136.678 100.521 138.676 101.255 142.579C101.261 142.611 101.252 142.644 101.231 142.669C101.21 142.694 101.179 142.708 101.146 142.709V142.709Z"
            fill="#0B122D"
          />
          <g opacity="0.6">
            <path
              d="M105.125 126.396L102.189 115.513C102.189 115.513 100.651 121.407 101.311 127.784C101.971 134.161 101.588 137.331 101.588 137.331L105.125 126.396Z"
              fill="#030F1C"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M90.7941 101.161L90.4053 106.278C102.667 111.118 121.732 107.659 121.732 107.659L121.492 101.215L90.7941 101.161Z"
              fill="#030F1C"
            />
          </g>
          <path
            d="M120.967 97.5839C119.721 92.8666 119.892 90.4743 120.038 87.6986C120.185 84.923 118.866 65.5968 121.065 61.9873C121.163 60.429 118.234 52.5552 116.47 53.6383C112.891 55.8359 110.699 55.6568 110.699 55.6568L109.694 51.9072C108.549 54.5767 106.232 56.571 103.414 57.3125C101.532 56.4592 100.676 54.342 100.676 54.342L99.062 56.2411C99.062 56.2411 91.6787 59.4064 90.5052 60.9646C89.3317 62.5229 91.5851 80.3699 91.6298 85.6534C91.6588 89.0747 91.3107 93.033 91.2142 97.4865C91.4362 100.538 89.1606 103.74 90.7986 105.083C96.0849 109.417 121.945 105.083 121.945 105.083C121.904 102.555 121.576 100.039 120.967 97.5839Z"
            fill="#91A8A3"
          />
          <path
            d="M134.202 19.4526C131.709 20.3291 129.202 19.9152 127.553 18.5273C127.546 18.5251 125.084 39.4495 124.668 41.5922C124.23 43.8538 121.683 49.813 116.539 53.594C115.272 54.3905 113.898 55.0048 112.458 55.4187C112.458 55.4187 108.226 62.6445 106.051 66.2965C105.436 67.2348 104.741 68.1183 103.973 68.9369L103.725 60.5626L104.575 59.2366C104.575 59.2366 104.106 57.8843 103.414 57.3121L101.442 59.5402L101.808 60.5626L100.642 70.8584C97.817 65.7962 99.062 56.2408 99.062 56.2408C95.0721 56.0461 93.3622 55.4707 90.276 53.2746V53.2746C87.7993 51.4393 85.2874 48.9079 82.5096 45.382C82.194 44.9824 81.9518 44.5304 81.7941 44.0468C80.9321 41.3962 78.8624 36.1419 78.3685 34.2713C78.06 33.1042 74.4787 21.3288 74.1825 20.2074C72.0189 22.1795 69.9652 22.8369 67.8749 21.5221C68.4925 24.7548 70.9919 38.4494 71.7871 41.5133C72.0319 42.4556 72.2833 43.373 72.5414 44.2655C73.3486 47.0683 74.2154 49.6251 75.0756 51.9292C76.8885 55.5952 79.9763 59.3821 82.9141 62.5143C85.1793 64.9213 87.5713 67.2067 90.08 69.3609C90.2137 69.4742 90.3173 69.5616 90.391 69.623C90.6199 73.2327 91.5005 82.8896 91.6079 84.7874C91.7347 87.0262 91.3273 94.1644 91.2387 96.5363C91.1501 98.9082 90.6585 100.351 90.1733 102.916C89.688 105.481 92.2408 106.281 92.2408 106.281C95.5627 107.42 100.562 107.009 100.562 107.009C107.727 106.882 114.873 106.238 121.945 105.083C121.945 105.083 121.614 100.385 120.621 96.1724C119.628 91.9594 120.05 80.6783 120.475 76.8718C120.604 75.7094 120.502 72.9001 120.297 69.5595C120.418 69.3974 132.127 53.6997 133.483 46.7015C134.32 42.3823 134.207 19.4482 134.202 19.4526Z"
            fill="#50746C"
          />
          <g opacity="0.6">
            <path
              d="M100.676 54.3418C100.676 54.3418 101.532 56.459 103.414 57.3122L100.871 60.1853L99.062 56.2409L100.676 54.3418Z"
              fill="#91A8A3"
            />
          </g>
          <g opacity="0.6">
            <path
              d="M109.694 51.9072C108.549 54.5767 106.232 56.571 103.414 57.3124C104.538 58.3838 104.832 60.1855 104.832 60.1855L110.699 55.6568L109.694 51.9072Z"
              fill="#91A8A3"
            />
          </g>
          <path
            d="M93.7812 101.103C93.7618 101.103 93.7429 101.098 93.7261 101.088C91.802 99.9826 91.1354 97.616 91.1079 97.5157C91.0975 97.4778 91.1082 97.4373 91.136 97.4095C91.1637 97.3816 91.2043 97.3706 91.2424 97.3806C91.2805 97.3906 91.3103 97.42 91.3207 97.4579C91.3272 97.4816 91.9923 99.8382 93.8364 100.898C93.8797 100.923 93.9008 100.973 93.8878 101.022C93.8749 101.07 93.8311 101.103 93.7812 101.103Z"
            fill="#213739"
          />
          <g opacity="0.6">
            <path
              d="M104.832 60.1853C104.832 60.1853 113.12 59.9596 114.366 54.7256C112.28 55.7532 110.699 55.6566 110.699 55.6566L104.832 60.1853Z"
              fill="#030F1C"
            />
          </g>
          <path
            d="M90.8537 74.7966C90.7992 74.7965 90.753 74.7568 90.7448 74.7032C90.0093 69.8402 90.3582 66.3221 90.3618 66.2871C90.368 66.2267 90.4222 66.1828 90.4828 66.1891C90.5434 66.1953 90.5875 66.2493 90.5813 66.3096C90.5776 66.3443 90.2319 69.8368 90.9629 74.6704C90.972 74.7304 90.9306 74.7863 90.8703 74.7954C90.8648 74.7962 90.8593 74.7966 90.8537 74.7966Z"
            fill="#213739"
          />
          <g opacity="0.6">
            <path
              d="M120.294 69.5638L125.74 61.7432C124.296 62.3387 117.492 66.2618 116.099 69.4179C114.594 72.8262 115.28 77.6946 116.862 84.2916C118.873 92.679 115.731 98.2549 112.615 101.542C109.969 104.332 104.429 105.077 100.501 107.003C109.45 107.174 121.954 105.081 121.954 105.081C121.954 105.081 121.994 101.446 120.629 96.0887C119.494 91.6324 120.005 84.7486 120.248 79.663C120.366 76.1012 120.779 76.7275 120.294 69.5638Z"
              fill="#030F1C"
            />
          </g>
          <path
            fillRule="evenodd"
            clip-rule="evenodd"
            d="M133.959 8.12397C133.959 8.12397 134.682 7.22907 135.434 7.90359C135.768 8.20403 135.815 9.62644 135.515 11.3689L135.514 11.3706C135.508 11.408 135.5 11.4911 135.489 11.612C135.399 12.5812 135.083 15.9843 133.409 17.8459C133.409 17.8459 133.401 18.5247 133.357 19.3025L133.347 19.7015C131.465 20.1449 129.632 19.8702 128.227 19.012L128.26 18.4544C128.288 17.9435 128.285 17.4313 128.251 16.9207C128.251 16.9207 127.426 14.123 126.924 12.4713C126.924 12.4713 126.115 10.4265 126.474 9.59377C126.713 9.09037 127.185 8.7361 127.736 8.6465C127.736 8.6465 126.858 2.96453 127.778 1.36194C128.5 0.101531 128.849 2.34274 129.24 4.85334C129.352 5.57318 129.467 6.31516 129.596 7.0031C129.614 6.79617 129.983 5.943 131.048 5.56199C131.915 5.25116 131.807 7.22314 131.807 7.22314C131.807 7.22314 132.717 5.56612 133.4 5.84028C134.162 6.14631 133.959 8.12397 133.959 8.12397ZM70.98 2.87269C72.192 4.26913 72.4279 10.0133 72.4279 10.0133C72.9865 9.99548 73.5181 10.2527 73.8493 10.701C74.3632 11.4493 73.9637 13.611 73.9637 13.611C73.9058 14.1853 73.9003 14.7657 73.8949 15.3316C73.8842 16.4577 73.8741 17.526 73.4526 18.3725C73.459 18.6072 73.485 18.841 73.5303 19.0715L73.8945 20.46C72.2737 21.8548 70.7112 22.488 69.1451 22.081L69.0255 21.3061C68.8375 20.4453 68.7012 19.7531 68.7012 19.7531C67.3393 18.872 66.7784 17.5682 66.138 16.0798C66.0088 15.7793 65.8762 15.4712 65.7332 15.1576L65.7321 15.1575C65.5804 14.8258 65.4435 14.4968 65.3219 14.1769L65.3204 14.1753C64.6898 12.5229 64.4612 11.118 64.7307 10.7592C65.3385 9.95321 66.221 10.6928 66.221 10.6928C66.221 10.6928 65.602 8.7281 66.328 8.34474C67.1842 7.89258 68.1581 9.39655 68.1581 9.39655C68.1581 9.39655 67.8681 7.54781 68.7782 7.68682C69.8976 7.85651 70.2279 8.55728 70.2855 8.75688C70.279 8.05713 70.2489 7.30688 70.2198 6.57903C70.1183 4.04045 70.0277 1.77425 70.98 2.87269Z"
            fill="#CAAB8E"
          />
          <path
            d="M73.5437 12.4277C73.5093 12.3776 73.4407 12.3648 73.3904 12.399C73.3725 12.4109 71.6074 13.5982 70.5542 13.0864C70.3412 13.0059 70.1819 12.8259 70.1283 12.6055C70.0293 12.0985 70.5822 11.48 70.5878 11.4738C70.6143 11.4447 70.6233 11.4036 70.6112 11.3662C70.599 11.3287 70.5677 11.3006 70.5291 11.2924C70.4905 11.2843 70.4504 11.2973 70.4241 11.3266C70.3982 11.3551 69.7921 12.032 69.9117 12.6469C69.9777 12.9355 70.1818 13.1736 70.4577 13.2839C70.6802 13.3873 70.9235 13.4387 71.1691 13.434C72.246 13.4339 73.4526 12.6226 73.5149 12.5803C73.5651 12.546 73.578 12.4778 73.5437 12.4277Z"
            fill="black"
          />
          <path
            d="M69.3932 11.6633L69.3922 11.662L69.3919 11.661L68.4487 9.83242C68.4308 9.79738 68.3955 9.77456 68.356 9.7726C68.3166 9.77063 68.2791 9.78983 68.2578 9.82291C68.2365 9.85599 68.2345 9.89792 68.2527 9.93281L69.1945 11.7587L69.1946 11.759C69.2015 11.7732 69.872 13.1805 68.9275 13.6919C68.7245 13.812 68.4797 13.8404 68.2545 13.7699C67.7044 13.592 67.3488 12.8748 67.335 12.8466L67.335 12.8464L66.5092 11.0248C66.493 10.9891 66.4588 10.9646 66.4196 10.9607C66.3804 10.9567 66.3421 10.974 66.319 11.0058C66.296 11.0377 66.2918 11.0794 66.308 11.1151L67.1316 12.9318C67.2761 13.3319 67.5409 14.4178 67.053 14.6802C66.9539 14.7355 66.8358 14.7459 66.7284 14.7089C66.2664 14.5625 65.8669 13.7662 65.8628 13.7582C65.8454 13.723 65.8104 13.6998 65.7711 13.6972C65.7318 13.6947 65.6941 13.7132 65.6723 13.7458C65.6504 13.7784 65.6476 13.8202 65.6651 13.8554C65.6829 13.8915 66.1101 14.7431 66.6612 14.918C66.7261 14.9391 66.7939 14.9501 66.8622 14.9504C66.9657 14.95 67.0674 14.9235 67.1579 14.8735C67.6061 14.6323 67.5941 13.9891 67.5053 13.4924C67.6843 13.7122 67.9192 13.88 68.1855 13.9785C68.4685 14.0689 68.7768 14.0349 69.0331 13.885C69.9347 13.3965 69.6629 12.2181 69.3932 11.6633Z"
            fill="black"
          />
          <path
            d="M70.2739 13.631C70.2322 13.6563 69.2468 14.2633 69.0243 15.2675C68.9116 15.835 69.0445 16.4236 69.3902 16.8885C69.4243 16.9384 69.4924 16.9517 69.5428 16.9182C69.5932 16.8846 69.607 16.8169 69.5737 16.7665C69.2626 16.3512 69.1412 15.8249 69.2392 15.3161C69.4408 14.4041 70.3793 13.8244 70.3885 13.8186C70.4403 13.787 70.4566 13.7196 70.4251 13.668C70.3935 13.6163 70.3259 13.5998 70.2739 13.631L70.2739 13.631Z"
            fill="black"
          />
          <path
            d="M130.45 10.7441C130.569 10.1292 129.963 9.45237 129.937 9.42384C129.896 9.3789 129.827 9.37539 129.782 9.41599C129.736 9.45659 129.733 9.52596 129.774 9.57099C129.779 9.57721 130.332 10.1957 130.233 10.7027C130.179 10.9232 130.02 11.1032 129.807 11.1837C128.754 11.6948 126.989 10.5083 126.971 10.4963C126.938 10.4741 126.896 10.4708 126.861 10.4878C126.825 10.5048 126.802 10.5393 126.799 10.5785C126.796 10.6176 126.814 10.6554 126.846 10.6775C126.909 10.7199 128.115 11.5314 129.192 11.5314C129.438 11.5359 129.681 11.4846 129.904 11.3812C130.18 11.2709 130.384 11.0328 130.45 10.7441Z"
            fill="#030F1C"
          />
          <path
            d="M134.646 11.8064C134.592 11.7798 134.526 11.802 134.499 11.8563C134.494 11.8644 134.095 12.6605 133.633 12.807C133.526 12.8441 133.407 12.8337 133.308 12.7785C132.819 12.5154 133.086 11.4249 133.231 11.027C133.232 11.0246 133.231 11.022 133.232 11.0196C133.232 11.0184 133.233 11.0179 133.234 11.0167L133.798 8.80562C133.807 8.76758 133.796 8.72724 133.768 8.69982C133.74 8.6724 133.699 8.66208 133.661 8.67276C133.623 8.68344 133.594 8.71349 133.584 8.75157L133.023 10.9517C132.981 11.0353 132.634 11.6971 132.107 11.8678C131.882 11.9389 131.637 11.9106 131.434 11.7901C130.797 11.4455 130.957 10.8172 131.202 10.3509C131.314 10.1385 131.397 9.91222 131.449 9.67801L131.861 7.82436C131.87 7.78598 131.857 7.74592 131.828 7.71933C131.799 7.69274 131.758 7.68368 131.72 7.69558C131.682 7.70748 131.654 7.73851 131.646 7.77695L131.234 9.63061C131.186 9.84576 131.11 10.0536 131.007 10.2488C130.601 11.0202 130.718 11.6523 131.328 11.9832C131.584 12.1329 131.893 12.1669 132.176 12.0767C132.442 11.9782 132.677 11.8103 132.856 11.5905C132.767 12.0872 132.755 12.7304 133.203 12.9717C133.294 13.0217 133.395 13.0482 133.499 13.0486C133.567 13.0483 133.635 13.0373 133.7 13.0162C134.251 12.8412 134.678 11.9896 134.696 11.9535C134.723 11.8991 134.701 11.8333 134.646 11.8064V11.8064Z"
            fill="#030F1C"
          />
          <path
            d="M130.087 11.7287C130.035 11.6974 129.968 11.714 129.936 11.7656C129.904 11.8173 129.921 11.8847 129.973 11.9163C129.982 11.9221 130.92 12.5018 131.122 13.4138C131.22 13.9226 131.099 14.4489 130.788 14.8641C130.754 14.9146 130.768 14.9823 130.819 15.0158C130.869 15.0493 130.937 15.0361 130.971 14.9862C131.317 14.5212 131.45 13.9327 131.337 13.3652C131.114 12.3609 130.129 11.754 130.087 11.7287Z"
            fill="#030F1C"
          />
        </g>
        <path
          d="M4.42137 64.0005H59.8387C61.219 64.0289 62.3676 65.0677 62.5319 66.4362L66.5537 105.323C66.6352 105.946 66.4401 106.574 66.0195 107.042C65.5989 107.511 64.9946 107.772 64.3649 107.759H41.3245L41.304 107.876L40.0931 115.237H49.4142C49.9317 115.237 50.3512 115.656 50.3512 116.172C50.3512 116.689 49.9317 117.108 49.4142 117.108H24.4819C23.9644 117.108 23.5449 116.689 23.5449 116.172C23.5449 115.656 23.9644 115.237 24.4819 115.237H27.4823L28.7137 107.759H8.94753C7.56682 107.731 6.41761 106.692 6.25436 105.323L2.23253 66.4362C2.15104 65.8126 2.34614 65.1847 2.76676 64.7166C3.18739 64.2485 3.79165 63.987 4.42137 64.0005Z"
          fill="#F3F3F3"
        />
        <g opacity="0.6">
          <path
            d="M29.725 107.759L28.4936 115.237H27.4824L28.7112 107.759H29.725V107.759Z"
            fill="#030F1C"
          />
        </g>
        <g opacity="0.15">
          <path
            d="M29.725 107.759L28.4936 115.237H27.4824L28.7112 107.759H29.725V107.759Z"
            fill="#A8A8A8"
          />
        </g>
        <g opacity="0.6">
          <path
            d="M41.3218 107.759L41.3014 107.876L28.4986 115.24L29.7325 107.759H41.3218V107.759Z"
            fill="#030F1C"
          />
        </g>
        <g opacity="0.15">
          <path
            d="M4.22908 64.001H5.90847C4.66684 64.001 3.78363 65.1843 3.93467 66.6462L7.91298 105.114C8.06403 106.576 9.19301 107.759 10.4346 107.759H8.75268C7.51106 107.759 6.38208 106.576 6.23103 105.114L2.25272 66.6462C2.10168 65.1843 2.98745 64.001 4.22908 64.001Z"
            fill="#A8A8A8"
          />
        </g>
        <g opacity="0.15">
          <rect
            width="9.09841"
            height="1.87086"
            rx="0.935428"
            transform="matrix(-1 0 0 1 32.6816 115.236)"
            fill="#A8A8A8"
          />
        </g>
        <ellipse
          cx="1.54115"
          cy="1.5386"
          rx="1.54115"
          ry="1.5386"
          transform="matrix(-1 0 0 1 37.0029 102.266)"
          fill="#E4E4E4"
        />
        <g opacity="0.6">
          <path
            d="M9.71829 100.986H63.3436C63.6939 100.993 64.0299 100.848 64.264 100.587C64.4981 100.327 64.607 99.978 64.5622 99.6311L61.2341 67.5531C61.1446 66.7911 60.5048 66.2125 59.7365 66.1985H6.12143C5.77116 66.1912 5.43509 66.3366 5.201 66.5968C4.96691 66.857 4.85804 67.2062 4.90284 67.5531L8.23091 99.6311C8.31988 100.389 8.95389 100.967 9.71829 100.986V100.986Z"
            fill="#FAFAF9"
          />
        </g>
        <path
          fillRule="evenodd"
          clip-rule="evenodd"
          d="M42.1026 82.9997C42.1026 86.6816 39.1011 89.6663 35.3987 89.6663C31.6962 89.6663 28.6948 86.6816 28.6948 82.9997C28.6948 79.3178 31.6962 76.333 35.3987 76.333C39.1011 76.333 42.1026 79.3178 42.1026 82.9997ZM33.6439 82.379C33.7256 82.4139 33.7996 82.4647 33.8615 82.5284L34.7283 83.3904L36.9359 81.195C36.9977 81.1313 37.0717 81.0806 37.1535 81.0456C37.2353 81.0107 37.3232 80.9923 37.4123 80.9915C37.5013 80.9908 37.5895 81.0076 37.6719 81.0411C37.7543 81.0747 37.8292 81.1242 37.8921 81.1868C37.9551 81.2494 38.0048 81.3238 38.0386 81.4057C38.0723 81.4876 38.0892 81.5754 38.0884 81.664C38.0877 81.7525 38.0692 81.84 38.034 81.9213C37.9989 82.0026 37.9478 82.0762 37.8838 82.1377L35.2022 84.8044C35.0765 84.9293 34.906 84.9995 34.7283 84.9995C34.5505 84.9995 34.38 84.9293 34.2543 84.8044L32.9135 83.471C32.8495 83.4095 32.7984 83.336 32.7633 83.2546C32.7282 83.1733 32.7097 83.0858 32.7089 82.9973C32.7081 82.9088 32.7251 82.821 32.7588 82.7391C32.7925 82.6571 32.8423 82.5827 32.9052 82.5201C32.9682 82.4575 33.043 82.408 33.1254 82.3745C33.2078 82.341 33.2961 82.3241 33.3851 82.3249C33.4741 82.3256 33.5621 82.344 33.6439 82.379Z"
          fill="#85CEB4"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2587_202279"
            x1="0"
            y1="1"
            x2="155.248"
            y2="174.196"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E1E7E6" />
            <stop offset="1" stopColor="#F6F8F7" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
