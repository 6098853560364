import React from "react";

import { TargetMemberTypeChoices } from "../../__generated__/graphql";
import SettingsBlock from "../SettingsBlock";
import BuilderSectionHeader from "./builder-section-header";
import SectionProps from "./section_props";

export default function FollowUpActionsSection({
  data,
  campaignDefinition,
  openDrawer,
}: SectionProps) {
  const targetMemberType = data.campaignById?.targetMemberType;
  const isOrgCampaign = targetMemberType === TargetMemberTypeChoices.Org;
  const hasFollowup = campaignDefinition.after_actions.actions.some(
    (action) =>
      action.type === "notify_completed" ||
      action.type === "webhook" ||
      action.type === "email",
  );

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Follow-up Actions"
        description={`Automate what’ll happen after a ${isOrgCampaign ? "member of the organization" : "customer"} completes the event`}
        isValid={hasFollowup}
        onClick={() => {}}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {!hasFollowup && <div></div>}
        {campaignDefinition.after_actions.actions
          .sort((a, b) => (a.id < b.id ? -1 : 1))
          .map((action) => {
            switch (action.type) {
              case "email":
                return (
                  <SettingsBlock
                    key={action.id}
                    title={`Send follow-up email`}
                    errors={
                      action.messages[0].subject
                        ? []
                        : ["Follow-up email has no subject"]
                    }
                    section="follow-up"
                    onClick={() => {}}
                  />
                );
              case "update_member":
                return (
                  <SettingsBlock
                    title={
                      isOrgCampaign ? "Update organization" : "Update Person"
                    }
                    section="attributes"
                    onClick={() => {}}
                  />
                );
              case "notify_completed":
                return (
                  <SettingsBlock
                    title="Notify the team"
                    section="notify"
                    onClick={() => {}}
                  />
                );
              case "update_crm":
                return (
                  <SettingsBlock
                    title="Update CRM fields"
                    section="update-crm"
                    onClick={() => {}}
                  />
                );
              case "incentive":
              case "delayed_email":
              case "notify_entered":
              case "notify_exited":
              case "webhook":
                return <></>;
              default:
                const exhaustiveCheck: never = action;
                throw new Error(`Unknown action type ${exhaustiveCheck}`);
            }
          })}
      </div>
    </div>
  );
}
