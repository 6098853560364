import React from "react";
import Tiptap from "../tiptap/tiptap";

const DEFAULT_PLACEHOLDER =
  "This is your opportunity to thank them, or explain how / when they’ll be able to claim their incentive..";

interface Props {
  value: string;
  label: string;
  editable?: boolean;
  placeholder?: string;
  mentionUsers?: any[];
  showIncentivePicker?: boolean;
  onChange: (s: string) => void;
}

export default function Editor({
  value,
  label,
  editable = true,
  onChange,
  placeholder = DEFAULT_PLACEHOLDER,
  mentionUsers = [],
  showIncentivePicker = false,
}: Props) {
  return (
    <>
      <label>{label}</label>
      <form>
        <Tiptap
          onChange={onChange}
          content={value}
          editable={editable}
          placeholder={placeholder}
          mentionUsers={mentionUsers}
          showIncentivePicker={showIncentivePicker}
        />
      </form>
    </>
  );
}
