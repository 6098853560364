import { Dispatch } from "react";
import { IncentiveAction } from "../../types/BackendTypes";
import { CampaignDefinitionEditAction } from "../reducer";
import TreesCampaignConfig from "./incentive_types/trees";
import DiscountCampaignConfig from "./incentive_types/discount";
import WebhookCampaignConfig from "./incentive_types/webhook";
import ShopifyGiftCampaignConfig from "./incentive_types/shopify_gift";

interface Props {
  action: IncentiveAction;
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  isEditable: boolean;
}

export default function IncentiveConfig({
  action,
  dispatch,
  isEditable,
}: Props) {
  const incentiveType = action.incentives[0].type;
  switch (incentiveType) {
    case "trees":
      return <TreesCampaignConfig action={action} dispatch={dispatch} />;
    case "discount_sample":
      return <DiscountCampaignConfig action={action} dispatch={dispatch} />;
    case "webhook":
      return <WebhookCampaignConfig action={action} dispatch={dispatch} />;
    case "shopify_gift":
      return (
        <ShopifyGiftCampaignConfig
          action={action}
          dispatch={dispatch}
          isEditable={isEditable}
        />
      );
    default:
      const s: never = incentiveType;
      throw new Error(s);
  }
}
