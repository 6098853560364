import { useNavigate } from "react-router-dom";
import StatsHeading from "../patterns/StatsHeading";
import Card from "../patterns/Card";
import CardMenu from "../patterns/CardMenu";
import EmptyData from "../patterns/illustrations/EmptyData";
import { BehaviorType } from "../__generated__/graphql";
import Spark from "../patterns/charts/Spark";

interface Props {
  behavior: BehaviorType;
}
export default function BehaviorCard({ behavior }: Props) {
  const navigate = useNavigate();

  const entered = behavior.stats.entered.count;
  const completed = behavior.stats.completed.count;
  const completedPercentage =
    entered && Math.round((completed / entered) * 100);

  const hasAnyData = behavior.stats.completed.timeSeries.length > 0;
  return (
    <div className="flex flex-wrap">
      <CardMenu>
        <Card>
          <div
            key={behavior.id}
            onClick={() => {
              navigate(`/behaviors/${behavior.id}`);
            }}
          >
            {hasAnyData ? (
              <StatsHeading
                title={behavior.name}
                stat={completed}
                subtitle={completedPercentage ? `${completedPercentage}%` : "-"}
              />
            ) : (
              <StatsHeading
                title={behavior.name}
                stat={100}
                subtitle="No data yet"
              />
            )}
            <div className="flex items-center justify-center w-full h-32">
              {hasAnyData ? (
                <div className="flex w-full h-full">
                  <Spark
                    data={[
                      {
                        id: 1,
                        data: behavior.stats.completed.timeSeries,
                      },
                    ]}
                    color={"#1A41CF"}
                  />
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="flex flex-col h-32">
                    <EmptyData />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </CardMenu>
    </div>
  );
}
