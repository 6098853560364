import { useQuery } from "@apollo/client";
import { GET_SHOPIFY_PRODUCTS } from "../../graphql/queries";
import { Gift } from "../types";
import SkeletonScreen from "../../patterns/Skeleton";
import Modal from "../../patterns/Modal";
import CardMenu from "../../patterns/CardMenu";
import Incentive from "../../patterns/illustrations/Incentive";
import SectionHeader from "../../patterns/SectionHeader";

interface Props {
  close: () => void;
  updateGift: (gift: Gift) => void;
}

export default function ShopifyGiftModal({ close, updateGift }: Props) {
  const { data, loading } = useQuery(GET_SHOPIFY_PRODUCTS, {
    variables: { queryString: "inventory_total:>0" },
  });

  const selectGift = (gift: Gift) => {
    updateGift(gift);
    close();
  };

  if (loading) {
    return (
      <Modal title="Select a gift" size="large" close={close}>
        <SkeletonScreen />
      </Modal>
    );
  }

  return (
    <Modal title="Select a gift" size="large" close={close}>
      {data.allShopifyProducts?.length === 0 ? (
        <div className="h-full mt-8">No shopify items!</div>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-4 px-8">
            {data.allShopifyProducts.map((product) => (
              <CardMenu
                key={product.id}
                onClick={() =>
                  selectGift({
                    product_id: product.id,
                    variant_id: product.firstVariantId,
                    title: product.title,
                    description: product.description,
                    image: product.featuredImage.url,
                  })
                }
              >
                <div className="w-auto h-40 rounded-t-lg bg-gradient-to-br from-[#E5F5F2] to-[#E4ECF6] p-6 flex items-center justify-center">
                  {product.featuredImage?.url !== "" ? (
                    <img
                      src={product.featuredImage.url}
                      className="object-contain w-auto h-40"
                      alt=""
                    />
                  ) : (
                    <Incentive />
                  )}
                </div>
                <div className="px-4 pt-4 pb-2">
                  <SectionHeader
                    title={product.title}
                    description={product.description}
                  />
                </div>
              </CardMenu>
            ))}
          </div>
        </>
      )}
    </Modal>
  );
}
