import { useEffect, useRef } from "react";

export default function useInfiniteScroll(loadNextPage: () => void) {
  const observerTarget = useRef(null);

  useEffect(() => {
    const current = observerTarget.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadNextPage();
        }
      },
      { threshold: 1 },
    );
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [observerTarget, loadNextPage]);
  return observerTarget;
}
