import React, { useState, useRef, useCallback } from "react";
import Modal from "../patterns/Modal";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CREATE_REFERRAL } from "../graphql/mutations";
import { VIEW_PARAM } from "../campaign";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import BehaviorButton from "../patterns/atoms/BehaviorButton";

interface Props {
  closeModal: () => void;
}

export default function NewBehavior({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [createReferralMutation] = useMutation(CREATE_REFERRAL);

  const createReferral = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        const result = await createReferralMutation({
          variables: {
            name: "Referral Program",
          },
          refetchQueries: ["GetReferrals"],
        });
        if (result.data.createReferral.ok) {
          navigate(
            `/dashboard/referrals/${result.data.createReferral.referral.id}?${VIEW_PARAM}=settings`,
          );
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [createReferralMutation, navigate],
  );

  return (
    <Modal
      close={closeModal}
      title={`Set up your referral program`}
      size="large"
    >
      <form
        ref={formRef}
        className=""
        action="#"
        onSubmit={createReferral}
        data-testid="referrals__new-referral__form"
      >
        <>
          <div className="flex w-full gap-x-4">
            <SecondaryButton
              label="Cancel"
              onClick={() => closeModal()}
              fullWidth={true}
            />
            <BehaviorButton
              label="Set up referral program"
              isDisabled={isSaving}
              onClick={() => formRef.current.requestSubmit()}
              fullWidth={true}
            />
          </div>
        </>
      </form>
    </Modal>
  );
}
