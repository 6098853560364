import { useContext } from "react";
import { useParams } from "react-router-dom";
import { GET_BEHAVIORS } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import { SessionContext } from "../SessionContext";
import { DashboardItemList } from "../types/BackendTypes";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import EmptyState from "../patterns/EmptyState";
import FlexibleBehaviorCard from "../behavior/FlexibleBehaviorCard";
import BehaviorsEmpty from "../patterns/illustrations/BehaviorsEmpty";
import PageHeader from "../patterns/PageHeader";
import { titleCase } from "../lib/string";

interface Props {
  monitorName?: string;
}

export default function SignalMonitor({ monitorName }: Props) {
  const session = useContext(SessionContext);
  const params = useParams();

  const {
    data: behaviorsData,
    loading: behaviorsLoading,
    error: behaviorsError,
  } = useQuery(GET_BEHAVIORS);

  const dashboardConfig = session?.dashboardConfigs.find(
    (dc) => dc.location === (monitorName ?? params.monitorName),
  );

  const dashboardDefinition = dashboardConfig
    ? JSON.parse(dashboardConfig?.definition)
    : undefined;

  if (behaviorsLoading) {
    return <SkeletonScreen />;
  }

  if (behaviorsError) {
    return <FullScreenError />;
  }

  if (dashboardDefinition === undefined) {
    return (
      <EmptyState
        title="There's nothing here yet..."
        description="Contact us to set up this signal monitor"
        icon={<BehaviorsEmpty />}
      />
    );
  }

  const behaviorMap = {};
  behaviorsData.allBehaviors
    .filter((behavior) => !behavior.deleted)
    .forEach((behavior) => {
      behaviorMap[behavior.id] = behavior;
    });

  return (
    <div>
      <PageHeader
        header={`Monitor ${titleCase(monitorName ?? params.monitorName)}`}
        subhead={``}
        buttons={[]}
      />

      <div className="px-8 flex flex-col gap-3 w-full">
        {dashboardDefinition.rows.map((row: DashboardItemList, index) => {
          return (
            <div key={index} className="flex flex-row gap-3 w-full">
              {row.items.map((itemConfig) => (
                <FlexibleBehaviorCard
                  behavior={behaviorMap[itemConfig.objectId]}
                  config={itemConfig}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}
