import Toggle from "../patterns/Toggle";

interface Props {
  label: string;
  description?: string;
  isOn: boolean;
  disabled?: boolean;
  onChange: (s: boolean) => void;
}

export default function LabelAndToggle({
  label,
  description,
  isOn,
  disabled = false,
  onChange,
}: Props) {
  return (
    <div className="flex my-2">
      <div className="flex-grow font-bold text-sm">
        {label}
        <span className="block text-xs font-normal py-1.5 text-body-text-lighter max-w-screen-sm">
          {description}
        </span>
      </div>
      <Toggle isOn={isOn} disabled={disabled} onChange={onChange} />
    </div>
  );
}
