import { CompletionCriteria, Filter } from "../../types/BackendTypes";
import {
  BehaviorType,
  TargetMemberTypeChoices,
} from "../../__generated__/graphql";
import { uuidv7 } from "@kripod/uuidv7";
import {
  Action,
  updatedActionList,
  removeActionOfType,
} from "../../lib/reducer_utils";
import { ActionListNames } from "../../actions/action_list_names";

const NULL_BEHAVIOR = {
  id: "",
  name: "",
  status: "DRAFT",
  targetMemberType: TargetMemberTypeChoices.Person,
  audience: {},
  observing: {},
  actionsOnEnter: { actions: [] },
  actionsOnCompletion: { actions: [] },
  actionsWhileObserving: [],
};

interface InitAction {
  type: "init";
  data: BehaviorType;
}

interface UpdateAudience {
  type: "UpdateAudience";
  data: Filter;
}

interface UpdateCompletionCriteria {
  type: "UpdateCompletionCriteria";
  criteria: CompletionCriteria;
}

interface EnableNotifyCompletedAction {
  type: "EnableNotifyCompletedAction";
}

interface EnableNotifyEnteredAction {
  type: "EnableNotifyEnteredAction";
}

interface EnableWebhookAction {
  type: "EnableWebhookAction";
  actionList: ActionListNames;
}

interface EnableUpdateMemberAction {
  type: "EnableUpdateMemberAction";
  actionList: ActionListNames;
}

interface EnableUpdateCrmAction {
  type: "EnableUpdateCrmAction";
  actionList: ActionListNames;
}

interface RemoveActionOfType {
  type: "RemoveActionOfType";
  actionType: Action["type"];
  actionList: ActionListNames;
}

interface UpdateActionOfType {
  type: "UpdateActionOfType";
  actionType: Action["type"];
  actionList: ActionListNames;
  action: Action;
}

export type BehaviorUpdate =
  | UpdateAudience
  | UpdateCompletionCriteria
  | EnableNotifyCompletedAction
  | EnableNotifyEnteredAction
  | EnableWebhookAction
  | EnableUpdateMemberAction
  | EnableUpdateCrmAction
  | RemoveActionOfType
  | UpdateActionOfType;

export function updateBehaviorReducer(
  prev: BehaviorType,
  reducerAction: InitAction | BehaviorUpdate,
): BehaviorType {
  switch (reducerAction.type) {
    case "init":
      return {
        ...NULL_BEHAVIOR,
        id: reducerAction.data.id,
        name: reducerAction.data.name,
        status: reducerAction.data.status,
        targetMemberType: reducerAction.data.targetMemberType,
        audience: JSON.parse(reducerAction.data.audience),
        observing: JSON.parse(reducerAction.data.observing),
        actionsOnEnter: JSON.parse(reducerAction.data.actionsOnEnter),
        actionsOnCompletion: JSON.parse(reducerAction.data.actionsOnCompletion),
      };
    case "UpdateAudience":
      return {
        ...prev,
        audience: {
          criteria: [
            {
              type: "filter",
              version: "1",
              filter: reducerAction.data,
            },
          ],
        },
      };

    case "UpdateCompletionCriteria":
      return { ...prev, observing: { criteria: [reducerAction.criteria] } };

    case "EnableNotifyEnteredAction":
      return {
        ...prev,
        actionsOnEnter: {
          actions: [
            ...prev.actionsOnEnter.actions,
            {
              type: "notify_entered",
              id: uuidv7(),
              message: {
                template: "",
              },
              enabled: true,
            },
          ],
        },
      };
    case "EnableNotifyCompletedAction":
      return {
        ...prev,
        actionsOnCompletion: {
          actions: [
            ...prev.actionsOnCompletion.actions,
            {
              type: "notify_completed",
              id: uuidv7(),
              message: {
                template: "",
              },
              enabled: true,
            },
          ],
        },
      };
    case "EnableUpdateMemberAction":
      return {
        ...prev,
        [reducerAction.actionList]: {
          actions: [
            ...prev[reducerAction.actionList].actions,
            {
              id: uuidv7(),
              type: "update_member",
              updates: [],
            },
          ],
        },
      };
    case "EnableWebhookAction":
      return {
        ...prev,
        [reducerAction.actionList]: {
          actions: [
            ...prev[reducerAction.actionList].actions,
            {
              id: uuidv7(),
              type: "webhook",
              url: "",
              method: "POST",
              headers: [],
              triggering_step_name: "completed",
              enabled: true,
            },
          ],
        },
      };
    case "EnableUpdateCrmAction":
      return {
        ...prev,
        [reducerAction.actionList]: {
          actions: [
            ...prev[reducerAction.actionList].actions,
            {
              id: uuidv7(),
              type: "update_crm",
              updates: [],
            },
          ],
        },
      };
    case "RemoveActionOfType":
      return {
        ...prev,
        [reducerAction.actionList]: {
          actions: [
            ...removeActionOfType(
              prev[reducerAction.actionList],
              reducerAction.actionType,
            ),
          ],
        },
      };
    case "UpdateActionOfType":
      return {
        ...prev,
        [reducerAction.actionList]: {
          actions: [
            ...updatedActionList(
              prev[reducerAction.actionList],
              reducerAction.action,
            ),
          ],
        },
      };
    default:
      const exhaustiveCheck: never = reducerAction;
      throw new Error(`Unknown action type ${exhaustiveCheck}`);
  }
}
