export function SetCampaignLive() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="220"
        height="220"
        rx="110"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 220 220)"
        fill="url(#paint0_linear_143_1633)"
        fill-opacity="0.33"
      />
      <path
        d="M166 108.503C166 104.459 164.18 100.686 161.015 98.1588L100.58 49.9231C96.553 46.7111 91.1765 46.0953 86.528 48.3286C81.884 50.5526 79 55.1295 79 60.2669V156.738C79 161.876 81.884 166.453 86.528 168.677C88.3754 169.563 90.3472 170 92.3052 170C95.2492 170 98.1608 169.012 100.58 167.082L161.015 118.846C164.185 116.319 166 112.546 166 108.503ZM97.7001 163.493C95.0372 165.621 91.6142 166.016 88.5228 164.536C85.4453 163.061 83.6117 160.143 83.6117 156.738V60.2669C83.6117 56.8572 85.4453 53.9439 88.5274 52.4688C89.7621 51.8807 91.0521 51.5866 92.3237 51.5866C94.231 51.5866 96.1015 52.2391 97.7001 53.5119L158.136 101.748C160.204 103.397 161.393 105.86 161.393 108.503C161.393 111.145 160.204 113.608 158.136 115.258L97.7001 163.493Z"
        fill="#1E293B"
      />
      <path
        d="M138 52C136.761 52 135.756 53.0052 135.756 54.2438V55.7562H134.244C133.005 55.7562 132 56.7614 132 58C132 59.2386 133.005 60.2438 134.244 60.2438H135.756V61.7562C135.756 62.9948 136.761 64 138 64C139.239 64 140.244 62.9948 140.244 61.7562V60.2438H141.756C142.995 60.2438 144 59.2386 144 58C144 56.7614 142.995 55.7562 141.756 55.7562H140.244V54.2438C140.244 53.0052 139.239 52 138 52Z"
        fill="#1E293B"
      />
      <path
        d="M68.2956 138.165L66.8049 137.738L67.1994 136.12C67.5204 134.796 66.7957 133.436 65.571 133.084C64.328 132.731 63.0895 133.525 62.7684 134.845L62.374 136.463L60.8786 136.036C59.6356 135.689 58.4017 136.478 58.076 137.798C57.755 139.123 58.4797 140.482 59.7044 140.834L61.1951 141.261L60.8006 142.879C60.4796 144.204 61.2043 145.563 62.429 145.916C62.6262 145.97 62.8235 146 63.0161 146C64.0344 146 64.961 145.266 65.2316 144.154L65.626 142.536L67.1214 142.963C67.3186 143.018 67.5158 143.048 67.7085 143.048C68.7268 143.048 69.6533 142.313 69.924 141.202C70.245 139.877 69.5203 138.517 68.2956 138.165Z"
        fill="#1E293B"
      />
      <path
        d="M161 137C157.137 137 154 140.137 154 144C154 147.863 157.137 151 161 151C164.863 151 168 147.863 168 144C168 140.137 164.863 137 161 137ZM161 146.44C159.655 146.44 158.56 145.345 158.56 144C158.56 142.655 159.655 141.56 161 141.56C162.345 141.56 163.44 142.655 163.44 144C163.44 145.345 162.345 146.44 161 146.44Z"
        fill="#1E293B"
      />
      <path
        d="M131 160C132.657 160 134 158.657 134 157C134 155.343 132.657 154 131 154C129.343 154 128 155.343 128 157C128 158.657 129.343 160 131 160Z"
        fill="#1E293B"
      />
      <path
        d="M141.266 116.498L100.862 148.896C99.8691 149.693 99.7128 151.139 100.503 152.134C100.958 152.705 101.625 153 102.3 153C102.806 153 103.312 152.834 103.734 152.498L144.138 120.1C145.131 119.303 145.287 117.857 144.497 116.862C143.706 115.867 142.263 115.71 141.266 116.498Z"
        fill="#1E293B"
      />
      <defs>
        <linearGradient
          id="paint0_linear_143_1633"
          x1="226"
          y1="150"
          x2="-37.2822"
          y2="143.301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE8C7" />
          <stop offset="0.51" stop-color="#FFC2FC" />
          <stop offset="1" stop-color="#DBFAFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
