export default function OrganizationIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" />
      <path
        d="M13 20.0032L13 10.0089L21 5L29 10.0089V20.0032L21 25L13 20.0032Z"
        fill="white"
        fill-opacity="0.75"
        stroke="#1E293B"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M21 25V14.9995M21 14.9995L29 10M21 14.9995L13 10"
        stroke="#1E293B"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M13 8.00325L13 -1.99112L21 -7L29 -1.99112V8.00325L21 13L13 8.00325Z"
        fill="white"
        stroke="#1E293B"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="3 3"
      />
      <path
        d="M21 13V2.99949M21 2.99949L29 -2M21 2.99949L13 -2"
        stroke="#1E293B"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="3 3"
      />
      <path
        d="M3 26.0032L3 16.0089L11 11L19 16.0089V26.0032L11 31L3 26.0032Z"
        fill="white"
        stroke="#1E293B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 31L11 20.9995M11 20.9995L19 16M11 20.9995L3 16"
        stroke="#1E293B"
        stroke-width="0.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 14.0032L3 4.00888L11 -1L19 4.00888V14.0032L11 19L3 14.0032Z"
        fill="white"
        stroke="#1E293B"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <path
        d="M11 19L11 8.99949M11 8.99949L19 4M11 8.99949L3 4"
        stroke="#1E293B"
        stroke-width="0.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="2 2"
      />
      <defs>
        <clipPath id="clip0_1987_60357">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
