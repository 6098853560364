import React from "react";
import { ButtonProps } from "./types";
import { cx } from "../../lib/cx";

export default function PrimaryButton({
  label,
  onClick,
  isDisabled = false,
  fullWidth = false,
  size = "default",
}: ButtonProps & { size?: "default" | "large" }) {
  const sizeClasses = {
    default: "btn-md",
    large: "btn-lg",
  };
  return (
    <button
      onClick={onClick}
      type="button"
      style={{ pointerEvents: isDisabled ? "none" : "auto" }}
      className={cx(
        isDisabled && "opacity-30",
        fullWidth && "w-full",
        sizeClasses[size],
        "animate bg-body-text text-grey-50 hover:bg-active-blue focus:outline-none focus:ring-2 focus:ring-grey-300 ",
      )}
    >
      {label}
    </button>
  );
}
