import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_REFERRALS } from "../graphql/queries";
import CampaignStatusPill from "../campaigns/CampaignStatePill";
import PageHeader from "../patterns/PageHeader";
import NewReferral from "./NewReferral";
import SkeletonScreen from "../patterns/Skeleton";
import EmptyState from "../patterns/EmptyState";
import BehaviorsEmpty from "../patterns/illustrations/BehaviorsEmpty";

export default function ReferralsMain() {
  const { data, loading } = useQuery(GET_REFERRALS);
  const navigate = useNavigate();
  const [modalIsVisible, setModalIsVisible] = useState(false);

  if (loading) {
    return <SkeletonScreen />;
  }
  return (
    <div className="flex flex-col">
      {data.allReferrals.length === 0 ? (
        <div className="mb-8">
          <PageHeader header="Referrals" subhead="" buttons={[]} />
          <EmptyState
            title="No Referrals yet"
            icon={<BehaviorsEmpty />}
            description="Create a referral program to get started"
            buttonLabel="Create Referral Program"
            onClick={() => setModalIsVisible(true)}
          />
        </div>
      ) : (
        <>
          <PageHeader header="Referrals" subhead="" buttons={[]} />
          {data?.allReferrals.map((referral) => (
            <div
              className="mx-8 mb-6 rounded-xl bg-white"
              onClick={() => navigate(`/referrals/${referral.id}`)}
            >
              <div className="animate cursor-pointer border-b border-rule-color last:border-none p-2 hover:bg-slate-5">
                <div className="flex gap-4 justify-between text-sm text-gray-500">
                  <div className="flex flex-col gap-2">
                    <span>Referral Program</span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span>Status</span>
                    <span>
                      <CampaignStatusPill status={referral.status} />
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span>Total Referrals</span>
                    <span className="font-bold text-sm text-body-text">
                      {referral.stats.totalReferrals}
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span>Total Referrers</span>
                    <span className="font-bold text-sm text-body-text">
                      {referral.stats.totalReferrers}
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span>Total Referees</span>
                    <span className="font-bold text-sm text-body-text">
                      {referral.stats.totalReferees}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {modalIsVisible && (
        <NewReferral closeModal={() => setModalIsVisible(false)} />
      )}
    </div>
  );
}
