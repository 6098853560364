import React from "react";
import { ResponsiveLine, Serie } from "@nivo/line";

interface Props {
  data: Serie[];
  color: string;
}

export default function Spark({ data, color }: Props) {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 4, right: 0, bottom: 4, left: 0 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      colors={[color]}
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      enableArea={true} // Enable area filling
      areaOpacity={0.08} // Adjust the opacity of the filled area
      pointSize={5}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
    />
  );
}
