import { Dispatch } from "react";
import TextInput from "../../../patterns/forms/TextInput";
import {
  DiscountIncentiveCampaign,
  IncentiveAction,
} from "../../../types/BackendTypes";
import { CampaignDefinitionEditAction } from "../../reducer";
import SelectInput from "../../../patterns/forms/SelectInput";
// import AutoClaimToggle from "./common/auto-claim";

interface Props {
  action: IncentiveAction;
  dispatch: Dispatch<CampaignDefinitionEditAction>;
}

export default function DiscountCampaignConfig({ action, dispatch }: Props) {
  const incentive = action.incentives[0] as DiscountIncentiveCampaign;
  return (
    <>
      {/* <AutoClaimToggle action={action} dispatch={dispatch} /> */}
      <SelectInput
        label="Discount Type"
        value={String(incentive.config.type)}
        placeholder="--"
        options={[
          { label: "Percentage", value: "percentage" },
          { label: "Fixed Amount", value: "absolute" },
        ]}
        required={true}
        onChange={(s) => {
          dispatch({
            type: "UpdateAfterAction",
            action: {
              ...action,
              incentives: [
                {
                  ...incentive,
                  config: {
                    ...incentive.config,
                    type: s as "percentage" | "absolute",
                  },
                },
              ],
            },
          });
        }}
      />
      <TextInput
        type="number"
        label={`Discount Value (${String(incentive.config.type) === "percentage" ? "%" : "Fixed Amount"})`}
        value={String(incentive.config.value)}
        placeholder="Discount amount"
        required={true}
        onChange={(s) => {
          dispatch({
            type: "UpdateAfterAction",
            action: {
              ...action,
              incentives: [
                {
                  ...incentive,
                  config: {
                    ...incentive.config,
                    value: parseInt(s),
                  },
                },
              ],
            },
          });
        }}
      />
    </>
  );
}
