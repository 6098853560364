export default function NoAccess() {
  return (
    <div className="grid h-screen items-center text-center">
      <div>
        <div>
          You don't have access to this part of pavlov. Get in touch to gain
          access.
        </div>
      </div>
    </div>
  );
}
