export default function PeopleIcon() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 23.5L7 20.5019V9.50532L12 12.5034M12 23.5V12.5034M12 23.5L17 20.5019V9.50532L12 12.5034M17 9.49857L12 12.5101L7 9.49857L12 6.5L17 9.49857Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 3.50532L7 9.50195L12 12.5V6.50338L7 3.50532Z" fill="white" />
      <path d="M12 6.50338L17 3.50532V9.50195L12 12.5" fill="white" />
      <path
        d="M17 3.49857L12 6.51013L7 3.49857L12 0.5L17 3.49857Z"
        fill="white"
      />
      <path
        d="M12 12.5L7 9.50195L7 3.50532L12 6.50338M12 12.5V6.50338M12 12.5L17 9.50195V3.50532L12 6.50338M17 3.49857L12 6.51013L7 3.49857L12 0.5L17 3.49857Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 19.5068L12 22.5048M17 19.5L12 22.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 18.5068L12 21.5048M17 18.5L12 21.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17.5068L12 20.5048M17 17.5L12 20.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 16.5068L12 19.5048M17 16.5L12 19.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 15.5068L12 18.5048M17 15.5L12 18.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 14.5068L12 17.5048M17 14.5L12 17.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13.5068L12 16.5048M17 13.5L12 16.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 12.5068L12 15.5048M17 12.5L12 15.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11.5068L12 14.5048M17 11.5L12 14.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 10.5068L12 13.5048M17 10.5L12 13.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
