import { cx } from "../lib/cx";
import {
  ArrowDownRightIcon,
  ArrowRightIcon,
  ArrowUpRightIcon,
} from "@heroicons/react/24/outline";

const PILLS = {
  green: {
    text: "High",
    icon: ArrowUpRightIcon,
    className: "bg-[#009FE31A] text-[#366EAA]",
  },
  amber: {
    text: "Medium",
    icon: ArrowRightIcon,
    className: "bg-[#2AACBB1A] text-[#2AACBB]",
  },
  red: {
    text: "Low",
    icon: ArrowDownRightIcon,
    className: "bg-[#FFECE8] text-[#C5280C]",
  },
  default: {
    text: "N/A",
    className: "bg-grey-200",
  },
};

interface Props {
  status: string;
}

export default function EngagementPill({ status }: Props) {
  const config = PILLS[status] || PILLS.default;
  const Icon = config.icon;

  return (
    <div
      className={cx(
        "flex flex-row items-center px-2 py-1 w-min text-center tracking-wide rounded-2xl",
        config.className,
      )}
    >
      {Icon && (
        <div className="w-3 h-3 mr-2">
          <Icon />
        </div>
      )}
      {config.text}
    </div>
  );
}
