import { Dispatch } from "react";
import TextInput from "../../../patterns/forms/TextInput";
import {
  IncentiveAction,
  TreeIncentiveCampaign,
} from "../../../types/BackendTypes";
import { CampaignDefinitionEditAction } from "../../reducer";
import AutoClaimToggle from "./common/auto-claim";

interface Props {
  action: IncentiveAction;
  dispatch: Dispatch<CampaignDefinitionEditAction>;
}

export default function TreesCampaignConfig({ action, dispatch }: Props) {
  const incentive = action.incentives[0] as TreeIncentiveCampaign;

  return (
    <>
      <AutoClaimToggle action={action} dispatch={dispatch} />
      <TextInput
        type="number"
        label="Number of trees"
        value={String(incentive.config.amount)}
        placeholder="Enter number of trees to offer"
        required={true}
        onChange={(s) => {
          dispatch({
            type: "UpdateAfterAction",
            action: {
              ...action,
              incentives: [
                {
                  ...incentive,
                  config: {
                    ...incentive.config,
                    amount: parseInt(s),
                  },
                },
              ],
            },
          });
        }}
      />
    </>
  );
}
