import { Dispatch } from "react";
import SelectInput from "../patterns/forms/SelectInput";
import OptionsAndDetail from "./options-and-details";
import { CampaignDefinitionEditAction } from "./reducer";
import {
  MessagingSenderType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import {
  DelayedEmailAction,
  EmailAction,
  IncentiveAction,
} from "../types/BackendTypes";
import FilterBuilder from "../filter_builder";
import { emptyFilterDoc } from "../lib/filters";
import TextInput from "../patterns/forms/TextInput";
import Editor from "./editor";
import { DAY_IN_SECONDS } from "../lib/date";
import { UserGroupIcon } from "@heroicons/react/24/solid";

interface Props {
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  message: EmailAction | DelayedEmailAction | IncentiveAction;
  targetMemberType: TargetMemberTypeChoices;
  isEditable: boolean;
  senders?: MessagingSenderType[];
  actionType: "UpdateBeforeAction" | "UpdateAfterAction";
}

export default function MessageEditor({
  dispatch,
  targetMemberType,
  message,
  isEditable,
  senders,
  actionType,
}: Props) {
  return (
    <OptionsAndDetail
      left={
        <>
          {message.type === "delayed_email" && (
            <TextInput
              label="Days to wait before sending"
              type="number"
              placeholder="number of days"
              value={`${message.delay / DAY_IN_SECONDS}`}
              error={
                !(message.delay / DAY_IN_SECONDS > 0)
                  ? "Must be at least 1 day"
                  : null
              }
              onChange={(value) => {
                dispatch({
                  type: "UpdateBeforeAction",
                  action: {
                    ...message,
                    delay: parseInt(value) * DAY_IN_SECONDS,
                  },
                });
              }}
              required={true}
              min={"1"}
            />
          )}
          <div className="pb-4">
            <SelectInput
              label={"Send from"}
              description={"Senders can be managed in Integrations/Messaging"}
              placeholder="Pick a sender"
              required={true}
              value={message.messages[0].sender || ""}
              options={senders.map((s) => ({
                label: s.displayName,
                value: s.id,
              }))}
              disabled={!isEditable}
              onChange={(s) =>
                dispatch({
                  type: actionType,
                  action: {
                    ...message,
                    messages: [
                      {
                        ...message.messages[0],
                        sender: s,
                      },
                    ],
                  },
                })
              }
            />
          </div>
          {targetMemberType === TargetMemberTypeChoices.Org && (
            <>
              <label className="w-full">
                Send to
                <div className="flex my-3 py-2 px-3 text-xs leading-6 tracking-wide rounded-lg text-grey-500 bg-grey-100 border ">
                  <div className="flex h-4 w-4 mr-2 rounded-md my-auto">
                    <UserGroupIcon />
                  </div>
                  <div className="text-xs leading-6 pr-4">
                    Campaign Audience
                  </div>
                </div>
                <FilterBuilder
                  filterDoc={message.messages[0].audience || emptyFilterDoc}
                  attributePickerLabel="Add a filter"
                  showPersonAttrs={true}
                  showOrgAttrs={false}
                  showEvents={false}
                  readonly={!isEditable}
                  onChange={(d) => {
                    dispatch({
                      type: actionType,
                      action: {
                        ...message,
                        messages: [
                          {
                            ...message.messages[0],
                            audience: d,
                          },
                        ],
                      },
                    });
                  }}
                />
              </label>
            </>
          )}
        </>
      }
      right={
        <>
          <TextInput
            placeholder="Subject"
            label="Enter E-mail subject"
            value={message.messages[0].subject || ""}
            required={true}
            disabled={!isEditable}
            onChange={(s) =>
              dispatch({
                type: actionType,
                action: {
                  ...message,
                  messages: [
                    {
                      ...message.messages[0],
                      subject: s,
                    },
                  ],
                },
              })
            }
          />
          <Editor
            label="Message to send"
            value={message.messages[0].body || ""}
            editable={isEditable}
            onChange={(s) =>
              dispatch({
                type: actionType,
                action: {
                  ...message,
                  messages: [
                    {
                      ...message.messages[0],
                      body: s,
                    },
                  ],
                },
              })
            }
          />
        </>
      }
    />
  );
}
