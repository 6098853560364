import React, { useCallback } from "react";

interface Props {
  label: string;
  options: Array<{ label: string; value: string | number; disabled?: boolean }>;
  value: string;
  onChange: (s: string) => void;
}

export default function SearchSelect({
  label,
  options,
  value,
  onChange,
}: Props) {
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value),
    [onChange],
  );

  return (
    <button className="m-0 ml-2 flex items-center justify-between rounded-full border border-rule-color bg-white py-2.5 pl-4 text-xs hover:bg-grey-100 hover:border-slate-40 animate">
      <div className="flex-1 whitespace-nowrap font-normal text-body-text-lighter ">
        {label}:
      </div>
      <select
        id="search-select__select"
        value={value}
        className="block rounded-full border-none py-0 pl-1 pr-10 text-xs text-body-text font-semibold bg-inherit cursor-pointer"
        onChange={onInputChange}
      >
        {options.map((o) => (
          <option
            key={o.value}
            value={o.value}
            className="text-xs font-normal leading-4 tracking-wide"
            disabled={o.disabled}
          >
            {o.label}
          </option>
        ))}
      </select>
    </button>
  );
}
