import {
  AttrTypeEnum,
  OrgAttribute,
  PersonAttribute,
} from "../__generated__/graphql";
import { AttributeType, FilterConditionTarget } from "../filter_builder/lib";

export function isOrgAttribute(
  attr: PersonAttribute | OrgAttribute,
): attr is OrgAttribute {
  return attr.__typename === "OrgAttribute";
}

export function parsedAttributeValue(attr: PersonAttribute | OrgAttribute) {
  const parsedValue = JSON.parse(attr.value);
  if (attr.aType === AttrTypeEnum.Datetime && parsedValue) {
    return new Date(parsedValue);
  }
  return parsedValue;
}

export function isAttributeType(
  filterConditionTarget: FilterConditionTarget,
): filterConditionTarget is AttributeType {
  return typeof filterConditionTarget !== "string";
}
