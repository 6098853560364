import { cx } from "../lib/cx";
import { useCallback } from "react";
import { attrIcon } from "../filter_builder/lib";
import { AttributeValue } from "./AttributeValue";
import { AttributeEditor } from "./AttributeEditor";
import { OrgAttribute, PersonAttribute } from "../__generated__/graphql";

interface Props {
  attr: PersonAttribute | OrgAttribute;
  entityId: string;
}

export function Attribute({
  attr,
  entityId,
  isEditing,
  openEditor,
  closeEditor,
}: Props & {
  isEditing: boolean;
  openEditor: () => void;
  closeEditor: () => void;
}) {
  const IconComponent = attrIcon(attr.source, attr.aType);
  const baseClassName =
    "flex p-2 mr-1 text-xs leading-6 tracking-wide animate rounded-lg";
  const isEditable = attr.source === "api";
  const classes = cx(
    baseClassName,
    isEditable && "hover:bg-blue-10 hover:text-active-blue cursor-pointer",
    !isEditable && "opacity-60",
    isEditing && "bg-blue-10 text-active-blue",
    !isEditing && "text-body-text bg-grey-100",
  );
  const handleEditClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      openEditor();
    },
    [openEditor],
  );

  return (
    <div
      className={classes}
      onClick={
        attr.source === "api" && !isEditing ? handleEditClick : undefined
      }
    >
      <div className={cx("h-3 w-3 mt-1.5 mr-1.5 rounded-md")}>
        <IconComponent className="" />
      </div>
      <div title={attr.displayName || attr.name}>
        {attr.displayName || attr.name}&nbsp;
      </div>
      <span className="font-bold mr-1.5">
        <AttributeValue attr={attr} />
      </span>
      {isEditing && (
        <AttributeEditor
          attr={attr}
          closeEditor={closeEditor}
          entityId={entityId}
        />
      )}
    </div>
  );
}
