import { useContext, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { AttributeTarget } from "../__generated__/graphql";
import {
  BehaviorType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import { GET_LIFECYCLE_STAGES } from "../graphql/queries";
import { ORDERED_LIFECYCLE_STAGES, LifecycleStages } from "../types/LocalTypes";
import { formatPercentage } from "../lib/number";
import { SessionContext } from "../SessionContext";

import EnteredCompletedBarChart from "../lifecycle_stage/graphs/EnteredCompletedBarChart";
import PageHeader, { Button } from "../patterns/PageHeader";
import EmptyState from "../patterns/EmptyState";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import TabNavigation from "../patterns/TabNavigation";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import AudienceAttributeSummaries from "../patterns/AudienceAttributeSummaries";
import CampaignEmpty from "../patterns/illustrations/CampaignEmpty";
import NewLifecycleStage from "./NewLifecycleStage";
import Objectives from "./Objectives";

export const VIEW_PARAM = "lifecycle_stage";

function lifecycleStageSort(a: any, b: any) {
  const ax = ORDERED_LIFECYCLE_STAGES.findIndex(
    (s) => s.toLowerCase() === a.label.toLowerCase(),
  );
  const bx = ORDERED_LIFECYCLE_STAGES.findIndex(
    (s) => s.toLowerCase() === b.label.toLowerCase(),
  );
  return ax - bx;
}

export default function LifecycleStagesMain() {
  const navigate = useNavigate();
  const [modalIsVisible, setModalVisible] = useState(false);
  const { data, loading, error } = useQuery(GET_LIFECYCLE_STAGES);
  const lifecycleStages = data?.allLifecycleStages;

  let [searchParams, setSearchParams] = useSearchParams();
  let view = searchParams.get(VIEW_PARAM) ?? LifecycleStages.Onboarding;

  const currentLifecycleStage = useMemo(() => {
    return lifecycleStages?.find((stage) => stage.name === view);
  }, [view, lifecycleStages]);

  const lifecycleStageTabs = useMemo(
    () =>
      lifecycleStages?.map((stage) => ({
        label: stage.name,
        action: () => setSearchParams({ [VIEW_PARAM]: stage.name }),
        isActive: view === stage.name,
      })) || [],
    [lifecycleStages, setSearchParams, view],
  );
  lifecycleStageTabs?.sort((a, b) => lifecycleStageSort(a, b));

  const addLifecycleStageButton = {
    type: "lifecycle_stage",
    label: "Add a Lifecycle Stage",
    action: () => setModalVisible(true),
  } as Button;

  if (loading) return <SkeletonScreen />;
  if (error) return <FullScreenError />;

  const hasData = currentLifecycleStage?.behavior.stats.entered.count > 0;

  return (
    <>
      <PageHeader
        header="Dashboard"
        subhead=""
        buttons={[addLifecycleStageButton]}
      />
      {modalIsVisible && (
        <NewLifecycleStage closeModal={() => setModalVisible(false)} />
      )}
      {lifecycleStages.length > 0 ? (
        <>
          <TabNavigation tabs={lifecycleStageTabs} />
          {hasData ? (
            <LifecycleStageOverview
              currentLifecycleStage={currentLifecycleStage}
            />
          ) : (
            <EmptyState
              title="No data available"
              icon={<CampaignEmpty />}
              description={
                "There is no data available for this Lifecycle Stage."
              }
              buttonLabel={`Configure ${currentLifecycleStage.name}`}
              onClick={() =>
                navigate(
                  `/lifecycle_stages/${currentLifecycleStage.id}?view=settings`,
                )
              }
            />
          )}
        </>
      ) : (
        <EmptyState
          title="What are your customers doing?"
          icon={<AttributesEmpty />}
          description={
            "By setting up Lifecycle Stages, you can build insights over time around what actions your customers are taking"
          }
        />
      )}
    </>
  );
}

function LifecycleStageOverview({
  currentLifecycleStage,
}: {
  currentLifecycleStage: any;
}) {
  const navigate = useNavigate();
  const lifecycleStageBehavior = currentLifecycleStage?.behavior;
  const hasObjectives = currentLifecycleStage?.objectiveBehaviors.length > 0;

  return (
    <div className="mx-6">
      <StatsBlock currentLifecycleStage={currentLifecycleStage} />
      <div className="my-4 p-6 bg-grey-80 rounded-lg animate shadow-sm">
        <div className="flex flex-row items-center gap-x-2">
          <h2 className="text-xl font-bold tracking-tighter">
            {currentLifecycleStage.name}
          </h2>
          <ArrowTopRightOnSquareIcon
            className="w-4 h-4 cursor-pointer"
            onClick={() => {
              navigate(
                `/lifecycle_stages/${currentLifecycleStage.id}?view=overview`,
              );
            }}
          />
          <span className="text-sm text-body-text-lightest font-normal">
            Total entered and completed:
          </span>
        </div>
        <div className="text-xs py-1 text-body-text-lighter tracking-wide mb-3"></div>
        <div className="h-60">
          <EnteredCompletedBarChart behavior={lifecycleStageBehavior} />
        </div>
      </div>
      {hasObjectives && (
        <>
          <h2 className="mt-8 mb-4 text-xl font-bold tracking-tighter">
            {currentLifecycleStage.name} Objectives
          </h2>
          {currentLifecycleStage.objectiveBehaviors.map(
            (objectiveBehavior: BehaviorType) => {
              return <Objectives behavior={objectiveBehavior} />;
            },
          )}
        </>
      )}
    </div>
  );
}

function StatsBlock({ currentLifecycleStage }: { currentLifecycleStage: any }) {
  const session = useContext(SessionContext);
  const behavior = currentLifecycleStage.behavior;
  const { entered, completed, timeToCompletion } = behavior.stats;
  const target =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";
  const enteredDenominator =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? session.orgCount
      : session.peopleCount;

  const statsData = {
    entered: {
      title: `Total entered`,
      value: entered.count,
      subtext: `${formatPercentage(entered.count, enteredDenominator)} of ${target}`,
    },
    completed: {
      title: `Total completed`,
      value: completed.count,
      subtext: `${formatPercentage(completed.count, entered.count)} of ${target} entered`,
    },
    completionTime: {
      title: `Avg time to completion`,
      value: `${timeToCompletion.average.toFixed(2)}`,
      subtext: "days",
    },
    notCompleted: {
      title: `Not completed`,
      value: entered.count - completed.count,
      subtext: `${formatPercentage(entered.count - completed.count, entered.count)} of ${target} entered`,
    },
  };

  const attributesToSummarize = [
    {
      name: "Onboarding_rag_status",
      source: "lifecycle_stage",
      target: "org",
      roll_up: {
        type: "count",
      },
    },
  ];

  const attributeSummaryFilter = useMemo(() => {
    return {
      version: "1",
      filters: {
        operator: "AND",
        conditions: [
          {
            source: "machine_metadata",
            path: `member::${currentLifecycleStage?.behavior.id}`,
            target: AttributeTarget.Org,
            operator: "NOT_NULL",
          },
        ],
      },
    };
  }, [currentLifecycleStage]);

  return (
    <div className="flex flex-row w-full gap-4 text-body-text-lightest">
      <div className="w-1/3 flex flex-col gap-4">
        <div className="flex-1 p-3 bg-grey-80 rounded-lg">
          <div className="text-xs">{statsData.entered.title}</div>
          <div className="text-2xl font-bold my-1 text-body-text">
            {statsData.entered.value}
          </div>
          <div className="text-xs">{statsData.entered.subtext}</div>
        </div>
        <div className="flex-1 p-3 bg-grey-80 rounded-lg">
          <div className="text-xs">{statsData.completed.title}</div>
          <div className="text-2xl font-bold my-1 text-body-text">
            {statsData.completed.value}
          </div>
          <div className="text-xs">{statsData.completed.subtext}</div>
        </div>
      </div>
      <div className="w-1/3 flex flex-col gap-4">
        <div className="flex-1 p-3 bg-grey-80 rounded-lg">
          <div className="text-xs">{statsData.notCompleted.title}</div>
          <div className="text-2xl font-bold my-1 text-body-text">
            {statsData.notCompleted.value}
          </div>
          <div className="text-xs">{statsData.notCompleted.subtext}</div>
        </div>
        <div className="flex-1 p-3 bg-grey-80 rounded-lg">
          <div className="text-xs">{statsData.completionTime.title}</div>
          <div className="text-2xl font-bold my-1 text-body-text">
            {statsData.completionTime.value}
          </div>
          <div className="text-xs">{statsData.completionTime.subtext}</div>
        </div>
      </div>
      <div className="w-1/3">
        <div className="h-full p-3 bg-grey-80 rounded-lg flex flex-col items-center">
          <div className="text-xs mb-4">
            {currentLifecycleStage.name} Engagement
          </div>
          <AudienceAttributeSummaries
            filter={attributeSummaryFilter}
            attributeTarget={AttributeTarget.Org}
            attributesToSummarize={attributesToSummarize}
          />
        </div>
      </div>
    </div>
  );
}
