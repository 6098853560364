import { Dispatch, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import PrimaryButton from "../../../patterns/atoms/PrimaryButton";
import {
  IncentiveAction,
  ShopifyGiftIncentiveCampaign,
} from "../../../types/BackendTypes";
import { CampaignDefinitionEditAction } from "../../reducer";
import { Gift } from "../../types";
import ShopifyGiftModal from "../ShopifyGiftModal";
import { GET_SHOPIFY_PRODUCT } from "../../../graphql/queries";

interface Props {
  action: IncentiveAction;
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  isEditable: boolean;
}

export default function ShopifyGiftCampaignConfig({
  action,
  dispatch,
  isEditable,
}: Props) {
  const incentive = action.incentives[0] as ShopifyGiftIncentiveCampaign;
  const [shopifyGiftModalVisible, setShopifyGiftModalVisible] = useState(false);
  const [selectedGift, setSelectedGift] = useState<Gift>({
    product_id: "",
    variant_id: "",
    title: "",
    description: "",
    image: "",
  });

  const [fetchItems, { data, loading }] = useLazyQuery(GET_SHOPIFY_PRODUCT, {
    variables: {
      id: incentive.config.product_gid,
    },
  });

  useEffect(() => {
    if (incentive.config.product_gid) {
      fetchItems();
    }
  }, [fetchItems, incentive.config.product_gid]);

  useEffect(() => {
    if (data) {
      setSelectedGift({
        product_id: data.shopifyProduct.id,
        variant_id: data.shopifyProduct.firstVariantId,
        title: data.shopifyProduct.title,
        description: data.shopifyProduct.description,
        image: data.shopifyProduct.featuredImage.url,
      });
    }
  }, [data]);

  const updateSelectedGift = (gift: Gift) => {
    dispatch({
      type: "UpdateAfterAction",
      action: {
        ...action,
        incentives: [
          {
            ...incentive,
            config: {
              ...incentive.config,
              product_gid: gift.product_id,
              variant_gid: gift.variant_id,
            },
          },
        ],
      },
    });
    setSelectedGift(gift);
  };
  return (
    <>
      <label className="mb-2">Choose the gift you'll offer</label>
      <div className="text-xs font-normal leading-4 tracking-wide text-body-text-lighter">
        This gift will be offered to users who complete the behavior you define
      </div>

      {loading && <p className="py-4 text-sm">Loading...</p>}

      {!loading && selectedGift.product_id !== "" && (
        <div className="mt-4 p-3 text-sm bg-white border-1 border-grey-100 rounded-lg flex">
          <img
            src={selectedGift.image}
            className="object-contain w-10 self-center h-10 mr-2"
            alt=""
          />
          <div className="text-left flex-grow">
            <span className="font-semibold">Gift:</span> {selectedGift.title}
            <div className="text-xs text-grey-500 overflow-hidden">
              {selectedGift.description}
            </div>
          </div>
          {isEditable && (
            <div className="text-right float-right self-center">
              <PrimaryButton
                label="Update"
                onClick={() => setShopifyGiftModalVisible(true)}
              />
            </div>
          )}
        </div>
      )}

      {!selectedGift.product_id && (
        <div className="mt-4">
          <PrimaryButton
            label="Select gift"
            onClick={() => setShopifyGiftModalVisible(true)}
          />
        </div>
      )}

      {shopifyGiftModalVisible && (
        <ShopifyGiftModal
          close={() => setShopifyGiftModalVisible(false)}
          updateGift={updateSelectedGift}
        />
      )}
    </>
  );
}
