import { isBinaryFilterCondition, isTimeDelta } from "../lib/filters";
import { BinaryFilterCondition, TimeDelta } from "../types/BackendTypes";
import { Config, FilterCondition, Operators } from "./lib";
import RelativeDateInput from "./inputs/RelativeDateInput";

interface RelativeDateOptionsProps {
  operator: Operators;
  condition: FilterCondition;
  config: Config;
  onChange: (f?: BinaryFilterCondition) => void;
}
export const operatorSymbolMapRelativeDates: { [key in Operators]: string } = {
  EQ: "equal to",
  NE: "not equal to",
  GT: "after",
  LT: "before",
  NOT_NULL: "has any value",
  NULL: "has no value",
};

export function RelativeDateOption({
  operator,
  config,
  condition,
  onChange,
}: RelativeDateOptionsProps) {
  const isActive =
    condition.operator === operator &&
    isBinaryFilterCondition(condition) &&
    isTimeDelta(condition.value);
  return (
    <label className="px-2 text-xs leading-6 text-body-text-lighter">
      <input
        type="radio"
        name="operator"
        className="mr-2 text-body-text active:ring-1 focus:ring-white animate"
        defaultChecked={isActive}
        onClick={() => {
          const newCondition = { ...condition } as BinaryFilterCondition;
          newCondition.operator = operator as any;
          if (!isTimeDelta(newCondition.value)) {
            newCondition.value = {
              type: "time_delta",
              value: 2,
              unit: "day",
              direction: "past",
            } as TimeDelta;
          }

          onChange(newCondition);
        }}
      />
      {operatorSymbolMapRelativeDates[operator]}
      {isActive && (
        <div className="ml-6 mt-2">
          <RelativeDateInput
            onChange={(s: any) => {
              const newCondition = { ...condition };
              newCondition.value = s;
              onChange(newCondition);
            }}
            disabled={false}
            value={condition.value as TimeDelta}
          ></RelativeDateInput>
        </div>
      )}
    </label>
  );
}
