import { useContext, useMemo } from "react";
import { SessionContext } from "../SessionContext";
import { isBinaryFilterCondition, isTimeDelta } from "../lib/filters";
import { BinaryFilterCondition } from "../types/BackendTypes";
import {
  Config,
  FilterCondition,
  Operators,
  configForTarget,
  findTargetForCondition,
} from "./lib";
import { operatorSymbolMap } from "./FilterConditionEditor";

interface ConditionOptionProps {
  operator: Operators;
  condition: FilterCondition;
  config: Config;
  onChange: (f?: FilterCondition) => void;
}

export function ConditionOption({
  operator,
  condition,
  config,
  onChange,
}: ConditionOptionProps) {
  const session = useContext(SessionContext);
  const conditionTarget = useMemo(
    () => findTargetForCondition(condition, session.attributes),
    [condition, session.attributes],
  );
  const isActive =
    condition.operator === operator &&
    (!isBinaryFilterCondition(condition) || !isTimeDelta(condition.value));
  return (
    <label className="px-2 text-xs leading-6 text-body-text-lighter">
      <input
        type="radio"
        name="operator"
        className="mr-2 text-body-text active:ring-1 focus:ring-white animate"
        defaultChecked={isActive}
        onClick={() => {
          const newCondition = { ...condition };
          newCondition.operator = operator;
          if (operator === Operators.NOT_NULL || operator === Operators.NULL) {
            delete (newCondition as BinaryFilterCondition).value;
          } else if (!isBinaryFilterCondition(newCondition)) {
            const config = configForTarget(conditionTarget);
            (newCondition as any).value = config.defaultValue;
          }
          if (
            isBinaryFilterCondition(newCondition) &&
            isTimeDelta(newCondition.value)
          ) {
            newCondition.value = config.defaultValue;
          }
          onChange(newCondition);
        }}
      />
      {condition.target !== "event" && operatorSymbolMap[operator]}
      {condition.target === "event" && (
        <>has {operator === "NULL" && "not"} performed</>
      )}
      {isActive && isBinaryFilterCondition(condition) && (
        <div className="ml-6 mt-2">
          <config.valueComponent
            onChange={(s: any) => {
              const newCondition = { ...condition };
              newCondition.value = s;
              onChange(newCondition);
            }}
            value={condition.value}
            conditionTarget={conditionTarget}
          ></config.valueComponent>
        </div>
      )}
    </label>
  );
}
