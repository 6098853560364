import SecondaryButton from "./atoms/SecondaryButton";

export default function FullScreenError({
  message = "Sorry, something went wrong.",
  resetError,
}: {
  message?: string;
  resetError?: () => void;
}) {
  return (
    <div className="grid h-screen items-center text-center">
      <div>
        <div>{message}</div>
        {resetError && (
          <div className="inline-block">
            <SecondaryButton label="Try again" onClick={resetError} />
          </div>
        )}
      </div>
    </div>
  );
}
