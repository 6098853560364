import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { cx } from "../lib/cx";
import PavlovLogoBlack from "../patterns/symbols/PavlovLogoBlack";
import DataIntegrations from "./DataIntegrations";

const Stepper = ({ steps, currentStep }: any) => {
  return (
    <div className="mt-2 " aria-hidden="true">
      {/* Stepper component */}
      <div className="overflow-hidden rounded-full bg-grey-200">
        <div
          className="h-1 rounded-full bg-gradient-to-r from-grey-100 to-active-blue"
          style={{
            width:
              currentStep.name === "loading" ? "0%" : currentStep.progress.lg,
          }}
        />
      </div>

      <div className="hidden grid-cols-4 pt-2 text-sm font-medium sm:grid ">
        {steps.map((step: any, stepIdx: any) => (
          <div
            className={cx(
              stepIdx !== steps.length - 1
                ? "pr-8 text-body-text"
                : "text-right text-body-text-lightest",
            )}
            key={step.id}
          >
            {step.name}
          </div>
        ))}
      </div>
    </div>
  );
};

const OnboardFlow = ({ currentStep, handleStepChange }: any) => {
  switch (currentStep.name.toLowerCase()) {
    case "data":
      return (
        <DataIntegrations
          step={currentStep}
          handleStepChange={handleStepChange}
        />
      );
    case "link":
      return <StepTwo step={currentStep} handleStepChange={handleStepChange} />;
    case "incentives":
      return (
        <StepThree step={currentStep} handleStepChange={handleStepChange} />
      );
    case "integrate":
      return (
        <StepFour step={currentStep} handleStepChange={handleStepChange} />
      );
    default:
      // if there is an unknown step then go to stepone
      return (
        <DataIntegrations
          step={currentStep}
          handleStepChange={handleStepChange}
        />
      );
  }
};

const StepTwo = ({ step, handleStepChange }: any) => {
  const handleClick = (e) => {
    e.preventDefault();
    handleStepChange(step);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="pl pt-40">
        <button
          onClick={handleClick}
          className="rounded-full bg-dark-green px-10 py-3 font-bold text-white"
        >
          Complete Step 2
        </button>
      </div>
    </div>
  );
};

const StepThree = ({ step, handleStepChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    handleStepChange(step);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="pl pt-40">
        <button
          onClick={handleClick}
          className="rounded-full bg-dark-green px-10 py-3 font-bold text-white"
        >
          Complete Step 3
        </button>
      </div>
    </div>
  );
};
const StepFour = ({ step, handleStepChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    handleStepChange(step);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="pl pt-40">
        <button
          onClick={handleClick}
          className="rounded-full bg-dark-green px-10 py-3 font-bold text-white"
        >
          Complete Step 4
        </button>
      </div>
    </div>
  );
};

const steps = [
  {
    id: 1,
    name: "Data",
    href: "#",
    status: "complete",
    progress: { sm: "5%", lg: "4%" },
  },
  {
    id: 2,
    name: "Link",
    href: "#",
    status: "complete",
    progress: { sm: "5%", lg: "30%" },
  },
  {
    id: 3,
    name: "Incentives",
    href: "#",
    status: "current",
    progress: { sm: "5%", lg: "60%" },
  },
  {
    id: 4,
    name: "Integrate",
    href: "#",
    status: "upcoming",
    progress: { sm: "5%", lg: "100%" },
  },
];
const findStep = (st, stepList) => {
  return stepList.find((step) => step.name.toLowerCase() === st.toLowerCase());
};
// Setup the page stepper and onboarding flow components
export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get("step");

  const [currentStep, setCurrentStep] = React.useState({ name: "loading" });

  useEffect(() => {
    if (step === null) {
      setSearchParams("step=data");
    } else {
      if (findStep(step, steps) === undefined) {
        setSearchParams("step=data");
      } else {
        setCurrentStep(findStep(step, steps));
      }
    }
  }, [step, setSearchParams]);

  const handleStepChange = (stepEnding) => {
    const nextStep = steps.find((step) => step.id === stepEnding.id + 1);
    if (stepEnding.id === steps.length) {
      console.log("ONBOARDING COMPLETE THIS IS WHERE WE PUSH TO APP");
    } else {
      setSearchParams(`step=${nextStep.name.toLowerCase()}`);
    }
  };

  return (
    /* Onboarding Header */
    <div className="">
      <div className="border-b border-grey-100 py-4">
        <div className="container mx-auto flex flex-row">
          <div className="w-1/4 items-center">
            <Link to="/">
              <PavlovLogoBlack />
            </Link>
          </div>

          <div className="w-1/2">
            <Stepper steps={steps} currentStep={currentStep} />
          </div>
        </div>
      </div>

      <div className="container mx-auto flex flex-col">
        {/* loading state or onboardingflow */}
        {currentStep.name === "loading" ? (
          <div></div>
        ) : (
          <OnboardFlow
            currentStep={currentStep}
            handleStepChange={handleStepChange}
          />
        )}
      </div>
    </div>
  );
}
