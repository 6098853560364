import { Filter, FilterGroup } from "../types/BackendTypes";
import { FILTER_DOC_VERSION, buildNewCondition } from "./lib";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_ATTRIBUTES_FOR_CONTEXT } from "../graphql/queries";
import AttributePicker from "./AttributePicker";
import useDocumentClick from "../hooks/useDocumentClick";
import FilterGroupEditor from "./FilterGroupEditor";
import { AttributeType } from "./lib";
import { SessionContext } from "../SessionContext";
import { FilterBuilderContext } from "../FilterBuilderContext";

interface Props {
  filterDoc: Filter;
  readonly?: boolean;
  attributePickerLabel?: string;
  showOrgAttrs?: boolean;
  showPersonAttrs?: boolean;
  showEvents?: boolean;
  onChange: (d: Filter) => void;
}

export default function FilterBuilder({
  filterDoc,
  attributePickerLabel = "Add a filter group",
  readonly = false,
  showOrgAttrs = true,
  showPersonAttrs = true,
  showEvents = true,
  onChange,
}: Props) {
  const conditions = filterDoc.filters.conditions;
  const session = useContext(SessionContext);
  const filterBuilderContext = useContext(FilterBuilderContext);
  const { data: attributesData } = useQuery(GET_ATTRIBUTES_FOR_CONTEXT, {
    variables: {
      context: filterBuilderContext.from,
    },
  });

  const [openEditorPath, setOpenEditorPath] = useState<string>();
  const [attributePickerOpen, setAttributePickerOpen] = useState(false);

  const [personAttributes, orgAttributes] = useMemo(() => {
    if (attributesData?.attributesForContext) {
      return [
        attributesData.attributesForContext.person,
        attributesData.attributesForContext.org,
      ];
    } else {
      return [session.attributes.person, session.attributes.org];
    }
  }, [attributesData, session.attributes]);

  const availableAttributes = useMemo(() => {
    return {
      person: showPersonAttrs ? personAttributes : [],
      org: showOrgAttrs ? orgAttributes : [],
    };
  }, [personAttributes, orgAttributes, showPersonAttrs, showOrgAttrs]);

  const eventNames = useMemo(() => {
    if (filterBuilderContext.from) {
      return [];
    } else {
      return session.eventNames;
    }
  }, [filterBuilderContext.from, session.eventNames]);

  const availableEventNames = useMemo(() => {
    return showEvents ? eventNames : [];
  }, [eventNames, showEvents]);

  useEffect(() => {
    if (openEditorPath !== undefined) {
      setAttributePickerOpen(false);
    }
  }, [openEditorPath]);

  const closeAllEditors = useCallback(
    () => setOpenEditorPath(undefined),
    [setOpenEditorPath],
  );

  const closeAttributePicker = useCallback(
    () => setAttributePickerOpen(false),
    [setAttributePickerOpen],
  );

  useDocumentClick(closeAllEditors);
  useDocumentClick(closeAttributePicker);

  const addCondition = useCallback(
    (attr: AttributeType) => {
      onChange({
        version: FILTER_DOC_VERSION,
        filters: {
          operator: "AND",
          conditions: [...conditions, buildNewCondition(attr)],
        },
      });
      setOpenEditorPath(`0-${String(conditions.length)}`);
    },
    [onChange, setOpenEditorPath, conditions],
  );

  return (
    <div className="">
      <FilterGroupEditor
        filterGroup={filterDoc.filters}
        openEditorPath={openEditorPath}
        setOpenEditorPath={setOpenEditorPath}
        availableAttributes={availableAttributes}
        availableEventNames={availableEventNames}
        path={"0"}
        readonly={readonly}
        onChange={(newGroup: FilterGroup) => {
          onChange({
            version: FILTER_DOC_VERSION,
            filters: {
              ...newGroup,
            },
          });
        }}
      />
      {!readonly && (
        <AttributePicker
          attributePickerOpen={attributePickerOpen}
          availableAttributes={availableAttributes}
          availableEventNames={availableEventNames}
          setAttributePickerOpen={setAttributePickerOpen}
          onPickerOpened={setOpenEditorPath}
          openerStyle="root"
          onAttributePicked={addCondition}
          openerLabel={attributePickerLabel}
        />
      )}
    </div>
  );
}
