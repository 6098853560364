import { cx } from "../../lib/cx";
import CheckMark from "../check-mark";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

interface Props {
  label: string;
  description?: string;
  isValid: boolean;
  error?: boolean;
  onClick?: () => void;
}

export default function BuilderSectionHeader({
  label,
  description,
  isValid,
  error,
  onClick,
}: Props) {
  return (
    <div
      className={cx(
        "animate flex text-xs cursor-pointer hover:text-body-text mb-3",
        !isValid && "text-grey-500",
      )}
      onClick={onClick}
    >
      {!error ? (
        <CheckMark isChecked={isValid} />
      ) : (
        <div className="text-xs text-red-500 pr-2">
          <ExclamationTriangleIcon style={{ height: "16px", width: "16px" }} />
        </div>
      )}

      <div className="inline-block">
        <h6 className="font-semibold pb-1">{label}</h6>
        <div className={cx("text-grey-600", !isValid && "text-grey-400")}>
          {description}
        </div>
      </div>
    </div>
  );
}
