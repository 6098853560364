import React, { useState, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { CREATE_LIFECYCLE_STAGE } from "../graphql/mutations";
import Modal from "../patterns/Modal";
import SubSectionHeader from "../patterns/SubSectionHeader";
import LifecycleStageButton from "../patterns/atoms/LifecycleStageButton";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import SelectInput from "../patterns/forms/SelectInput";
import { TargetMemberTypeChoices } from "../__generated__/graphql";

interface Props {
  closeModal: () => void;
}

export default function NewLifecycleStage({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [lifecycleStageName, setLifecycleStageName] = useState("");
  const lifecycleStageNames = [
    {
      label: "Onboarding",
      value: "Onboarding",
    },
    {
      label: "Activation",
      value: "Activation",
    },
    {
      label: "Expansion",
      value: "Expansion",
    },
    {
      label: "Retention",
      value: "Retention",
    },
  ];

  const [createLifecycleStageMutation] = useMutation(CREATE_LIFECYCLE_STAGE);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const createLifecycleStage = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        const refetchQueries = ["GetLifecycleStages"];
        const result = await createLifecycleStageMutation({
          variables: {
            name: lifecycleStageName,
            targetMemberType: TargetMemberTypeChoices.Org,
          },
          refetchQueries,
        });
        if (result.data["createLifecycleStage"].ok) {
          toast.success("Lifecycle Stage created successfully");
          navigate(
            `/lifecycle_stages/${result.data["createLifecycleStage"]["lifecycleStage"].id}/?view=settings`,
          );
          closeModal();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [createLifecycleStageMutation, lifecycleStageName, navigate, closeModal],
  );

  return (
    <Modal close={closeModal} title={`Create a Lifecycle Stage`} size="large">
      <form
        ref={formRef}
        className=""
        action="#"
        onSubmit={createLifecycleStage}
      >
        <>
          <SelectInput
            label={"Select a Lifecycle Stage"}
            description={""}
            placeholder="Pick a stage"
            required={true}
            value={lifecycleStageName}
            options={lifecycleStageNames}
            disabled={false}
            onChange={(s) => {
              setLifecycleStageName(s);
            }}
          />

          <label>{`Who should this Lifecycle Stage target?`}</label>
          <div className="flex flex-col gap-y-2 mb-4">
            <label className="px-2 text-xs flex">
              <input
                type="radio"
                className="mr-3 active:ring-1 focus:ring-white animate text-gray-400"
                checked={false}
                onChange={() => {}}
                disabled={true}
              />{" "}
              <SubSectionHeader
                label="An Individual"
                description="Track if an individual completes an action themselves"
                disabled={true}
              />
            </label>
            <label className="px-2 text-xs flex">
              <input
                type="radio"
                className="mr-3 active:ring-1 focus:ring-white animate text-gray-400"
                checked={true}
                onChange={() => {}}
                disabled={true}
              />{" "}
              <SubSectionHeader
                label="An Organization"
                description="Track if anyone within an organization completes an action"
                disabled={true}
              />
            </label>
          </div>
          <div className="flex w-full gap-x-4">
            <SecondaryButton
              label="Cancel"
              onClick={() => closeModal()}
              fullWidth={true}
            />
            <LifecycleStageButton
              label="Create Lifecycle Stage"
              isDisabled={isSaving}
              onClick={() => formRef.current.requestSubmit()}
              fullWidth={true}
            />
          </div>
        </>
      </form>
    </Modal>
  );
}
