import StatsHeading from "../patterns/StatsHeading";
import Card from "../patterns/Card";
import CardMenu from "../patterns/CardMenu";
import { BehaviorType, Timeseries } from "../__generated__/graphql";
import Spark from "../patterns/charts/Spark";
import Bar from "../patterns/charts/Bar";
import { get } from "lodash";
import moment from "moment";
import {
  Items,
  AvgDaysToCompleteView,
  CompletedCountView,
  CompletedPercentageView,
  CompletedMembersView,
  CompletedTimeseriesView,
  CompletedAndEnteredCountGraphView,
  ListViewComponent,
  NumberComponent,
  PercentageComponent,
  TimeSeriesGraphComponent,
  EnteredCountView,
  EnteredPercentageView,
  ExitedCountView,
  ExitedPercentageView,
  BarGraphComponent,
  TextTile,
  Components,
} from "../types/BackendTypes";
import { binDataBy } from "../patterns/charts/utils";
import { formatPercentage, formatNumber } from "../lib/number";

type ElementOf<T> = T extends Array<infer U> ? U : never;
export type DashboardViewComponent = ElementOf<Components>;
export type DashboardItem = ElementOf<Items>;

function widthMapper(width: string) {
  switch (width) {
    case "full":
      return "w-full";
    case "1/5":
      return "w-1/5";
    case "1/6":
      return "w-1/6";
    case "2/5":
      return "w-2/5";
    case "2/6":
      return "w-2/6";
    case "3/5":
      return "w-3/5";
    case "3/6":
      return "w-3/6";
    case "4/5":
      return "w-4/5";
    case "4/6":
      return "w-4/6";
    case "5/6":
      return "w-5/6";
    default:
      return "w-full";
  }
}

function TimeSeriesGraph({
  behavior,
  config,
}: {
  behavior: BehaviorType;
  config: CompletedTimeseriesView;
}) {
  const numberComponent = config.components[0] as NumberComponent;
  const percentageComponent = config.components[1] as PercentageComponent;
  const timeseriesComponent = config.components[2] as TimeSeriesGraphComponent;
  const completed = get(behavior, numberComponent.key, 0);
  const completedPercentage = Math.round(
    (get(behavior, percentageComponent.nominator_key, 0) /
      get(behavior, percentageComponent.denominator_key, 1)) *
      100,
  );
  const data = get(behavior, timeseriesComponent.key, []);
  const hasAnyData = data.length > 0;
  return (
    <div className="flex flex-col h-full">
      {hasAnyData ? (
        <StatsHeading
          title={behavior.name}
          stat={completed}
          subtitle={completedPercentage ? `${completedPercentage}%` : "-"}
        />
      ) : (
        <StatsHeading title={behavior.name} stat={100} subtitle="No data yet" />
      )}
      <div className="flex h-full">
        <Spark
          data={[
            {
              id: 1,
              data: data,
            },
          ]}
          color={"#1A41CF"}
        />
      </div>
    </div>
  );
}

function BarGraph({
  behavior,
  config,
}: {
  behavior: BehaviorType;
  config: CompletedAndEnteredCountGraphView;
}) {
  const enteredComp = config.components[0] as BarGraphComponent;
  const entered = get(behavior, enteredComp.key, []);
  const completedComp = config.components[1] as BarGraphComponent;
  const completed = get(behavior, completedComp.key, []);

  const weeklyAggregator = (datum: Timeseries) => {
    const date = new Date(datum.x);
    return `${date.getFullYear()}-${date.getMonth()}-${moment(date).startOf("isoWeek").date()}`;
  };

  const enteredByDate = binDataBy(entered, weeklyAggregator);
  const completedByDate = binDataBy(completed, weeklyAggregator);
  const allDates = new Set([
    ...Object.keys(enteredByDate),
    ...Object.keys(completedByDate),
  ]);

  const data = Array.from(allDates).map((d) => {
    const c = completedByDate[d] || 0;
    const e = enteredByDate[d] || 0;
    return {
      date: d,
      entered: e,
      completed: c,
    };
  });

  return (
    <div className="flex flex-col w-full h-full min-h-64">
      <div className="text-xs text-body-text-lightest">{config.title}</div>
      <Bar data={data} keys={["entered", "completed"]} indexBy="date" />
    </div>
  );
}

function Count({
  behavior,
  config,
}: {
  behavior: BehaviorType;
  config:
    | CompletedCountView
    | EnteredCountView
    | ExitedCountView
    | AvgDaysToCompleteView;
}) {
  const numberComponent = config.components[0] as NumberComponent;
  const count = get(behavior, numberComponent.key, 0);
  const formattedCount = count === Math.floor(count) ? count : count.toFixed(2);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <StatsHeading
        title={behavior.name}
        stat={formattedCount}
        subtitle={config.title}
      />
    </div>
  );
}

function Percentage({
  behavior,
  config,
}: {
  behavior: BehaviorType;
  config:
    | CompletedPercentageView
    | EnteredPercentageView
    | ExitedPercentageView;
}) {
  const percentageComponent = config.components[0] as PercentageComponent;
  const nominator = get(behavior, percentageComponent.nominator_key, 0);
  const denominator = get(behavior, percentageComponent.denominator_key) || 1;
  const percentage = Math.round((nominator / denominator) * 100);
  return (
    <>
      <div className="text-xs text-body-text-lightest">{behavior.name}</div>
      <div className="flex flex-col gap-2 items-center justify-center w-full h-full">
        <div className="font-bold tracking-tight text-body-text text-3xl">
          {percentage}%
        </div>
        <div className="text-xs text-body-text-lightest">{config.title}</div>
      </div>
    </>
  );
}

function CompletedSummary({ behavior, config }) {
  return (
    <div className="flex flex-col w-full h-full">
      {config.components.map((component: DashboardViewComponent) => {
        return (
          <>
            {component.viz_type === "number" && (
              <StatsHeading
                title={component.label}
                stat={formatNumber(get(behavior, component.key, 0), 1)}
              />
            )}
            {component.viz_type === "percentage" && (
              <StatsHeading
                title={component.label}
                stat={formatPercentage(
                  get(behavior, component.nominator_key, 0),
                  get(behavior, component.denominator_key) || 1,
                )}
              />
            )}
          </>
        );
      })}
    </div>
  );
}

function CompletedMembers({
  behavior,
  config,
}: {
  behavior: BehaviorType;
  config: CompletedMembersView;
}) {
  const listComponent = config.components[1] as ListViewComponent;
  const members = get(behavior, listComponent.key, []);
  // const numberComponent = config.components[0] as NumberComponent;
  // const count = get(behavior, numberComponent.key, 0);

  return (
    <>
      <div className="text-xs text-body-text-lightest">{behavior.name}</div>
      <div className="flex flex-col gap-2 w-full h-full">
        <div className="font-bold tracking-tight text-body-text">
          {config.title}
        </div>
        <div className="flex flex-col gap-2">
          {members.map((member: any) => (
            <span className="text-body-text text-xs py-2 border-b border-border-color">
              {member.displayName}
            </span>
          ))}
        </div>
        {/* <div className="text-xs text-body-text-lightest">
          ...{count - members.length} more
        </div> */}
      </div>
    </>
  );
}

function TextDivider({
  behavior,
  config,
}: {
  behavior: BehaviorType;
  config: TextTile;
}) {
  return (
    <>
      <div className="flex flex-col justify-center items-center gap-2 w-full h-full">
        <div className="font-bold tracking-tight text-body-text">
          {config.title}
        </div>
      </div>
    </>
  );
}

interface Props {
  behavior: BehaviorType;
  config: DashboardItem;
}
export default function FlexibleBehaviorCard({ behavior, config }: Props) {
  return (
    <div className={`flex ${widthMapper(config.width)}`}>
      <CardMenu clickableCards={false}>
        <Card>
          <div className="h-full" key={behavior.id}>
            {(() => {
              switch (config.type) {
                case "completed_count":
                case "entered_count":
                case "exited_count":
                case "avg_days_to_complete":
                  return <Count behavior={behavior} config={config} />;
                case "completed_timeseries":
                  return (
                    <TimeSeriesGraph behavior={behavior} config={config} />
                  );
                case "completed_members":
                  return (
                    <CompletedMembers behavior={behavior} config={config} />
                  );
                case "completed_percentage":
                case "entered_percentage":
                case "exited_percentage":
                  return <Percentage behavior={behavior} config={config} />;

                case "completed_entered_count_graph":
                  return <BarGraph behavior={behavior} config={config} />;

                case "text_divider":
                  return <TextDivider behavior={behavior} config={config} />;
                case "completed_summary":
                  return (
                    <CompletedSummary behavior={behavior} config={config} />
                  );
                case "entered_timeseries":
                case "entered_members":
                case "exited_members":
                case "graph_days_to_complete":
                  return <></>;
                default:
                  const exhaustiveCheck: never = config;
                  throw new Error(`Unknown action type ${exhaustiveCheck}`);
              }
            })()}
          </div>
        </Card>
      </CardMenu>
    </div>
  );
}
