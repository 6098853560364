import { cx } from "../../lib/cx";
import SkeletonScreen from "../Skeleton";

interface TableCell {
  content: string | React.ReactNode;
}

interface TableRow {
  id: string;
  onClick: (e: React.MouseEvent, id: string) => void;
  cells: TableCell[];
}

export interface TableProps {
  header: { label: string; width?: string }[];
  rows: TableRow[];
  filterBar?: React.ReactNode;
  isLoading?: boolean;
  emptyMessage?: string;
}

export default function Table({
  header,
  rows,
  filterBar,
  isLoading = false,
  emptyMessage = "No results",
}: TableProps) {
  return (
    <div className="mx-8 mb-6 rounded-xl bg-white">
      {filterBar && (
        <div className="rounded-xl rounded-b-none border-b border-rule-color bg-grey-50 p-2">
          {filterBar}
        </div>
      )}
      <div className="relative">
        {isLoading && (
          <div className="absolute grid justify-center bg-white w-full h-dvh">
            <SkeletonScreen />
          </div>
        )}
        {rows.length === 0 && !isLoading && (
          <div className="absolute grid justify-center bg-white w-full p-4">
            <div className="grid items-center justify-center text-xs py-2 tracking-wide">
              {" "}
              {emptyMessage}
            </div>
          </div>
        )}
        <div
          className={`w-full overflow-hidden ${!filterBar ? "rounded-lg" : ""}`}
        >
          <table className="w-full text-sm">
            <thead className="text-xs border-b border-rule-color bg-grey-50 text-left text-body-text-lightest">
              <tr>
                {header.map((h, i) => (
                  <th
                    key={h.label}
                    className={cx(
                      "py-2 font-normal",
                      i === 0 && "pl-4",
                      h.width,
                    )}
                  >
                    {h.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((r, rowIndex) => (
                <tr
                  key={r.id}
                  className="animate cursor-pointer border-b border-rule-color last:border-none p-2 bg-white hover:bg-grey-20"
                  onClick={(e: React.MouseEvent) =>
                    r.onClick(e, rowIndex.toString())
                  }
                  data-testid="patterns__table__table-row"
                >
                  {r.cells.map((c, i) => (
                    <td
                      key={i}
                      className={cx(
                        "py-3 text-xs",
                        i === 0
                          ? "pl-4 text-left " // width for the first column
                          : i === 1
                            ? "pr-4 text-left text-body-text-lightest" // width for the second column
                            : "pr-4 text-left text-body-text-lightest", // default width for other columns
                        i === r.cells.length - 1 && "", // last column
                      )}
                    >
                      {c.content}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
