import { MachineCampaignType } from "../../__generated__/graphql";
import Modal from "../../patterns/Modal";
import PrimaryButton from "../../patterns/atoms/PrimaryButton";
import SecondaryButton from "../../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";

import { ARCHIVE_CAMPAIGN } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

interface Props {
  campaign: MachineCampaignType;
  close: () => void;
}

export default function ArchiveModal({ campaign, close }: Props) {
  const navigate = useNavigate();
  const [archiveMutation] = useMutation(ARCHIVE_CAMPAIGN);
  const entityName = campaign.isBehavior ? "behavior" : "campaign";
  return (
    <Modal
      title={`Are you sure you want to archive this ${entityName}?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 220 220"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="220"
            height="220"
            rx="110"
            transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 220 220)"
            fill="url(#paint0_linear_143_1732)"
            fill-opacity="0.33"
          />
          <path
            d="M30.49 81.4206C29.3567 81.9203 28.8425 83.2457 29.3422 84.379L29.9523 85.7628L28.5685 86.373C27.4352 86.8727 26.9209 88.198 27.4206 89.3313C27.9203 90.4646 29.2457 90.9789 30.379 90.4792L31.7628 89.8691L32.373 91.2529C32.8727 92.3862 34.198 92.9004 35.3313 92.4007C36.4646 91.901 36.9789 90.5757 36.4792 89.4423L35.869 88.0585L37.2528 87.4484C38.3862 86.9487 38.9004 85.6234 38.4007 84.49C37.901 83.3567 36.5757 82.8425 35.4423 83.3422L34.0585 83.9523L33.4484 82.5685C32.9487 81.4352 31.6234 80.9209 30.49 81.4206Z"
            fill="#1E293B"
          />
          <path
            d="M186.296 145.165L184.805 144.738L185.199 143.12C185.52 141.796 184.796 140.436 183.571 140.084C182.328 139.731 181.09 140.525 180.768 141.845L180.374 143.463L178.879 143.036C177.636 142.689 176.402 143.478 176.076 144.798C175.755 146.123 176.48 147.482 177.704 147.834L179.195 148.261L178.801 149.879C178.48 151.204 179.204 152.563 180.429 152.916C180.626 152.97 180.823 153 181.016 153C182.034 153 182.961 152.266 183.232 151.154L183.626 149.536L185.121 149.963C185.319 150.018 185.516 150.048 185.708 150.048C186.727 150.048 187.653 149.313 187.924 148.202C188.245 146.877 187.52 145.517 186.296 145.165Z"
            fill="#1E293B"
          />
          <path
            d="M35.1015 117.202C35.1576 121.065 38.3403 124.156 42.2024 124.1C46.0645 124.044 49.1561 120.861 49.1001 116.999C49.044 113.137 45.8613 110.046 41.9992 110.102C38.1371 110.158 35.0455 113.34 35.1015 117.202ZM44.5403 117.065C44.5598 118.411 43.4813 119.521 42.1362 119.54C40.7911 119.56 39.6808 118.481 39.6613 117.136C39.6418 115.791 40.7203 114.681 42.0654 114.661C43.4105 114.642 44.5208 115.72 44.5403 117.065Z"
            fill="#1E293B"
          />
          <path
            d="M159.043 46.9997C159.019 45.343 157.656 44.0195 156 44.0435C154.343 44.0676 153.019 45.4301 153.043 47.0867C153.067 48.7434 154.43 50.0669 156.087 50.0429C157.743 50.0188 159.067 48.6563 159.043 46.9997Z"
            fill="#1E293B"
          />
          <path
            d="M162.25 82.5L158.292 149.836C158.078 153.465 156.486 156.876 153.84 159.37C151.195 161.863 147.696 163.251 144.061 163.25H75.9393C72.3038 163.251 68.8051 161.863 66.1596 159.37C63.514 156.876 61.9216 153.465 61.7083 149.836L57.75 82.5M97.3333 106.25H122.667M55.375 82.5H164.625C168.558 82.5 171.75 79.308 171.75 75.375V65.875C171.75 61.942 168.558 58.75 164.625 58.75H55.375C51.442 58.75 48.25 61.942 48.25 65.875V75.375C48.25 79.308 51.442 82.5 55.375 82.5Z"
            stroke="black"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_143_1732"
              x1="226"
              y1="150"
              x2="-37.2822"
              y2="143.301"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFE8C7" />
              <stop offset="0.51" stop-color="#FFC2FC" />
              <stop offset="1" stop-color="#DBFAFF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="text-center text-xs mt-2">
        {`Once archived the ${entityName} will no longer be shown in the ${entityName} list.`}
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <PrimaryButton
          label="Archive"
          onClick={async () => {
            try {
              const response = await archiveMutation({
                variables: { id: campaign.id },
                update(cache) {
                  const normalizedId = cache.identify({
                    id: campaign.id,
                    __typename: "MachineCampaignType",
                  });
                  cache.modify({
                    id: normalizedId,
                    fields: {
                      deleted() {
                        return true;
                      },
                    },
                  });
                  cache.gc();
                },
              });
              if (response.data.archiveCampaign.ok) {
                navigate(`/${entityName}s`);
                toast.success(`${entityName} archived`);
              } else {
                toast.error(`Failed to archive ${entityName}`);
              }
            } catch (e) {
              toast.error(`Failed to archive ${entityName}`);
            }
          }}
        />
      </div>
    </Modal>
  );
}
