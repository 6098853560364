import {
  CohortType,
  TargetMemberTypeChoices,
} from "../../../__generated__/graphql";
import BuilderSectionHeader from "../../../campaign_form/builder_sections/builder-section-header";
import FilterBuilder from "../../../filter_builder";
import useFilteredAudience from "../../../hooks/useFilteredAudience";
import AudienceTargeting from "../../../campaign_form/drawer/AudienceTargeting";

interface Props {
  cohort: CohortType;
  openDrawer: () => void;
}

export default function AudienceBuilderSection({ cohort, openDrawer }: Props) {
  const audienceCriteria = cohort.audience;
  const hasAudience =
    audienceCriteria.criteria?.[0]?.filter?.filters?.conditions.length > 0;
  const targetingOrgs = cohort.targetMemberType === TargetMemberTypeChoices.Org;

  const { audience, loading: audienceLoading } = useFilteredAudience(
    audienceCriteria.criteria[0].filter,
    cohort.targetMemberType,
  );

  return (
    <div onClick={openDrawer}>
      <BuilderSectionHeader
        label="Audience Targeting"
        description={`Define how to match ${targetingOrgs ? "organizations" : "individuals"} to this cohort?`}
        isValid={hasAudience}
      />
      <div
        className={`${hasAudience ? "flex gap-2 flex-wrap ml-7 cursor-pointer  " : ""}`}
      >
        <FilterBuilder
          filterDoc={audienceCriteria.criteria[0].filter}
          readonly={true}
          onChange={() => {}}
        />
      </div>
      <div className="ml-6 max-w-md">
        <AudienceTargeting
          filter={audienceCriteria.criteria[0].filter}
          audience={audience}
          loading={audienceLoading}
          objTargetsOrgs={targetingOrgs}
          entityName="cohort"
        />
      </div>
    </div>
  );
}
