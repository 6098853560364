import { CohortType } from "../../../__generated__/graphql";
import BuilderSectionHeader from "../../../campaign_form/builder_sections/builder-section-header";
import FilterBuilder from "../../../filter_builder";
import SettingsBlock from "../../../campaign_form/SettingsBlock";
import {
  hasValidCriteria,
  completionCriteriaDescription,
  isAttrCriteria,
} from "../../../campaign/lib";

interface Props {
  cohort: CohortType;
  openDrawer: () => void;
}

export default function CompletionCriteriaBuilderSection({
  cohort,
  openDrawer,
}: Props) {
  const completionCriteria = cohort.observing.criteria[0];
  const hasCriteria = cohort.observing.criteria.length > 0;
  return (
    <div onClick={openDrawer}>
      <BuilderSectionHeader
        label="Completion Criteria"
        description="Define the criteria that will disqualify a member from this cohort"
        isValid={hasCriteria}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {isAttrCriteria(completionCriteria) ? (
          <div className="mt-2">
            <FilterBuilder
              filterDoc={completionCriteria.condition.filter}
              readonly={true}
              onChange={() => {}}
            />
          </div>
        ) : (
          hasValidCriteria(completionCriteria) && (
            <SettingsBlock
              title={completionCriteriaDescription(completionCriteria)}
              section="events"
              onClick={() => {}}
            />
          )
        )}
      </div>
    </div>
  );
}
