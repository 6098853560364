import { Node } from "@tiptap/core";
import { DOMOutputSpec, Node as ProseMirrorNode } from "@tiptap/pm/model";
import { PluginKey } from "@tiptap/pm/state";

export type VariableOptions = {
  HTMLAttributes: Record<string, any>;
  renderText: (props: {
    options: VariableOptions;
    node: ProseMirrorNode;
  }) => string;
  renderHTML: (props: {
    options: VariableOptions;
    node: ProseMirrorNode;
  }) => DOMOutputSpec;
};

export const MentionPluginKey = new PluginKey("mention");

export const Variable = Node.create<VariableOptions>({
  name: "variable",
  group: "inline",
  inline: true,
  selectable: true,
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      label: {
        default: null,
      },

      value: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
        getAttrs: (element: HTMLElement) => {
          const value = element.getAttribute("data-value");
          const segments = value.split(".");
          return {
            value: value,
            label: segments[segments.length - 1],
          };
        },
      },
    ];
  },

  renderHTML({ node }) {
    return [
      "span",
      {
        class: "tiptap-variable",
        "data-type": Variable.name,
        "data-value": node.attrs.value,
      },
      `${node.attrs.label}`,
    ];
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isVariable = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isVariable = true;
              tr.insertText("", pos, pos + node.nodeSize);

              return false;
            }
          });

          return isVariable;
        }),
    };
  },
});
