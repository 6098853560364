import { OrgAttribute, PersonAttribute } from "../__generated__/graphql";
import { useCallback, useState } from "react";
import { attrTypeMap } from "../filter_builder/lib";
import { useMutation } from "@apollo/client";
import {
  UPDATE_ORG_ATTRIBUTE,
  UPDATE_PERSON_ATTRIBUTE,
} from "../graphql/mutations";
import toast from "react-hot-toast";
import IconButton from "../patterns/atoms/IconButton";
import { isOrgAttribute, parsedAttributeValue } from "../lib/attributes";

interface Props {
  attr: PersonAttribute | OrgAttribute;
  entityId: string;
  allowEditing?: boolean;
  closeEditor: () => void;
}

export function AttributeEditor({ attr, entityId, closeEditor }: Props) {
  const config = attrTypeMap[attr.aType];
  const [value, setValue] = useState(
    parsedAttributeValue(attr) ?? config.defaultValue,
  );
  const [updateAttributeMutation] = useMutation(
    isOrgAttribute(attr) ? UPDATE_ORG_ATTRIBUTE : UPDATE_PERSON_ATTRIBUTE,
  );
  const updateAttribute = useCallback(async () => {
    try {
      const optimisticResponse = {};
      optimisticResponse[
        isOrgAttribute(attr) ? "updateOrgAttribute" : "updatePersonAttribute"
      ] = {
        ok: true,
        attribute: {
          id: attr.id,
          source: attr.source,
          aType: attr.aType,
          value: JSON.stringify(value),
          __typename: isOrgAttribute(attr) ? "OrgAttribute" : "PersonAttribute",
        },
        __typename: isOrgAttribute(attr)
          ? "UpdateOrgAttribute"
          : "UpdatePersonAttribute",
      };
      const result = await updateAttributeMutation({
        variables: {
          entityId,
          name: attr.name,
          source: attr.source,
          value: JSON.stringify(value),
        },
        optimisticResponse,
      });
      if (result.data[Object.keys(result.data)[0]].ok) {
        toast.success("Attribute updated");
      } else {
        toast.error("Failed to update");
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [attr, value, updateAttributeMutation, entityId]);

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="absolute z-10 rounded-lg bg-white shadow-md mt-10 -ml-2 text-body-text"
    >
      <div className="flex flex-row w-auto h-auto overflow-y-auto p-3">
        <div className="flex">
          <config.valueComponent
            onChange={(s: any) => {
              setValue(s);
            }}
            value={value}
          ></config.valueComponent>
        </div>
        <div className="flex mt-1.5">
          <IconButton
            onClick={() => closeEditor()}
            icon="xMark"
            variant="destructive"
          />
          <IconButton
            onClick={() => {
              closeEditor();
              updateAttribute();
            }}
            icon="check"
            variant="confirm"
          />
        </div>
      </div>
    </div>
  );
}
