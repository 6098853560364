import { useQuery } from "@apollo/client";
import SkeletonScreen from "../patterns/Skeleton";
import { GET_PERSON_EVENT_HISTORY } from "../graphql/queries";
import HeaderCard from "../person/header-cards";
import { formatLong } from "../lib/date";
import { formatDistance } from "date-fns";
import Table, { TableProps } from "../patterns/tables/table";
import { sourceIcon } from "../filter_builder/lib";

interface PersonEventHistoryProps {
  personId: string;
  eventName: string;
}

interface EventNode {
  id: string;
  happenedAt: string;
  postedAt: string;
  source: string;
}

interface EventEdge {
  node?: EventNode;
}

function toTable(data: EventEdge[]): TableProps {
  return {
    header: [
      { label: "Event happened at" },
      { label: "Event imported at" },
      { label: "Event source" },
    ],
    rows: data.map((edge: any) => {
      const Icon = sourceIcon(edge.node.source);
      return {
        id: edge.node.id,
        onClick: () => {},
        cells: [
          { content: formatLong(edge.node.happenedAt) },
          { content: formatLong(edge.node.postedAt) },
          {
            content: (
              <div className="h-3 w-3">
                <Icon />
              </div>
            ),
          },
        ],
      };
    }),
  };
}

export default function PersonEventHistory({
  personId,
  eventName,
}: PersonEventHistoryProps) {
  const { loading, data } = useQuery(GET_PERSON_EVENT_HISTORY, {
    variables: { personId, eventName },
  });

  if (loading) return <SkeletonScreen />;

  const personDisplayName = data?.personEventHistory.person.displayName;
  const numEvents = data.personEventHistory.edges.length;
  const firstEventRelativeTime = formatDistance(
    new Date(data.personEventHistory.edges[numEvents - 1].node.happenedAt),
    new Date(),
  );
  const lastEventRelativeTime = formatDistance(
    new Date(data.personEventHistory.edges[0].node.happenedAt),
    new Date(),
  );
  const tableData = toTable(data.personEventHistory.edges);
  return (
    <>
      <div className="flex flex-row gap-2 px-8 py-6 text-xl font-bold tracking-tight">
        <span>{eventName}</span>
        <span className="text-grey-500">history for</span>
        <span>{personDisplayName}</span>
      </div>
      {numEvents && (
        <>
          <div className="flex flex-row gap-4 mx-8">
            <HeaderCard
              header="Recorded"
              indicator={String(numEvents)}
              footer="events in total"
            />
            <HeaderCard
              header="First Seen"
              indicator={firstEventRelativeTime}
              footer="ago"
            />
            <HeaderCard
              header="Last Seen"
              indicator={lastEventRelativeTime}
              footer="ago"
            />
          </div>
          <div className="mt-8">
            <Table header={tableData.header} rows={tableData.rows} />
          </div>
        </>
      )}
    </>
  );
}
