import { useQuery } from "@apollo/client";
import Modal from "../patterns/Modal";
import { GET_USER_PROFILE } from "../graphql/queries";
import { useState } from "react";
import SectionHeader from "../patterns/SectionHeader";
import IconButton from "../patterns/atoms/IconButton";
import useCopyToClipboard from "../hooks/useCopyToClipboard";

interface Props {
  closeModal: () => void;
}

export default function ApiKey({ closeModal }: Props) {
  const { data, loading } = useQuery(GET_USER_PROFILE);

  const [isHidden, setIsHidden] = useState(true);

  const copyToClipboard = useCopyToClipboard(data.userProfile.apiToken);

  if (loading) {
    return <></>;
  }

  return (
    <Modal close={closeModal} title="User profile">
      <div className="space-y-12">
        <div>
          <SectionHeader title="API Key" />
          <div className="py-2 flex items-center">
            <div className="text-xs w-96">
              {isHidden
                ? "****************************************"
                : data.userProfile.apiToken}
            </div>
            <IconButton
              icon={isHidden ? "eye" : "eyeSlash"}
              onClick={() => setIsHidden(!isHidden)}
            />
            <IconButton icon="clipboard" onClick={copyToClipboard} />
          </div>
        </div>
      </div>
    </Modal>
  );
}
