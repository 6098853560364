import PageHeader from "../../patterns/PageHeader";
import LabelAndToggle from "../label-and-toggle";
import { DrawerComponentProps } from "..";
import Card from "../../patterns/Card";
import EmptyState from "../../patterns/EmptyState";
import NoSender from "../../patterns/illustrations/NoSender";
import Alert from "../../patterns/Alert";
import {
  DelayedEmailAction,
  EmailAction,
  NotifyEnteredAction,
  UpdateCrmAction,
} from "../../types/BackendTypes";
import IncentiveActionConfig from "./incentive-action-config";
import MessageEditor from "../message-editor";
import SlackAction from "./slack_action";
import UpdateCrmActionEditor from "../../actions/UpdateCrmActionEditor";
import {
  MachineStatusChoices,
  TargetMemberTypeChoices,
  Flags,
} from "../../__generated__/graphql";
import useIsFeatureEnabled from "../../hooks/useIsFeatureEnabled";

export default function Engagement({
  dispatch,
  campaignConfig,
  targetMemberType,
  senders,
  incentives,
  campaignStatus,
}: DrawerComponentProps) {
  const canUseUpdateCrm = useIsFeatureEnabled(Flags.UpdateCrmAction);
  const campaignTarget =
    targetMemberType === TargetMemberTypeChoices.Org
      ? "organization"
      : "person";
  const pluralCampaignTarget =
    targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";

  const message = campaignConfig.before_actions.actions.find(
    (a) => a.type === "email",
  ) as EmailAction;
  const delayedMessage = campaignConfig.before_actions.actions.find(
    (a) => a.type === "delayed_email",
  ) as DelayedEmailAction;
  const notifyEnteredAction = campaignConfig.before_actions.actions.find(
    (a) => a.type === "notify_entered",
  ) as NotifyEnteredAction;
  const updateCrmAction = campaignConfig.before_actions.actions.find(
    (a) => a.type === "update_crm",
  ) as UpdateCrmAction | undefined;
  const hasSenders = senders.length > 0;

  return (
    <div>
      <PageHeader
        header="Audience engagement"
        subhead="How you'll incentivize customer behavior"
      />
      <IncentiveActionConfig
        dispatch={dispatch}
        campaignConfig={campaignConfig}
        campaignStatus={campaignStatus}
        senders={senders}
        incentives={incentives}
        isEditable={campaignStatus === MachineStatusChoices.Draft}
      />

      <div className="px-8 mb-8">
        <LabelAndToggle
          label="Message customer"
          isOn={!!message}
          disabled={campaignStatus === MachineStatusChoices.Complete}
          onChange={() => {
            !!message
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "email",
                  actionList: "before_actions",
                })
              : dispatch({ type: "EnableBeforeMessage" });
          }}
        />
        {!!message && !hasSenders && (
          <Card>
            <div className="p-4">
              <EmptyState
                title="You haven't added a sender yet"
                icon={<NoSender />}
                description={
                  "You'll need to add a sender before you can send an email. You can do this in Integrations/Messaging"
                }
              />
            </div>
          </Card>
        )}

        {!!message && hasSenders && (
          <MessageEditor
            dispatch={dispatch}
            targetMemberType={targetMemberType}
            message={message}
            senders={senders}
            isEditable={campaignStatus !== MachineStatusChoices.Complete}
            actionType="UpdateBeforeAction"
          />
        )}
      </div>
      <div className="px-8 mb-8">
        <LabelAndToggle
          label="Send reminder message"
          description={`If the ${campaignTarget} has not completed the campaign within a set number of days after entering, send them a follow up email`}
          isOn={!!delayedMessage}
          disabled={campaignStatus === MachineStatusChoices.Complete}
          onChange={() => {
            !!delayedMessage
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "delayed_email",
                  actionList: "before_actions",
                })
              : dispatch({ type: "EnableBeforeDelayedMessage" });
          }}
        />

        {!!delayedMessage && campaignStatus === MachineStatusChoices.Live && (
          <div className="pb-2">
            <Alert
              type="info"
              message={`Edits to this message, or the delay, will only affect ${pluralCampaignTarget} who enter the campaign after the changes are saved`}
              size="small"
            />
          </div>
        )}
        {!!delayedMessage && !hasSenders && (
          <Card>
            <div className="p-4">
              <EmptyState
                title="You haven't added a sender yet"
                icon={<NoSender />}
                description={
                  "You'll need to add a sender before you can send an email. You can do this in Integrations/Messaging"
                }
              />
            </div>
          </Card>
        )}

        {!!delayedMessage && hasSenders && (
          <>
            <MessageEditor
              dispatch={dispatch}
              targetMemberType={targetMemberType}
              message={delayedMessage}
              senders={senders}
              isEditable={campaignStatus !== MachineStatusChoices.Complete}
              actionType="UpdateBeforeAction"
            />
          </>
        )}
      </div>

      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Notify the team`}
          isOn={!!notifyEnteredAction}
          disabled={!(campaignStatus !== MachineStatusChoices.Complete)}
          onChange={() => {
            !!notifyEnteredAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "notify_entered",
                  actionList: "before_actions",
                })
              : dispatch({
                  type: "EnableNotifyEntered",
                  message: {
                    template: "",
                  },
                });
          }}
        />
        {notifyEnteredAction && (
          <SlackAction
            dispatch={dispatch}
            action={notifyEnteredAction}
            actionSource="before_actions"
          />
        )}
      </div>
      {canUseUpdateCrm && (
        <div className="px-8 mb-8">
          <LabelAndToggle
            label={`Update CRM fields`}
            isOn={!!updateCrmAction}
            disabled={!(campaignStatus !== MachineStatusChoices.Complete)}
            onChange={() => {
              !!updateCrmAction
                ? dispatch({
                    type: "RemoveActionOfType",
                    actionType: "update_crm",
                    actionList: "before_actions",
                  })
                : dispatch({
                    type: "EnableUpdateCrmFields",
                    actionList: "before_actions",
                  });
            }}
          />
          {updateCrmAction && (
            <Card>
              <UpdateCrmActionEditor
                dispatch={dispatch}
                action={updateCrmAction}
                actionSource="before_actions"
              />
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
