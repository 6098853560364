import { useContext } from "react";
import { SessionContext } from "../../../SessionContext";
import PageHeader from "../../../patterns/PageHeader";
import LabelAndToggle from "../../../campaign_form/label-and-toggle";
import DrawerComponentProps from "./drawer-component-props";
import {
  EmailAction,
  NotifyCompletedAction,
  UpdateCrmAction,
  WebhookAction,
} from "../../../types/BackendTypes";
import {
  TargetMemberTypeChoices,
  Flags,
  MachineStatusChoices,
} from "../../../__generated__/graphql";
import Card from "../../../patterns/Card";
import EmptyState from "../../../patterns/EmptyState";
import NoSender from "../../../patterns/illustrations/NoSender";
import EmailMessageEditor from "../../../actions/EmailMessageEditor";
import SlackAction from "../../../actions/SlackAction";
import WebhookActionEditor from "../../../actions/WebhookActionEditor";
import UpdateCrmActionEditor from "../../../actions/UpdateCrmActionEditor";
import useIsFeatureEnabled from "../../../hooks/useIsFeatureEnabled";

export default function FollowUpActionsDrawer({
  dispatch,
  referral,
  senders,
  close,
}: DrawerComponentProps) {
  const sessionContext = useContext(SessionContext);
  const canUseUpdateCrm = useIsFeatureEnabled(Flags.UpdateCrmAction);
  const referrerEmailAttribute = sessionContext?.attributes.person.find(
    (a) => a.name === "referrer_email" && a.source === "pavlov_referrals",
  );
  const referrerNameAttribute = sessionContext?.attributes.person.find(
    (a) => a.name === "referrer_name" && a.source === "pavlov_referrals",
  );
  const actionList = referral.actionsOnReferred;
  const notifyCompletedAction = actionList.actions.find(
    (a) => a.type === "notify_completed",
  ) as NotifyCompletedAction | undefined;
  const emailAction = actionList.actions.find((a) => a.type === "email") as
    | EmailAction
    | undefined;
  const webhookAction = actionList.actions.find((a) => a.type === "webhook") as
    | WebhookAction
    | undefined;
  const updateCrmAction = actionList.actions.find(
    (a) => a.type === "update_crm",
  ) as UpdateCrmAction | undefined;
  const hasSenders = senders.length > 0;
  const isOrgCampaign =
    referral.targetMemberType === TargetMemberTypeChoices.Org;
  const isEditable = referral.status !== MachineStatusChoices.Complete;

  return (
    <div>
      <PageHeader
        header="Follow up actions"
        subhead={`These will run after a ${isOrgCampaign ? "member of the organization" : "customer"} does the observed behavior`}
      />
      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Notify the team`}
          isOn={!!notifyCompletedAction}
          disabled={!isEditable}
          onChange={() => {
            !!notifyCompletedAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "notify_completed",
                  actionList: "actionsOnReferred",
                })
              : dispatch({
                  type: "EnableNotifyCompletedAction",
                });
          }}
        />
        {notifyCompletedAction && (
          <SlackAction
            dispatch={dispatch}
            action={notifyCompletedAction}
            actionSource="actionsOnReferred"
          />
        )}
      </div>
      {referrerEmailAttribute && referrerNameAttribute && (
        <div className="px-8 mb-8">
          <LabelAndToggle
            label={`Message the referrer`}
            isOn={!!emailAction}
            disabled={!isEditable}
            onChange={() => {
              !!emailAction
                ? dispatch({
                    type: "RemoveActionOfType",
                    actionType: "email",
                    actionList: "actionsOnReferred",
                  })
                : dispatch({
                    type: "EnableEmailReferrerAction",
                    referrerAttributeIds: {
                      email: referrerEmailAttribute?.id,
                      name: referrerNameAttribute?.id,
                    },
                  });
            }}
          />
          {!!emailAction && !hasSenders && (
            <Card>
              <div className="p-4">
                <EmptyState
                  title="You haven't added a sender yet"
                  icon={<NoSender />}
                  description={
                    "You'll need to add a sender before you can send an email. You can do this in Integrations/Messaging."
                  }
                />
              </div>
            </Card>
          )}

          {!!emailAction && hasSenders && (
            <EmailMessageEditor
              dispatch={dispatch}
              message={emailAction}
              senders={senders}
              isEditable={isEditable}
              actionList="actionsOnReferred"
            />
          )}
        </div>
      )}
      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Send a webhook`}
          isOn={!!webhookAction}
          disabled={!isEditable}
          onChange={() => {
            !!webhookAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "webhook",
                  actionList: "actionsOnReferred",
                })
              : dispatch({
                  type: "EnableWebhookAction",
                });
          }}
        />
        {webhookAction && (
          <WebhookActionEditor
            dispatch={dispatch}
            action={webhookAction}
            actionSource="actionsOnReferred"
          />
        )}
      </div>
      {canUseUpdateCrm && (
        <div className="px-8 mb-8">
          <LabelAndToggle
            label={`Update CRM fields`}
            isOn={!!updateCrmAction}
            disabled={!isEditable}
            onChange={() => {
              !!updateCrmAction
                ? dispatch({
                    type: "RemoveActionOfType",
                    actionType: "update_crm",
                    actionList: "actionsOnReferred",
                  })
                : dispatch({
                    type: "EnableUpdateCrmAction",
                  });
            }}
          />
          {updateCrmAction && (
            <Card>
              <UpdateCrmActionEditor
                dispatch={dispatch}
                action={updateCrmAction}
                actionSource="actionsOnReferred"
              />
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
