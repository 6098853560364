import { IncentiveType } from "../__generated__/graphql";

export default function incentiveLabel(
  incentives: Omit<IncentiveType, "config">[],
  id: string,
) {
  const incentive = incentives.find((i) => {
    return i.id === id;
  });
  if (!incentive) {
    return "Please choose an incentive";
  }
  return `Offer the incentive "${incentive.name}"`;
}
