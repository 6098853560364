import {
  BinaryFilterCondition,
  UnaryFilterCondition,
} from "../types/BackendTypes";
import { Config, FilterCondition, Operators } from "./lib";
import { AttrTypeEnum } from "../__generated__/graphql";
import { DateOptions } from "./DateOptions";
import { ConditionOption } from "./ConditionOption";

interface Props {
  condition: FilterCondition;
  config: Config;
  closeEditor: () => void;
  onChange: (f?: BinaryFilterCondition | UnaryFilterCondition) => void;
}

export const operatorSymbolMap: { [key in Operators]: string } = {
  EQ: "equal to",
  NE: "not equal to",
  GT: "greater than",
  LT: "less than",
  NOT_NULL: "has any value",
  NULL: "has no value",
};

const dateTypes = [AttrTypeEnum.Date, AttrTypeEnum.Datetime];

export default function FilterConditionEditor({
  condition,
  config,
  closeEditor,
  onChange,
}: Props) {
  const isDateOrDatetime = dateTypes.includes(config.dataType);
  return (
    <div className="absolute z-10 rounded-lg bg-white shadow-md mt-1">
      <div className="flex flex-col w-80 h-auto px-2 pt-4 pb-1">
        {isDateOrDatetime ? (
          <DateOptions
            condition={condition}
            config={config}
            onChange={onChange}
          />
        ) : (
          config.operators.map((o) => (
            <ConditionOption
              operator={o}
              condition={condition}
              config={config}
              onChange={onChange}
            />
          ))
        )}
      </div>
      <button
        className="flex w-full animate rounded-b-lg text-center justify-center bg-white text-body-text-lighter border-t py-3 text-sm font-normal hover:bg-blue-10 hover:text-active-blue"
        onClick={() => {
          closeEditor();
        }}
      >
        Close
      </button>
    </div>
  );
}
