import {
  BehaviorType,
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import { formatPercentage } from "./number";

export const emptyBehavior = {
  id: "",
  name: "",
  started: "",
  status: "DRAFT" as MachineStatusChoices,
  targetMemberType: "ORG" as TargetMemberTypeChoices,
  audience: JSON.stringify({
    criteria: [
      {
        version: "1",
        type: "filter",
        filter: {
          version: "1",
          filters: {
            operator: "AND",
            conditions: [],
          },
        },
      },
    ],
  }),
  observing: JSON.stringify({ criteria: [] }),
  actionsOnEnter: JSON.stringify({
    delivery: {
      mode: "bulk",
    },
    actions: [],
  }),
  actionsOnCompletion: JSON.stringify({
    delivery: {
      mode: "bulk",
    },
    actions: [],
  }),
  actionsWhileObserving: [],
  deleted: false,
  stats: {},
};

export function formatStats(
  behavior: BehaviorType,
  orgCount: number,
  peopleCount: number,
) {
  const { targetMemberType, stats } = behavior;
  const { entered, completed, timeToCompletion, observing } = stats;
  const enteredDenominator =
    targetMemberType === TargetMemberTypeChoices.Org ? orgCount : peopleCount;
  const enteredPercentage = formatPercentage(entered.count, enteredDenominator);
  const completedPercentage = formatPercentage(completed.count, entered.count);
  const averageTimeToCompletion = `${timeToCompletion.average.toFixed(2)}`;
  const observingPercentage = formatPercentage(observing.count, entered.count);

  return {
    entered: {
      count: entered.count,
      percentage: enteredPercentage,
    },
    completed: {
      count: completed.count,
      percentage: completedPercentage,
    },
    observing: {
      count: observing.count,
      percentage: observingPercentage,
    },
    averageTimeToCompletion,
  };
}
