export default function EventsIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2939_76027)">
        <path
          d="M16 15L11 12.0019L11 6.00532L16 9.00338M16 15V9.00338M16 15L21 12.0019V6.00532L16 9.00338M21 5.99857L16 9.01013L11 5.99857L16 3L21 5.99857Z"
          stroke="#1E293B"
          strokeWidth="0.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M3 11.5053L3 17.5019L8 20.5V14.5034L3 11.5053Z" fill="white" />
        <path d="M8 14.5034L13 11.5053L13 17.5019L8 20.5" fill="white" />
        <path
          d="M13 11.4986L8 14.5101L3 11.4986L8 8.5L13 11.4986Z"
          fill="white"
        />
        <path
          d="M8 20.5L3 17.5019L3 11.5053L8 14.5034M8 20.5V14.5034M8 20.5L13 17.5019L13 11.5053L8 14.5034M13 11.4986L8 14.5101L3 11.4986L8 8.5L13 11.4986Z"
          stroke="#1E293B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2939_76027">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
