export default function Italic() {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.83331 0.916585C2.83331 0.594419 3.09448 0.333252 3.41665 0.333252H5.16641H6.91665C7.23881 0.333252 7.49998 0.594419 7.49998 0.916585C7.49998 1.23875 7.23881 1.49992 6.91665 1.49992H5.60675L3.60672 8.5H4.58333C4.9055 8.5 5.16667 8.76117 5.16667 9.08333C5.16667 9.4055 4.9055 9.66667 4.58333 9.66667H2.84955C2.83899 9.66696 2.82838 9.66696 2.81775 9.66667H1.08333C0.761167 9.66667 0.5 9.4055 0.5 9.08333C0.5 8.76117 0.761167 8.5 1.08333 8.5H2.39337L4.39339 1.49992H3.41665C3.09448 1.49992 2.83331 1.23875 2.83331 0.916585Z"
        fill="#5F5F5F"
      />
    </svg>
  );
}
