import React, { useState, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { TargetMemberTypeChoices } from "../__generated__/graphql";

import { CREATE_COHORT } from "../graphql/mutations";
import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import BehaviorButton from "../patterns/atoms/BehaviorButton";
import { VIEW_PARAM } from "../campaign";

interface Props {
  closeModal: () => void;
  targetMemberType?: TargetMemberTypeChoices;
}

export default function NewCohort({ closeModal, targetMemberType }: Props) {
  const [cohortName, setCohortName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const formRef = useRef<HTMLFormElement>();
  const navigate = useNavigate();
  const [createCohortMutation] = useMutation(CREATE_COHORT);

  const createBehavior = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        const result = await createCohortMutation({
          variables: {
            name: cohortName,
            targetMemberType: targetMemberType,
          },
          refetchQueries: ["GetCohorts"],
        });
        if (result.data["createCohort"].ok) {
          navigate(
            `/cohorts/${result.data["createCohort"].cohort.id}?${VIEW_PARAM}=settings`,
          );
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [createCohortMutation, cohortName, targetMemberType, navigate],
  );

  return (
    <Modal close={closeModal} title={`Create a Behavior`} size="large">
      <form
        ref={formRef}
        className=""
        action="#"
        onSubmit={createBehavior}
        data-testid="cohorts__new-cohort__form"
      >
        <>
          <TextInput
            label={`Give your cohort a name`}
            value={cohortName}
            placeholder="Cohort name"
            required={true}
            onChange={setCohortName}
            autoFocus={true}
          />

          <div className="flex w-full gap-x-4">
            <SecondaryButton
              label="Cancel"
              onClick={() => closeModal()}
              fullWidth={true}
            />
            <BehaviorButton
              label="Create Cohort"
              isDisabled={isSaving}
              onClick={() => formRef.current.requestSubmit()}
              fullWidth={true}
            />
          </div>
        </>
      </form>
    </Modal>
  );
}
