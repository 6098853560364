import { useCallback, useMemo, useRef, useState } from "react";
import Modal from "../patterns/Modal";
import SelectInput from "../patterns/forms/SelectInput";
import { MessagingTypeChoices } from "../__generated__/graphql";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { useMutation } from "@apollo/client";
import { CREATE_MESSAGING_INTEGRATION } from "../graphql/mutations";
import toast from "react-hot-toast";
import {
  IntercomProps,
  MailgunProps,
  PavlovProps,
} from "../types/BackendTypes";
import MailgunConfig from "./config/MailgunConfig";
import PavlovConfig from "./config/PavlovConfig";

interface Props {
  closeModal: () => void;
}

export default function NewIntegrationModal({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [createMessagingIntegration] = useMutation(
    CREATE_MESSAGING_INTEGRATION,
  );
  const [config, setConfig] = useState<MailgunProps | IntercomProps>({});
  const [type, setType] = useState<MessagingTypeChoices>();
  const [isSaving, setIsSaving] = useState(false);
  const options = useMemo(() => {
    const o = Object.keys(MessagingTypeChoices).map((k) => {
      return {
        label: k,
        value: MessagingTypeChoices[k],
      };
    });
    return o;
  }, []);

  const createIntegration = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setIsSaving(true);

      try {
        const response = await createMessagingIntegration({
          variables: {
            type,
            config: JSON.stringify(config),
          },
          refetchQueries: ["GetMessagingIntegrations", "GetSession"],
        });
        const redirect = response.data.createMessagingIntegration.redirect;
        if (redirect) {
          if (process.env.NODE_ENV === "production") {
            window.location.replace(`/${redirect}`);
          } else {
            window.location.replace(`//localhost:8000/${redirect}`);
          }
        } else {
          toast.success("Integration created");
          closeModal();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSaving(false);
      }
    },
    [type, config, closeModal, createMessagingIntegration],
  );
  return (
    <Modal close={closeModal} title="Add an email integration">
      <form ref={formRef} className="" action="#" onSubmit={createIntegration}>
        <SelectInput
          label="Choose the integration you would like to use"
          placeholder="Choose an integration"
          options={options}
          required={true}
          onChange={(s) => {
            setType(s as MessagingTypeChoices);
          }}
        />

        {type === MessagingTypeChoices.Mailgun && (
          <MailgunConfig
            config={config as MailgunProps}
            setConfig={setConfig}
          />
        )}

        {type === MessagingTypeChoices.Pavlov && (
          <PavlovConfig config={config as PavlovProps} setConfig={setConfig} />
        )}

        <PrimaryButton
          label="Create integration"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
