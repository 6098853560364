export function CompleteCampaignIllustration() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="220"
        height="220"
        rx="110"
        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 220 220)"
        fill="url(#paint0_linear_143_1719)"
        fill-opacity="0.33"
      />
      <path
        d="M30.49 81.4206C29.3567 81.9203 28.8425 83.2457 29.3422 84.379L29.9523 85.7628L28.5685 86.373C27.4352 86.8727 26.9209 88.198 27.4206 89.3313C27.9203 90.4646 29.2457 90.9789 30.379 90.4792L31.7628 89.8691L32.373 91.2529C32.8727 92.3862 34.198 92.9004 35.3313 92.4007C36.4646 91.901 36.9789 90.5757 36.4792 89.4423L35.869 88.0585L37.2528 87.4484C38.3862 86.9487 38.9004 85.6234 38.4007 84.49C37.901 83.3567 36.5757 82.8425 35.4423 83.3422L34.0585 83.9523L33.4484 82.5685C32.9487 81.4352 31.6234 80.9209 30.49 81.4206Z"
        fill="#1E293B"
      />
      <path
        d="M196.296 121.165L194.805 120.738L195.199 119.12C195.52 117.796 194.796 116.436 193.571 116.084C192.328 115.731 191.09 116.525 190.768 117.845L190.374 119.463L188.879 119.036C187.636 118.689 186.402 119.478 186.076 120.798C185.755 122.123 186.48 123.482 187.704 123.834L189.195 124.261L188.801 125.879C188.48 127.204 189.204 128.563 190.429 128.916C190.626 128.97 190.823 129 191.016 129C192.034 129 192.961 128.266 193.232 127.154L193.626 125.536L195.121 125.963C195.319 126.018 195.516 126.048 195.708 126.048C196.727 126.048 197.653 125.313 197.924 124.202C198.245 122.877 197.52 121.517 196.296 121.165Z"
        fill="#1E293B"
      />
      <path
        d="M172.102 160.202C172.158 164.065 175.34 167.156 179.202 167.1C183.065 167.044 186.156 163.861 186.1 159.999C186.044 156.137 182.861 153.046 178.999 153.102C175.137 153.158 172.045 156.34 172.102 160.202ZM181.54 160.065C181.56 161.411 180.481 162.521 179.136 162.54C177.791 162.56 176.681 161.481 176.661 160.136C176.642 158.791 177.72 157.681 179.065 157.661C180.411 157.642 181.521 158.72 181.54 160.065Z"
        fill="#1E293B"
      />
      <path
        d="M157.043 54.9997C157.019 53.343 155.656 52.0195 154 52.0435C152.343 52.0676 151.019 53.4301 151.043 55.0868C151.067 56.7434 152.43 58.0669 154.087 58.0429C155.743 58.0189 157.067 56.6564 157.043 54.9997Z"
        fill="#1E293B"
      />
      <path
        d="M90 115.375L104.625 130L129 95.875M168 110.5C168 118.182 166.487 125.789 163.547 132.887C160.607 139.985 156.298 146.434 150.866 151.866C145.434 157.298 138.985 161.607 131.887 164.547C124.789 167.487 117.182 169 109.5 169C101.818 169 94.2106 167.487 87.113 164.547C80.0155 161.607 73.5665 157.298 68.1343 151.866C62.702 146.434 58.3929 139.985 55.453 132.887C52.5131 125.789 51 118.182 51 110.5C51 94.9848 57.1634 80.1051 68.1343 69.1343C79.1051 58.1634 93.9848 52 109.5 52C125.015 52 139.895 58.1634 150.866 69.1343C161.837 80.1051 168 94.9848 168 110.5Z"
        stroke="#1E293B"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M102.484 158.831C96.3 158.017 90.3365 155.992 84.9345 152.873C79.5324 149.755 74.7975 145.602 71.0002 140.653"
        stroke="#1E293B"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_143_1719"
          x1="226"
          y1="150"
          x2="-37.2822"
          y2="143.301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE8C7" />
          <stop offset="0.51" stop-color="#FFC2FC" />
          <stop offset="1" stop-color="#DBFAFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
