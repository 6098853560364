export function titleCase(string: string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

const irregularPlurals = {
  person: "people",
};

export function pluralize(number: number | string, object: string) {
  const parsedNumber = typeof number === "string" ? parseInt(number) : number;
  if (parsedNumber === 1) {
    return `${parsedNumber} ${object}`;
  }
  if (irregularPlurals[object]) {
    return `${parsedNumber} ${irregularPlurals[object]}`;
  }
  return `${parsedNumber} ${object}s`;
}

export function truncate(string: string, length: number, ellipsis = true) {
  return string.length > length
    ? `${string.slice(0, length)}${ellipsis ? "..." : ""}`
    : string;
}
