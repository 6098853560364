import { useState, useContext } from "react";
import {
  BehaviorType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import StatsHeading from "../patterns/StatsHeading";
import { iconMap } from "../patterns/StatsHeading";
import { formatPercentage } from "../lib/number";
import { Tabs } from "../campaign/lib";
import { cx } from "../lib/cx";
import { SessionContext } from "../SessionContext";
import Line from "../patterns/charts/Line";
import EmptyState from "../patterns/EmptyState";
import BehaviorsEmpty from "../patterns/illustrations/BehaviorsEmpty";

interface Props {
  behavior: BehaviorType;
  setActiveTab: (tab: Tabs) => void;
}

export default function BehaviorOverview({ behavior, setActiveTab }: Props) {
  const [currentTab, setCurrentTab] = useState(0);
  const session = useContext(SessionContext);

  const target =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";

  const enteredDenominator =
    behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? session.orgCount
      : session.peopleCount;
  const entered = behavior.stats.entered.count;
  const enteredPercentage = formatPercentage(entered, enteredDenominator);
  const completed = behavior.stats.completed.count;
  const completedPercentage = formatPercentage(completed, entered);
  const averageTimeToCompletion = `${behavior.stats.timeToCompletion.average.toFixed(2)} days`;

  const dataPoints = [
    {
      id: 0,
      title: `Total ${target} entered`,
      value: entered,
      percentage: enteredPercentage || "-",
      current: true,
      tab: Tabs.Eligible,
      emptyStateDescription: `No ${target} have yet to meet the audience criteria for this behavior.`,
    },
    {
      id: 1,
      title: `Total ${target} completed`,
      value: completed,
      percentage: completedPercentage || "-",
      current: false,
      tab: Tabs.Completed,
      emptyStateDescription: `No ${target} have yet to complete this behavior.`,
    },
    {
      id: 2,
      title: `Average time to completion`,
      value: averageTimeToCompletion,
      percentage: "",
      current: false,
      tab: Tabs.TimeToCompletion,
      emptyStateDescription: `No ${target} have yet to complete this behavior.`,
    },
  ];
  const graphdata = {
    0: {
      data: [{ id: 1, data: behavior.stats.entered.timeSeries }],
      type: "time",
    },
    1: {
      data: [{ id: 1, data: behavior.stats.completed.timeSeries }],
      type: "time",
    },
    2: {
      data: [{ id: 1, data: behavior.stats.timeToCompletion.plot }],
      type: "linear",
      valueSuffix: "day",
      markers: [
        {
          axis: "x",
          legend: "Average",
          lineStyle: {
            stroke: "#8D67FC",
            strokeWidth: 2,
            strokeDasharray: 8,
          } as Partial<React.CSSProperties>,
          textStyle: {
            fontSize: 12,
            fill: "#1E293B",
          } as Partial<React.CSSProperties>,
          value: behavior.stats.timeToCompletion.average,
        },
      ],
    },
  };
  const hasData = !!graphdata[currentTab].data[0].data.length;
  return (
    <div className="flex flex-col rounded-xl mx-8 mb-6">
      <div className="grid grid-cols-3">
        {dataPoints.map((item) => (
          <div
            className={cx(
              currentTab === item.id
                ? "bg-white"
                : "animate bg-grey-50 hover:bg-grey-100",
              "flex cursor-pointer flex-col rounded-t-xl border-r-2 border-white p-4",
            )}
            key={item.id}
            onClick={() => {
              setCurrentTab(item.id);
              setActiveTab(item.tab);
            }}
          >
            <StatsHeading
              title={item.title}
              stat={item.value}
              subtitle={item.percentage}
              icon={item.title as keyof typeof iconMap} // Type assertion
            />
          </div>
        ))}
      </div>
      <div className="h-96 w-auto bg-white px-4 py-4 rounded-b-lg">
        {hasData ? (
          <Line
            data={graphdata[currentTab].data}
            type={graphdata[currentTab].type}
            valueSuffix={graphdata[currentTab].valueSuffix}
            markers={graphdata[currentTab].markers}
          />
        ) : (
          <EmptyState
            title="No data"
            description={dataPoints[currentTab].emptyStateDescription}
            icon={<BehaviorsEmpty />}
            fullHeight={false}
          ></EmptyState>
        )}
      </div>
    </div>
  );
}
