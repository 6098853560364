import { ButtonProps } from "./types";
import { cx } from "../../lib/cx";
import {
  BeakerIcon,
  CheckCircleIcon,
  PlayCircleIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import { ArchiveBoxIcon, PauseCircleIcon } from "@heroicons/react/24/outline";

const iconMapping = {
  "set campaign live": {
    icon: (
      <PlayCircleIcon
        className="StaticIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className: "CampaignButton",
  },
  "pause campaign": {
    icon: (
      <PauseCircleIcon
        className="StaticIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className: "PauseButton",
  },
  "complete campaign": {
    icon: (
      <CheckCircleIcon
        className="StaticIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className:
      "text-body-text bg-grey-50 animate hover:text-active-blue hover:bg-blue-10",
  },
  archive: {
    icon: (
      <ArchiveBoxIcon
        className="StaticIcon"
        style={{ width: "16px", height: "16px" }}
      />
    ),
    className:
      "text-grey-50 bg-body-text animate hover:bg-grey-200 hover:text-body-text",
  },
  "create campaign": {
    icon: (
      <SparklesIcon
        className="StaticIcon"
        style={{ width: "0px", height: "0px" }}
      />
    ),
  },
};

export default function CampaignButton({
  label,
  onClick,
  isDisabled = false,
  fullWidth = false,
}: ButtonProps) {
  const selectedIcon = iconMapping[label.toLowerCase()];

  return (
    <button
      onClick={onClick}
      type="button"
      style={{ pointerEvents: isDisabled ? "none" : "auto" }}
      className={cx(
        isDisabled && "opacity-30",
        fullWidth && "w-full",
        "btn-md",
        selectedIcon?.className || "CampaignButton",
      )}
    >
      <div className="">
        {selectedIcon?.icon || (
          <BeakerIcon
            className="RotateIcon"
            style={{ width: "16px", height: "16px" }}
          />
        )}
      </div>
      {label}
    </button>
  );
}
