import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { TargetMemberTypeChoices } from "../__generated__/graphql";
import { GET_COHORTS } from "../graphql/queries";
import StatsHeading from "../patterns/StatsHeading";
import SkeletonScreen from "../patterns/Skeleton";
import Card from "../patterns/Card";
import IconButton from "../patterns/atoms/IconButton";
import { cx } from "../lib/cx";
import EmptyState from "../patterns/EmptyState";
import PeopleEmpty from "../patterns/illustrations/PeopleEmpty";
import { titleCase } from "../lib/string";

interface CohortProps {
  targetMemberType: TargetMemberTypeChoices;
  selectedCohort: string;
  onSelectCohort: (cohortId: string) => void;
}

export default function Cohorts({
  targetMemberType,
  selectedCohort,
  onSelectCohort,
}: CohortProps) {
  const { data, loading } = useQuery(GET_COHORTS);
  const navigate = useNavigate();

  const targetCohorts = useMemo(() => {
    return data?.allCohorts.filter(
      (cohort) => cohort.targetMemberType === targetMemberType,
    );
  }, [data?.allCohorts, targetMemberType]);

  if (loading) {
    return <SkeletonScreen />;
  }

  if (!targetCohorts || targetCohorts.length === 0) {
    return (
      <EmptyState
        title="You don't have any cohorts yet"
        icon={<PeopleEmpty />}
        description={`Set up a cohort to track a segment of your ${titleCase(targetMemberType)} throughout lifecycle stages and campaigns`}
      />
    );
  }
  return (
    <div className="flex flex-row flex-wrap gap-2 w-full px-8">
      {targetCohorts.map((cohort) => (
        <div className="flex flex-row shadow-sm relative w-1/4">
          <Card
            classNames={cx(
              "border-1",
              selectedCohort === cohort.id && "border-blue-400",
            )}
            onClick={() => onSelectCohort(cohort.id)}
          >
            <StatsHeading
              title={cohort.name}
              stat={cohort.stats.observing.count}
              subtitle="members"
            />
            <div className="absolute top-2 right-2">
              <IconButton
                icon="cog"
                onClick={() => {
                  navigate(`/cohorts/${cohort.id}?view=overview`);
                }}
              />
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}
