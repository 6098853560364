import { ShopifyProductType } from "../__generated__/graphql";
import Table, { TableProps } from "../patterns/tables/table";
import Incentive from "../patterns/illustrations/Incentive";

interface Props {
  products: ShopifyProductType[];
}

function toTable(
  products: ShopifyProductType[],
): Omit<TableProps, "filterBar"> {
  return {
    header: [
      { label: "Image", width: "w-24" },
      { label: "Name", width: "w-1/5" },
      { label: "Description", width: "w-1/3" },
      { label: "Price", width: "w-1/8" },
      { label: "Stock" },
      { label: "Vendor" },
    ],
    rows: products.map((product) => {
      return {
        id: product.id,
        onClick: () => {},
        cells: [
          {
            content: (
              <div className="flex flex-col w-24">
                {product.featuredImage?.url !== "" ? (
                  <img
                    src={product.featuredImage.url}
                    className="object-contain w-16 h-16 rounded-lg border border-gray-200"
                    alt=""
                  />
                ) : (
                  <Incentive />
                )}
              </div>
            ),
          },
          {
            content: (
              <span className="text-sm font-medium text-body-text">
                {product.title}
              </span>
            ),
          },
          {
            content: <span className="font-medium">{product.description}</span>,
          },
          {
            content: (
              <span className="text-body-text">
                {product.price.amount} ({product.price.currencyCode})
              </span>
            ),
          },
          {
            content: (
              <span
                className={`${
                  parseInt(product.totalInventory) < 1
                    ? "text-red-500 font-bold"
                    : ""
                }`}
              >
                {product.totalInventory}
              </span>
            ),
          },
          {
            content: (
              <span className="font-semibold text-body-text">
                {product.vendor}
              </span>
            ),
          },
        ],
      };
    }),
  };
}

export default function ShopifyProductTable({ products }: Props) {
  const tableDefinition = toTable(products);
  return <Table header={tableDefinition.header} rows={tableDefinition.rows} />;
}
