import React from "react";
import SettingsBlock from "../SettingsBlock";
import incentiveLabel from "../lib";
import BuilderSectionHeader from "./builder-section-header";
import { pluralize } from "../../lib/string";
import { DAY_IN_SECONDS } from "../../lib/date";
import { ClockIcon } from "@heroicons/react/24/solid";
import SectionProps from "./section_props";

function engagementActionsSort(a: any, b: any) {
  const order = ["incentive", "email", "delayed_email"];
  const aIdx = order.indexOf(a.type);
  const bIdx = order.indexOf(b.type);
  if (aIdx < bIdx) {
    return -1;
  } else if (aIdx > bIdx) {
    return 1;
  } else {
    return a.id < b.id ? -1 : 1;
  }
}

export default function IncentiveAndEngagementSection({
  data,
  campaignDefinition,
  openDrawer,
}: SectionProps) {
  const incentiveActions = campaignDefinition.after_actions.actions.filter(
    (action) => action.type === "incentive",
  );
  const engagementActions = campaignDefinition.before_actions.actions;
  const relevantActions = engagementActions.concat(incentiveActions);
  const hasEngagementAction = relevantActions.length > 0;

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Incentives & Engagement"
        description="What you’ll offer, and how you’ll notify your audience"
        isValid={hasEngagementAction}
        onClick={() => {}}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {relevantActions.sort(engagementActionsSort).map((action) => {
          switch (action.type) {
            case "email":
              return (
                <SettingsBlock
                  key={action.id}
                  title={`Send email`}
                  errors={
                    action.messages[0].subject ? [] : ["Email has no subject"]
                  }
                  section="email"
                  onClick={() => {}}
                />
              );
            case "incentive":
              return (
                <SettingsBlock
                  key={action.id}
                  section="incentive"
                  title={incentiveLabel(
                    data.incentives,
                    action.incentives[0]?.id,
                  )}
                  onClick={() => {}}
                />
              );
            case "notify_entered":
              return (
                <SettingsBlock
                  title="Notify the team"
                  section="notify"
                  onClick={() => {}}
                />
              );
            case "update_crm":
              return (
                <SettingsBlock
                  title="Update CRM fields"
                  section="update-crm"
                  onClick={() => {}}
                />
              );
            case "update_member":
            case "notify_completed":
            case "notify_exited":
            case "webhook":
              return <></>;

            case "delayed_email":
              return (
                <div className="flex flex-row items-center gap-2">
                  <div className="flex text-xs text-grey-500 items-center">
                    {action.delay ? (
                      <>
                        wait {pluralize(action.delay / DAY_IN_SECONDS, "day")},
                        then{" "}
                      </>
                    ) : (
                      <>
                        wait &nbsp;
                        <div className="flex py-2 pl-3 pr-4 text-xs leading-6 tracking-wide rounded-lg border bg-yellow-50 text-yellow-800 border-yellow-50 animate cursor-pointer">
                          <div className="flex h-4 w-4 mr-2 rounded-md my-auto">
                            <ClockIcon />
                          </div>
                          No delay set
                        </div>
                        &nbsp; then
                      </>
                    )}
                  </div>
                  <SettingsBlock
                    key={action.id}
                    title={`Send reminder email`}
                    section="email"
                    errors={[
                      action.messages[0].subject
                        ? ""
                        : "Reminder email has no subject",
                    ].filter((e) => e !== "")}
                    onClick={() => {}}
                  />
                </div>
              );

            default:
              const exhaustiveCheck: never = action;
              throw new Error(`Unknown action type ${exhaustiveCheck}`);
          }
        })}
      </div>
    </div>
  );
}
