import { useQuery } from "@apollo/client";
import {
  CohortType,
  PersonType,
  OrgType,
  TargetMemberTypeChoices,
  CohortStateNames,
} from "../__generated__/graphql";
import { formatLong } from "../lib/date";
import { useCallback, useEffect } from "react";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { GET_COHORT_MEMBERS } from "../graphql/queries";
import Table, { TableProps } from "../patterns/tables/table";
import SkeletonScreen from "../patterns/Skeleton";
import { CohortTabs } from "./lib";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import { downloadFile } from "../lib/file";
import EmptyState from "../patterns/EmptyState";

interface Props {
  cohort: CohortType;
  activeTab: CohortTabs;
  showMember: (m: PersonType | OrgType) => void;
}

interface PersonNode {
  id: string;
  lastEntered: string;
  lastLeft: string;
  member: PersonType;
}

interface OrgNode {
  id: string;
  lastEntered: string;
  lastLeft: string;
  member: PersonType;
}

interface CellData {
  content: string;
}

const HEADERS = {
  [TargetMemberTypeChoices.Person]: {
    [CohortTabs.LastEntered]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Last Entered" },
    ],
    [CohortTabs.LastLeft]: [
      { label: "Name" },
      { label: "Email" },
      { label: "Organization" },
      { label: "Last Left" },
    ],
  },
  [TargetMemberTypeChoices.Org]: {
    [CohortTabs.LastEntered]: [{ label: "Name" }, { label: "Last Entered" }],
    [CohortTabs.LastLeft]: [{ label: "Name" }, { label: "Last Left" }],
  },
};

const CELLS = {
  [TargetMemberTypeChoices.Person]: {
    [CohortTabs.LastEntered]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.lastEntered) },
    ],
    [CohortTabs.LastLeft]: (n: PersonNode): CellData[] => [
      { content: n.member.name },
      { content: n.member.email },
      { content: n.member.org?.name || "-" },
      { content: formatLong(n.lastLeft) },
    ],
  },
  [TargetMemberTypeChoices.Org]: {
    [CohortTabs.LastEntered]: (n: OrgNode): CellData[] => [
      { content: n.member.name },
      { content: formatLong(n.lastEntered) },
    ],
    [CohortTabs.LastLeft]: (n: OrgNode): CellData[] => [
      { content: n.member.name },
      { content: formatLong(n.lastLeft) },
    ],
  },
};

const QUERY_MAP = {
  [CohortTabs.LastEntered]: {
    query: GET_COHORT_MEMBERS,
    variables: (cohort) => ({
      cohortId: cohort.id,
      stateFilter: CohortStateNames.Observing,
    }),
  },
  [CohortTabs.LastLeft]: {
    query: GET_COHORT_MEMBERS,
    variables: (cohort) => ({
      cohortId: cohort.id,
      stateFilter: CohortStateNames.Completed,
    }),
  },
};

const PAGE_SIZE = 50;

function toTable(
  targetMemberType: TargetMemberTypeChoices,
  data: any,
  activeTab: CohortTabs,
  showMember: (p: PersonType) => void,
): Omit<TableProps, "filterBar"> {
  return {
    header: HEADERS[targetMemberType][activeTab],
    rows: data.edges.map((p) => {
      return {
        id: p.node.id,
        onClick: (e: React.MouseEvent, id: string) => {
          showMember(p.node.member);
          e.stopPropagation();
        },
        cells: CELLS[targetMemberType][activeTab](p.node),
      };
    }),
  };
}

export default function MemberTable({ cohort, activeTab, showMember }: Props) {
  const query = QUERY_MAP[activeTab];
  const { data, loading, fetchMore, refetch } = useQuery(query.query, {
    variables: query.variables(cohort),
  });

  useEffect(() => {
    refetch({ cohortId: cohort.id });
  }, [cohort, refetch]);

  const loadNext = useCallback(() => {
    const key = Object.keys(data)[0];
    fetchMore({
      variables: {
        cursor: data[key].edges[data[key].edges.length - 1].node.id,
        limit: PAGE_SIZE,
      },
    });
  }, [data, fetchMore]);

  const nextPageTrigger = useInfiniteScroll(loadNext);

  if (loading) {
    return <SkeletonScreen />;
  }

  const key = Object.keys(data)[0];
  const hasMore = data[key].total > data[key].edges.length;

  const tableData = toTable(
    cohort.targetMemberType,
    data[key],
    activeTab,
    showMember,
  );

  if (tableData.rows.length === 0 && !loading) {
    return (
      <EmptyState
        title="No members found"
        description="There are no members in this cohort."
      />
    );
  }

  return (
    <>
      {tableData.rows.length !== 0 && !loading && (
        <>
          <div className="flex flex-col items-end mx-8 mb-4">
            <SecondaryButton
              label="Download CSV"
              onClick={() => {
                downloadFile(
                  `/reporting/download/membership?object_id=${cohort.id}`,
                  cohort.name,
                );
              }}
            />
          </div>
          <Table header={tableData.header} rows={tableData.rows} />
          {hasMore && <div ref={nextPageTrigger}></div>}
        </>
      )}
    </>
  );
}
