import { MachineCampaignType } from "../../__generated__/graphql";
import Modal from "../../patterns/Modal";
import PrimaryButton from "../../patterns/atoms/PrimaryButton";
import SecondaryButton from "../../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import { DeleteCampaignIllustration } from "../../patterns/illustrations/DeleteCampaignIllustration";
import { DELETE_CAMPAIGN } from "../../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { titleCase } from "../../lib/string";

interface Props {
  campaign: MachineCampaignType;
  close: () => void;
}

export default function DeleteModal({ campaign, close }: Props) {
  const navigate = useNavigate();
  const [deleteMutation] = useMutation(DELETE_CAMPAIGN);
  const entityName = campaign.isBehavior ? "behavior" : "campaign";
  return (
    <Modal
      title={`Are you sure you want to delete this ${entityName}?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <DeleteCampaignIllustration />
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <PrimaryButton
          label="Delete"
          onClick={() => {
            deleteMutation({
              variables: { id: campaign.id },
              update(cache) {
                const normalizedId = cache.identify({
                  id: campaign.id,
                });
                cache.modify({
                  id: normalizedId,
                  fields: {
                    deleted() {
                      return true;
                    },
                  },
                });
                cache.gc();
              },
            }).then((response) => {
              if (response.data.deleteCampaign.ok) {
                navigate(campaign.isBehavior ? "/dashboard" : "/campaigns");
                toast.success(`${titleCase(entityName)} deleted`);
              } else {
                toast.error(`Failed to delete ${entityName}`);
              }
            });
          }}
        />
      </div>
    </Modal>
  );
}
