import React, { useState, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { TargetMemberTypeChoices } from "../__generated__/graphql";

import { CREATE_BEHAVIOR } from "../graphql/mutations";
import { Filter } from "../types/BackendTypes";
import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import SubSectionHeader from "../patterns/SubSectionHeader";
import BehaviorButton from "../patterns/atoms/BehaviorButton";
import FilterBuilder from "../filter_builder";
import { VIEW_PARAM } from "../campaign";

interface Props {
  closeModal: () => void;
  audienceCriteria?: Filter;
  predefinedTargetMemberType?: TargetMemberTypeChoices;
}

export default function NewBehavior({
  closeModal,
  audienceCriteria,
  predefinedTargetMemberType,
}: Props) {
  const [behaviorName, setBehaviorName] = useState("");
  const [targetMemberType, setTargetMemberType] =
    useState<TargetMemberTypeChoices>(predefinedTargetMemberType);
  const [isSaving, setIsSaving] = useState(false);

  const formRef = useRef<HTMLFormElement>();
  const navigate = useNavigate();
  const [createBehaviorMutation] = useMutation(CREATE_BEHAVIOR);

  const targetMemberTypeSelectionDisabled =
    predefinedTargetMemberType !== undefined;

  const createBehavior = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        const refetchQueries = ["GetBehaviors"];
        const behaviorVariables = {
          name: behaviorName,
          targetMemberType: targetMemberType,
        };
        if (audienceCriteria) {
          behaviorVariables["audience"] = JSON.stringify({
            criteria: [
              {
                type: "filter",
                filter: audienceCriteria,
                version: "1",
              },
            ],
          });
        }
        const result = await createBehaviorMutation({
          variables: behaviorVariables,
          refetchQueries,
        });
        if (result.data["createBehavior"].ok) {
          navigate(
            `/behaviors/${result.data["createBehavior"].behavior.id}?${VIEW_PARAM}=settings`,
          );
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [
      createBehaviorMutation,
      behaviorName,
      targetMemberType,
      audienceCriteria,
      navigate,
    ],
  );

  return (
    <Modal close={closeModal} title={`Create a Behavior`} size="large">
      <form
        ref={formRef}
        className=""
        action="#"
        onSubmit={createBehavior}
        data-testid="behaviors__new-behavior__form"
      >
        <>
          <TextInput
            label={`Give your behavior a name`}
            value={behaviorName}
            placeholder="Behavior name"
            required={true}
            onChange={setBehaviorName}
            autoFocus={true}
          />

          <label>{`Who should this behavior target?`}</label>
          <div className="flex flex-col gap-y-2 mb-4">
            <label className="px-2 text-xs flex">
              <input
                type="radio"
                className={`mr-3 active:ring-1 focus:ring-white animate ${targetMemberTypeSelectionDisabled ? "text-gray-400" : "text-body-text"}`}
                checked={targetMemberType === TargetMemberTypeChoices.Person}
                onChange={() => {
                  setTargetMemberType(TargetMemberTypeChoices.Person);
                }}
                disabled={targetMemberTypeSelectionDisabled}
              />{" "}
              <SubSectionHeader
                label="An Individual"
                description="Track if an individual completes an action themselves"
                disabled={targetMemberTypeSelectionDisabled}
              />
            </label>
            <label className="px-2 text-xs flex">
              <input
                type="radio"
                className={`mr-3 active:ring-1 focus:ring-white animate ${targetMemberTypeSelectionDisabled ? "text-gray-400" : "text-body-text"}`}
                checked={targetMemberType === TargetMemberTypeChoices.Org}
                onChange={() => {
                  setTargetMemberType(TargetMemberTypeChoices.Org);
                }}
                disabled={targetMemberTypeSelectionDisabled}
              />{" "}
              <SubSectionHeader
                label="An Organization"
                description="Track if anyone within an organization completes an action"
                disabled={targetMemberTypeSelectionDisabled}
              />
            </label>
          </div>

          {audienceCriteria && (
            <div className="mb-8">
              <label>
                This behavior will be created with the following entry criteria:
              </label>
              <FilterBuilder
                filterDoc={audienceCriteria}
                readonly={true}
                onChange={() => {}}
              />
            </div>
          )}

          <div className="flex w-full gap-x-4">
            <SecondaryButton
              label="Cancel"
              onClick={() => closeModal()}
              fullWidth={true}
            />
            <BehaviorButton
              label="Create Behavior"
              isDisabled={isSaving}
              onClick={() => formRef.current.requestSubmit()}
              fullWidth={true}
            />
          </div>
        </>
      </form>
    </Modal>
  );
}
