import { useMutation, useQuery } from "@apollo/client";
import { GET_INVITES } from "../graphql/queries";
import { InviteType } from "../__generated__/graphql";
import Table, { TableProps } from "../patterns/tables/table";
import EmptyState from "../patterns/EmptyState";
import { formatLong } from "../lib/date";
import { DELETE_INVITE } from "../graphql/mutations";
import toast from "react-hot-toast";
import { useCallback } from "react";
import PrimaryButton from "../patterns/atoms/PrimaryButton";

function toTable(
  teammates: InviteType[],
  deleteInvite: (id: string) => void,
): Omit<TableProps, "filterBar"> {
  return {
    header: [{ label: "Email" }, { label: "Expires" }],
    rows: teammates.map((t) => {
      return {
        id: t.email,
        onClick: () => {},
        cells: [
          { content: t.email },
          {
            content: (
              <div className="flex items-center">
                <span className="pr-2">{formatLong(t.expires)}</span>
                <PrimaryButton
                  label="Revoke"
                  onClick={() => deleteInvite(t.id)}
                />
              </div>
            ),
          },
        ],
      };
    }),
  };
}

interface Props {
  invite: () => void;
}

export default function Invites({ invite }: Props) {
  const { data, loading } = useQuery(GET_INVITES);
  const [deleteInviteMutation] = useMutation(DELETE_INVITE);

  const deleteInvite = useCallback(
    async (id: string) => {
      try {
        await deleteInviteMutation({
          variables: { id },
          update(cache) {
            const normalizedId = cache.identify({
              id: id,
              __typename: "InviteType",
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        toast.success("Invite revoked");
      } catch (e) {
        toast.error(e.message);
      }
    },
    [deleteInviteMutation],
  );

  if (loading) {
    return <></>;
  }
  if (data.invites.length === 0) {
    return (
      <div className="mt-8">
        <EmptyState
          title="No invites"
          description="You have no pending invites"
          buttonLabel="Invite a teammate"
          onClick={invite}
          icon={""}
        />
      </div>
    );
  }
  const tableDefinition = toTable(data.invites, deleteInvite);
  return <Table header={tableDefinition.header} rows={tableDefinition.rows} />;
}
