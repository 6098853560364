import React, { useCallback } from "react";

interface Props {
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  description?: string;
  placeholder: string;
  options?: Array<{ label: string; value: string | number }>;
  groups?: Array<{
    label: string;
    options: Array<{ label: string; value: string | number }>;
  }>;
  value?: string;
  required: boolean;
  disabled?: boolean;
  onChange: (s: string) => void;
}

export default function SelectInput({
  label = "",
  description,
  placeholder,
  options,
  groups,
  value,
  required,
  disabled = false,
  onChange,
}: Props) {
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value),
    [onChange],
  );

  return (
    <div className="pb-4">
      <div className="flex justify-between leading-6">
        <label className="w-full">
          {label}
          {description && (
            <div className="text-xs font-normal leading-5 tracking-wide text-body-text-lighter mb-2">
              {description}
            </div>
          )}
          <select
            id="select-input__select"
            value={value}
            className="mt-2 block w-full rounded-lg border border-rule-color bg-white px-2 pl-3 text-xs font-normal leading-6 tracking-wide text-body-text focus:border-blue-800 focus:ring-blue-300"
            onChange={onInputChange}
            required={required}
            disabled={disabled}
          >
            <option value="" disabled selected={!value}>
              {placeholder}
            </option>
            {options &&
              options.map((o) => (
                <option
                  key={o.value}
                  value={o.value}
                  className="text-xs font-normal leading-4 tracking-wide"
                >
                  {o.label}
                </option>
              ))}
            {groups &&
              groups.map((g) => (
                <optgroup label={g.label}>
                  {g.options.map((o) => (
                    <option
                      key={o.value}
                      value={o.value}
                      className="text-xs font-normal leading-4 tracking-wide"
                    >
                      {o.label}
                    </option>
                  ))}
                </optgroup>
              ))}
          </select>
        </label>
      </div>
    </div>
  );
}
