import { FormEvent, useCallback, useRef, useState } from "react";
import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { useMutation } from "@apollo/client";
import { CREATE_INVITE } from "../graphql/mutations";
import toast from "react-hot-toast";

interface Props {
  closeModal: () => void;
}

export default function NewTeammate({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [editingEmail, setEditingEmail] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [createInviteMutation] = useMutation(CREATE_INVITE);

  const sendInvite = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        await createInviteMutation({
          variables: { email: editingEmail },
          refetchQueries: ["GetInvites"],
        });
        toast.success("Teammate invited");
        closeModal();
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [editingEmail, closeModal, createInviteMutation],
  );

  return (
    <Modal close={closeModal} title="Invite a teammate">
      <form ref={formRef} className="" action="#" onSubmit={sendInvite}>
        <TextInput
          type="email"
          placeholder="teammate@example.com"
          label="Email Address"
          value={editingEmail}
          required={true}
          onChange={setEditingEmail}
        />
        <PrimaryButton
          label="Send invite"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
