import Modal from "../patterns/Modal";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import { SetCampaignLive } from "../patterns/illustrations/SetCampaignLiveIllustration";
import BehaviorButton from "../patterns/atoms/BehaviorButton";

interface Props {
  setLive: () => void;
  close: () => void;
}

export default function SetLiveModal({ setLive, close }: Props) {
  const setLifecycleStageLive = () => {
    setLive();
    close();
  };

  return (
    <Modal
      title={`Are you sure you want to set this Lifecycle Stage live?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <SetCampaignLive />
      </div>
      <div className="text-center text-xs mt-2">
        <p>
          {`You won't be able to edit the completion criteria after you put
          the Lifecycle Stage live.`}
        </p>
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <BehaviorButton
          label={`Set Lifecycle Stage Live`}
          onClick={setLifecycleStageLive}
        />
      </div>
    </Modal>
  );
}
