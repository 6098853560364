interface Props {
  left: React.ReactNode;
  right: React.ReactNode;
}

export default function OptionsAndDetail(props: Props) {
  return (
    <div className="grid grid-cols-optionsAndDetail shadow-sm rounded-lg">
      <div className="bg-grey-50 p-4 rounded-l-lg">{props.left}</div>
      <div className="bg-white p-4 rounded-r-lg">{props.right}</div>
    </div>
  );
}
