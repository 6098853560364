import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import Modal from "../patterns/Modal";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import { SetCampaignLive } from "../patterns/illustrations/SetCampaignLiveIllustration";
import BehaviorButton from "../patterns/atoms/BehaviorButton";
import { BehaviorType } from "../__generated__/graphql";
import { UPDATE_BEHAVIOR } from "../graphql/mutations";

interface Props {
  behavior: BehaviorType;
  close: () => void;
}

export default function SetLiveModal({ behavior, close }: Props) {
  const [updateBehaviorMutation] = useMutation(UPDATE_BEHAVIOR);

  const setBehaviorLive = useCallback(async () => {
    try {
      const response = await updateBehaviorMutation({
        variables: {
          id: behavior.id,
          name: behavior.name,
          status: "LIVE",
          audience: JSON.stringify(behavior.audience),
          observing: JSON.stringify(behavior.observing),
          actionsOnEnter: JSON.stringify(behavior.actionsOnEnter),
          actionsOnCompletion: JSON.stringify(behavior.actionsOnCompletion),
          actionsWhileObserving: JSON.stringify(behavior.actionsWhileObserving),
        },
      });

      if (response.data.updateBehavior.ok) {
        toast.success(`Behavior set live`);
      } else {
        toast.error(() => <p>Could not set Behavior live</p>);
      }
    } catch (e) {
      toast.error(e.message);
    }
    close();
  }, [behavior, close, updateBehaviorMutation]);

  return (
    <Modal
      title={`Are you sure you want to set this behavior live?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <SetCampaignLive />
      </div>
      <div className="text-center text-xs mt-2">
        <p>
          {`You won't be able to edit the completion criteria after you put
          the behavior live.`}
        </p>
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <BehaviorButton label={`Set Behavior Live`} onClick={setBehaviorLive} />
      </div>
    </Modal>
  );
}
