import { useEffect } from "react";
import { GET_FILTERED_ORGS, GET_FILTERED_PEOPLE } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { Filter } from "../types/BackendTypes";
import { TargetMemberTypeChoices } from "../__generated__/graphql";
import { filterDocIsComplete, filterDocIsEmpty } from "../filter_builder/lib";

export default function useFilteredAudience(
  filter: Filter,
  targetMemberType: TargetMemberTypeChoices,
  forceReloadHack?: string,
) {
  const targetsOrgs = targetMemberType === TargetMemberTypeChoices.Org;
  const [getPeople, { data: people, loading: peopleLoading }] = useLazyQuery(
    GET_FILTERED_PEOPLE,
    {
      variables: {
        filter: JSON.stringify(filter),
      },
    },
  );
  const [getOrgs, { data: orgs, loading: orgsLoading }] = useLazyQuery(
    GET_FILTERED_ORGS,
    {
      variables: {
        filter: JSON.stringify(filter),
      },
    },
  );

  useEffect(() => {
    const filterDoc = filter;
    if (filterDocIsComplete(filterDoc) && !filterDocIsEmpty(filterDoc)) {
      const queryFunc = targetsOrgs ? getOrgs : getPeople;
      queryFunc({
        variables: {
          filter: JSON.stringify(filter),
        },
        fetchPolicy: "no-cache",
      });
    }
  }, [filter, getPeople, getOrgs, targetsOrgs, forceReloadHack]);

  return {
    audience: targetsOrgs ? orgs?.allOrgs : people?.allPeople,
    loading: targetsOrgs ? orgsLoading : peopleLoading,
  };
}
