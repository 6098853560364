import { useEffect } from "react";

export default function useDocumentClick(handler: (e: MouseEvent) => void) {
  useEffect(() => {
    const h = handler;
    document.addEventListener("click", h);
    return () => {
      document.removeEventListener("click", h);
    };
  }, [handler]);
}
