import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_REFERRALS } from "../graphql/queries";
import { CREATE_REFERRER_REFEREE_PAIR } from "../graphql/mutations";
import Card from "../patterns/Card";
import PageHeader from "../patterns/PageHeader";
import TextInput from "../patterns/forms/TextInput";
import SectionHeader from "../patterns/SectionHeader";
import { toast } from "react-hot-toast";
import SkeletonScreen from "../patterns/Skeleton";
import { MachineStatusChoices } from "../__generated__/graphql";
import EmptyState from "../patterns/EmptyState";

export default function NewReferrerRefereePair() {
  const [referrerName, setReferrerName] = useState("");
  const [referrerEmail, setReferrerEmail] = useState("");
  const [refereeName, setRefereeName] = useState("");
  const [refereeEmail, setRefereeEmail] = useState("");
  const { data: referralsData, loading: referralsLoading } =
    useQuery(GET_REFERRALS);
  const [createReferralMutation] = useMutation(CREATE_REFERRER_REFEREE_PAIR);
  const navigate = useNavigate();

  const createReferral = useCallback(async () => {
    try {
      await createReferralMutation({
        variables: {
          referrerName,
          referrerEmail,
          refereeName,
          refereeEmail,
        },
      });
      toast.success("Referral created");
      setReferrerName("");
      setReferrerEmail("");
      setRefereeName("");
      setRefereeEmail("");
    } catch (e) {
      toast.error(e.message);
    }
  }, [
    createReferralMutation,
    referrerName,
    referrerEmail,
    refereeName,
    refereeEmail,
  ]);

  if (referralsLoading) {
    return <SkeletonScreen />;
  }

  if (!referralsData?.allReferrals) {
    return (
      <EmptyState
        title="No Referrals yet"
        description="Your referral program has not been set up yet. Contact us to proceed."
      />
    );
  }

  const referral = referralsData.allReferrals[0];

  if (referral.status !== MachineStatusChoices.Live) {
    return (
      <EmptyState
        title="Referral program not live"
        description="Your referral program has not yet been set live"
        buttonLabel="Go to referral settings to set live"
        onClick={() => navigate(`/referrals/${referral.id}`)}
      />
    );
  }

  return (
    <div>
      <PageHeader
        header="New Referral"
        subhead={``}
        buttons={[
          {
            type: "primary",
            label: "Create new referral",
            action: () => createReferral(),
          },
        ]}
      />
      <div className="flex flex-col gap-4 pl-8">
        <Card>
          <SectionHeader title="Referrer" />
          <TextInput
            label="Name"
            placeholder={`Sally Sells`}
            value={referrerName}
            onChange={(s) => setReferrerName(s)}
            required={true}
            autoFocus={true}
          />
          <TextInput
            label="Email"
            placeholder={`sally@trypavlov.com`}
            value={referrerEmail}
            onChange={(s) => setReferrerEmail(s)}
            required={true}
          />
        </Card>
        <Card>
          <SectionHeader title="Referee" />
          <TextInput
            label="Name"
            placeholder={`Bobby Buys`}
            value={refereeName}
            onChange={(s) => setRefereeName(s)}
            required={true}
          />
          <TextInput
            label="Email"
            placeholder={`bobby@new-customer.com`}
            value={refereeEmail}
            onChange={(s) => setRefereeEmail(s)}
            required={true}
          />
        </Card>
      </div>
    </div>
  );
}
