import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Link, NavLink } from "react-router-dom";
import { GET_PERSON } from "../graphql/queries";

import Card from "../patterns/Card";
import HeaderCard from "./header-cards";
import { formatLong } from "../lib/date";
import AttributeCloud from "../attributes/AttributeCloud";
import PageHeader, { Button } from "../patterns/PageHeader";
import SectionHeader from "../patterns/SectionHeader";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { CubeIcon, CubeTransparentIcon } from "@heroicons/react/24/outline";
import SubSectionHeader from "../patterns/SubSectionHeader";
import { useCallback, useContext } from "react";
import { SessionContext } from "../SessionContext";
import { titleCase } from "../lib/string";
import {
  CREATE_EVENT_FOR_PERSON,
  UPDATE_PERSON_FROM_CRM,
} from "../graphql/mutations";
import toast from "react-hot-toast";
import Table from "../patterns/tables/table";
import SkeletonScreen from "../patterns/Skeleton";
import DataSources from "./data-sources";
import useFinishedCampaigns from "../hooks/useFinishedCampaigns";
import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";
import { Flags } from "../__generated__/graphql";
import BehaviorTimelines from "../patterns/charts/BehaviorTimelines";

interface Props {
  id: string;
}

function toTable(data: any) {
  return {
    header: [
      { label: "Event" },
      { label: "Total" },
      { label: "First Seen" },
      { label: "Last Seen" },
    ],
    rows: data.map((eventSummary: any) => ({
      id: eventSummary.eventName,
      onClick: () => {},
      cells: [
        { content: eventSummary.eventName },
        { content: eventSummary.count },
        { content: formatLong(eventSummary.firstSeen) },
        { content: formatLong(eventSummary.lastSeen) },
      ],
    })),
  };
}

export default function PersonProfile({ id }: Props) {
  const { data, loading } = useQuery(GET_PERSON, {
    variables: { id: id },
  });
  const [updatePersonFromCrmMutation] = useMutation(UPDATE_PERSON_FROM_CRM);

  const sessionContext = useContext(SessionContext);
  const canUseFireEvent = useIsFeatureEnabled(Flags.FireEvent);
  const updatePersonFromCrm = useCallback(async () => {
    try {
      await updatePersonFromCrmMutation({ variables: { personId: id } });
      toast.success("Updated");
    } catch (e) {
      toast.error(e.message);
    }
  }, [id, updatePersonFromCrmMutation]);

  const [showFireEventModal, setShowFireEventModal] = useState(false);
  const [eventName, setEventName] = useState("");
  const [fireEventForPerson] = useMutation(CREATE_EVENT_FOR_PERSON);

  const fireEvent = useCallback(async () => {
    try {
      await fireEventForPerson({
        variables: { personId: id, eventName: eventName },
      });
      toast.success("Event fired");
      setEventName("");
      setShowFireEventModal(false);
    } catch (e) {
      toast.error(e.message);
    }
  }, [eventName, id, fireEventForPerson]);

  const allFinishedCampaigns = useFinishedCampaigns(
    data?.personById.completedCampaigns || [],
    data?.personById.exitedCampaigns || [],
  );

  if (loading) {
    return <SkeletonScreen />;
  }

  const tableData = toTable(data.personById.eventsSummary);

  const buttons = [];
  if (canUseFireEvent) {
    buttons.push({
      label: "Fire Event",
      action: () => setShowFireEventModal(true),
      type: "primary",
    } as Button);
  }

  if (sessionContext.session.crmType) {
    buttons.push({
      label: `Refresh from ${titleCase(sessionContext.session.crmType)}`,
      action: updatePersonFromCrm,
      type: "primary",
    } as Button);
  }

  return (
    <>
      {showFireEventModal && (
        <Modal title="Fire Event" close={() => setShowFireEventModal(false)}>
          <TextInput
            label="Event Name"
            value={eventName}
            onChange={(s) => setEventName(s)}
            placeholder="Event Name"
            required={true}
          />
          <PrimaryButton label="Fire Event" onClick={() => fireEvent()} />
        </Modal>
      )}
      <div className="flex flex-col">
        <PageHeader
          header={data.personById.name}
          subhead={data.personById.email}
          buttons={buttons}
        />
        <div className="text-xs text-body-text space-y-8 mx-8">
          <div className="flex space-x-4">
            <HeaderCard
              header="Campaigns entered"
              indicator={String(
                data.personById.activeCampaigns.length +
                  data.personById.completedCampaigns.length +
                  data.personById.exitedCampaigns.length,
              )}
              footer=""
            />
            <HeaderCard
              header="Events completed"
              indicator={data.personById.eventsTotal.toString()}
              footer=""
            />
            <HeaderCard header="Incentives claimed" indicator="0" footer="" />
          </div>

          <BehaviorTimelines member={data.personById} />

          <div className="">
            <SectionHeader
              title="Personal Attributes"
              icon="Personal Attributes"
            ></SectionHeader>
            <Card>
              <AttributeCloud
                attrs={data.personById.attributes}
                entityId={data.personById.id}
              />
            </Card>
          </div>
          <div>
            <SectionHeader
              title={`Other members of ${data.personById.org?.name || ""}`}
              icon="Other members"
            ></SectionHeader>
            <Card>
              {!data.personById.org ? (
                `${data.personById.name} has not been assigned to an organization`
              ) : (
                <>
                  <SubSectionHeader label="Members" />

                  {data.personById.org.members.filter(
                    (m) => m.id !== data.personById.id,
                  ).length === 0 ? (
                    <p>No other Members</p>
                  ) : (
                    <>
                      {data.personById.org.members
                        .filter((m) => m.id !== data.personById.id)
                        .map((m) => (
                          <Link
                            className="border-b border-rule-color last:border-0 py-2.5 first:pt-0 last:pb-0 rounded-sm hover:text-active-blue"
                            key={m.id}
                            to={`/audience/people/${m.id}`}
                          >
                            <div className="flex flex-row items-center gap-x-1.5 text-center">
                              <div className="w-5 h-5 rounded-full">
                                <UserCircleIcon className="w-5 h-5 text-yellow-300" />
                              </div>
                              {m.displayName}
                            </div>
                          </Link>
                        ))}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
          <div className="">
            <SectionHeader
              title="Active Campaigns"
              icon="Active Campaigns"
            ></SectionHeader>
            <Card>
              <div>
                {data.personById.activeCampaigns.length === 0 ? (
                  <p>No Active campaigns</p>
                ) : (
                  <div>
                    {data.personById.activeCampaigns.map((c) => (
                      <div
                        key={c.campaignId}
                        className="flex-row w-full py-1.5 first:pt-0 last:pb-0"
                      >
                        <NavLink
                          to={`/campaigns/${c.campaignId}`}
                          className="flex items-center"
                        >
                          <div className="flex flex-row items-center gap-x-1.5 text-center">
                            <div className="flex w-5 h-5 rounded-full bg-active-blue items-center text-center">
                              <CubeTransparentIcon className="p-1 text-white" />
                            </div>
                            <div className="min-w-44 text-start">
                              {c.campaignName}
                            </div>
                          </div>

                          <div className="mr-6 leading-6 min-w-44">
                            {formatLong(c.date)}
                          </div>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>

          <div className="">
            <SectionHeader
              title="Finished Campaigns"
              icon="Finished Campaigns"
            ></SectionHeader>
            <Card>
              <div>
                {allFinishedCampaigns.length === 0 ? (
                  <p className="font-normal text-body-text-lightest">
                    No finished campaigns
                  </p>
                ) : (
                  <div>
                    {allFinishedCampaigns.map((c) => (
                      <div
                        key={c.campaignId}
                        className="flex-row w-full py-1.5 first:pt-0 last:pb-0"
                      >
                        <NavLink
                          to={`/campaigns/${c.campaignId}`}
                          className="flex items-center hover:text-active-blue animate"
                        >
                          <div className="flex flex-row items-center gap-x-1.5 text-center">
                            <div className="flex w-5 h-5 rounded-full bg-body-text items-center text-center">
                              <CubeIcon className="p-1 text-white" />
                            </div>
                            <div className="min-w-44 text-start">
                              {c.campaignName}
                            </div>
                          </div>

                          <div className="mr-6 leading-6 min-w-44">
                            {formatLong(c.date)}
                          </div>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>
        </div>
        <div className="mt-6">
          <div className="mx-8">
            <SectionHeader
              title="Events Summary"
              icon="Events Summary"
            ></SectionHeader>
          </div>
          <Table header={tableData.header} rows={tableData.rows} />
        </div>
        {data.personById.updates.length > 0 && (
          <div className="mt-6">
            <div className="mx-8">
              <DataSources updates={data.personById.updates} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
