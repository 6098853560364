export function downloadFile(url: string, name: string) {
  const base =
    process.env.NODE_ENV === "production"
      ? `//${window.location.host}`
      : "http://localhost:8000";
  const a = document.createElement("a");
  a.href = base + url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
