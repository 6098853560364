import { useCallback, useMemo, useRef, useState } from "react";
import Modal from "../patterns/Modal";
import SelectInput from "../patterns/forms/SelectInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { useMutation, useQuery } from "@apollo/client";
import TextInput from "../patterns/forms/TextInput";
import { GET_MESSAGING_SENDERS } from "../graphql/queries";
import { SEND_TEST_EMAIL } from "../graphql/mutations";
import toast from "react-hot-toast";

interface Props {
  closeModal: () => void;
}

export default function TestEmailModal({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [isSending, setIsSending] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [senderId, setSenderId] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const { data, loading } = useQuery(GET_MESSAGING_SENDERS);
  const [sendTestEmailMutation] = useMutation(SEND_TEST_EMAIL);

  const senderOptions = useMemo(
    () =>
      (data?.messagingSenders || []).map((s) => ({
        label: s.displayName,
        value: s.id,
      })),
    [data],
  );

  const send = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setIsSending(true);
      try {
        await sendTestEmailMutation({
          variables: {
            senderId,
            recipient,
            body,
            subject,
          },
        });
        toast.success("Message sent");
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSending(false);
      }
    },
    [senderId, recipient, body, subject, sendTestEmailMutation],
  );

  if (loading) {
    return <></>;
  }

  return (
    <Modal close={closeModal} title="Add an email integration">
      <form ref={formRef} className="" action="#" onSubmit={send}>
        <SelectInput
          label="Sender"
          placeholder="Choose an sender"
          options={senderOptions}
          value={senderId}
          required={true}
          onChange={setSenderId}
        />

        <TextInput
          type="email"
          placeholder="recipient@domain.com"
          label="Recipient"
          value={recipient}
          required={true}
          onChange={setRecipient}
        />

        <TextInput
          placeholder="Test subject"
          label="Subject"
          value={subject}
          required={true}
          onChange={setSubject}
        />

        <TextInput
          placeholder="Body"
          label="Body"
          value={body}
          required={true}
          onChange={setBody}
        />

        <PrimaryButton
          label="Send test"
          isDisabled={isSending}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
