import { MachineCampaignType } from "../../__generated__/graphql";
import Modal from "../../patterns/Modal";
import PrimaryButton from "../../patterns/atoms/PrimaryButton";
import SecondaryButton from "../../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import { COMPLETE_CAMPAIGN } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { titleCase } from "../../lib/string";
import { CompleteCampaignIllustration } from "../../patterns/illustrations/CompleteCampaignIllustration";

interface Props {
  campaign: MachineCampaignType;
  close: () => void;
}

export default function CompleteModal({ campaign, close }: Props) {
  const [completeMutation] = useMutation(COMPLETE_CAMPAIGN);
  const entityName = campaign.isBehavior ? "behavior" : "campaign";
  return (
    <Modal
      title={`Confirm ${entityName} is complete`}
      close={close}
      size="default"
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <CompleteCampaignIllustration />
      </div>
      <div className="text-center text-xs mt-2">
        <p>
          {`Once a ${entityName} has been completed nobody can enter or exit the
          ${entityName} and the ${entityName} cannot be set live again.`}
        </p>
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <PrimaryButton
          label={`Complete ${entityName}`}
          onClick={async () => {
            const response = await completeMutation({
              variables: { id: campaign.id },
            });
            if (response.data.completeCampaign.ok) {
              toast.success(`${titleCase(entityName)} completed`);
              close();
            } else {
              toast.error(`Failed to complete ${entityName}`);
            }
          }}
        />
      </div>
    </Modal>
  );
}
