import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { AttributeContexts, BehaviorType } from "../../__generated__/graphql";
import { CREATE_CAMPAIGN } from "../../graphql/mutations";
import CampaignsIcon from "../../patterns/symbols/CampaignsIcon";

export default function CampaignAction({
  objective,
}: {
  objective: BehaviorType;
}) {
  const navigate = useNavigate();
  const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN);
  const createCampaignFromBehavior = useCallback(
    async (objective: BehaviorType) => {
      try {
        const result = await createCampaignMutation({
          variables: {
            name: `Incentivize ${objective.name}`,
            targetMemberType: objective.targetMemberType,
            fromBehaviorId: objective.id,
          },
        });
        if (result.data.createCampaign.ok) {
          navigate(
            `/campaigns/${result.data.createCampaign.campaign.id}?view=settings&from=${AttributeContexts.ObjectiveCampaign}`,
          );
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
    [createCampaignMutation, navigate],
  );

  return (
    <div className="bg-white rounded-lg p-4 flex flex-row gap-4">
      <div className="w-5 h-5">
        <CampaignsIcon strokeColor={"white"} />
      </div>
      <div
        className="grow flex flex-row gap-2 items-center text-sm"
        onClick={() => {
          createCampaignFromBehavior(objective);
        }}
      >
        Campaign Drafted:
        <span className="text-blue-700 cursor-pointer flex flex-row">
          {objective.name}
          <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2 mb-0.5" />
        </span>
      </div>
    </div>
  );
}
