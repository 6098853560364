import { Editor } from "@tiptap/react";
import IconButton from "../patterns/atoms/IconButton";
import Input from "../patterns/forms/Input";
import { useCallback, useState } from "react";
import useDocumentClick from "../hooks/useDocumentClick";

interface Props {
  editor: Editor;
  close: () => void;
}

export default function LinkEditor({ editor, close }: Props) {
  const [editingLink, setEditingLink] = useState(
    editor.getAttributes("link").href,
  );

  const saveChanges = useCallback(() => {
    if (editor.getAttributes("link").href) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: editingLink })
        .run();
    } else {
      editor.chain().focus().setLink({ href: editingLink }).run();
    }

    close();
  }, [editor, editingLink, close]);

  const removeLink = useCallback(() => {
    if (editor.getAttributes("link").href) {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    close();
  }, [editor, close]);

  useDocumentClick(close);
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="absolute z-10 rounded-lg bg-white shadow-md -ml-2"
    >
      <div className="flex flex-row w-auto h-auto overflow-y-auto p-3">
        <Input
          autoFocus={true}
          value={editingLink}
          onChange={setEditingLink}
          placeholder="Enter link URL"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              saveChanges();
            } else if (e.key === "Escape") {
              close();
            }
          }}
        />
        <div className="flex mt-1.5 items-center">
          <IconButton onClick={removeLink} icon="xMark" variant="destructive" />
          <IconButton onClick={saveChanges} icon="check" variant="confirm" />
        </div>
      </div>
    </div>
  );
}
