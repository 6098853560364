import React, { useState, useRef, useCallback } from "react";
import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { CREATE_CAMPAIGN } from "../graphql/mutations";
import { TargetMemberTypeChoices } from "../__generated__/graphql";
import { VIEW_PARAM } from "../campaign";
import CampaignButton from "../patterns/atoms/CampaignButton";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import SubSectionHeader from "../patterns/SubSectionHeader";
import toast from "react-hot-toast";

interface Props {
  closeModal: () => void;
}

export default function NewCampaign({ closeModal }: Props) {
  const [campaignName, setCampaignName] = useState("");
  const [targetMemberType, setTargetMemberType] =
    useState<TargetMemberTypeChoices>();
  const [isSaving, setIsSaving] = useState(false);

  const formRef = useRef<HTMLFormElement>();
  const navigate = useNavigate();
  const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN);

  const createCampaign = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        const refetchQueries = ["GetCampaigns"];
        const result = await createCampaignMutation({
          variables: {
            name: campaignName,
            targetMemberType: targetMemberType,
          },
          refetchQueries,
        });
        if (result.data.createCampaign.ok) {
          navigate(
            `/campaigns/${result.data.createCampaign.campaign.id}?${VIEW_PARAM}=settings`,
          );
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [createCampaignMutation, campaignName, targetMemberType, navigate],
  );

  return (
    <Modal close={closeModal} title={`Create a Campaign`} size="large">
      <form
        ref={formRef}
        className=""
        action="#"
        onSubmit={createCampaign}
        data-testid="campaigns__new-campaign__form"
      >
        <>
          <TextInput
            label={`Give your campaign a name`}
            value={campaignName}
            placeholder="Campaign Name"
            required={true}
            onChange={setCampaignName}
            autoFocus={true}
          />
          <label>{`Who should this campaign target?`}</label>
          <div className="flex flex-col gap-y-2 mb-4">
            <label className="px-2 text-xs flex">
              <input
                type="radio"
                className="mr-3 text-body-text active:ring-1 focus:ring-white animate"
                checked={targetMemberType === TargetMemberTypeChoices.Person}
                onChange={() => {
                  setTargetMemberType(TargetMemberTypeChoices.Person);
                }}
              />{" "}
              <SubSectionHeader
                label="An Individual"
                description="Target an individual for an action they'll complete themselves"
              />
            </label>
            <label className="px-2 text-xs flex">
              <input
                type="radio"
                className="mr-3 text-body-text active:ring-1 focus:ring-white animate"
                checked={targetMemberType === TargetMemberTypeChoices.Org}
                onChange={() => {
                  setTargetMemberType(TargetMemberTypeChoices.Org);
                }}
              />{" "}
              <SubSectionHeader
                label="An Organization"
                description="Target an organization for an action somebody within it will complete"
              />
            </label>
          </div>
          <div className="flex w-full gap-x-4">
            <SecondaryButton
              label="Cancel"
              onClick={() => closeModal()}
              fullWidth={true}
            />
            <CampaignButton
              label={`Create campaign`}
              isDisabled={isSaving}
              onClick={() => formRef.current.requestSubmit()}
              fullWidth={true}
            />
          </div>
        </>
      </form>
    </Modal>
  );
}
