import React from "react";

export default function SegmentLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 264.968"
      width="100%"
      height="100%"
    >
      <path
        fill="#4fb58b"
        d="m233.56 141.927.17.013 17.892 1.87a4.927 4.927 0 0 1 3.225 1.707l.133.163-.17.085a4.93 4.93 0 0 1 1.02 3.74 133.272 133.272 0 0 1-41.604 81.083 128.86 128.86 0 0 1-87.629 34.38 127.488 127.488 0 0 1-46.156-8.57l-.802-.312a4.716 4.716 0 0 1-2.686-2.533l-.077-.187a4.891 4.891 0 0 1-.083-3.66l7.062-17.23a4.846 4.846 0 0 1 6.118-2.799l.163.061c36.097 13.938 76.98 6.088 105.349-20.228a104.455 104.455 0 0 0 32.891-63.32 4.93 4.93 0 0 1 5.013-4.27l.17.006zm-190.08 64.31.251-.002.253.002c8.12.093 14.658 6.658 14.746 14.749v.253c0 .084 0 .168-.002.252-.14 8.284-6.97 14.885-15.254 14.745-8.284-.142-14.884-6.97-14.745-15.254.139-8.115 6.695-14.616 14.75-14.745zM4.93 147.082h146.316a4.973 4.973 0 0 1 4.928 4.844l.002.171v18.316a4.974 4.974 0 0 1-4.76 5.01l-.17.004H4.93A4.974 4.974 0 0 1 0 170.584v-18.659a4.975 4.975 0 0 1 4.755-4.838zM169.56 7.311a4.974 4.974 0 0 1 2.848 2.635 5.096 5.096 0 0 1 0 3.867l-6.375 16.999a4.845 4.845 0 0 1-6.162 2.974A101.228 101.228 0 0 0 62.13 51.252a105.267 105.267 0 0 0-34.507 54.99 4.93 4.93 0 0 1-4.76 3.698h-1.105L4.25 105.733a4.886 4.886 0 0 1-3.103-2.295h-.085A4.929 4.929 0 0 1 .51 99.57 133.393 133.393 0 0 1 44.92 29.366C79.739.7 127.019-7.666 169.56 7.311zm-64.807 73.434H251.07a4.972 4.972 0 0 1 4.922 4.67l.008.174v18.317a4.973 4.973 0 0 1-4.76 5.01l-.17.004H104.754a4.972 4.972 0 0 1-4.885-4.841l-.002-.174V85.76a4.972 4.972 0 0 1 4.714-5.008zm101.572-55.883.252-.002.253.002c8.12.093 14.658 6.659 14.746 14.748v.253l-.001.253c-.141 8.284-6.97 14.885-15.255 14.744-8.283-.14-14.884-6.97-14.744-15.253.138-8.116 6.695-14.616 14.75-14.745z"
      />
    </svg>
  );
}
