import React from "react";

export default function CampaignsIcon({ strokeColor, strokeWidth = 1 }) {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 18.5L11 15.5019V3.50532L16 6.50338M16 18.5V6.50338M16 18.5L21 15.5019V3.50532L16 6.50338M21 3.49857L16 6.51013L11 3.49857L16 0.5L21 3.49857Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 14.5068L16 17.5048M21 14.5L16 17.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13.5068L16 16.5048M21 13.5L16 16.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12.5068L16 15.5048M21 12.5L16 15.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11.5068L16 14.5048M21 11.5L16 14.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 10.5068L16 13.5048M21 10.5L16 13.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 9.50675L16 12.5048M21 9.5L16 12.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 8.50675L16 11.5048M21 8.5L16 11.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 7.50675L16 10.5048M21 7.5L16 10.5116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 6.50675L16 9.5048M21 6.5L16 9.51155"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5.50675L16 8.5048M21 5.5L16 8.51155"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 4.50675L16 7.5048M21 4.5L16 7.51155"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 14.5053L3 20.5019L8 23.5V17.5034L3 14.5053Z" fill="white" />
      <path d="M8 17.5034L13 14.5053L13 20.5019L8 23.5" fill="white" />
      <path
        d="M13 14.4986L8 17.5101L3 14.4986L8 11.5L13 14.4986Z"
        fill="white"
      />
      <path
        d="M8 23.5L3 20.5019L3 14.5053L8 17.5034M8 23.5V17.5034M8 23.5L13 20.5019L13 14.5053L8 17.5034M13 14.4986L8 17.5101L3 14.4986L8 11.5L13 14.4986Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_2647_206022">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
