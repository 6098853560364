import { Config, FilterCondition, relativeDateOperators } from "./lib";
import { ConditionOption } from "./ConditionOption";
import { RelativeDateOption } from "./RelativeDateOption";

interface DateOptionsProps {
  condition: FilterCondition;
  config: Config;
  onChange: (f?: FilterCondition) => void;
}

export function DateOptions({ config, condition, onChange }: DateOptionsProps) {
  return (
    <div className="m-2">
      <div className="text-sm font-semibold mb-2">Relative</div>
      <div>
        {relativeDateOperators.map((o) => (
          <RelativeDateOption
            key={o}
            operator={o}
            condition={condition}
            config={config}
            onChange={onChange}
          />
        ))}
      </div>
      <div className="text-sm font-semibold mb-2 mt-4">Absolute</div>
      <div>
        {config.operators.map((o) => (
          <ConditionOption
            key={o}
            operator={o}
            condition={condition}
            config={config}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
}
