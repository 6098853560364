import PageHeader from "../../../patterns/PageHeader";
import { isAttrCriteria, isEventCriteria } from "../../../campaign/lib";
import EventCompletionCriteriaEditor from "../../../campaign_form/event-completion-criteria-editor";
import AttrCompletionCriteriaEditor from "../../../campaign_form/attr-completion-criteria-editor";
import { emptyFilterDoc } from "../../../lib/filters";
import SectionHeader from "../../../patterns/SectionHeader";
import SubSectionHeader from "../../../patterns/SubSectionHeader";
import DrawerComponentProps from "./drawer-component-props";
import { MachineStatusChoices } from "../../../__generated__/graphql";
import Alert from "../../../patterns/Alert";

export default function CompletionCriteriaDrawer({
  dispatch,
  behavior,
}: DrawerComponentProps) {
  const completionCriteria = behavior.observing.criteria[0];
  const isEditable = behavior.status === MachineStatusChoices.Draft;

  return (
    <div>
      <PageHeader
        header="Completion Criteria"
        subhead="What events and attributes make up this behavior?"
      />
      <div className="mx-8">
        {!isEditable ? (
          <div className="mb-2">
            <Alert
              message={`You can't edit the target behavior after the behavior has gone live`}
              type="warning"
              size="small"
            />
          </div>
        ) : (
          <>
            <div className="mb-2">
              <SectionHeader title="What would you like to track?" />
              <div className="grid grid-cols-2 gap-4">
                <label className="col-span-1 px-4 py-4 text-xs flex justify-between rounded-md bg-white">
                  <SubSectionHeader
                    label="An event"
                    description="Track an event being performed"
                    icon="Event"
                  />
                  <input
                    type="radio"
                    className="text-body-text active:ring-1 focus:ring-white animate"
                    checked={isEventCriteria(completionCriteria)}
                    onChange={() => {
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: {
                          version: "1",
                          type: "event",
                          target: "",
                        },
                      });
                    }}
                  />{" "}
                </label>
                <label className="col-span-1 px-4 py-4 text-xs flex justify-between rounded-md bg-white">
                  <SubSectionHeader
                    label="An attribute change"
                    description="Track an attribute being changed"
                    icon="CRM"
                  />
                  <input
                    type="radio"
                    className="text-body-text active:ring-1 focus:ring-white animate"
                    checked={isAttrCriteria(completionCriteria)}
                    onChange={() => {
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: {
                          version: "1",
                          type: "attribute",
                          target: null,
                          condition: {
                            type: "attribute_filter",
                            filter: emptyFilterDoc,
                          },
                        },
                      });
                    }}
                  />{" "}
                </label>
              </div>
            </div>
            {completionCriteria && (
              <>
                {isEventCriteria(completionCriteria) && (
                  <EventCompletionCriteriaEditor
                    entityName="behavior"
                    targetMemberType={behavior.targetMemberType}
                    criteria={completionCriteria}
                    onChange={(c) =>
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: c,
                      })
                    }
                  />
                )}
                {isAttrCriteria(completionCriteria) && (
                  <AttrCompletionCriteriaEditor
                    entityName="behavior"
                    targetMemberType={behavior.targetMemberType}
                    criteria={completionCriteria}
                    onChange={(c) =>
                      dispatch({
                        type: "UpdateCompletionCriteria",
                        criteria: c,
                      })
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
