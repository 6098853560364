import { useCallback, useEffect, useMemo, useReducer, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_REFERRAL } from "../graphql/queries";
import { UPDATE_REFERRAL } from "../graphql/mutations";
import { MachineStatusChoices, ReferralType } from "../__generated__/graphql";
import { SessionContext } from "../SessionContext";
import CampaignStatusPill from "../campaigns/CampaignStatePill";
import TopNav from "../patterns/TopNav";
import DashboardIcon from "../patterns/symbols/DashboardIcon";
import TabNavigation from "../patterns/TabNavigation";
import PageHeader, { Button } from "../patterns/PageHeader";
import StatsHeading from "../patterns/StatsHeading";

import { toast } from "react-hot-toast";
import { MemberTable } from "./MemberTable";
import { updateReferralReducer } from "./referral_form/reducer";
import ReferralForm from "./referral_form";

function StatCell({ title, value }: { title: string; value: number }) {
  return (
    <div className="flex grow flex-col rounded-lg p-4 shadow-sm cursor-pointer bg-white">
      <StatsHeading title={title} stat={value.toLocaleString()} subtitle={""} />
    </div>
  );
}

const VIEW_PARAM = "overview";

export default function Referrals() {
  const sessionContext = useContext(SessionContext);
  const params = useParams();

  let [searchParams, setSearchParams] = useSearchParams();
  let view = searchParams.get(VIEW_PARAM) ?? "overview";
  const [referralData, dispatch] = useReducer(updateReferralReducer, null);
  const { data, loading } = useQuery(GET_REFERRAL, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });
  const [updateReferralMutation] = useMutation(UPDATE_REFERRAL);

  const views = useMemo(
    () => [
      {
        label: "Overview",
        action: () => setSearchParams(`${VIEW_PARAM}=overview`),
        isActive: view === "overview",
      },
      {
        label: "Settings",
        action: () => setSearchParams(`${VIEW_PARAM}=settings`),
        isActive: view === "settings",
      },
    ],
    [setSearchParams, view],
  );

  const pauseReferral = useCallback(() => {
    return updateReferralMutation({
      variables: {
        id: data.referralById.id,
        name: data.referralById.name,
        status: MachineStatusChoices.Paused,
        actionsOnReferred: data.referralById.actionsOnReferred,
      },
    });
  }, [data?.referralById, updateReferralMutation]);

  const setReferralLive = useCallback(() => {
    return updateReferralMutation({
      variables: {
        id: data.referralById.id,
        name: data.referralById.name,
        status: MachineStatusChoices.Live,
        actionsOnReferred: data.referralById.actionsOnReferred,
      },
    });
  }, [data?.referralById, updateReferralMutation]);

  const updateReferral = useCallback(() => {
    return updateReferralMutation({
      variables: {
        id: data.referralById.id,
        name: data.referralById.name,
        status: data.referralById.status,
        actionsOnReferred: JSON.stringify(referralData.actionsOnReferred),
      },
    });
  }, [data?.referralById, referralData, updateReferralMutation]);

  useEffect(() => {
    if (data?.referralById) {
      dispatch({
        type: "init",
        data: data?.referralById,
      });
    }
  }, [data?.referralById]);

  const hasAnyMembers = useMemo(() => {
    return data?.referralById.stats.totalReferrals > 0;
  }, [data?.referralById]);

  const isReferralDirty = useMemo(() => {
    if (!data?.referralById || !referralData) {
      return false;
    }
    return (
      JSON.stringify(JSON.parse(data?.referralById.actionsOnReferred)) !==
      JSON.stringify(referralData?.actionsOnReferred)
    );
  }, [data?.referralById, referralData]);

  if (loading) {
    return <p>Loading...</p>;
  }

  const topNavOptions = sessionContext?.session?.settings?.isReferralsCustomer
    ? {
        title: "Referrals",
        breadcrumb: <DashboardIcon strokeColor={"white"} strokeWidth={1.5} />,
        backLink: "/referrals/signals",
      }
    : {
        title: "Dashboard",
        breadcrumb: <DashboardIcon strokeColor={"white"} strokeWidth={1.5} />,
        backLink: "/dashboard/referrals",
      };

  const referral = data.referralById as ReferralType;

  const allButtons = {
    pause: {
      label: `Pause Referrals`,
      type: "primary",
      action: () => {
        pauseReferral()
          .then(() => toast.success("Referrals paused"))
          .catch((e) => toast.error(`Failed: ${e.message}`));
      },
    } as Button,
    setLive: {
      label: `Set Referrals Live`,
      type: "primary",
      action: () => {
        setReferralLive()
          .then(() => toast.success("Referrals set live"))
          .catch((e) => toast.error(`Failed: ${e.message}`));
      },
    } as Button,
    save: {
      label: `Save`,
      type: "primary",
      isDisabled: !isReferralDirty,
      action: () => {
        updateReferral()
          .then(() => toast.success("Referral updated"))
          .catch((e) => toast.error(`Failed: ${e.message}`));
      },
    } as Button,
  };

  const buttons = [];
  buttons.push(allButtons.save);
  if (
    referral.status === MachineStatusChoices.Draft ||
    referral.status === MachineStatusChoices.Paused
  ) {
    buttons.push(allButtons.setLive);
  } else if (referral.status === MachineStatusChoices.Live) {
    buttons.push(allButtons.pause);
  }

  const referInbox = sessionContext?.session?.settings.referInbox;
  const subheader = referInbox
    ? `forward your referral email chain to ${referInbox}`
    : "Contact us to set up your referral inbox address";

  return (
    <div>
      <TopNav {...topNavOptions} />
      <div className="max-w-8xl mx-auto mt-0 2xl:mt-8">
        <PageHeader
          header="Referrals"
          subhead={subheader}
          headerPill={
            <CampaignStatusPill
              status={referral.status as MachineStatusChoices}
            />
          }
          buttons={buttons}
          slug=""
        />
        <TabNavigation tabs={views} />
        {view === "overview" && (
          <>
            <div className="mx-8 mb-8 flex gap-2">
              <StatCell
                title="Total Referrals"
                value={referral.stats.totalReferrals}
              />
              <StatCell
                title="Total Referrers"
                value={referral.stats.totalReferrers}
              />
              <StatCell
                title="Total Referees"
                value={referral.stats.totalReferees}
              />
            </div>
            {hasAnyMembers ? (
              <MemberTable referral={referral} />
            ) : (
              <div className="mx-8">
                <p>No Referrals... yet</p>
              </div>
            )}
          </>
        )}
        {view === "settings" && !loading && (
          <div className="mx-8 mb-8">
            <ReferralForm
              referral={referralData}
              dispatch={dispatch}
              senders={data.messagingSenders}
            />
          </div>
        )}
      </div>
    </div>
  );
}
