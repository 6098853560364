import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import "./tiptap.css";
import Link from "@tiptap/extension-link";
import { Variable } from "./tiptap-variable";
import { Mention, mentionConfig } from "./mentions";

import AttributePicker from "../filter_builder/AttributePicker";
import { useContext, useState } from "react";
import { AttributeType, IncentiveAttributeType } from "../filter_builder/lib";
import IncentivePicker from "../filter_builder/IncentivePicker";
import useDocumentClick from "../hooks/useDocumentClick";
import { SessionContext } from "../SessionContext";
import ToolbarButton from "./toolbar-button";
import toast from "react-hot-toast";
import LinkEditor from "./link-editor";

const default_extensions = [
  StarterKit.configure({
    bulletList: {
      HTMLAttributes: {
        class: "list-disc pl-4",
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: "list-decimal pl-4",
      },
    },
  }),
  Link.extend({ inclusive: false }).configure({
    protocols: ["http", "https"],
    openOnClick: false,
    HTMLAttributes: {
      class: "text-blue underline",
    },
  }),
  Variable.configure(),
];

interface Props {
  content: string;
  editable: boolean;
  onChange: (n: String) => void;
  placeholder: string;
  mentionUsers?: any[];
  showIncentivePicker?: boolean;
}

function MenuBar({ showIncentivePicker }: { showIncentivePicker: boolean }) {
  const { editor } = useCurrentEditor();
  const [linkEditorOpen, setLinkEditorOpen] = useState(false);
  const [attributePickerOpen, setAttributePickerOpen] = useState(false);
  useDocumentClick(() => {
    setAttributePickerOpen(false);
  });
  const session = useContext(SessionContext);
  return (
    <div className="w-full border border-grey-100 bg-grey-50 py-1 rounded-t-md">
      <div className="flex items-center justify-between border-grey-100 px-3">
        <div className="flex flex-wrap items-center divide-grey-100  sm:divide-x">
          <div className="flex items-center space-x-1 sm:pr-4">
            <ToolbarButton
              icon="bold"
              isActive={editor.isActive("bold")}
              onClick={() => editor.chain().focus().toggleBold().run()}
            />

            <ToolbarButton
              icon="italic"
              isActive={editor.isActive("italic")}
              onClick={() => editor.chain().focus().toggleItalic().run()}
            />
            <div>
              <ToolbarButton
                icon="link"
                isActive={editor.isActive("link")}
                isDisabled={
                  editor.state.selection.empty &&
                  !editor.getAttributes("link").href
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setLinkEditorOpen(!linkEditorOpen);
                }}
              />
              {linkEditorOpen && (
                <LinkEditor
                  editor={editor}
                  close={() => setLinkEditorOpen(false)}
                />
              )}
            </div>
            <div className="text-gray-300">|</div>
            <AttributePicker
              attributePickerOpen={attributePickerOpen}
              setAttributePickerOpen={(s) => setAttributePickerOpen(s)}
              onPickerOpened={() => {}}
              openerStyle="toolbar"
              availableAttributes={session.attributes}
              availableEventNames={[]}
              onAttributePicked={(attribute: AttributeType) => {
                editor
                  .chain()
                  .focus()
                  .insertContent(
                    `<span data-type="variable" data-value="${attribute.target}.${attribute.source}.${attribute.slug}" data-label="${attribute.slug}"></span>`,
                  )
                  .run();
              }}
            />
            {showIncentivePicker && (
              <IncentivePicker
                onAttributePicked={(attribute: IncentiveAttributeType) => {
                  editor
                    .chain()
                    .focus()
                    .insertContent(
                      `<span data-type="variable" data-value="${attribute.slug}" data-label="${attribute.name}"></span>`,
                    )
                    .run();
                }}
              />
            )}
          </div>
        </div>

        <ToolbarButton
          icon="copy"
          isActive={false}
          onClick={() => {
            navigator.clipboard.writeText(editor.getHTML());
            toast.success("copied");
          }}
        />
      </div>
    </div>
  );
}

export default function TipTap({
  onChange,
  content,
  editable,
  placeholder,
  mentionUsers = [],
  showIncentivePicker = false,
}: Props) {
  const extensions = [
    ...default_extensions,
    Placeholder.configure({ placeholder }),
    mentionUsers.length > 0 && Mention.configure(mentionConfig(mentionUsers)),
  ];
  return (
    <EditorProvider
      onUpdate={({ editor }) => onChange(editor.getHTML())}
      extensions={extensions}
      content={content}
      slotBefore={<MenuBar showIncentivePicker={showIncentivePicker} />}
      editorProps={{
        attributes: {
          class:
            "block border border-grey-100 bg-white px-3 pt-3 pb-4 text-sm font-normal leading-6 tracking-normal focus:outline-none rounded-b-md",
          style: "min-height: 80px",
        },
      }}
      editable={editable}
    >
      {" "}
    </EditorProvider>
  );
}
