import { COMPLETION_RATE_THRESHOLD } from "../ai_agent/AiAgentDrawer";

export default function LifecycleStageStatePill({
  completedPercentage,
}: {
  completedPercentage: number;
}) {
  const state =
    completedPercentage >= COMPLETION_RATE_THRESHOLD ? "green" : "red";

  const stateMap = {
    green: {
      label: "On Track",
      className: "bg-green-100 text-green-700",
    },
    amber: {
      label: "In Progress",
      className: "bg-amber-100 text-amber-700",
    },
    red: {
      label: "At Risk",
      className: "bg-red-100 text-red-700",
    },
    default: {
      label: "No Data",
      className: "bg-grey-200",
    },
  };

  return (
    <span
      className={`px-2 py-0.5 ${stateMap[state].className} text-xs rounded-3xl`}
    >
      &#9678; {stateMap[state].label}
    </span>
  );
}
