import { useCallback, useMemo, useRef, useState } from "react";
import Modal from "../../patterns/Modal";
import { useMutation } from "@apollo/client";
import { CREATE_ATTRIBUTE } from "../../graphql/mutations";
import TextInput from "../../patterns/forms/TextInput";
import { AttrTypeEnum, AttributeTarget } from "../../__generated__/graphql";
import SelectInput from "../../patterns/forms/SelectInput";
import SecondaryButton from "../../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";

interface Props {
  target: AttributeTarget;
  closeModal: () => void;
}

export default function NewAttribute({ closeModal, target }: Props) {
  const [createAttributeMutation] = useMutation(CREATE_ATTRIBUTE);
  const [isSaving, setIsSaving] = useState(false);
  const [attrName, setAttrName] = useState("");
  const [attrType, setAttrType] = useState<AttrTypeEnum>(AttrTypeEnum.String);

  const formRef = useRef<HTMLFormElement>();

  const options = useMemo(() => {
    return Object.keys(AttrTypeEnum).map((e) => ({
      label: e,
      value: AttrTypeEnum[e],
    }));
  }, []);

  const createAttribute = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsSaving(true);
      createAttributeMutation({
        variables: {
          name: attrName,
          source: "api",
          type: attrType,
          target,
        },
        refetchQueries: ["GetAllAttributes", "GetSession"],
      })
        .then((result) => {
          closeModal();
          toast.success("Attribute created");
        })
        .catch((e) => {
          if (e.message?.includes("UNIQUE constraint failed")) {
            toast.error("Attribute name is already taken");
          } else {
            toast.error(e.message);
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [createAttributeMutation, attrName, attrType, closeModal, target],
  );

  return (
    <Modal close={closeModal} title="Add an attribute">
      <form ref={formRef} className="" action="#" onSubmit={createAttribute}>
        <TextInput
          label="Attribute name"
          value={attrName}
          placeholder="Attribute name"
          required={true}
          onChange={setAttrName}
        />

        <SelectInput
          label="Type"
          placeholder="Choose a type"
          options={options}
          required={true}
          onChange={(s) => setAttrType(s as AttrTypeEnum)}
        />

        <SecondaryButton
          label="Add attribute"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
