import React from "react";

export default function StringIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12.8392V11.1801C1.52805 11.1801 1.9626 11.0257 2.30363 10.717C2.64466 10.3955 2.81518 9.90032 2.81518 9.23151V6.58842C2.81518 4.19614 3.80528 3 5.78548 3H6V4.44695H5.78548C5.19142 4.44695 4.77338 4.60772 4.53135 4.92926C4.28933 5.23794 4.16832 5.75241 4.16832 6.47267V9.63666C4.16832 10.2797 4.0143 10.8071 3.70627 11.2186C3.40924 11.6302 2.9912 11.8939 2.45215 12.0096C2.9912 12.1254 3.40924 12.3891 3.70627 12.8006C4.0143 13.2122 4.16832 13.7396 4.16832 14.3826V17.5273C4.16832 18.2476 4.28933 18.7621 4.53135 19.0707C4.77338 19.3923 5.19142 19.5531 5.78548 19.5531H6V21H5.78548C3.80528 21 2.81518 19.8039 2.81518 17.4116V14.7878C2.81518 14.119 2.64466 13.6302 2.30363 13.3215C1.9626 13 1.52805 12.8392 1 12.8392Z"
        fill="#1E293B"
      />
      <path
        d="M23 11.1608L23 12.8199C22.4719 12.8199 22.0374 12.9743 21.6964 13.283C21.3553 13.6045 21.1848 14.0997 21.1848 14.7685L21.1848 17.4116C21.1848 19.8039 20.1947 21 18.2145 21L18 21L18 19.5531L18.2145 19.5531C18.8086 19.5531 19.2266 19.3923 19.4686 19.0707C19.7107 18.7621 19.8317 18.2476 19.8317 17.5273L19.8317 14.3633C19.8317 13.7203 19.9857 13.1929 20.2937 12.7814C20.5908 12.3698 21.0088 12.1061 21.5479 11.9904C21.0088 11.8746 20.5908 11.6109 20.2937 11.1994C19.9857 10.7878 19.8317 10.2604 19.8317 9.61736L19.8317 6.47267C19.8317 5.75241 19.7107 5.23794 19.4686 4.92926C19.2266 4.60772 18.8086 4.44695 18.2145 4.44695L18 4.44695L18 3L18.2145 3C20.1947 3 21.1848 4.19614 21.1848 6.58842L21.1848 9.21222C21.1848 9.88103 21.3553 10.3698 21.6964 10.6785C22.0374 11 22.4719 11.1608 23 11.1608Z"
        fill="#1E293B"
      />
      <circle cx="9" cy="17" r="1" fill="#1E293B" />
      <circle cx="12" cy="17" r="1" fill="#1E293B" />
      <circle cx="15" cy="17" r="1" fill="#1E293B" />
    </svg>
  );
}
