export function formatPercentage(n: number, d: number, p: number = 0) {
  if (d === 0) return "-";
  return `${((n / d) * 100).toFixed(p)}%`;
}

export function formatNumber(n: number, p: number = 0) {
  if (Number.isInteger(n)) return n;

  return n.toFixed(p);
}
