import Modal from "../patterns/Modal";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import { DeleteCampaignIllustration } from "../patterns/illustrations/DeleteCampaignIllustration";
import { DELETE_COHORT } from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CohortType } from "../__generated__/graphql";

interface Props {
  cohort: CohortType;
  close: () => void;
}

export default function DeleteModal({ cohort, close }: Props) {
  const navigate = useNavigate();
  const [deleteMutation] = useMutation(DELETE_COHORT);
  return (
    <Modal title={`Are you sure you want to delete this cohort?`} close={close}>
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <DeleteCampaignIllustration />
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <PrimaryButton
          label="Delete"
          onClick={() => {
            deleteMutation({
              variables: { id: cohort.id },
              update(cache) {
                const normalizedId = cache.identify({
                  id: cohort.id,
                  __typename: "CohortType",
                });
                cache.modify({
                  id: normalizedId,
                  fields: {
                    deleted() {
                      return true;
                    },
                  },
                });
                cache.gc();
              },
            }).then((response) => {
              if (response.data.deleteCohort.ok) {
                navigate("/dashboard");
                toast.success(`Cohort deleted`);
              } else {
                toast.error(`Failed to delete cohort`);
                close();
              }
            });
          }}
        />
      </div>
    </Modal>
  );
}
