import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import Modal from "../patterns/Modal";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import toast from "react-hot-toast";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { BehaviorType } from "../__generated__/graphql";
import { UPDATE_BEHAVIOR } from "../graphql/mutations";
import { CompleteCampaignIllustration } from "../patterns/illustrations/CompleteCampaignIllustration";

interface Props {
  behavior: BehaviorType;
  close: () => void;
}

export default function CompleteModal({ behavior, close }: Props) {
  const [updateBehaviorMutation] = useMutation(UPDATE_BEHAVIOR);

  const completeBehavior = useCallback(async () => {
    try {
      const response = await updateBehaviorMutation({
        variables: {
          id: behavior.id,
          name: behavior.name,
          status: "COMPLETE",
          audience: JSON.stringify(behavior.audience),
          observing: JSON.stringify(behavior.observing),
          actionsOnEnter: JSON.stringify(behavior.actionsOnEnter),
          actionsOnCompletion: JSON.stringify(behavior.actionsOnCompletion),
          actionsWhileObserving: JSON.stringify(behavior.actionsWhileObserving),
        },
      });

      if (response.data.updateBehavior.ok) {
        toast.success(`Behavior completed`);
      } else {
        toast.error(() => <p>Could not complete behavior</p>);
      }
    } catch (e) {
      toast.error(e.message);
    }
    close();
  }, [behavior, close, updateBehaviorMutation]);

  return (
    <Modal
      title={`Are you sure you want to complete this behavior?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <CompleteCampaignIllustration />
      </div>
      <div className="text-center text-xs mt-2">
        <p>
          {`Completing a behavior will prevent any new entries, and stop any existing entries from completing normally.`}
        </p>
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        <PrimaryButton label={`Complete behavior`} onClick={completeBehavior} />
      </div>
    </Modal>
  );
}
