import {
  AttrCompletionCriteria,
  MultiAttrCompletionCondition,
} from "../types/BackendTypes";
import { TargetMemberTypeChoices } from "../__generated__/graphql";
import clone from "../lib/clone";
import FilterBuilder from "../filter_builder";
import { titleCase } from "../lib/string";

interface Props {
  entityName: string;
  targetMemberType?: TargetMemberTypeChoices;
  criteria: AttrCompletionCriteria;
  onChange: (c: AttrCompletionCriteria) => void;
}

export default function AttrCompletionCriteriaEditor({
  entityName,
  targetMemberType,
  criteria,
  onChange,
}: Props) {
  let targetsOrgs = targetMemberType === TargetMemberTypeChoices.Org;

  return (
    <div>
      <div>
        <div className="px-4 py-4 text-xs flex justify-between border border-rule-color rounded-md">
          {`${titleCase(entityName)} is completed when the ${targetsOrgs ? "organization" : "person"} matches this filter`}
        </div>
        <div className="my-2">
          <FilterBuilder
            showOrgAttrs={targetsOrgs}
            showPersonAttrs={!targetsOrgs}
            showEvents={false}
            filterDoc={criteria.condition.filter}
            onChange={(f) => {
              const newCriteria = clone(criteria);
              (newCriteria.condition as MultiAttrCompletionCondition).filter =
                f;
              onChange(newCriteria);
            }}
          />
        </div>
      </div>
    </div>
  );
}
