import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";
import {
  MachineCampaignType,
  MachineStatusChoices,
} from "../__generated__/graphql";
import NewCampaign from "./NewCampaign";
import EmptyState from "../patterns/EmptyState";
import CampaignEmpty from "../patterns/illustrations/CampaignEmpty";
import { GET_CAMPAIGNS } from "../graphql/queries";
import PageHeader from "../patterns/PageHeader";
import CampaignTable from "./CampaignTable";
import FullScreenError from "../patterns/Errors";
import SkeletonScreen from "../patterns/Skeleton";

function campaignCompareBy(
  a: MachineCampaignType,
  b: MachineCampaignType,
  key: string,
) {
  if (!a[key] || !b[key]) return 0;

  const dtFields = ["started"];
  if (dtFields.includes(key)) {
    return new Date(a[key]).getTime() - new Date(b[key]).getTime();
  } else {
    return a[key].localeCompare(b[key]);
  }
}

export default function CampaignMain() {
  let [modalIsVisible, setModalVisible] = useState(false);
  const [filteredState, setFilteredState] = useState("ALL");
  const { data, loading, error } = useQuery(GET_CAMPAIGNS, {
    variables: {
      onlyArchived: filteredState === MachineStatusChoices.Archived,
    },
  });

  const [searchString, setSearchString] = useState("");
  const [sortKey, setSortKey] = useState("started");

  const [filteredList, setFilteredList] = useState<MachineCampaignType[]>([]);
  useEffect(() => {
    const campaigns = data?.allCampaigns || [];
    const filteredByState =
      filteredState === "ALL"
        ? campaigns
        : campaigns.filter((c) => c.status === filteredState);
    const filteredBySearch =
      searchString === ""
        ? filteredByState
        : filteredByState.filter((c) =>
            c.name.toLowerCase().includes(searchString.toLocaleLowerCase()),
          );
    const sortedAndFiltered = filteredBySearch
      .slice()
      .sort((a, b) => campaignCompareBy(a, b, sortKey));
    setFilteredList(sortedAndFiltered);
  }, [filteredState, data, searchString, sortKey]);

  if (loading) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <FullScreenError />;
  }

  return (
    <div className="mt-8">
      {data.allCampaigns.length === 0 ? (
        <EmptyState
          title="Accelerate time to value"
          icon={<CampaignEmpty />}
          description={
            "By setting targeted campaigns, you’ll build insights over time around collective behaviours"
          }
          buttonLabel={"Create a campaign"}
          onClick={() => setModalVisible(true)}
        />
      ) : (
        <>
          <PageHeader
            header="Campaigns"
            subhead={`${data.allCampaigns.length} total`}
            buttons={[
              {
                type: "campaign",
                label: "Start a campaign",
                action: () => setModalVisible(true),
              },
            ]}
          />

          <CampaignTable
            campaigns={filteredList}
            totalPeople={data.allPeople.total}
            search={searchString}
            filter={filteredState}
            setSearch={setSearchString}
            updateFilter={setFilteredState}
            sortKey={sortKey}
            setSortKey={setSortKey}
          />
        </>
      )}

      {modalIsVisible && (
        <NewCampaign closeModal={() => setModalVisible(false)} />
      )}
    </div>
  );
}
