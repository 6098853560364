import React from "react";
import OnboardingFlow from "../onboarding";

export default function Onboarding() {
  return (
    <div className="flex h-screen flex-col justify-between">
      <OnboardingFlow />
      <footer className="sticky bottom-0">
        <div className="h-14 bg-footer-grey">
          <div className="flex flex-row-reverse ">
            <span className="py-5 pr-9 text-sm text-footer-text">Terms</span>
            <span className="py-5 pr-9 text-sm text-footer-text">Privacy</span>
            <span className="py-5 pr-9 text-sm text-footer-text">Help</span>
          </div>
        </div>
      </footer>
    </div>
  );
}
