import React from "react";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";
import { TagIcon, RectangleStackIcon } from "@heroicons/react/24/outline";

interface Props {
  label: string;
  description?: string;
  icon?: string;
  disabled?: boolean;
}

const iconMapping: { [key: string]: React.ComponentType } = {
  Event: CursorArrowRaysIcon,
  Events: RectangleStackIcon,
  CRM: TagIcon,
};

export default function SubSectionHeader({
  label,
  description,
  icon,
  disabled,
}: Props) {
  const IconComponent = icon && iconMapping[icon] ? iconMapping[icon] : null;

  return (
    <div className="flex flex-row">
      {icon && (
        <div className="h-4 w-4 items-center text-center mr-3">
          {IconComponent && (
            <span className="w-full">
              <IconComponent />
            </span>
          )}
        </div>
      )}
      <div
        className={`animate text-xs cursor-pointer ${disabled ? "text-gray-400" : "text-body-text"}`}
      >
        <h6 className="font-semibold pb-1">{label}</h6>
        {description && (
          <div
            className={` font-normal ${disabled ? "text-gray-400" : "text-text-body-text-lighter"}`}
          >
            {description}
          </div>
        )}
      </div>
    </div>
  );
}
