import React from "react";
import PageHeader from "../../../patterns/PageHeader";
import LabelAndToggle from "../../../campaign_form/label-and-toggle";
import DrawerComponentProps from "./drawer-component-props";
import {
  NotifyCompletedAction,
  UpdateCrmAction,
  UpdateMemberAction,
  WebhookAction,
} from "../../../types/BackendTypes";
import SlackAction from "../../../actions/SlackAction";
import WebhookActionEditor from "../../../actions/WebhookActionEditor";
import {
  MachineStatusChoices,
  TargetMemberTypeChoices,
  Flags,
} from "../../../__generated__/graphql";
import Card from "../../../patterns/Card";
import AttributeUpdater from "../../../actions/AttributeUpdater";
import useIsFeatureEnabled from "../../../hooks/useIsFeatureEnabled";
import UpdateCrmActionEditor from "../../../actions/UpdateCrmActionEditor";

export default function FollowUpActionsDrawer({
  dispatch,
  behavior,
  close,
}: DrawerComponentProps) {
  const canUseUpdateCrm = useIsFeatureEnabled(Flags.UpdateCrmAction);
  const actionList = behavior.actionsOnEnter;
  const notifyEnteredAction = actionList.actions.find(
    (a) => a.type === "notify_entered",
  ) as NotifyCompletedAction | undefined;
  const webhookAction = actionList.actions.find((a) => a.type === "webhook") as
    | WebhookAction
    | undefined;
  const updateMemberAction = actionList.actions.find(
    (a) => a.type === "update_member",
  ) as UpdateMemberAction | undefined;
  const updateCrmAction = actionList.actions.find(
    (a) => a.type === "update_crm",
  ) as UpdateCrmAction | undefined;
  const targetingOrgs =
    behavior.targetMemberType === TargetMemberTypeChoices.Org;
  const isEditable = behavior.status !== MachineStatusChoices.Complete;

  return (
    <div>
      <PageHeader
        header="Entry actions"
        subhead={`These will run after a ${targetingOrgs ? "member of the organization" : "customer"} enters the behavior`}
      />
      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Notify the team`}
          isOn={!!notifyEnteredAction}
          disabled={!isEditable}
          onChange={() => {
            !!notifyEnteredAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "notify_entered",
                  actionList: "actionsOnEnter",
                })
              : dispatch({
                  type: "EnableNotifyEnteredAction",
                });
          }}
        />
        {notifyEnteredAction && (
          <SlackAction
            dispatch={dispatch}
            action={notifyEnteredAction}
            actionSource="actionsOnEnter"
          />
        )}
      </div>
      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Send a webhook`}
          isOn={!!webhookAction}
          disabled={!isEditable}
          onChange={() => {
            !!webhookAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "webhook",
                  actionList: "actionsOnEnter",
                })
              : dispatch({
                  type: "EnableWebhookAction",
                  actionList: "actionsOnEnter",
                });
          }}
        />
        {webhookAction && (
          <WebhookActionEditor
            dispatch={dispatch}
            action={webhookAction}
            actionSource="actionsOnEnter"
          />
        )}
      </div>
      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Add, or update, an attribute`}
          isOn={!!updateMemberAction}
          disabled={!isEditable}
          onChange={() => {
            !!updateMemberAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "update_member",
                  actionList: "actionsOnEnter",
                })
              : dispatch({
                  type: "EnableUpdateMemberAction",
                  actionList: "actionsOnEnter",
                });
          }}
        />
        {updateMemberAction && (
          <Card>
            <AttributeUpdater
              isEditable={isEditable}
              targetMemberType={behavior.targetMemberType}
              dispatch={dispatch}
              action={updateMemberAction}
              actionSource="actionsOnEnter"
            />
          </Card>
        )}
      </div>
      {canUseUpdateCrm && (
        <div className="px-8 mb-8">
          <LabelAndToggle
            label={`Update CRM fields`}
            isOn={!!updateCrmAction}
            disabled={!isEditable}
            onChange={() => {
              !!updateCrmAction
                ? dispatch({
                    type: "RemoveActionOfType",
                    actionType: "update_crm",
                    actionList: "actionsOnEnter",
                  })
                : dispatch({
                    type: "EnableUpdateCrmAction",
                    actionList: "actionsOnEnter",
                  });
            }}
          />
          {updateCrmAction && (
            <Card>
              <UpdateCrmActionEditor
                dispatch={dispatch}
                action={updateCrmAction}
                actionSource="actionsOnEnter"
              />
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
