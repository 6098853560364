import { useCallback, useContext, useState } from "react";
import AttributePicker from "../filter_builder/AttributePicker";
import { EventCompletionCriteria } from "../types/BackendTypes";
import { TargetMemberTypeChoices } from "../__generated__/graphql";
import { SessionContext } from "../SessionContext";
import clone from "../lib/clone";
import useDocumentClick from "../hooks/useDocumentClick";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SubSectionHeader from "../patterns/SubSectionHeader";
import SettingsBlock from "./SettingsBlock";
import { isEventSetCondition, isEventCountCondition } from "../campaign/lib";
import { titleCase } from "../lib/string";

interface Props {
  entityName: string;
  targetMemberType?: TargetMemberTypeChoices;
  criteria: EventCompletionCriteria;
  onChange: (c: EventCompletionCriteria) => void;
}

export default function EventCompletionCriteriaEditor({
  entityName,
  targetMemberType,
  criteria,
  onChange,
}: Props) {
  const session = useContext(SessionContext);
  const [attributePickerOpen, setAttributePickerOpen] = useState(false);
  const closeAttributePicker = useCallback(
    () => setAttributePickerOpen(false),
    [setAttributePickerOpen],
  );

  useDocumentClick(closeAttributePicker);
  const singleActive =
    !criteria.condition || isEventCountCondition(criteria.condition);
  const multipleActive = isEventSetCondition(criteria.condition);
  const singleEventName =
    criteria.target || Object.keys(criteria.condition?.events || {})[0];
  const singleEventCount = isEventCountCondition(criteria.condition)
    ? criteria.condition.events[singleEventName]
    : 1;

  const targetsOrgs = targetMemberType === TargetMemberTypeChoices.Org;
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="px-4 py-4 text-xs rounded-md bg-white">
          <label className="flex justify-between">
            <SubSectionHeader
              label="A single event"
              description={`${titleCase(entityName)} will be completed when${" "}
              ${targetsOrgs ? "the organization" : "the person"}${" "}
          completes a single event`}
              icon="Event"
            />
            <input
              type="radio"
              className="text-body-text active:ring-1 focus:ring-white animate"
              name="event-criteria-type"
              defaultChecked={singleActive}
              onChange={() => {
                const newCriteria = clone(criteria);
                delete newCriteria.condition;
                onChange(newCriteria);
              }}
            />{" "}
          </label>

          {singleActive && (
            <div className="ml-6 pt-1">
              {singleEventName && (
                <div className="flex flex-row items-center gap-2">
                  <SettingsBlock title={singleEventName}>
                    <div className="flex items-center">
                      <input
                        value={singleEventCount}
                        type="number"
                        min="1"
                        onChange={(e) => {
                          let newValue = parseInt(e.target.value);
                          if (newValue < 1) {
                            newValue = 1;
                          }
                          if (newValue === 1) {
                            const newCriteria = clone(criteria);
                            newCriteria.target = singleEventName;
                            delete newCriteria.condition;
                            onChange(newCriteria);
                          } else {
                            const newCriteria = clone(criteria);
                            delete newCriteria.target;
                            newCriteria.condition = {
                              type: "event_count",
                              events: {},
                            };
                            newCriteria.condition.events[singleEventName] =
                              newValue;
                            onChange(newCriteria);
                          }
                        }}
                        onBlur={(e) => {
                          const newValue = e.target.value;
                          if (newValue === "") {
                            const newCriteria = clone(criteria);
                            newCriteria.target = singleEventName;
                            delete newCriteria.condition;
                            onChange(newCriteria);
                          }
                        }}
                        className="border w-16 border-rule-color bg-grey-50 -my-2 text-xs rounded-md focus:ring-2 focus:ring-blue-300 focus:ring-inset"
                      />{" "}
                      <div className="mx-2">
                        {singleEventCount === 1 ? "time" : "times"}
                      </div>
                      <div
                        className="flex h-4 w-4 rounded-md my-auto cursor-pointer"
                        onClick={() => {
                          const newCriteria = clone(criteria);
                          delete newCriteria.target;
                          delete newCriteria.condition;
                          onChange(newCriteria);
                        }}
                      >
                        <XMarkIcon />
                      </div>
                    </div>
                  </SettingsBlock>
                </div>
              )}
              {!singleEventName && (
                <AttributePicker
                  openerStyle="root"
                  openerLabel="Pick an event"
                  attributePickerOpen={attributePickerOpen}
                  setAttributePickerOpen={setAttributePickerOpen}
                  availableAttributes={{ person: [], org: [] }}
                  availableEventNames={session.eventNames}
                  onAttributePicked={(e: string) => {
                    const newCriteria = clone(criteria);
                    newCriteria.target = e;
                    delete newCriteria.condition;
                    onChange(newCriteria);
                  }}
                  onPickerOpened={() => {}}
                />
              )}
            </div>
          )}
        </div>
        <div className="px-4 py-4 text-xs rounded-md bg-white">
          <label className="flex justify-between">
            <SubSectionHeader
              label="Multiple events"
              description={`${titleCase(entityName)} will be completed when${" "}
          ${targetsOrgs ? "the organization" : "the person"}${" "}
          completes all of these events`}
              icon="Events"
            />
            <input
              type="radio"
              className="text-body-text active:ring-1 focus:ring-white animate"
              name="event-criteria-type"
              defaultChecked={multipleActive}
              onChange={() => {
                const newCriteria = clone(criteria);
                delete newCriteria.target;
                newCriteria.condition = {
                  type: "event_set",
                  events: [],
                };
                onChange(newCriteria);
              }}
            />{" "}
          </label>
          {isEventSetCondition(criteria.condition) && (
            <div className="ml-6 font-normal">
              <div className="flex flex-row items-center gap-2 mb-2">
                {criteria.condition.events.map((event) => {
                  return (
                    <>
                      <SettingsBlock title={event}>
                        <div
                          className="flex h-4 w-4 rounded-md my-auto cursor-pointer"
                          onClick={() => {
                            const newCriteria = clone(criteria);
                            newCriteria.condition.events = (
                              newCriteria.condition.events as Array<string>
                            ).filter((e) => e !== event);
                            onChange(newCriteria);
                          }}
                        >
                          <XMarkIcon />
                        </div>
                      </SettingsBlock>
                    </>
                  );
                })}
              </div>
              <AttributePicker
                openerStyle="root"
                openerLabel="Add event"
                attributePickerOpen={attributePickerOpen}
                setAttributePickerOpen={setAttributePickerOpen}
                availableAttributes={{ person: [], org: [] }}
                availableEventNames={session.eventNames.filter(
                  (e) =>
                    !(criteria.condition.events as Array<string>).includes(e),
                )}
                onAttributePicked={(e: string) => {
                  const newCriteria = clone(criteria);
                  (newCriteria.condition.events as Array<string>).push(e);
                  onChange(newCriteria);
                }}
                onPickerOpened={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
