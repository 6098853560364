import { useState } from "react";
import { TargetMemberTypeChoices } from "../../__generated__/graphql";
import PageHeader from "../../patterns/PageHeader";
import { DrawerComponentProps } from "..";
import TextInput from "../../patterns/forms/TextInput";
import { MachineStatusChoices } from "../../__generated__/graphql";
import Alert from "../../patterns/Alert";
import { DAY_IN_SECONDS } from "../../lib/date";
import Card from "../../patterns/Card";
import LabelAndToggle from "../label-and-toggle";

export default function AutoExit({
  dispatch,
  campaignConfig,
  campaignStatus,
  targetMemberType,
  entityName,
}: DrawerComponentProps) {
  const { auto_exit_after: autoExitAfter } = campaignConfig;
  const isOrgCampaign = targetMemberType === TargetMemberTypeChoices.Org;
  const isEditable = campaignStatus === MachineStatusChoices.Draft;
  const [autoExitOn, setAutoExitOn] = useState(autoExitAfter !== null);
  const [invalidAutoExit, setInvalidAutoExit] = useState(false);
  const campaignTarget =
    targetMemberType === TargetMemberTypeChoices.Org
      ? "organization"
      : "person";
  return (
    <>
      <PageHeader
        header="Auto Exit"
        subhead={`Limit the time an ${isOrgCampaign ? "organization" : "individual"} has to complete the event`}
      />
      <div className="mx-8">
        {!isEditable && (
          <div className="mb-2">
            <Alert
              message="You can't edit the auto exit duration after the campaign has gone live"
              type="warning"
              size="small"
            />
          </div>
        )}
        {isEditable && (
          <>
            <div className="mb-8">
              <LabelAndToggle
                label="Auto exit members from the campaign"
                description={`If the ${campaignTarget} has not completed the campaign within a set number of days after entering, automatically exit them from the campaign`}
                isOn={autoExitOn}
                disabled={!isEditable}
                onChange={(checked) => {
                  const newAutoExitAfter = checked ? DAY_IN_SECONDS : null;
                  setAutoExitOn(checked);
                  dispatch({
                    type: "UpdateAutoExit",
                    autoExitAfter: newAutoExitAfter,
                  });
                }}
              />
              {autoExitOn && (
                <Card>
                  <div className="flex flex-row items-center">
                    <div className="w-1/4">
                      <TextInput
                        label="Number of days to wait"
                        type="number"
                        placeholder="number of days"
                        value={
                          autoExitAfter !== null
                            ? `${autoExitAfter / DAY_IN_SECONDS}`
                            : ""
                        }
                        onChange={(value) => {
                          let newAutoExitAfter: number | null;
                          if (value !== "") {
                            newAutoExitAfter = parseInt(value) * DAY_IN_SECONDS;
                          } else {
                            newAutoExitAfter = null;
                          }
                          setInvalidAutoExit(
                            typeof newAutoExitAfter !== "number" ||
                              newAutoExitAfter <= 0,
                          );

                          dispatch({
                            type: "UpdateAutoExit",
                            autoExitAfter: newAutoExitAfter,
                          });
                        }}
                        required={true}
                        disabled={!isEditable}
                        error={invalidAutoExit ? "Invalid number of days" : ""}
                      />
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
