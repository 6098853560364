import React, { useMemo, useState } from "react";
import PageHeader from "../../patterns/PageHeader";
import { useQuery } from "@apollo/client";
import { GET_ALL_ATTRIBUTES } from "../../graphql/queries";

import { useSearchParams } from "react-router-dom";
import TabNavigation from "../../patterns/TabNavigation";
import Attributes from "./Attributes";
import NewAttribute from "./NewAttribute";
import { AttributeTarget } from "../../__generated__/graphql";

export const VIEW_PARAM = "view";

enum Views {
  person = "person",
  org = "org",
}

export default function AttributesMain() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [modalIsVisible, setModalVisible] = useState(false);
  const { data, loading } = useQuery(GET_ALL_ATTRIBUTES);
  let view = (searchParams.get(VIEW_PARAM) as Views) ?? Views.person;
  const tabs = useMemo(
    () => [
      {
        label: "People attributes",
        action: () => setSearchParams(``),
        isActive: view === Views.person,
      },
      {
        label: "Organization attributes",
        action: () => setSearchParams(`${VIEW_PARAM}=${Views.org}`),
        isActive: view === Views.org,
      },
    ],
    [setSearchParams, view],
  );

  if (loading) {
    return <></>;
  }

  const dataSet =
    view === Views.person ? data.allPersonAttributes : data.allOrgAttributes;

  return (
    <div className="flex flex-col max-w-8xl mx-auto mt-0 2xl:mt-8">
      <PageHeader
        header={
          view === Views.person
            ? "People Attributes"
            : "Organization Attributes"
        }
        subhead={`${dataSet.length} attributes`}
        buttons={[
          {
            label: "Add an attribute",
            type: "primary",
            action: () => setModalVisible(true),
          },
        ]}
      />
      <TabNavigation tabs={tabs} />
      <Attributes
        attributes={dataSet}
        showNewAttributeModal={() => setModalVisible(true)}
      />
      {modalIsVisible && (
        <NewAttribute
          closeModal={() => setModalVisible(false)}
          target={
            view === Views.person ? AttributeTarget.Person : AttributeTarget.Org
          }
        />
      )}
    </div>
  );
}
