import { CompletionCriteria, Filter } from "../types/BackendTypes";
import { CampaignDefinition } from "./types";
import { uuidv7 } from "@kripod/uuidv7";
import {
  Action,
  updatedActionList,
  removeActionOfType,
} from "../lib/reducer_utils";
import { DAY_IN_SECONDS } from "../lib/date";

const NULL_CAMPAIGN: CampaignDefinition = {
  entry_criteria: {
    audience: {
      filter: {
        version: "1",
        filters: {
          operator: "AND",
          conditions: [],
        },
      },
    },
    events: [],
  },
  completion_criteria: {
    version: "1",
    type: "event",
    target: "",
  },
  before_actions: {
    delivery: { mode: "bulk" },
    actions: [],
  },
  after_actions: {
    delivery: { mode: "bulk" },
    actions: [],
  },
  auto_exit_after: null,
};

type actionListName = "before_actions" | "after_actions" | "exit_actions";

interface InitAction {
  type: "init";
  data: CampaignDefinition;
}

interface UpdateAudienceAction {
  type: "UpdateAudience";
  data: Filter;
}

interface UpdateEventAction {
  type: "UpdateEvent";
  event: string;
}

interface UpdateCompletionCriteria {
  type: "UpdateCompletionCriteria";
  criteria: CompletionCriteria;
}

interface RemoveActionOfType {
  type: "RemoveActionOfType";
  actionType: Action["type"];
  actionList: actionListName;
}

interface EnableIncentiveAction {
  type: "EnableIncentive";
}

interface EnableBeforeMessageAction {
  type: "EnableBeforeMessage";
}

interface EnableBeforeDelayedMessageAction {
  type: "EnableBeforeDelayedMessage";
}

interface EnableAfterMessageAction {
  type: "EnableAfterMessage";
}

interface EnableUpdateMemberAction {
  type: "EnableUpdateMember";
  actionList: actionListName;
}

interface EnableNotifyEnteredAction {
  type: "EnableNotifyEntered";
  message: { template: string };
}

interface EnableNotifyCompletedAction {
  type: "EnableNotifyCompleted";
  message: { template: string };
}

interface EnableNotifyExitedAction {
  type: "EnableNotifyExited";
  message: { template: string };
}

interface EnableUpdateCrmAction {
  type: "EnableUpdateCrmFields";
  actionList: actionListName;
}

interface UpdateBeforeAction {
  type: "UpdateBeforeAction";
  action: Action;
}
interface UpdateAfterAction {
  type: "UpdateAfterAction";
  action: Action;
}

interface UpdateActionOfType {
  type: "UpdateActionOfType";
  action: Action;
  actionList: actionListName;
}

interface UpdateAutoExit {
  type: "UpdateAutoExit";
  autoExitAfter: number | null;
}

export type CampaignDefinitionEditAction =
  | InitAction
  | UpdateAudienceAction
  | UpdateEventAction
  | UpdateCompletionCriteria
  | EnableBeforeMessageAction
  | EnableBeforeDelayedMessageAction
  | EnableIncentiveAction
  | EnableAfterMessageAction
  | EnableUpdateMemberAction
  | EnableNotifyEnteredAction
  | EnableNotifyCompletedAction
  | EnableNotifyExitedAction
  | EnableUpdateCrmAction
  | RemoveActionOfType
  | UpdateBeforeAction
  | UpdateAfterAction
  | UpdateActionOfType
  | UpdateAutoExit;

export function updateCampaignDefinition(
  prev: CampaignDefinition,
  reducerAction: CampaignDefinitionEditAction | InitAction,
): CampaignDefinition {
  switch (reducerAction.type) {
    case "init":
      return { ...NULL_CAMPAIGN, ...reducerAction.data };
    case "UpdateAudience":
      return {
        ...prev,
        entry_criteria: {
          audience: {
            filter: reducerAction.data,
          },
        },
      };
    case "UpdateEvent":
      return {
        ...prev,
        completion_criteria: {
          version: "1",
          type: "event",
          target: reducerAction.event,
        },
      };
    case "UpdateCompletionCriteria":
      return {
        ...prev,
        completion_criteria: reducerAction.criteria,
      };
    case "EnableIncentive":
      return {
        ...prev,
        after_actions: {
          delivery: { ...prev.after_actions.delivery },
          actions: [
            ...prev.after_actions.actions,
            {
              id: uuidv7(),
              type: "incentive",
              delivery: { mode: "single" },
              incentives: [],
              messages: [],
            },
          ],
        },
      };

    case "RemoveActionOfType":
      return {
        ...prev,
        [reducerAction.actionList]: {
          delivery: { ...prev[reducerAction.actionList].delivery },
          actions: [
            ...removeActionOfType(
              prev[reducerAction.actionList],
              reducerAction.actionType,
            ),
          ],
        },
      };

    case "EnableBeforeMessage":
      return {
        ...prev,
        before_actions: {
          delivery: { ...prev.before_actions.delivery },
          actions: [
            ...prev.before_actions.actions,
            {
              id: uuidv7(),
              type: "email",
              delivery: { mode: "single" },
              messages: [{ subject: "", body: "", sender: "" }],
            },
          ],
        },
      };

    case "EnableBeforeDelayedMessage":
      return {
        ...prev,
        before_actions: {
          delivery: { ...prev.before_actions.delivery },
          actions: [
            ...prev.before_actions.actions,
            {
              id: uuidv7(),
              type: "delayed_email",
              delay: 7 * DAY_IN_SECONDS,
              delivery: { mode: "single" },
              messages: [{ subject: "", body: "", sender: "" }],
            },
          ],
        },
      };

    case "EnableAfterMessage":
      return {
        ...prev,
        after_actions: {
          delivery: { ...prev.after_actions.delivery },
          actions: [
            ...prev.after_actions.actions,
            {
              id: uuidv7(),
              type: "email",
              delivery: { mode: "single" },
              messages: [{ subject: "", body: "", sender: "" }],
            },
          ],
        },
      };

    case "EnableUpdateMember":
      return {
        ...prev,
        [reducerAction.actionList]: {
          delivery: { ...prev[reducerAction.actionList].delivery },
          actions: [
            ...prev[reducerAction.actionList].actions,
            {
              id: uuidv7(),
              type: "update_member",
              updates: [],
            },
          ],
        },
      };

    case "EnableNotifyEntered":
      return {
        ...prev,
        before_actions: {
          delivery: { ...prev.before_actions.delivery },
          actions: [
            ...prev.before_actions.actions,
            {
              id: uuidv7(),
              type: "notify_entered",
              message: reducerAction.message,
            },
          ],
        },
      };

    case "EnableNotifyCompleted":
      return {
        ...prev,
        after_actions: {
          delivery: { ...prev.after_actions.delivery },
          actions: [
            ...prev.after_actions.actions,
            {
              id: uuidv7(),
              type: "notify_completed",
              message: reducerAction.message,
            },
          ],
        },
      };

    case "EnableNotifyExited":
      return {
        ...prev,
        exit_actions: {
          delivery: { ...prev.exit_actions.delivery },
          actions: [
            ...prev.exit_actions.actions,
            {
              id: uuidv7(),
              type: "notify_exited",
              message: reducerAction.message,
            },
          ],
        },
      };

    case "EnableUpdateCrmFields":
      return {
        ...prev,
        [reducerAction.actionList]: {
          delivery: { ...prev[reducerAction.actionList].delivery },
          actions: [
            ...prev[reducerAction.actionList].actions,
            {
              id: uuidv7(),
              type: "update_crm",
              updates: [],
            },
          ],
        },
      };

    case "UpdateBeforeAction":
      return {
        ...prev,
        before_actions: {
          delivery: { ...prev.before_actions.delivery },
          actions: [
            ...updatedActionList(prev.before_actions, reducerAction.action),
          ],
        },
      };

    case "UpdateAfterAction":
      return {
        ...prev,
        after_actions: {
          delivery: { ...prev.after_actions.delivery },
          actions: [
            ...updatedActionList(prev.after_actions, reducerAction.action),
          ],
        },
      };

    case "UpdateActionOfType":
      return {
        ...prev,
        [reducerAction.actionList]: {
          delivery: { ...prev[reducerAction.actionList].delivery },
          actions: [
            ...updatedActionList(
              prev[reducerAction.actionList],
              reducerAction.action,
            ),
          ],
        },
      };

    case "UpdateAutoExit":
      return {
        ...prev,
        auto_exit_after: reducerAction.autoExitAfter,
      };

    default:
      const exhaustiveCheck: never = reducerAction;
      throw new Error(`Unknown action type ${exhaustiveCheck}`);
  }
}
