import { useCallback, useRef, useState } from "react";
import Modal from "../patterns/Modal";
import TextInput from "../patterns/forms/TextInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { useMutation } from "@apollo/client";
import { CREATE_PERSON } from "../graphql/mutations";
import toast from "react-hot-toast";

interface Props {
  closeModal: () => void;
}

export default function NewPersonModal({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [createPersonMutation] = useMutation(CREATE_PERSON);
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const createPerson = useCallback(
    async (e: React.FormEvent) => {
      setIsSaving(true);
      e.preventDefault();
      try {
        await createPersonMutation({
          variables: {
            name,
            email,
          },
          refetchQueries: ["GetPeople"],
        });
        toast.success("Person created");
        closeModal();
      } catch (error) {
        if (error.message.startsWith("duplicate")) {
          toast.error("There is already a person with this email address");
        }
        if (error.message.includes("valid email")) {
          toast.error("Invalid email address");
        }
        if (error.message.includes("already exists")) {
          toast.error("A person with this email already exists");
        }
      } finally {
        setIsSaving(false);
      }
    },
    [name, email, closeModal, createPersonMutation],
  );
  return (
    <Modal close={closeModal} title="Add a person">
      <form ref={formRef} className="" action="#" onSubmit={createPerson}>
        <TextInput
          label="Name"
          value={name}
          placeholder="Name"
          required={true}
          onChange={setName}
        />

        <TextInput
          label="Email"
          value={email}
          placeholder="Email"
          required={true}
          onChange={setEmail}
        />

        <PrimaryButton
          label="Create person"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
