import { Mention as BaseMention } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import { MentionList } from "./MentionList";
import { SlackUserType } from "../../__generated__/graphql";

export const Mention = BaseMention.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element) => element.getAttribute("data-id"),
        // … and customize the HTML rendering.
        renderHTML: (attributes) => {
          return {
            "data-id": attributes.id,
          };
        },
      },
      label: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element) => element.getAttribute("data-label"),
        // … and customize the HTML rendering.
        renderHTML: (attributes) => {
          return {
            "data-label": attributes.label,
          };
        },
      },
      email: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element) => element.getAttribute("data-email"),
        // … and customize the HTML rendering.
        renderHTML: (attributes) => {
          return {
            "data-email": attributes.email,
          };
        },
      },
      accountOwnerEmailAttribute: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element) =>
          element.getAttribute("data-account-owner-email-attribute"),
        // … and customize the HTML rendering.
        renderHTML: (attributes) => {
          return {
            "data-account-owner-email-attribute":
              attributes.accountOwnerEmailAttribute,
          };
        },
      },
    };
  },
});

export function mentionConfig(people: SlackUserType[]) {
  return {
    HTMLAttributes: {
      class: "tiptap mentionNode",
    },
    suggestion: {
      items: () => people,
      render: () => {
        let reactRenderer: ReactRenderer | null = null;
        return {
          onStart: (props) => {
            reactRenderer = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });
          },

          onUpdate(props) {
            reactRenderer?.updateProps(props);
          },

          onKeyDown(props) {
            if (props.event.key === "Escape") {
              reactRenderer?.destroy();
              return true;
            }

            return (reactRenderer?.ref as any)?.onKeyDown(props);
          },

          onExit() {
            reactRenderer.destroy();
          },
        };
      },
    },
  };
}
