import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BehaviorType } from "../__generated__/graphql";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { formatStats } from "../lib/behaviors";
import { SessionContext } from "../SessionContext";
import EmptyState from "../patterns/EmptyState";
import BehaviorsEmpty from "../patterns/illustrations/BehaviorsEmpty";
import Line from "../patterns/charts/Line";

export default function Objectives({ behavior }: { behavior: BehaviorType }) {
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const behaviorStats = formatStats(
    behavior,
    session.orgCount,
    session.peopleCount,
  );
  const lineChartData = {
    data: [{ id: 1, data: behavior.stats.timeToCompletion.plot }],
    markers: [
      {
        axis: "x",
        legend: "Average",
        lineStyle: {
          stroke: "#8D67FC",
          strokeWidth: 2,
          strokeDasharray: 8,
        } as Partial<React.CSSProperties>,
        textStyle: {
          fontSize: 12,
          fill: "#1E293B",
        } as Partial<React.CSSProperties>,
        value: behavior.stats.timeToCompletion.average,
      },
    ],
  };
  const hasData = behaviorStats.entered.count > 0;
  return (
    <>
      {hasData ? (
        <div className="mb-8">
          <div className="flex w-full overflow-hidden rounded-xl mb-2 shadow-sm">
            <div className="flex flex-col flex-[3] bg-grey-80">
              <div className="pl-6 pt-5 text-md font-bold flex flex-row gap-x-2 items-center">
                <div>{behavior.name}</div>
                <ArrowTopRightOnSquareIcon
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => {
                    navigate(`/behaviors/${behavior.id}?view=overview`);
                  }}
                />
                <span className="text-sm text-body-text-lightest font-normal">
                  Avg Time to Completion:
                </span>
              </div>
              <div className="flex-1 p-6 pt-0 h-full">
                <Line
                  data={lineChartData.data}
                  type={"linear"}
                  valueSuffix={"day"}
                  markers={lineChartData.markers}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 bg-grey-50 py-2">
              <div className="h-full border-b-1 border-grey-300 border-dashed py-4 mx-4">
                <StatBlock
                  title="Avg Time to Completion:"
                  value={behaviorStats.averageTimeToCompletion}
                  subtext="days"
                />
              </div>
              <div className="h-full border-b-1 border-grey-300 border-dashed py-4 mx-4">
                <StatBlock
                  title="Total Completed:"
                  value={behaviorStats.completed.count.toString()}
                  subtext={`(${behaviorStats.completed.percentage})`}
                />
              </div>
              <div className="h-full py-4 mx-4">
                <StatBlock
                  title="Total Pending:"
                  value={behaviorStats.observing.count.toString()}
                  subtext={`(${behaviorStats.observing.percentage})`}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-grey-50 p-6 mb-6 rounded-lg">
          <div className="text-md font-bold mb-4">{behavior.name}</div>
          <EmptyState
            title="No Data"
            description="No data available for this Lifecycle Stage"
            icon={<BehaviorsEmpty />}
            fullHeight={false}
          ></EmptyState>
        </div>
      )}
    </>
  );
}

function StatBlock({
  title,
  value,
  subtext,
}: {
  title: string;
  value: string;
  subtext: string;
}) {
  return (
    <div className="text-body-text-lightest">
      <div className="text-xs">{title}</div>
      <div className="text-2xl font-bold my-2 text-body-text">{value}</div>
      <div className="text-xs">{subtext}</div>
    </div>
  );
}
