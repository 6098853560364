import React, { useEffect, useRef, useContext } from "react";
import { Flags } from "../__generated__/graphql";
import CampaignMain from "../campaigns";
import BehaviorsMain from "../behaviors";
import ReferralsMain from "../referrals";
import LifecycleStagesMain from "../lifecycle_stages/";
import { SessionContext } from "../SessionContext";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";

export default function ReferralSignals({ scrollTo }: { scrollTo?: string }) {
  const behaviorRef = useRef<HTMLDivElement>(null);
  const campaignsRef = useRef<HTMLDivElement>(null);
  const referralsRef = useRef<HTMLDivElement>(null);
  const lifecycleStagesRef = useRef<HTMLDivElement>(null);
  const sessionContext = useContext(SessionContext);

  const scrollToRef = (ref) => ref.current.scrollIntoView();

  const canSeeLifecycleStagesLink = useIsFeatureEnabled(Flags.LifecycleUi);

  // If a customer is a referrals customer they get a separate left nav item for referrals, so we don't show the referrals section here
  const canSeeReferralsLink =
    !sessionContext?.session?.settings.isReferralsCustomer &&
    sessionContext?.session?.settings.canAccessReferrals;

  useEffect(() => {
    const refs = {
      behaviors: behaviorRef,
      campaigns: campaignsRef,
      referrals: referralsRef,
      lifecycleStages: lifecycleStagesRef,
    };

    if (scrollTo) {
      scrollToRef(refs[scrollTo]);
    }
  }, [scrollTo]);

  return (
    <>
      <div ref={lifecycleStagesRef} className="scroll-mt-8">
        {canSeeLifecycleStagesLink && <LifecycleStagesMain />}
      </div>
      <div ref={behaviorRef} className="scroll-mt-8">
        <BehaviorsMain />
      </div>
      <div ref={campaignsRef} className="scroll-mt-8">
        <CampaignMain />
      </div>
      <div ref={referralsRef} className="scroll-mt-8">
        {canSeeReferralsLink && <ReferralsMain />}
      </div>
    </>
  );
}
