import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { MachineCampaignType } from "../../__generated__/graphql";
import Modal from "../../patterns/Modal";
import CampaignButton from "../../patterns/atoms/CampaignButton";
import SecondaryButton from "../../patterns/atoms/SecondaryButton";
import {
  UPDATE_CAMPAIGN_DEFINITION,
  SET_CAMPAIGN_LIVE,
} from "../../graphql/mutations";
import { CampaignDefinition } from "../../campaign_form/types";
import toast from "react-hot-toast";
import { titleCase } from "../../lib/string";
import { SetCampaignLive } from "../../patterns/illustrations/SetCampaignLiveIllustration";
import BehaviorButton from "../../patterns/atoms/BehaviorButton";

interface Props {
  campaign: MachineCampaignType;
  campaignDefinition: CampaignDefinition;
  close: () => void;
}

export default function SetLiveModal({
  campaign,
  campaignDefinition,
  close,
}: Props) {
  const [updateCampaignMutation] = useMutation(UPDATE_CAMPAIGN_DEFINITION);
  const [setLiveMutation] = useMutation(SET_CAMPAIGN_LIVE);
  const entityName = campaign.isBehavior ? "behavior" : "campaign";
  const saveCampaign = useCallback(() => {
    return updateCampaignMutation({
      variables: {
        id: campaign.id,
        definition: JSON.stringify(campaignDefinition),
      },
    });
  }, [campaign, campaignDefinition, updateCampaignMutation]);

  const isDirty =
    JSON.stringify(campaignDefinition) !==
    JSON.stringify(JSON.parse(campaign?.definition || "[]"));

  const setLiveClickHandler = useCallback(async () => {
    try {
      if (isDirty) {
        const saveResponse = await saveCampaign();
        if (!saveResponse.data.updateCampaignDefinition.ok) {
          toast.error(`Failed to save ${entityName}`);
          return;
        }
      }
      const response = await setLiveMutation({
        variables: { id: campaign.id },
      });

      if (response.data.setCampaignLive.ok) {
        toast.success(`${titleCase(entityName)} set live`);
      } else {
        toast.error(() => (
          <p>
            {`Could not set ${entityName} live:`}
            <br />
            {response.data.setCampaignLive.campaign.validationErrors.map(
              (e) => (
                <span>
                  {e}
                  <br />
                </span>
              ),
            )}
          </p>
        ));
      }
    } catch (e) {
      toast.error(e.message);
    }
    close();
  }, [isDirty, saveCampaign, setLiveMutation, campaign, close, entityName]);

  return (
    <Modal
      title={`Are you sure you want to set this ${entityName} live?`}
      close={close}
    >
      <div className="align-middle justify-center items-center w-full h-32 pb-2">
        <SetCampaignLive />
      </div>
      <div className="text-center text-xs mt-2">
        <p>
          {`You won't be able to edit the target event or incentives after you put
          the ${entityName} live.`}
        </p>
      </div>
      <div className="flex justify-center mt-6 gap-2">
        <SecondaryButton label="Cancel" onClick={close} />
        {entityName === "campaign" ? (
          <CampaignButton
            label={`Set ${entityName} Live`}
            onClick={setLiveClickHandler}
          />
        ) : (
          <BehaviorButton
            label={`Set ${entityName} Live`}
            onClick={setLiveClickHandler}
          />
        )}
      </div>
    </Modal>
  );
}
