import { useState, useCallback, Dispatch } from "react";
import { CohortUpdate } from "./reducer";
import { CohortType } from "../../__generated__/graphql";
import Drawer from "../../patterns/Drawer";
import AudienceBuilderSection from "./builder_sections/AudienceBuilderSection";
import AudienceDrawer from "./drawer/AudienceDrawer";
import CompletionCriteriaBuilderSection from "./builder_sections/CompletionCriteriaBuilderSection";
import CompletionCriteriaDrawer from "./drawer/CompletionCriteriaDrawer";

interface Props {
  dispatch: Dispatch<CohortUpdate>;
  cohort: CohortType;
}

type DrawerComponent = typeof AudienceDrawer | typeof CompletionCriteriaDrawer;

export default function CohortForm({ dispatch, cohort }: Props) {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [DrawerComponent, setDrawerComponent] = useState<{
    Component: DrawerComponent;
  }>();
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  return (
    <>
      {drawerIsVisible && (
        <Drawer close={closeDrawer}>
          <DrawerComponent.Component
            dispatch={dispatch}
            cohort={cohort}
            close={closeDrawer}
          />
        </Drawer>
      )}
      <div className="flex grow flex-col mx-8 mb-32 gap-y-6">
        <div>
          <div className="bg-white rounded-lg">
            <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
              <AudienceBuilderSection
                cohort={cohort}
                openDrawer={() => {
                  setDrawerIsVisible(true);
                  setDrawerComponent({
                    Component: AudienceDrawer,
                  });
                }}
              />
            </div>
            <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
              <CompletionCriteriaBuilderSection
                cohort={cohort}
                openDrawer={() => {
                  setDrawerIsVisible(true);
                  setDrawerComponent({
                    Component: CompletionCriteriaDrawer,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
