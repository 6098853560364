import React from "react";

export default function PavlovLogoBlack() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1548 386"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M348 177.165C348.003 178.599 347.239 179.924 345.997 180.638L339.329 184.475C337.418 185.574 334.978 184.915 333.879 183.001C332.781 181.088 333.44 178.645 335.351 177.545L340.012 174.863L340.001 169.481C339.997 167.274 341.78 165.482 343.985 165.477C346.19 165.473 347.98 167.259 347.985 169.466L348 177.165Z"
        fill="#1F2124"
      />
      <path
        d="M347.939 146.367C347.943 148.574 346.16 150.366 343.955 150.371C341.751 150.375 339.96 148.589 339.955 146.382L339.925 130.983C339.92 128.776 341.704 126.983 343.909 126.979C346.113 126.974 347.904 128.76 347.908 130.967L347.939 146.367Z"
        fill="#1F2124"
      />
      <path
        d="M315.347 189.055C317.258 187.955 319.698 188.615 320.797 190.528C321.895 192.442 321.236 194.885 319.325 195.985L315.039 198.45L307.046 193.831L315.347 189.055Z"
        fill="#1F2124"
      />
      <path
        d="M347.863 107.868C347.867 110.075 346.083 111.868 343.879 111.872C341.674 111.877 339.883 110.091 339.879 107.884L339.849 92.4845C339.844 90.2774 341.628 88.4847 343.832 88.4803C346.037 88.4759 347.828 90.2616 347.832 92.4687L347.863 107.868Z"
        fill="#1F2124"
      />
      <path
        d="M347.786 69.3696C347.791 71.5767 346.007 73.3694 343.802 73.3738C341.598 73.3782 339.807 71.5925 339.803 69.3854L339.801 68.592L339.123 68.9842C337.213 70.0878 334.772 69.433 333.67 67.5217C332.567 65.6103 333.221 63.1662 335.131 62.0626L335.796 61.678L335.13 61.2933C333.221 60.1897 332.567 57.7456 333.669 55.8342C334.772 53.9228 337.213 53.2679 339.122 54.3715L345.775 58.2169C347.008 58.9294 347.768 60.245 347.771 61.6699L347.786 69.3696Z"
        fill="#1F2124"
      />
      <path
        d="M315.173 73.5994C317.082 72.4958 319.523 73.1506 320.626 75.0619C321.728 76.9733 321.074 79.4174 319.165 80.521L305.86 88.2122C303.95 89.3159 301.509 88.6611 300.407 86.7497C299.304 84.8384 299.959 82.3942 301.868 81.2906L315.173 73.5994Z"
        fill="#1F2124"
      />
      <path
        d="M319.164 42.8352C321.073 43.9387 321.727 46.3828 320.625 48.2942C319.522 50.2056 317.081 50.8605 315.172 49.757L301.866 42.0661C299.957 40.9625 299.303 38.5185 300.405 36.6071C301.507 34.6957 303.949 34.0408 305.858 35.1443L319.164 42.8352Z"
        fill="#1F2124"
      />
      <path
        d="M239.997 155.075L247.981 159.69L247.981 150.125C247.981 147.918 246.194 146.128 243.989 146.128C241.785 146.128 239.998 147.917 239.997 150.125L239.997 155.075Z"
        fill="#1F2124"
      />
      <path
        d="M281.91 92.8274C283.819 91.7238 286.26 92.3786 287.363 94.29C288.465 96.2013 287.811 98.6454 285.902 99.7491L272.597 107.44C270.688 108.544 268.246 107.889 267.144 105.978C266.042 104.066 266.696 101.622 268.605 100.519L281.91 92.8274Z"
        fill="#1F2124"
      />
      <path
        d="M248.647 112.055C250.556 110.952 252.997 111.607 254.1 113.518C255.202 115.429 254.548 117.873 252.639 118.977L247.982 121.669L247.982 127.053C247.982 129.26 246.194 131.049 243.99 131.049C241.785 131.049 239.998 129.26 239.998 127.052L239.999 121.669L235.342 118.977C233.432 117.874 232.778 115.43 233.881 113.518C234.983 111.607 237.424 110.952 239.333 112.056L243.99 114.747L248.647 112.055Z"
        fill="#1F2124"
      />
      <path
        d="M285.899 23.608C287.808 24.7116 288.463 27.1557 287.36 29.067C286.258 30.9784 283.817 31.6333 281.907 30.5298L268.602 22.8389C266.692 21.7354 266.038 19.2913 267.141 17.3799C268.243 15.4685 270.684 14.8136 272.593 15.9171L285.899 23.608Z"
        fill="#1F2124"
      />
      <path
        d="M219.375 100.519C221.284 101.623 221.938 104.067 220.836 105.978C219.733 107.89 217.292 108.545 215.383 107.441L202.077 99.7502C200.168 98.6466 199.514 96.2025 200.616 94.2911C201.718 92.3797 204.16 91.7248 206.069 92.8284L219.375 100.519Z"
        fill="#1F2124"
      />
      <path
        d="M252.635 4.38084C254.544 5.48438 255.198 7.92848 254.096 9.83988C252.994 11.7513 250.552 12.4062 248.643 11.3026L243.986 8.61086L239.329 11.3027C237.42 12.4063 234.979 11.7515 233.877 9.84017C232.774 7.92882 233.428 5.48469 235.337 4.38108L241.99 0.535486C243.225 -0.178466 244.747 -0.178497 245.982 0.535402L252.635 4.38084Z"
        fill="#1F2124"
      />
      <path
        d="M186.11 81.2921C188.02 82.3956 188.674 84.8397 187.571 86.7511C186.469 88.6625 184.028 89.3174 182.119 88.2139L168.813 80.523C166.904 79.4194 166.249 76.9753 167.352 75.0639C168.454 73.1526 170.895 72.4977 172.805 73.6012L186.11 81.2921Z"
        fill="#1F2124"
      />
      <path
        d="M215.38 15.9179C217.289 14.8143 219.73 15.4691 220.833 17.3804C221.935 19.2918 221.281 21.7359 219.372 22.8395L206.066 30.5307C204.157 31.6344 201.716 30.9796 200.614 29.0682C199.511 27.1568 200.165 24.7127 202.075 23.6091L215.38 15.9179Z"
        fill="#1F2124"
      />
      <path
        d="M140.204 97.3939L148.187 102.008L148.188 92.4433C148.188 90.2362 146.401 88.447 144.196 88.4469C141.992 88.4468 140.204 90.2359 140.204 92.443L140.204 97.3939Z"
        fill="#1F2124"
      />
      <path
        d="M182.117 35.1459C184.026 34.0423 186.467 34.6971 187.57 36.6085C188.672 38.5198 188.018 40.9639 186.109 42.0676L172.804 49.7587C170.894 50.8624 168.453 50.2076 167.351 48.2962C166.248 46.3849 166.902 43.9407 168.812 42.8371L182.117 35.1459Z"
        fill="#1F2124"
      />
      <path
        d="M152.846 62.0649C154.755 63.1685 155.409 65.6126 154.307 67.5239C153.205 69.4353 150.763 70.0902 148.854 68.9867L148.189 68.602L148.189 69.3712C148.189 71.5783 146.401 73.3674 144.197 73.3674C141.992 73.3673 140.205 71.578 140.205 69.3709L140.205 61.6802C140.206 60.2526 140.966 58.9334 142.201 58.2195L148.854 54.3739C150.763 53.2703 153.204 53.9251 154.307 55.8365C155.409 57.7478 154.755 60.192 152.846 61.2956L152.18 61.6802L152.846 62.0649Z"
        fill="#1F2124"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M101.789 90.1303C103.024 89.4164 104.546 89.4164 105.781 90.1303L305.367 205.493C306.602 206.207 307.363 207.526 307.363 208.954V324.317C307.363 325.745 306.602 327.064 305.367 327.778L205.574 385.465C204.339 386.178 202.817 386.178 201.582 385.465L1.99587 270.102C0.760819 269.388 0 268.068 0 266.641V151.278C0 149.85 0.760763 148.531 1.99574 147.817L101.789 90.1303ZM103.785 98.2057L11.9749 151.278L203.578 262.026L295.388 208.954L103.785 98.2057ZM299.38 215.876L207.57 268.948V375.082L299.38 322.01V215.876ZM199.586 375.082L7.98346 264.333V158.199L199.586 268.948V375.082Z"
        fill="#1F2124"
      />
      <path
        d="M577.928 183.755H611.443C633.301 183.755 648.24 171.065 648.24 151.115C648.24 131.166 633.31 118.121 611.443 118.121H577.928V183.764V183.755ZM617.271 233.082H577.564V325.561H520V68.4291H617.28C669.753 68.4291 705.814 102.89 705.814 150.751C705.814 198.612 669.753 233.073 617.28 233.073H617.271V233.082Z"
        fill="#1F2124"
      />
      <path
        d="M753.27 235.26C753.27 264.993 771.846 283.139 794.805 283.139C817.763 283.139 835.975 265.731 835.975 235.26C835.975 204.789 817.4 187.754 794.805 187.754C772.21 187.754 753.27 204.069 753.27 235.26ZM836.348 304.164V302.351C828.329 319.759 810.117 330.636 787.522 330.636C734.322 330.636 697.534 290.381 697.534 234.895C697.534 179.41 732.139 139.875 785.703 139.875C818.136 139.875 832.339 157.638 835.985 165.992V145.313H889.549V293.278C889.549 309.957 890.64 321.562 891.003 325.552H837.803C837.076 321.562 836.348 311.77 836.348 304.164Z"
        fill="#1F2124"
      />
      <path
        d="M1095.35 145.313L1026.5 325.552H970.761L896.43 145.313H956.54L998.438 257.378L1037.05 145.313H1095.35Z"
        fill="#1F2124"
      />
      <path d="M1160.73 325.571H1105.36V63H1160.73V325.571Z" fill="#1F2124" />
      <path
        d="M1267.16 280.224C1288.29 280.224 1307.97 265.722 1307.97 235.26C1307.97 204.798 1288.29 190.651 1267.16 190.651C1246.03 190.651 1226.36 204.798 1226.36 235.26C1226.36 265.722 1246.4 280.224 1267.16 280.224ZM1267.16 139.875C1321.45 139.875 1363.35 179.41 1363.35 235.26C1363.35 291.11 1321.45 331 1267.16 331C1212.87 331 1170.97 291.11 1170.97 235.26C1170.97 179.41 1213.23 139.875 1267.16 139.875Z"
        fill="#1F2124"
      />
      <path
        d="M1548 145.313L1479.14 325.552H1423.41L1349.08 145.313H1409.19L1451.08 257.378L1489.7 145.313H1548Z"
        fill="#1F2124"
      />
    </svg>
  );
}
