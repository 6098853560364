import React from "react";
import {
  CheckIcon,
  XMarkIcon,
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
  ClipboardIcon,
  TrashIcon,
  PencilIcon,
  Cog6ToothIcon,
  BookmarkIcon,
} from "@heroicons/react/24/outline";
import { cx } from "../../lib/cx";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/solid";

interface Props {
  onClick: () => void;
  icon: keyof typeof iconMap;
  variant?: "plain" | "destructive" | "confirm";
  isDisabled?: boolean;
}

export const iconMap = {
  check: CheckIcon,
  xMark: XMarkIcon,
  pencil: PencilIcon,
  plus: PlusIcon,
  eye: EyeIcon,
  eyeSlash: EyeSlashIcon,
  clipboard: ClipboardIcon,
  trash: TrashIcon,
  cog: Cog6ToothIcon,
  bookmark: BookmarkIcon,
  ellipsis: EllipsisHorizontalCircleIcon,
};

export default function IconButton({
  icon,
  onClick,
  variant = "plain",
  isDisabled = false,
}: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      style={{ pointerEvents: isDisabled ? "none" : "auto" }}
      className={cx(
        "ml-2 bg-slate-10 w-8 h-8 rounded-full animate hover:bg-blue-10 hover:text-active-blue",
        variant === "confirm" && "hover:bg-green-10 hover:text-green",
        variant === "destructive" && "hover:bg-red-10 hover:text-red",
      )}
    >
      {React.createElement(iconMap[icon], {
        className: `inline-block ${icon === "pencil" ? "w-4" : "w-5"}`,
      })}
    </button>
  );
}
