import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import PageHeader from "../patterns/PageHeader";
import SkeletonScreen from "../patterns/Skeleton";
import { GET_REFERRALS } from "../graphql/queries";
import FullScreenError from "../patterns/Errors";
import SignalMonitor from "../signal_monitors";

export default function ReferralSignals() {
  const {
    data: referralsData,
    loading: referralsLoading,
    error: referralsError,
  } = useQuery(GET_REFERRALS);
  const navigate = useNavigate();

  if (referralsLoading) {
    return <SkeletonScreen />;
  }

  if (referralsError) {
    return <FullScreenError />;
  }

  const referral = referralsData.allReferrals[0];

  return (
    <div>
      <>
        <PageHeader
          header="Signals"
          subhead={``}
          buttons={[
            {
              type: "icon",
              label: "",
              icon: "cog",
              action: () => navigate(`/referrals/${referral.id}`),
            },
          ]}
        />
        <SignalMonitor monitorName="referrals" />
      </>
    </div>
  );
}
