import FilterBuilder from "../../filter_builder";
import PageHeader from "../../patterns/PageHeader";
import Card from "../../patterns/Card";
import AudienceTargeting from "./AudienceTargeting";
import { DrawerComponentProps } from "..";
import useFilteredAudience from "../../hooks/useFilteredAudience";
import {
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../../__generated__/graphql";

export default function Audience({
  dispatch,
  campaignId,
  campaignConfig,
  targetMemberType,
  campaignStatus,
  entityName,
}: DrawerComponentProps) {
  const isOrgCampaign = targetMemberType === TargetMemberTypeChoices.Org;
  const isEditable = campaignStatus !== MachineStatusChoices.Complete;

  const { audience, loading: audienceLoading } = useFilteredAudience(
    campaignConfig.entry_criteria.audience.filter,
    targetMemberType,
  );

  const subhead = isOrgCampaign ? "Which organizations" : "Who";

  return (
    <div>
      <PageHeader
        header="Audience Targeting"
        subhead={`${subhead} should be entered into this ${entityName}`}
      />
      <div className="px-8">
        <Card>
          <FilterBuilder
            readonly={!isEditable}
            filterDoc={campaignConfig.entry_criteria.audience.filter}
            showOrgAttrs={true}
            showPersonAttrs={!isOrgCampaign}
            showEvents={true}
            onChange={(d) => {
              dispatch({
                type: "UpdateAudience",
                data: d,
              });
            }}
          />
          <div className="mt-2">
            <AudienceTargeting
              filter={campaignConfig.entry_criteria.audience.filter}
              audience={audience}
              loading={audienceLoading}
              objTargetsOrgs={isOrgCampaign}
              entityName={entityName}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
