import { cx } from "../lib/cx";

interface Tab {
  label: string;
  action: () => void;
  isActive: boolean;
}

interface Props {
  tabs: Tab[];
  initialTab?: string;
}

export default function TabNavigation({ tabs, initialTab }: Props) {
  return (
    <div className="flex rounded-full bg-grey-80 p-1 py-1.5 text-xs mx-8 mb-6">
      {tabs.map((t) => (
        <div
          key={t.label}
          className={cx(
            "rounded-full px-4 py-2 ml-1",
            t.isActive
              ? "bg-white font-bold"
              : "animate cursor-pointer hover:bg-grey-100 active:bg-grey-200",
          )}
          onClick={() => {
            t.action();
          }}
        >
          {t.label}
        </div>
      ))}
    </div>
  );
}
