import PageHeader from "../../patterns/PageHeader";
import LabelAndToggle from "../label-and-toggle";
import Card from "../../patterns/Card";
import EmptyState from "../../patterns/EmptyState";
import NoSender from "../../patterns/illustrations/NoSender";
import { DrawerComponentProps } from "..";
import {
  EmailAction,
  NotifyCompletedAction,
  UpdateMemberAction,
  UpdateCrmAction,
} from "../../types/BackendTypes";
import AttributeUpdater from "../attribute-updater";
import {
  TargetMemberTypeChoices,
  MachineStatusChoices,
  Flags,
} from "../../__generated__/graphql";
import MessageEditor from "../message-editor";
import SlackAction from "./slack_action";
import UpdateCrmActionEditor from "../../actions/UpdateCrmActionEditor";
import useIsFeatureEnabled from "../../hooks/useIsFeatureEnabled";

export default function FollowUp({
  dispatch,
  campaignConfig,
  targetMemberType,
  senders,
  campaignStatus,
}: DrawerComponentProps) {
  const canUseUpdateCrm = useIsFeatureEnabled(Flags.UpdateCrmAction);
  const actionList = campaignConfig.after_actions;
  const message = actionList.actions.find(
    (a) => a.type === "email",
  ) as EmailAction;
  const hasSenders = senders.length > 0;
  const updateMemberAction = actionList.actions.find(
    (a) => a.type === "update_member",
  ) as UpdateMemberAction;
  const notifyCompletedAction = actionList.actions.find(
    (a) => a.type === "notify_completed",
  ) as NotifyCompletedAction;
  const updateCrmAction = actionList.actions.find(
    (a) => a.type === "update_crm",
  ) as UpdateCrmAction | undefined;
  const isOrgCampaign = targetMemberType === TargetMemberTypeChoices.Org;
  const isEditable = campaignStatus !== MachineStatusChoices.Complete;

  return (
    <div>
      <PageHeader
        header="Follow up actions"
        subhead={`These will run after a ${isOrgCampaign ? "member of the organization" : "customer"} completes this event`}
      />
      <div className="px-8 mb-8">
        <LabelAndToggle
          label="Message customer"
          isOn={!!message}
          disabled={!isEditable}
          onChange={() => {
            !!message
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "email",
                  actionList: "after_actions",
                })
              : dispatch({ type: "EnableAfterMessage" });
          }}
        />

        {!!message && !hasSenders && (
          <Card>
            <div className="p-4">
              <EmptyState
                title="You haven't added a sender yet"
                icon={<NoSender />}
                description={
                  "You'll need to add a sender before you can send an email. You can do this in Integrations/Messaging."
                }
              />
            </div>
          </Card>
        )}

        {!!message && hasSenders && (
          <MessageEditor
            dispatch={dispatch}
            targetMemberType={targetMemberType}
            message={message}
            senders={senders}
            isEditable={isEditable}
            actionType="UpdateAfterAction"
          />
        )}
      </div>

      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Add, or update, an attribute`}
          isOn={!!updateMemberAction}
          disabled={!isEditable}
          onChange={() => {
            !!updateMemberAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "update_member",
                  actionList: "after_actions",
                })
              : dispatch({
                  type: "EnableUpdateMember",
                  actionList: "after_actions",
                });
          }}
        />
        {updateMemberAction && (
          <Card>
            <AttributeUpdater
              isEditable={isEditable}
              targetMemberType={targetMemberType}
              dispatch={dispatch}
              action={updateMemberAction}
              actionList="after_actions"
            />
          </Card>
        )}
      </div>

      <div className="px-8 mb-8">
        <LabelAndToggle
          label={`Notify the team`}
          isOn={!!notifyCompletedAction}
          disabled={!isEditable}
          onChange={() => {
            !!notifyCompletedAction
              ? dispatch({
                  type: "RemoveActionOfType",
                  actionType: "notify_completed",
                  actionList: "after_actions",
                })
              : dispatch({
                  type: "EnableNotifyCompleted",
                  message: {
                    template: "",
                  },
                });
          }}
        />
        {notifyCompletedAction && (
          <SlackAction
            dispatch={dispatch}
            action={notifyCompletedAction}
            actionSource="after_actions"
          />
        )}
      </div>
      {canUseUpdateCrm && (
        <div className="px-8 mb-8">
          <LabelAndToggle
            label={`Update CRM fields`}
            isOn={!!updateCrmAction}
            disabled={!isEditable}
            onChange={() => {
              !!updateCrmAction
                ? dispatch({
                    type: "RemoveActionOfType",
                    actionType: "update_crm",
                    actionList: "after_actions",
                  })
                : dispatch({
                    type: "EnableUpdateCrmFields",
                    actionList: "after_actions",
                  });
            }}
          />
          {updateCrmAction && (
            <Card>
              <UpdateCrmActionEditor
                dispatch={dispatch}
                action={updateCrmAction}
                actionSource="after_actions"
              />
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
