import React, { useCallback, useContext, useEffect, useState } from "react";
import { isFilterGroup } from "../lib/filters";
import { BinaryFilterCondition, FilterGroup } from "../types/BackendTypes";
import AttributePicker from "./AttributePicker";
import ConditionBlock from "./ConditionBlock";
import {
  AttributeType,
  buildNewCondition,
  configForTarget,
  findTargetForCondition,
  pushConditionDownToNewGroup,
} from "./lib";
import useDocumentClick from "../hooks/useDocumentClick";
import { cx } from "../lib/cx";
import { FilterGroupOperatorSelector } from "./FilterGroupOperatorSelector";
import { AttributeGroup, SessionContext } from "../SessionContext";

interface Props {
  filterGroup: FilterGroup;
  openEditorPath: string;
  setOpenEditorPath: (s: string) => void;
  path: string;
  readonly: boolean;
  availableAttributes: AttributeGroup;
  availableEventNames: string[];
  onChange: (g: FilterGroup) => void;
}

export default function FilterGroupEditor({
  filterGroup,
  availableAttributes,
  availableEventNames,
  openEditorPath,
  setOpenEditorPath,
  path,
  readonly,
  onChange,
}: Props) {
  const session = useContext(SessionContext);
  const [attributePickerOpen, setAttributePickerOpen] = useState<number>(null);
  const [isOperatorSelectorHovered, setIsOperatorSelectorHovered] =
    useState(false);

  useEffect(() => {
    if (openEditorPath !== undefined) {
      setAttributePickerOpen(null);
    }
  }, [openEditorPath]);
  const closeAttributePicker = useCallback(
    () => setAttributePickerOpen(null),
    [setAttributePickerOpen],
  );
  useDocumentClick(closeAttributePicker);
  const isInRootFilterGroup = path.split("-").length === 1;
  return (
    <div
      className={cx(
        "flex flex-wrap gap-y-1",
        filterGroup.conditions.length > 0 && "mb-2",
        !isInRootFilterGroup && "rounded-lg",
      )}
    >
      {filterGroup.conditions.map((filterCondition, index) => {
        const isNotFirst = index > 0;
        const isLast = index === filterGroup.conditions.length - 1;
        const moreThanOneFilter = filterGroup.conditions.length > 1;
        const currentPath = `${path}-${index}`;
        return (
          <React.Fragment key={currentPath}>
            {isNotFirst && moreThanOneFilter && (
              <div className={cx(!isInRootFilterGroup && "bg-grey-100 ")}>
                <FilterGroupOperatorSelector
                  readonly={readonly}
                  currentlySelected={filterGroup.operator.toLowerCase()}
                  isOperatorSelectorHovered={isOperatorSelectorHovered}
                  setIsOperatorSelectorHovered={setIsOperatorSelectorHovered}
                  onClick={(newOp) => {
                    const newConditions = [...filterGroup.conditions];
                    onChange({
                      operator: newOp,
                      conditions: newConditions,
                    });
                  }}
                />
              </div>
            )}
            {isFilterGroup(filterCondition) ? (
              <FilterGroupEditor
                filterGroup={filterCondition}
                openEditorPath={openEditorPath}
                setOpenEditorPath={setOpenEditorPath}
                path={currentPath}
                availableAttributes={availableAttributes}
                availableEventNames={availableEventNames}
                readonly={readonly}
                onChange={(newGroup: FilterGroup) => {
                  const newConditions = [...filterGroup.conditions];
                  const operator = filterGroup.operator;
                  if (newGroup.conditions.length === 0) {
                    newConditions.splice(index);
                  } else if (newGroup.conditions.length === 1) {
                    newConditions[index] = newGroup.conditions[0];
                  } else {
                    newConditions[index] = newGroup;
                  }

                  onChange({
                    operator,
                    conditions: newConditions,
                  });
                }}
              />
            ) : (
              <div className="flex flex-nowrap">
                <ConditionBlock
                  key={index}
                  index={index}
                  editorIsOpen={openEditorPath === currentPath}
                  openEditor={() => !readonly && setOpenEditorPath(currentPath)}
                  closeEditor={() => setOpenEditorPath(undefined)}
                  availableAttributes={availableAttributes}
                  condition={filterCondition}
                  filterConfig={configForTarget(
                    findTargetForCondition(filterCondition, session.attributes),
                  )}
                  readonly={readonly}
                  firstInGroup={index === 0 || isInRootFilterGroup}
                  onChange={(
                    newCondition: BinaryFilterCondition | undefined,
                    index: number,
                  ) => {
                    const newConditions = [...filterGroup.conditions];
                    const operator = filterGroup.operator;
                    if (newCondition) {
                      newConditions[index] = newCondition;
                    } else {
                      newConditions.splice(index, 1);
                    }
                    onChange({
                      operator,
                      conditions: newConditions,
                    });
                  }}
                />
                {(isLast || isInRootFilterGroup) && !readonly && (
                  <AttributePicker
                    attributePickerOpen={attributePickerOpen === index}
                    setAttributePickerOpen={(s) =>
                      setAttributePickerOpen(s ? index : null)
                    }
                    availableAttributes={availableAttributes}
                    availableEventNames={availableEventNames}
                    onPickerOpened={setOpenEditorPath}
                    openerStyle="group"
                    onAttributePicked={(attribute: AttributeType) => {
                      const newCondition = buildNewCondition(attribute);
                      if (isInRootFilterGroup) {
                        const newGroup = pushConditionDownToNewGroup(
                          filterGroup,
                          filterCondition,
                        );
                        (
                          newGroup.conditions[index] as FilterGroup
                        ).conditions.push(newCondition);
                        onChange(newGroup);
                        setOpenEditorPath(`${currentPath}-1`);
                      } else {
                        onChange({
                          operator: filterGroup.operator,
                          conditions: [...filterGroup.conditions, newCondition],
                        });
                        setOpenEditorPath(
                          `${path}-${filterGroup.conditions.length}`,
                        );
                      }
                    }}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
