import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_EVENT_SUMMARIES } from "../graphql/queries";
import Table, { TableProps } from "../patterns/tables/table";
import { formatLong } from "../lib/date";
import PageHeader from "../patterns/PageHeader";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import SkeletonScreen from "../patterns/Skeleton";
import { NavigateFunction } from "react-router-dom";

const PAGE_SIZE = 50;

interface DataProps {
  edges: {
    node: {
      eventName: string;
      totalCount: number;
      lastSeen: string;
    };
  }[];
}

function toTable(data: DataProps, navigate: NavigateFunction): TableProps {
  return {
    header: [
      { label: "Event Name" },
      { label: "Total seen" },
      { label: "Last seen" },
    ],
    rows: data.edges.map((edge) => ({
      id: edge.node.eventName,
      onClick: (e: any, id: string) => {
        navigate(`/events/${edge.node.eventName}`);
      },
      cells: [
        { content: edge.node.eventName },
        { content: edge.node.totalCount },
        { content: formatLong(edge.node.lastSeen) },
      ],
    })),
  };
}

export default function EventSummariesMain() {
  const [isPaginating, setIsPaginating] = useState(false);

  const { data, fetchMore, previousData } = useQuery(GET_EVENT_SUMMARIES);

  const loadNext = useCallback(async () => {
    setIsPaginating(true);
    try {
      await fetchMore({
        variables: {
          cursor: data.allEventSummaries.edges.length - 1,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [data, fetchMore]);

  const nextPageTrigger = useInfiniteScroll(loadNext);
  const navigate = useNavigate();

  if (!previousData && !data) {
    return <SkeletonScreen />;
  }

  const summaries = data?.allEventSummaries || previousData?.allEventSummaries;

  const hasMore = summaries.total > summaries.edges.length;
  const tableData = toTable(summaries as DataProps, navigate);
  return (
    <div className="flex flex-col max-w-8xl mx-auto mt-0 2xl:mt-8">
      <PageHeader header="Events" subhead={`${summaries.total} total`} />
      <Table header={tableData.header} rows={tableData.rows} />
      {hasMore && <div ref={nextPageTrigger}></div>}
      {isPaginating ? (
        <SkeletonScreen />
      ) : (
        <div className="w-full min-h-96"></div>
      )}
    </div>
  );
}
