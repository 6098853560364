import React, {
  useCallback,
  useState,
  useMemo,
  useReducer,
  useEffect,
} from "react";

import TopNav from "../patterns/TopNav";
import DashboardIcon from "../patterns/symbols/DashboardIcon";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_BEHAVIOR } from "../graphql/queries";
import { UPDATE_BEHAVIOR } from "../graphql/mutations";
import CampaignStatusPill from "../campaigns/CampaignStatePill";
import PageHeader, { Button } from "../patterns/PageHeader";
import {
  PersonType,
  OrgType,
  MachineStatusChoices,
  TargetMemberTypeChoices,
  Flags,
} from "../__generated__/graphql";
import TabNavigation from "../patterns/TabNavigation";
import { updateBehaviorReducer } from "./behavior_form/reducer";
import Drawer from "../patterns/Drawer";
import FullScreenError from "../patterns/Errors";
import SkeletonScreen from "../patterns/Skeleton";
import PersonProfile from "../person/PersonProfile";
import OrgProfile from "../org/OrgProfile";
import BehaviorForm from "./behavior_form";
import DeleteModal from "./DeleteModal";
import CompleteModal from "./CompleteModal";
import { isBehaviorDirty } from "./lib";
import { toast } from "react-hot-toast";
import SetLiveModal from "./SetLiveModal";
import BehaviorOverview from "./BehaviorOverview";
import ScheduledSummary from "../scheduled_summary";
import { MemberTable } from "./MemberTable";
import { Tabs } from "../campaign/lib";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";

export const VIEW_PARAM = "view";

export default function Behavior() {
  const params = useParams();
  const canUseScheduledSummaries = useIsFeatureEnabled(Flags.MachineSummaries);
  let [searchParams, setSearchParams] = useSearchParams();
  let view = searchParams.get(VIEW_PARAM) ?? "overview";
  const { data, loading, error } = useQuery(GET_BEHAVIOR, {
    variables: { id: params.id },
    fetchPolicy: "cache-and-network",
  });
  const [updateBehaviorMutation] = useMutation(UPDATE_BEHAVIOR);
  const [behaviorData, dispatch] = useReducer(updateBehaviorReducer, null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [setLiveModalVisible, setSetLiveModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Eligible);
  const [activeMember, setActiveMember] = useState<PersonType | OrgType>();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const views = useMemo(
    () => [
      {
        label: "Overview",
        action: () => setSearchParams(`${VIEW_PARAM}=overview`),
        isActive: view === "overview",
      },
      {
        label: "Settings",
        action: () => setSearchParams(`${VIEW_PARAM}=settings`),
        isActive: view === "settings",
      },
      canUseScheduledSummaries && {
        label: "Scheduled Summary",
        action: () => setSearchParams(`${VIEW_PARAM}=summary`),
        isActive: view === "summary",
      },
    ],
    [setSearchParams, view, canUseScheduledSummaries],
  );

  const updateBehavior = useCallback(() => {
    return updateBehaviorMutation({
      variables: {
        id: params.id,
        name: behaviorData.name,
        status: behaviorData.status,
        audience: JSON.stringify(behaviorData.audience),
        observing: JSON.stringify(behaviorData.observing),
        actionsOnEnter: JSON.stringify(behaviorData.actionsOnEnter),
        actionsOnCompletion: JSON.stringify(behaviorData.actionsOnCompletion),
        actionsWhileObserving: JSON.stringify(
          behaviorData.actionsWhileObserving,
        ),
      },
    });
  }, [behaviorData, params.id, updateBehaviorMutation]);

  const pauseBehavior = useCallback(async () => {
    return updateBehaviorMutation({
      variables: {
        id: behaviorData.id,
        name: behaviorData.name,
        status: "PAUSED",
        audience: JSON.stringify(behaviorData.audience),
        observing: JSON.stringify(behaviorData.observing),
        actionsOnEnter: JSON.stringify(behaviorData.actionsOnEnter),
        actionsOnCompletion: JSON.stringify(behaviorData.actionsOnCompletion),
        actionsWhileObserving: JSON.stringify(
          behaviorData.actionsWhileObserving,
        ),
      },
    });
  }, [behaviorData, updateBehaviorMutation]);

  const updateBehaviorName = (behaviorName: string) => {
    behaviorData.name = behaviorName;
    updateBehavior()
      .then(() => toast.success("Behavior saved"))
      .catch((e) => toast.error(`Failed to save behavior: ${e.message}`));
  };

  useEffect(() => {
    if (data?.behaviorById) {
      dispatch({
        type: "init",
        data: data?.behaviorById,
      });
    }
  }, [data?.behaviorById]);

  const isDirty = useMemo(
    () => isBehaviorDirty(behaviorData, data?.behaviorById),
    [behaviorData, data?.behaviorById],
  );

  const topNavOptions = {
    title: "Dashboard",
    breadcrumb: <DashboardIcon strokeColor={"white"} strokeWidth={1.5} />,
    backLink: "/dashboard",
  };

  if (loading) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <FullScreenError message={error.message} />;
  }

  const behavior = data.behaviorById;
  const allButtons = {
    delete: {
      label: "Delete",
      type: "secondary",
      action: () => {
        setDeleteModalVisible(true);
      },
    } as Button,
    save: {
      label: "Save",
      type: "primary",
      isDisabled: !isDirty,
      action: () => {
        updateBehavior()
          .then(() => toast.success("Behavior saved"))
          .catch((e) => toast.error(`Failed to save behavior: ${e.message}`));
      },
    } as Button,
    setLive: {
      label: `Set Behavior Live`,
      type: "primary",
      action: () => setSetLiveModalVisible(true),
    } as Button,
    pause: {
      label: `Pause Behavior`,
      type: "primary",
      action: () => {
        pauseBehavior()
          .then(() => toast.success("Behavior paused"))
          .catch((e) => toast.error(`Failed to pause behavior: ${e.message}`));
      },
    } as Button,
    complete: {
      label: `Complete Behavior`,
      type: "primary",
      action: () => setCompleteModalVisible(true),
    } as Button,
  };

  let buttons: Button[];
  if (behavior.status === MachineStatusChoices.Draft) {
    buttons = [allButtons.delete, allButtons.save, allButtons.setLive];
  } else if (behavior.status === MachineStatusChoices.Paused) {
    buttons = [allButtons.complete, allButtons.save, allButtons.setLive];
  } else if (behavior.status === MachineStatusChoices.Live) {
    buttons = [allButtons.save, allButtons.pause];
  } else if (behavior.status === MachineStatusChoices.Complete) {
    buttons = [];
  }

  return (
    <div>
      {deleteModalVisible && (
        <DeleteModal
          behavior={behaviorData}
          close={() => setDeleteModalVisible(false)}
        />
      )}
      {setLiveModalVisible && (
        <SetLiveModal
          behavior={behaviorData}
          close={() => setSetLiveModalVisible(false)}
        />
      )}
      {completeModalVisible && (
        <CompleteModal
          behavior={behaviorData}
          close={() => setCompleteModalVisible(false)}
        />
      )}
      <TopNav {...topNavOptions} />
      {drawerIsVisible && activeMember && (
        <Drawer close={closeDrawer}>
          <div className="mt-10">
            {activeMember.__typename === "PersonType" ? (
              <PersonProfile id={activeMember.id} />
            ) : (
              <OrgProfile id={activeMember.id} />
            )}
          </div>
        </Drawer>
      )}
      <div className="flex flex-col max-w-8xl mx-auto mt-0 2xl:mt-8">
        <PageHeader
          header={behavior.name}
          subhead=""
          headerPill={
            <CampaignStatusPill
              status={behavior.status as MachineStatusChoices}
            />
          }
          buttons={buttons}
          slug={`Targeting: ${
            behavior.targetMemberType === TargetMemberTypeChoices.Person
              ? "Individuals"
              : "Organizations"
          }`}
          updateHeader={updateBehaviorName}
        />
        <TabNavigation tabs={views} />
        {view === "overview" && (
          <>
            <BehaviorOverview behavior={behavior} setActiveTab={setActiveTab} />
            <MemberTable
              behavior={behavior}
              activeTab={activeTab}
              showMember={(member) => {
                setActiveMember(member);
                setDrawerIsVisible(true);
              }}
            />
          </>
        )}
        {view === "settings" && !loading && (
          <BehaviorForm dispatch={dispatch} behavior={behaviorData} />
        )}
        {view === "summary" && canUseScheduledSummaries && (
          <ScheduledSummary
            objectId={behavior.id}
            summaryStateChoices={[
              { value: "observing", label: "Entered" },
              { value: "completed", label: "Completed" },
            ]}
            memberType={behavior.targetMemberType}
          />
        )}
      </div>
    </div>
  );
}
