export default function PavlovLogoBlack() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1610 586" fill="none">
      <path
        d="M569.38 278.23H605.32C628.76 278.23 644.78 264.65 644.78 243.3C644.78 221.95 628.77 207.99 605.32 207.99H569.38V278.24V278.23ZM611.57 331.02H568.99V429.99H507.26V154.81H611.58C667.85 154.81 706.52 191.69 706.52 242.91C706.52 294.13 667.85 331.01 611.58 331.01H611.57V331.02Z"
        fill="#1E293B"
      />
      <path
        d="M757.41 333.35C757.41 365.17 777.33 384.59 801.95 384.59C826.57 384.59 846.1 365.96 846.1 333.35C846.1 300.74 826.18 282.51 801.95 282.51C777.72 282.51 757.41 299.97 757.41 333.35ZM846.5 407.09V405.15C837.9 423.78 818.37 435.42 794.14 435.42C737.09 435.42 697.64 392.34 697.64 332.96C697.64 273.58 734.75 231.27 792.19 231.27C826.97 231.27 842.2 250.28 846.11 259.22V237.09H903.55V395.44C903.55 413.29 904.72 425.71 905.11 429.98H848.06C847.28 425.71 846.5 415.23 846.5 407.09Z"
        fill="#1E293B"
      />
      <path
        d="M1124.25 237.09L1050.41 429.98H990.64L910.93 237.09H975.39L1020.32 357.02L1061.73 237.09H1124.25Z"
        fill="#1E293B"
      />
      <path d="M1194.36 430H1134.98V149H1194.36V430Z" fill="#1E293B" />
      <path
        d="M1308.49 381.47C1331.15 381.47 1352.25 365.95 1352.25 333.35C1352.25 300.75 1331.15 285.61 1308.49 285.61C1285.83 285.61 1264.73 300.75 1264.73 333.35C1264.73 365.95 1286.22 381.47 1308.49 381.47ZM1308.49 231.27C1366.71 231.27 1411.64 273.58 1411.64 333.35C1411.64 393.12 1366.71 435.81 1308.49 435.81C1250.27 435.81 1205.34 393.12 1205.34 333.35C1205.34 273.58 1250.66 231.27 1308.49 231.27Z"
        fill="#1E293B"
      />
      <path
        d="M1609.65 237.09L1535.81 429.98H1476.04L1396.33 237.09H1460.79L1505.72 357.02L1547.13 237.09H1609.65Z"
        fill="#1E293B"
      />
      <path
        d="M308 119.463C308 118.033 307.24 116.713 306 116.003C306 116.003 306 116.003 305.99 116.003L106 0.5325C104.76 -0.1775 103.24 -0.1775 102 0.5325L2.02 58.2625C2.02 58.2625 2.01 58.2625 2 58.2625C0.76 58.9725 0 60.2925 0 61.7225V177.192C0 178.622 0.76 179.942 2 180.652L96 234.922L2.01 289.202C2.01 289.202 2.01 289.202 2 289.202C0.76 289.912 0 291.233 0 292.663V523.602C0 525.032 0.76 526.353 2 527.062L102 584.792C102 584.792 102 584.792 102.01 584.792C102.12 584.852 102.23 584.903 102.34 584.953C102.39 584.973 102.43 585.003 102.48 585.023C102.58 585.063 102.68 585.092 102.78 585.122C102.84 585.142 102.91 585.172 102.97 585.182C103.07 585.212 103.17 585.222 103.27 585.242C103.34 585.252 103.4 585.272 103.47 585.282C103.64 585.302 103.81 585.312 103.99 585.312C104.67 585.312 105.36 585.143 105.99 584.773L205.99 527.042C207.23 526.332 207.99 525.003 207.99 523.583V410.422L305.99 353.842C307.23 353.132 307.99 351.803 307.99 350.383V119.442L308 119.463ZM300 232.622L208 285.732V179.502L300 126.382V232.622ZM104 8.6125L296 119.463L204 172.582L12 61.7325L104 8.6125ZM8 68.6625L200 179.512V285.742L8 174.892V68.6625ZM104 239.552L196 292.672L104 345.783L12 292.672L104 239.552ZM100 352.712V458.943L8 405.832V299.602L100 352.712ZM8 521.302V415.072L100 468.182V574.412L8 521.302ZM200 521.302L108 574.412V468.182L200 415.062V521.302ZM300 348.092L108 458.932V352.702L205.97 296.142C205.97 296.142 205.99 296.143 206 296.133L300 241.862V348.092Z"
        fill="#1E293B"
      />
      <path
        d="M11.5388 520.253C11.1888 520.253 10.8588 520.073 10.6688 519.753C10.3888 519.273 10.5588 518.663 11.0388 518.383L16.2288 515.383C16.7088 515.103 17.3188 515.273 17.5988 515.753C17.8788 516.233 17.7088 516.843 17.2288 517.123L12.0388 520.123C11.8788 520.213 11.7088 520.253 11.5388 520.253Z"
        fill="#1E293B"
      />
      <path
        d="M27.4789 511.053C27.1289 511.053 26.7989 510.873 26.6089 510.553C26.3289 510.073 26.4989 509.463 26.9789 509.183L86.0889 475.053C86.5689 474.773 87.1789 474.943 87.4589 475.423C87.7389 475.903 87.5689 476.513 87.0889 476.793L27.9789 510.923C27.8189 511.013 27.6489 511.053 27.4789 511.053Z"
        fill="#1E293B"
      />
      <path
        d="M91.9588 473.823C91.6088 473.823 91.2788 473.643 91.0888 473.323C90.8088 472.843 90.9788 472.233 91.4588 471.953L96.6588 468.953C97.1388 468.673 97.7488 468.843 98.0288 469.323C98.3088 469.803 98.1388 470.413 97.6588 470.693L92.4588 473.693C92.2988 473.783 92.1288 473.823 91.9588 473.823Z"
        fill="#1E293B"
      />
      <path
        d="M117.199 474.203C117.039 474.203 116.869 474.163 116.719 474.073L111.479 471.163C110.999 470.893 110.819 470.283 111.089 469.803C111.359 469.323 111.969 469.143 112.449 469.413L117.689 472.323C118.169 472.593 118.349 473.203 118.079 473.683C117.899 474.013 117.559 474.193 117.199 474.193V474.203Z"
        fill="#1E293B"
      />
      <path
        d="M186.889 512.913C186.729 512.913 186.559 512.873 186.409 512.783L127.439 480.033C126.959 479.763 126.779 479.153 127.049 478.673C127.319 478.193 127.929 478.013 128.409 478.283L187.379 511.033C187.859 511.303 188.039 511.913 187.769 512.393C187.589 512.723 187.249 512.903 186.889 512.903V512.913Z"
        fill="#1E293B"
      />
      <path
        d="M197.499 518.803C197.339 518.803 197.169 518.763 197.019 518.683L191.769 515.773C191.289 515.503 191.109 514.893 191.379 514.413C191.649 513.933 192.259 513.753 192.739 514.023L197.989 516.933C198.469 517.203 198.649 517.813 198.379 518.293C198.199 518.623 197.859 518.813 197.499 518.813V518.803Z"
        fill="#1E293B"
      />
      <path
        d="M10.4388 405.423C10.0888 405.423 9.75882 405.243 9.56882 404.923C9.28882 404.443 9.45882 403.833 9.93882 403.553L15.1388 400.553C15.6188 400.273 16.2288 400.443 16.5088 400.923C16.7888 401.403 16.6188 402.013 16.1388 402.293L10.9388 405.293C10.7788 405.383 10.6088 405.423 10.4388 405.423Z"
        fill="#1E293B"
      />
      <path
        d="M26.5288 396.133C26.1788 396.133 25.8488 395.953 25.6588 395.633C25.3788 395.153 25.5488 394.543 26.0288 394.263L85.9188 359.683C86.3988 359.403 87.0088 359.573 87.2888 360.053C87.5688 360.533 87.3988 361.143 86.9188 361.423L27.0288 396.003C26.8688 396.093 26.6988 396.133 26.5288 396.133Z"
        fill="#1E293B"
      />
      <path
        d="M91.8589 358.413C91.5089 358.413 91.1789 358.233 90.9889 357.913C90.7089 357.433 90.8789 356.823 91.3589 356.543L96.5589 353.543C97.0389 353.263 97.6489 353.433 97.9289 353.913C98.2089 354.393 98.0389 355.003 97.5589 355.283L92.3589 358.283C92.1989 358.373 92.0289 358.413 91.8589 358.413Z"
        fill="#1E293B"
      />
      <path
        d="M215.829 301.333C215.669 301.333 215.499 301.293 215.349 301.213L210.099 298.303C209.619 298.033 209.439 297.423 209.709 296.943C209.979 296.463 210.589 296.283 211.069 296.553L216.319 299.463C216.799 299.733 216.979 300.343 216.709 300.823C216.529 301.153 216.189 301.343 215.829 301.343V301.333Z"
        fill="#1E293B"
      />
      <path
        d="M286.469 340.573C286.309 340.573 286.139 340.533 285.989 340.443L226.219 307.243C225.739 306.973 225.559 306.363 225.829 305.883C226.099 305.403 226.709 305.223 227.189 305.493L286.959 338.693C287.439 338.963 287.619 339.573 287.349 340.053C287.169 340.383 286.829 340.563 286.469 340.563V340.573Z"
        fill="#1E293B"
      />
      <path
        d="M297.149 346.503C296.989 346.503 296.819 346.463 296.669 346.373L291.429 343.463C290.949 343.193 290.769 342.583 291.039 342.103C291.309 341.623 291.919 341.443 292.399 341.713L297.639 344.623C298.119 344.893 298.299 345.503 298.029 345.983C297.849 346.313 297.509 346.493 297.149 346.493V346.503Z"
        fill="#1E293B"
      />
      <path
        d="M11.6188 173.753C11.2988 173.753 10.9888 173.583 10.8188 173.293C10.5688 172.853 10.7188 172.293 11.1588 172.033L15.9488 169.273C16.3888 169.013 16.9488 169.173 17.2088 169.613C17.4588 170.053 17.3088 170.613 16.8688 170.873L12.0788 173.633C11.9388 173.713 11.7788 173.753 11.6188 173.753Z"
        fill="#1E293B"
      />
      <path
        d="M11.6188 173.753C11.2988 173.753 10.9888 173.583 10.8188 173.293C10.5688 172.853 10.7188 172.293 11.1588 172.033L15.9488 169.273C16.3888 169.013 16.9488 169.173 17.2088 169.613C17.4588 170.053 17.3088 170.613 16.8688 170.873L12.0788 173.633C11.9388 173.713 11.7788 173.753 11.6188 173.753Z"
        fill="#1E293B"
      />
      <path
        d="M27.1788 164.763C26.8588 164.763 26.5488 164.593 26.3788 164.303C26.1288 163.863 26.2788 163.303 26.7188 163.043L85.9588 128.843C86.3988 128.583 86.9588 128.743 87.2188 129.183C87.4688 129.623 87.3188 130.183 86.8788 130.443L27.6388 164.643C27.4988 164.723 27.3388 164.763 27.1788 164.763Z"
        fill="#1E293B"
      />
      <path
        d="M27.1788 164.763C26.8588 164.763 26.5488 164.593 26.3788 164.303C26.1288 163.863 26.2788 163.303 26.7188 163.043L85.9588 128.843C86.3988 128.583 86.9588 128.743 87.2188 129.183C87.4688 129.623 87.3188 130.183 86.8788 130.443L27.6388 164.643C27.4988 164.723 27.3388 164.763 27.1788 164.763Z"
        fill="#1E293B"
      />
      <path
        d="M91.7988 127.453C91.4788 127.453 91.1688 127.283 90.9988 126.993C90.7488 126.553 90.8988 125.993 91.3388 125.733L96.1288 122.973C96.5688 122.723 97.1288 122.873 97.3888 123.313C97.6388 123.753 97.4888 124.313 97.0488 124.573L92.2588 127.333C92.1188 127.413 91.9588 127.453 91.7988 127.453Z"
        fill="#1E293B"
      />
      <path
        d="M91.7988 127.453C91.4788 127.453 91.1688 127.283 90.9988 126.993C90.7488 126.553 90.8988 125.993 91.3388 125.733L96.1288 122.973C96.5688 122.723 97.1288 122.873 97.3888 123.313C97.6388 123.753 97.4888 124.313 97.0488 124.573L92.2588 127.333C92.1188 127.413 91.9588 127.453 91.7988 127.453Z"
        fill="#1E293B"
      />
      <path
        d="M215.499 185.683C215.339 185.683 215.169 185.643 215.009 185.553L209.759 182.633C209.279 182.363 209.099 181.753 209.369 181.273C209.639 180.793 210.249 180.613 210.729 180.883L215.979 183.803C216.459 184.073 216.639 184.683 216.369 185.163C216.189 185.493 215.849 185.673 215.489 185.673L215.499 185.683Z"
        fill="#1E293B"
      />
      <path
        d="M215.499 185.683C215.339 185.683 215.169 185.643 215.009 185.553L209.759 182.633C209.279 182.363 209.099 181.753 209.369 181.273C209.639 180.793 210.249 180.613 210.729 180.883L215.979 183.803C216.459 184.073 216.639 184.683 216.369 185.163C216.189 185.493 215.849 185.673 215.489 185.673L215.499 185.683Z"
        fill="#1E293B"
      />
      <path
        d="M287.259 225.543C287.099 225.543 286.929 225.503 286.779 225.413L226.059 191.683C225.579 191.413 225.399 190.803 225.669 190.323C225.939 189.843 226.549 189.663 227.029 189.933L287.749 223.663C288.229 223.933 288.409 224.543 288.139 225.023C287.959 225.353 287.619 225.533 287.259 225.533V225.543Z"
        fill="#1E293B"
      />
      <path
        d="M287.259 225.543C287.099 225.543 286.929 225.503 286.779 225.413L226.059 191.683C225.579 191.413 225.399 190.803 225.669 190.323C225.939 189.843 226.549 189.663 227.029 189.933L287.749 223.663C288.229 223.933 288.409 224.543 288.139 225.023C287.959 225.353 287.619 225.533 287.259 225.533V225.543Z"
        fill="#1E293B"
      />
      <path
        d="M103.999 112.853C103.449 112.853 102.999 112.403 102.999 111.853V105.853C102.999 105.303 103.449 104.853 103.999 104.853C104.549 104.853 104.999 105.303 104.999 105.853V111.853C104.999 112.403 104.549 112.853 103.999 112.853Z"
        fill="#1E293B"
      />
      <path
        d="M298.029 231.523C297.869 231.523 297.699 231.483 297.539 231.393L292.289 228.473C291.809 228.203 291.629 227.593 291.899 227.113C292.169 226.633 292.779 226.453 293.259 226.723L298.509 229.643C298.989 229.913 299.169 230.523 298.899 231.003C298.719 231.333 298.379 231.513 298.019 231.513L298.029 231.523Z"
        fill="#1E293B"
      />
      <path
        d="M298.029 231.523C297.869 231.523 297.699 231.483 297.539 231.393L292.289 228.473C291.809 228.203 291.629 227.593 291.899 227.113C292.169 226.633 292.779 226.453 293.259 226.723L298.509 229.643C298.989 229.913 299.169 230.523 298.899 231.003C298.719 231.333 298.379 231.513 298.019 231.513L298.029 231.523Z"
        fill="#1E293B"
      />
      <path
        d="M103.999 112.853C103.449 112.853 102.999 112.403 102.999 111.853V105.853C102.999 105.303 103.449 104.853 103.999 104.853C104.549 104.853 104.999 105.303 104.999 105.853V111.853C104.999 112.403 104.549 112.853 103.999 112.853Z"
        fill="#1E293B"
      />
      <path
        d="M103.999 94.1827C103.449 94.1827 102.999 93.7327 102.999 93.1827V23.5427C102.999 22.9927 103.449 22.5427 103.999 22.5427C104.549 22.5427 104.999 22.9927 104.999 23.5427V93.1827C104.999 93.7327 104.549 94.1827 103.999 94.1827Z"
        fill="#1E293B"
      />
      <path
        d="M103.999 94.1827C103.449 94.1827 102.999 93.7327 102.999 93.1827V23.5427C102.999 22.9927 103.449 22.5427 103.999 22.5427C104.549 22.5427 104.999 22.9927 104.999 23.5427V93.1827C104.999 93.7327 104.549 94.1827 103.999 94.1827Z"
        fill="#1E293B"
      />
      <path
        d="M103.999 18.2026C103.449 18.2026 102.999 17.7526 102.999 17.2026V11.2026C102.999 10.6526 103.449 10.2026 103.999 10.2026C104.549 10.2026 104.999 10.6526 104.999 11.2026V17.2026C104.999 17.7526 104.549 18.2026 103.999 18.2026Z"
        fill="#1E293B"
      />
    </svg>
  );
}
