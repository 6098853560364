export default function IntegrationsIcon({ strokeColor, strokeWidth = 1 }) {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 16.0053V22.0019L25 25V19.0034L20 16.0053Z" fill="white" />
      <path d="M25 19.0034L30 16.0053V22.0019L25 25" fill="white" />
      <path
        d="M30 15.9986L25 19.0101L20 15.9986L25 13L30 15.9986Z"
        fill="white"
      />
      <path
        d="M25 25L20 22.0019V16.0053L25 19.0034M25 25V19.0034M25 25L30 22.0019V16.0053L25 19.0034M30 15.9986L25 19.0101L20 15.9986L25 13L30 15.9986Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 19.0053L15 25.0019L20 28V22.0034L15 19.0053Z" fill="white" />
      <path d="M20 22.0034L25 19.0053V25.0019L20 28" fill="white" />
      <path
        d="M25 18.9986L20 22.0101L15 18.9986L20 16L25 18.9986Z"
        fill="white"
      />
      <path
        d="M20 28L15 25.0019L15 19.0053L20 22.0034M20 28V22.0034M20 28L25 25.0019V19.0053L20 22.0034M25 18.9986L20 22.0101L15 18.9986L20 16L25 18.9986Z"
        stroke="#1E293B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 2"
      />
      <path d="M15 13.0053L15 19.0019L20 22V16.0034L15 13.0053Z" fill="white" />
      <path d="M20 16.0034L25 13.0053V19.0019L20 22" fill="white" />
      <path
        d="M25 12.9986L20 16.0101L15 12.9986L20 10L25 12.9986Z"
        fill="white"
      />
      <path
        d="M20 22L15 19.0019L15 13.0053L20 16.0034M20 22V16.0034M20 22L25 19.0019V13.0053L20 16.0034M25 12.9986L20 16.0101L15 12.9986L20 10L25 12.9986Z"
        stroke="#1E293B"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 2"
      />
      <path d="M10 22.0053L10 28.0019L15 31V25.0034L10 22.0053Z" fill="white" />
      <path d="M15 25.0034L20 22.0053V28.0019L15 31" fill="white" />
      <path
        d="M20 21.9986L15 25.0101L10 21.9986L15 19L20 21.9986Z"
        fill="white"
      />
      <path
        d="M15 31L10 28.0019L10 22.0053L15 25.0034M15 31V25.0034M15 31L20 28.0019V22.0053L15 25.0034M20 21.9986L15 25.0101L10 21.9986L15 19L20 21.9986Z"
        stroke="#1E293B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 28.0068L15 31.0048M20 28L15 31.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 27.0068L15 30.0048M20 27L15 30.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 26.0068L15 29.0048M20 26L15 29.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 25.0068L15 28.0048M20 25L15 28.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 24.0068L15 27.0048M20 24L15 27.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 23.0068L15 26.0048M20 23L15 26.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 22L25 25.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 21L25 24.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 20L25 23.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 19L25 22.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 18L25 21.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 17L25 20.0116"
        stroke="#1E293B"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
