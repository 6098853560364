import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Filter } from "../types/BackendTypes";
import { emptyFilterDocString } from "../lib/filters";

const FILTER_PARAM_NAME = "f";
const QUERY_PARAM_NAME = "s";

export default function useQueryAndFilter(initialDocString?: string) {
  let [searchParams, setSearchParams] = useSearchParams();
  const docString = initialDocString || emptyFilterDocString;
  const initialFilter = searchParams.get(FILTER_PARAM_NAME) || btoa(docString);
  const [filter, updateFilter] = useState<Filter>(
    JSON.parse(atob(initialFilter)),
  );
  const [query, updateQuery] = useState<string>(
    searchParams.get(QUERY_PARAM_NAME) ?? "",
  );
  useEffect(() => {
    setSearchParams(
      `${FILTER_PARAM_NAME}=${btoa(JSON.stringify(filter))}&${QUERY_PARAM_NAME}=${query}`,
    );
  }, [filter, query, setSearchParams]);
  return { query, updateQuery, filter, updateFilter };
}
