import { GiftIcon } from "@heroicons/react/24/outline";
import {
  IncentiveAttributeType,
  incentiveAttributes,
} from "../filter_builder/lib";

interface Props {
  onAttributePicked: (a: IncentiveAttributeType | string) => void;
}

export default function IncentivePicker({ onAttributePicked }: Props) {
  const giftRedemptionUrlAttribute = incentiveAttributes[0];
  return (
    <button
      title="Add the gift redemption URL"
      className="cursor-pointer rounded p-1.5 hover:bg-grey-200 active:bg-grey-400 animate"
      onClick={(e) => {
        onAttributePicked(giftRedemptionUrlAttribute);
        e.preventDefault();
      }}
    >
      <GiftIcon className="w-4 h-4" />
    </button>
  );
}
