import { useParams } from "react-router-dom";
import CampaignsIcon from "../patterns/symbols/CampaignsIcon";
import TopNav from "../patterns/TopNav";
import OrgProfile from "./OrgProfile";

export default function OrgMain() {
  const params = useParams();

  const topNavOptions = {
    title: "Organizations",
    breadcrumb: <CampaignsIcon strokeColor={"white"} strokeWidth={1.5} />,
    backLink: "/audience/organizations",
    buttonAction: () => {},
  };

  return (
    <>
      <TopNav {...topNavOptions} />
      <div className="max-w-8xl mx-auto mt-0 2xl:mt-8">
        <OrgProfile id={params.id} />
      </div>
    </>
  );
}
