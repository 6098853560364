import { MouseEventHandler } from "react";
import { cx } from "../lib/cx";
import Bold from "./icons/bold";
import Copy from "./icons/copy";
import Italic from "./icons/italic";
import Link from "./icons/link";

const Icons = {
  bold: Bold,
  italic: Italic,
  link: Link,
  copy: Copy,
};

interface Props {
  icon: keyof typeof Icons;
  isActive: boolean;
  isDisabled?: boolean;
  onClick: MouseEventHandler;
}

export default function ToolbarButton({
  icon,
  isDisabled,
  isActive,
  onClick,
}: Props) {
  const Icon = Icons[icon];
  return (
    <button
      type="button"
      className={cx(
        "cursor-pointer rounded p-2 hover:bg-grey-200 animate",
        isActive && !isDisabled && "bg-grey-100",
        isDisabled && "pointer-events-none opacity-60",
      )}
      disabled={isDisabled}
      onClick={onClick}
    >
      <Icon />
    </button>
  );
}
