import {
  BinaryFilterCondition,
  FilterGroup,
  TimeDelta,
  UnaryFilterCondition,
} from "../types/BackendTypes";

export type FilterElement =
  | UnaryFilterCondition
  | BinaryFilterCondition
  | FilterGroup;

export function isBinaryFilterCondition(
  filterCondition: FilterElement,
): filterCondition is BinaryFilterCondition {
  return (filterCondition as BinaryFilterCondition).value !== undefined;
}

export function isFilterGroup(f: FilterElement): f is FilterGroup {
  return (f as FilterGroup).conditions !== undefined;
}

export function filterConditionPath(filterCondition: FilterElement) {
  return isBinaryFilterCondition(filterCondition)
    ? filterCondition.value
    : null;
}

export function isTimeDelta(v): v is TimeDelta {
  return v?.type === "time_delta";
}

export const emptyFilterDoc = {
  version: "1" as "1",
  filters: {
    operator: "AND" as "AND",
    conditions: [],
  },
};

export const emptyFilterDocString = JSON.stringify(emptyFilterDoc);

export function isEmptyFilterDoc(a: any) {
  return JSON.stringify(a) === JSON.stringify(emptyFilterDocString);
}
