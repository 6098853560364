import { IncentiveType } from "../__generated__/graphql";

import Drawer from "../patterns/Drawer";
import PageHeader from "../patterns/PageHeader";
import Card from "../patterns/Card";

interface Props {
  close: () => void;
  activeIncentive: IncentiveType;
}

export default function IncentiveDetail({ close, activeIncentive }: Props) {
  return (
    <Drawer close={close}>
      <PageHeader header="Incentive details" subhead="" buttons={[]} />
      <div className="px-8">
        <Card>
          <div className="flex pb-2">
            <div className="text-xs leading-5 text-body-text-lighter w-2/5">
              Name:
            </div>
            <div className="text-sm font-semibold tracking-tight leading-5 text-body-text w-3/5">
              {activeIncentive.name}
            </div>
            <div className="hidden">{activeIncentive.config}</div>
          </div>
          <div className="flex">
            <div className="text-xs leading-5  text-body-text-lighter w-2/5">
              Type:
            </div>
            <div className="text-sm font-semibold tracking-tight leading-5 text-body-text w-3/5">
              {activeIncentive.type}
            </div>
            <div className="hidden">{activeIncentive.config}</div>
          </div>
        </Card>
      </div>
    </Drawer>
  );
}
