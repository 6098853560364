import { createContext } from "react";
import {
  AttributeTarget,
  DashboardConfigType,
  FeatureFlagType,
  SessionType,
  UserProfileType,
} from "./__generated__/graphql";
import { AttributeType } from "./filter_builder/lib";

export type AttributeGroup = {
  [K in AttributeTarget]: AttributeType[];
};

interface Session {
  session?: SessionType;
  userProfile?: UserProfileType;
  attributes?: AttributeGroup;
  eventNames?: string[];
  featureFlags?: FeatureFlagType[];
  peopleCount?: number;
  orgCount?: number;
  dashboardConfigs?: DashboardConfigType[];
}

export const SessionContext = createContext<Session>({});
