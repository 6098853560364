import { ValueComponentProps } from "../lib";

export default function NumberInput({
  value,
  disabled = false,
  onChange,
}: ValueComponentProps) {
  return (
    <input
      type="number"
      disabled={disabled}
      className="mb-2 border border-rule-color bg-grey-50 text-xs px-4 py-3 rounded-md focus:ring-2 focus:ring-blue-300 focus:ring-inset min-w-48 max-w-96"
      value={value ?? ""}
      onChange={(e) => {
        const value = e.target.value;
        if (value === "") {
          onChange(null);
        } else {
          onChange(parseInt(value));
        }
      }}
      autoFocus
    />
  );
}
