import React from "react";
import { TargetMemberTypeChoices } from "../../__generated__/graphql";
import SettingsBlock from "../SettingsBlock";
import BuilderSectionHeader from "./builder-section-header";
import { pluralize } from "../../lib/string";
import { DAY_IN_SECONDS } from "../../lib/date";
import SectionProps from "./section_props";

export default function AutoExitSection({
  data,
  campaignDefinition,
  openDrawer,
}: SectionProps) {
  const targetMemberType = data.campaignById.targetMemberType;
  const isOrgCampaign = targetMemberType === TargetMemberTypeChoices.Org;
  const autoExitAfter = campaignDefinition.auto_exit_after
    ? campaignDefinition.auto_exit_after
    : null;

  return (
    <div onClick={() => openDrawer()}>
      <BuilderSectionHeader
        label="Auto Exit"
        description={`Limit the time an ${isOrgCampaign ? "organization" : "individual"} has to complete the event`}
        isValid={!!autoExitAfter}
        onClick={() => {}}
      />
      <div className="flex gap-2 flex-wrap ml-7">
        {autoExitAfter && (
          <SettingsBlock
            title={`Auto exit after ${pluralize(autoExitAfter / DAY_IN_SECONDS, "day")}`}
            section="auto-exit"
            onClick={() => {}}
          />
        )}
      </div>
    </div>
  );
}
