import { useCallback, useMemo, useRef, useState } from "react";
import Modal from "../patterns/Modal";
import SelectInput from "../patterns/forms/SelectInput";
import { EventsTypeChoices } from "../__generated__/graphql";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import { useMutation } from "@apollo/client";
import { CREATE_EVENTS_INTEGRATION } from "../graphql/mutations";
import toast from "react-hot-toast";
import { MixpanelAuth, MixpanelProps } from "../types/BackendTypes";
import { SegmentAuth, SegmentProps } from "../types/BackendTypes";
import MixpanelConfig from "./config/MixpanelConfig";
import SegmentConfig from "./config/SegmentConfig";

interface Props {
  closeModal: () => void;
}

export default function NewIntegrationModal({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [createEventsIntegration] = useMutation(CREATE_EVENTS_INTEGRATION);
  const [props, setProps] = useState<MixpanelProps | SegmentProps>();
  const [auth, setAuth] = useState<MixpanelAuth | SegmentAuth>();
  const [type, setType] = useState<EventsTypeChoices>();
  const [isSaving, setIsSaving] = useState(false);
  const options = useMemo(
    () =>
      Object.keys(EventsTypeChoices).map((k) => {
        return {
          label: k,
          value: EventsTypeChoices[k],
        };
      }),
    [],
  );

  const createIntegration = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setIsSaving(true);

      try {
        const response = await createEventsIntegration({
          variables: {
            type,
            props: JSON.stringify(props),
            auth: JSON.stringify(auth),
          },
          refetchQueries: ["GetEventsIntegrations", "GetSession"],
        });
        const redirect = response.data.createEventsIntegration.redirect;
        if (redirect) {
          if (process.env.NODE_ENV === "production") {
            window.location.replace(`/${redirect}`);
          } else {
            window.location.replace(`//localhost:8000/${redirect}`);
          }
        } else {
          toast.success("Integration created");
          closeModal();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSaving(false);
      }
    },
    [auth, type, props, closeModal, createEventsIntegration],
  );
  return (
    <Modal close={closeModal} title="Add an email integration">
      <form ref={formRef} className="" action="#" onSubmit={createIntegration}>
        <SelectInput
          label="Choose the integration you would like to use"
          placeholder="Choose an integration"
          options={options}
          required={true}
          onChange={(s) => {
            setType(s as EventsTypeChoices);
          }}
        />

        {type === EventsTypeChoices.Mixpanel && (
          <MixpanelConfig
            props={props as MixpanelProps}
            setProps={setProps}
            auth={auth as MixpanelAuth}
            setAuth={setAuth}
          />
        )}

        {type === EventsTypeChoices.Segment && (
          <SegmentConfig
            props={props as SegmentProps}
            setProps={setProps}
            auth={auth as SegmentAuth}
            setAuth={setAuth}
          />
        )}

        <PrimaryButton
          label="Create integration"
          isDisabled={isSaving}
          onClick={() => formRef.current.requestSubmit()}
          fullWidth={true}
        />
      </form>
    </Modal>
  );
}
