import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";

import * as Sentry from "@sentry/react";
import { client } from "./lib/apollo";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://aad111e65a754ef0c1f129e42bf3028a@o4505335836246016.ingest.sentry.io/4506241811415040",
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
);
