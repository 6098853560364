import {
  BehaviorType,
  TargetMemberTypeChoices,
} from "../../../__generated__/graphql";
import BuilderSectionHeader from "../../../campaign_form/builder_sections/builder-section-header";
import FilterBuilder from "../../../filter_builder";
import useFilteredAudience from "../../../hooks/useFilteredAudience";
import AudienceTargeting from "../../../campaign_form/drawer/AudienceTargeting";
import { ContextType, contextMap } from "../../lib";

interface Props {
  behavior: BehaviorType;
  openDrawer: () => void;
  context?: ContextType;
}

export default function AudienceBuilderSection({
  behavior,
  openDrawer,
  context = "behavior",
}: Props) {
  const audienceCriteria = behavior.audience;
  const hasAudience =
    audienceCriteria.criteria?.[0]?.filter?.filters?.conditions.length > 0;
  const targetingOrgs =
    behavior.targetMemberType === TargetMemberTypeChoices.Org;

  const { audience, loading: audienceLoading } = useFilteredAudience(
    audienceCriteria.criteria[0].filter,
    behavior.targetMemberType,
  );

  const contextName = contextMap[context as ContextType] ?? "Behavior";

  return (
    <div onClick={openDrawer}>
      <BuilderSectionHeader
        label="Audience Targeting"
        description={`${targetingOrgs ? "Which organizations are" : "Who is"} being observed for this ${contextName}?`}
        isValid={hasAudience}
      />
      <div
        className={`${hasAudience ? "flex gap-2 flex-wrap ml-7 cursor-pointer  " : ""}`}
      >
        <FilterBuilder
          filterDoc={audienceCriteria.criteria[0].filter}
          readonly={true}
          onChange={() => {}}
        />
      </div>
      <div className="ml-6 max-w-md">
        <AudienceTargeting
          filter={audienceCriteria.criteria[0].filter}
          audience={audience}
          loading={audienceLoading}
          objTargetsOrgs={targetingOrgs}
          entityName="behavior"
        />
      </div>
    </div>
  );
}
