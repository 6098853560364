import React, {
  Dispatch,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react";
import { UpdateMemberAction } from "../types/BackendTypes";
import { CampaignDefinitionEditAction } from "./reducer";
import AttributePicker from "../filter_builder/AttributePicker";
import { configForTarget, findAttribute } from "../filter_builder/lib";
import { cx } from "../lib/cx";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { SessionContext } from "../SessionContext";
import useDocumentClick from "../hooks/useDocumentClick";
import {
  AttributeTarget,
  TargetMemberTypeChoices,
  OrgAttributeType,
  PersonAttributeType,
} from "../__generated__/graphql";

interface Props {
  action: UpdateMemberAction;
  targetMemberType: TargetMemberTypeChoices;
  isEditable: boolean;
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  actionList: "after_actions" | "exit_actions";
}

export default function AttributeUpdater({
  action,
  isEditable,
  targetMemberType,
  dispatch,
  actionList,
}: Props) {
  const session = useContext(SessionContext);
  const [attributePickerOpen, setAttributePickerOpen] = useState(false);
  const availableAttributes = useMemo(() => {
    const attrs =
      targetMemberType === TargetMemberTypeChoices.Org
        ? session.attributes.org
        : session.attributes.person;
    return attrs.filter((a) => {
      if (a.source !== "api") {
        return false;
      }

      const used = action.updates.find((update) => {
        return update.source === a.source && update.path === a.name;
      });

      return !used;
    });
  }, [session, action, targetMemberType]);

  const closeAttributePicker = useCallback(
    () => setAttributePickerOpen(false),
    [setAttributePickerOpen],
  );

  useDocumentClick(closeAttributePicker);
  return (
    <>
      <div className="">
        {action.updates.map((u) => {
          const attribute = findAttribute(
            u.path,
            u.source,
            targetMemberType === TargetMemberTypeChoices.Org
              ? AttributeTarget.Org
              : AttributeTarget.Person,
            session.attributes,
          );
          const config = configForTarget(attribute);
          return (
            <div className="grid grid-cols-flexibleLeftColumn items-center align-middle">
              <React.Fragment key={`${u.path}${u.source}`}>
                <div className="mb-1 text-sm font-semibold min-w-44">
                  {u.path}
                </div>
                <div className="flex items-center">
                  <config.valueComponent
                    disabled={!isEditable}
                    onChange={(s: any) => {
                      const newUpdates = JSON.parse(
                        JSON.stringify(action.updates),
                      );
                      newUpdates.forEach((update) => {
                        if (
                          u.path === update.path &&
                          u.source === update.source
                        ) {
                          update.value = s;
                        }
                      });
                      dispatch({
                        type: "UpdateActionOfType",
                        actionList,
                        action: {
                          id: action.id,
                          type: action.type,
                          updates: [...newUpdates],
                        },
                      });
                    }}
                    value={u.value}
                  ></config.valueComponent>
                  <div className="mb-2">
                    <button
                      className="ml-2 bg-slate-10 w-8 h-8 rounded-full hover:bg-red-10 hover:text-red animate"
                      disabled={!isEditable}
                      onClick={() => {
                        const newUpdates = JSON.parse(
                          JSON.stringify(action.updates),
                        );
                        const filteredUpdates = newUpdates.filter((update) => {
                          return (
                            u.path !== update.path || u.source !== update.source
                          );
                        });
                        dispatch({
                          type: "UpdateActionOfType",
                          actionList,
                          action: {
                            id: action.id,
                            type: action.type,
                            updates: [...filteredUpdates],
                          },
                        });
                      }}
                    >
                      <XMarkIcon className="w-5 inline-block" />
                    </button>
                  </div>
                </div>
              </React.Fragment>
            </div>
          );
        })}
      </div>
      <div className={cx(!isEditable && "pointer-events-none opacity-60")}>
        <AttributePicker
          openerStyle="root"
          openerLabel="Select an attribute"
          attributePickerOpen={attributePickerOpen}
          setAttributePickerOpen={setAttributePickerOpen}
          availableAttributes={
            targetMemberType === TargetMemberTypeChoices.Org
              ? { person: [], org: availableAttributes }
              : { person: availableAttributes, org: [] }
          }
          availableEventNames={[]}
          onAttributePicked={(
            attribute: PersonAttributeType | OrgAttributeType,
          ) => {
            const config = configForTarget(attribute);
            dispatch({
              type: "UpdateActionOfType",
              actionList,
              action: {
                id: action.id,
                type: action.type,
                updates: [
                  ...action.updates,
                  {
                    path: attribute.name,
                    source: attribute.source,
                    value: config.defaultValue,
                  },
                ],
              },
            });
          }}
          onPickerOpened={() => {}}
        />
      </div>
    </>
  );
}
