import { useMemo, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  AttributeContexts,
  AttributeTarget,
  BehaviorType,
  LifecycleStageType,
  OrgType,
  PersonType,
} from "../__generated__/graphql";

import { Tabs } from "./lib";
import { GET_BEHAVIORS } from "../graphql/queries";
import { CREATE_CAMPAIGN } from "../graphql/mutations";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import PageHeader from "../patterns/PageHeader";
import OrgProfile from "../org/OrgProfile";
import Drawer from "../patterns/Drawer";
import PersonProfile from "../person/PersonProfile";
import TabsAndGraph from "./overview/TabsAndGraph";
import { MemberTable } from "./overview/MemberTable";
import Objectives from "./overview/Objectives";
import AudienceAttributeSummaries from "../patterns/AudienceAttributeSummaries";

export default function LifecycleStageOverview({
  lifecycleStage,
}: {
  lifecycleStage: LifecycleStageType;
}) {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(GET_BEHAVIORS);
  const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Eligible);
  const [activeMember, setActiveMember] = useState<PersonType | OrgType>();
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  const behavior = lifecycleStage.behavior;

  const parsedObjectives = JSON.parse(lifecycleStage.objectives);

  const behaviorMap = useMemo(() => {
    let map: { [key: string]: BehaviorType } = {};
    data?.allBehaviors.forEach((behavior) => {
      map[behavior.id] = behavior;
    });
    return map;
  }, [data]);

  const objectiveBehaviors = useMemo(() => {
    return parsedObjectives?.map(
      (obj: { id: string; sub_behavior_ids: string[] }) => {
        let behavior: BehaviorType = behaviorMap[obj.id];
        let subBehaviors: BehaviorType[] = obj?.sub_behavior_ids.map(
          (subId) => behaviorMap[subId],
        );
        return { behavior, subBehaviors };
      },
    );
  }, [parsedObjectives, behaviorMap]);

  const createCampaignFromBehavior = useCallback(
    async (behavior: BehaviorType) => {
      try {
        const result = await createCampaignMutation({
          variables: {
            name: `Incentivize ${behavior.name}`,
            targetMemberType: behavior.targetMemberType,
            fromBehaviorId: behavior.id,
          },
        });
        if (result.data.createCampaign.ok) {
          navigate(
            `/campaigns/${result.data.createCampaign.campaign.id}?view=settings&from=${AttributeContexts.ObjectiveCampaign}`,
          );
        }
      } catch (e) {
        toast.error(e.message);
      }
    },
    [createCampaignMutation, navigate],
  );

  const attributeSummaryFilter = useMemo(() => {
    return {
      version: "1",
      filters: {
        operator: "AND",
        conditions: [
          {
            source: "machine_metadata",
            path: `member::${behavior?.id}`,
            target: AttributeTarget.Org,
            operator: "NOT_NULL",
          },
        ],
      },
    };
  }, [behavior]);

  const attributesToSummarize = [
    {
      name: "Onboarding_rag_status",
      source: "lifecycle_stage",
      target: "org",
      roll_up: {
        type: "count",
      },
    },
  ];

  if (!behavior) {
    return (
      <div className="grid py-24 items-center text-center">
        Lifecycle Stage: {lifecycleStage.name} has no associated Behavior
      </div>
    );
  }

  if (loading) return <SkeletonScreen />;
  if (error) return <FullScreenError />;

  const hasObjectives = parsedObjectives?.length > 0;
  return (
    <>
      {drawerIsVisible && activeMember && (
        <Drawer close={closeDrawer}>
          <div className="mt-10">
            {activeMember.__typename === "PersonType" ? (
              <PersonProfile id={activeMember.id} />
            ) : (
              <OrgProfile id={activeMember.id} />
            )}
          </div>
        </Drawer>
      )}
      <div className="flex flex-row items-center align-middle mx-8 -mt-6 py-4 text-sm justify-end">
        <AudienceAttributeSummaries
          filter={attributeSummaryFilter}
          attributeTarget={AttributeTarget.Org}
          attributesToSummarize={attributesToSummarize}
          orientation="horizontal"
        />
      </div>
      <TabsAndGraph behavior={behavior} setActiveTab={setActiveTab} />
      <MemberTable
        behavior={behavior}
        activeTab={activeTab}
        showMember={(member) => {
          setActiveMember(member);
          setDrawerIsVisible(true);
        }}
      />
      {hasObjectives && (
        <>
          <PageHeader
            header={`${lifecycleStage.name} Objectives`}
            subhead=""
            buttons={[
              {
                type: "primary",
                label: "Create Objective",
                action: () => {
                  navigate("./objectives/new");
                },
              },
            ]}
          />
          <div className="px-8">
            {objectiveBehaviors.map(
              ({ behavior: behaviorEntry, subBehaviors }) => (
                <Objectives
                  behavior={behaviorMap[behaviorEntry.id]}
                  subBehaviors={subBehaviors}
                  onCreateCampaign={createCampaignFromBehavior}
                />
              ),
            )}
          </div>
        </>
      )}
    </>
  );
}
