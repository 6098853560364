import CampaignStatusPill from "../campaigns/CampaignStatePill";
import { BehaviorType } from "../__generated__/graphql";
import { completionCriteriaDescription } from "../campaign/lib";
import { EyeIcon as VisibleIcon } from "@heroicons/react/24/solid";
import { EyeSlashIcon as HiddenIcon } from "@heroicons/react/24/outline";

interface Props {
  behavior: BehaviorType;
  pinned?: boolean;
  onClick?: (id: String) => void;
}

export default function BehaviorSummary({ behavior, pinned, onClick }: Props) {
  const completionCriteria = JSON.parse(behavior.observing).criteria[0];
  const description = completionCriteriaDescription(completionCriteria);

  return (
    <div
      className="flex flex-col bg-white items-center rounded-lg hover:bg-grey-200 p-3 cursor-pointer"
      key={behavior.id}
      onClick={() => onClick && onClick(behavior.id)}
    >
      <div className="flex flex-row items-center w-full">
        <div className="flex mr-2">
          {pinned ? (
            <VisibleIcon className="h-4 w-4" />
          ) : (
            <HiddenIcon className="h-4 w-4" />
          )}
        </div>
        <div className="flex text-xs font-medium text-body-text w-64">
          {behavior.name}
        </div>
        <div className="flex-grow text-xs text-body-text-lightest">
          {description}
        </div>
        <div className="flex justify-end">
          <CampaignStatusPill status={behavior.status} />
        </div>
      </div>
    </div>
  );
}
