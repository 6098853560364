export default function FluxIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17.8548L1 8.15837C1 7.44758 1.37724 6.7902 1.99092 6.43159L10.2766 1.58967C10.9 1.22538 11.6714 1.22538 12.2948 1.58967L20.5805 6.43159C21.1942 6.7902 21.5714 7.44623 21.5714 8.15701L21.5714 17.8575C21.5714 18.569 21.1934 19.2243 20.5787 19.5826L12.293 24.4128C11.6705 24.7757 10.9009 24.7757 10.2785 24.4128L1.99275 19.5826C1.37804 19.2243 1 18.5663 1 17.8548Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9106 13.0213C10.8118 13.0707 10.7717 13.1908 10.8211 13.2896C10.8705 13.3884 10.9906 13.4285 11.0894 13.3791L10.9106 13.0213ZM15.9333 10.2002C15.9333 10.7893 16.4109 11.2669 17 11.2669C17.5891 11.2669 18.0667 10.7893 18.0667 10.2002C18.0667 9.61109 17.5891 9.13353 17 9.13353C16.4109 9.13353 15.9333 9.61109 15.9333 10.2002ZM12.5894 12.6291C12.6882 12.5797 12.7283 12.4595 12.6789 12.3608C12.6295 12.262 12.5094 12.2219 12.4106 12.2713L12.5894 12.6291ZM15.4106 10.7713C15.3118 10.8207 15.2717 10.9408 15.3211 11.0396C15.3705 11.1384 15.4906 11.1785 15.5894 11.1291L15.4106 10.7713ZM11.0894 13.3791L12.5894 12.6291L12.4106 12.2713L10.9106 13.0213L11.0894 13.3791ZM15.5894 11.1291L17.0894 10.3791L16.9106 10.0213L15.4106 10.7713L15.5894 11.1291Z"
        fill="currentColor"
      />
      <path
        d="M10.973 13.3882C11.0687 13.4434 11.191 13.4106 11.2462 13.3149C11.3014 13.2192 11.2686 13.0969 11.1729 13.0417L10.973 13.3882ZM5.42472 10.572C5.9349 10.2775 6.1097 9.62512 5.81514 9.11495C5.52059 8.60477 4.86823 8.42997 4.35805 8.72452C3.84787 9.01907 3.67307 9.67143 3.96762 10.1816C4.26217 10.6918 4.91454 10.8666 5.42472 10.572ZM9.62754 12.1501C9.53187 12.0949 9.40956 12.1277 9.35436 12.2233C9.29915 12.319 9.33196 12.4413 9.42764 12.4965L9.62754 12.1501ZM6.33683 10.7132C6.43251 10.7684 6.55482 10.7356 6.61002 10.6399C6.66522 10.5442 6.63241 10.4219 6.53674 10.3667L6.33683 10.7132ZM11.1729 13.0417L9.62754 12.1501L9.42764 12.4965L10.973 13.3882L11.1729 13.0417ZM6.53674 10.3667L4.99134 9.47505L4.79143 9.82151L6.33683 10.7132L6.53674 10.3667Z"
        fill="currentColor"
      />
      <path
        d="M11.2679 13.3516C11.2678 13.2412 11.1783 13.1516 11.0678 13.1517C10.9574 13.1517 10.8678 13.2413 10.8679 13.3517L11.2679 13.3516ZM10.8693 18.4885L9.91515 20.4888L12.2246 20.4881L11.2693 18.4884L10.8693 18.4885ZM10.8684 15.1359C10.8684 15.2464 10.958 15.3359 11.0684 15.3359C11.1789 15.3358 11.2684 15.2463 11.2684 15.1358L10.8684 15.1359ZM11.2694 18.7042C11.2693 18.5937 11.1798 18.5042 11.0693 18.5042C10.9588 18.5043 10.8693 18.5938 10.8694 18.7043L11.2694 18.7042ZM10.8679 13.3517L10.8684 15.1359L11.2684 15.1358L11.2679 13.3516L10.8679 13.3517Z"
        fill="currentColor"
      />
    </svg>
  );
}
