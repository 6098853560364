import Modal from "../patterns/Modal";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import Success from "../patterns/illustrations/Success";

interface Props {
  closeModal: () => void;
}

export default function SuccessModal({ closeModal }: Props) {
  return (
    <Modal close={closeModal} title={"Success"}>
      <Success />
      <div className="text-center my-8 text-base ">
        <span className="font-semibold block leading-5">You did it!</span>Your
        integration has been added successfully
      </div>
      <PrimaryButton label="Close" onClick={closeModal} fullWidth={true} />
    </Modal>
  );
}
