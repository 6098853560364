import { useQuery } from "@apollo/client";
import { GET_SHOPIFY_PRODUCTS } from "../graphql/queries";
import PageHeader from "../patterns/PageHeader";
import SkeletonScreen from "../patterns/Skeleton";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import EmptyState from "../patterns/EmptyState";
import ShopifyProductTable from "./ShopifyProductTable";

export default function ShopifyProductsMain() {
  const { data, loading } = useQuery(GET_SHOPIFY_PRODUCTS);

  if (loading) {
    return (
      <>
        <SkeletonScreen />
      </>
    );
  }

  return (
    <>
      <PageHeader
        header="Gift Catalog"
        subhead={`${data.allShopifyProducts.length} products`}
      />
      {data.allShopifyProducts?.length === 0 ? (
        <div className="h-full mt-24">
          <EmptyState
            title="This is where you can manage your Gift Catalog"
            icon={<AttributesEmpty />}
            description={
              "Gifts can be used as incentives to encourage users to take actions in your product"
            }
          />
        </div>
      ) : (
        <ShopifyProductTable products={data.allShopifyProducts} />
      )}
    </>
  );
}
