import { NetworkStatus, useLazyQuery } from "@apollo/client";
import { isAttributeType } from "../../lib/attributes";
import { ValueComponentProps } from "../lib";
import { GET_VALUE_HINTS } from "../../graphql/queries";
import { useCallback, useEffect, useState } from "react";
import { cx } from "../../lib/cx";
import { debounce } from "lodash";

const DEBOUNCE_TIMEOUT = 1000;

export function TextInput({
  value,
  disabled = false,
  conditionTarget,
  onChange,
}: ValueComponentProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [lastFetchedQuery, setLastFetchedQuery] = useState();

  const [getValueHints, { loading, data, networkStatus, refetch }] =
    useLazyQuery(GET_VALUE_HINTS, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedRefetch = useCallback(debounce(refetch, DEBOUNCE_TIMEOUT), [
    refetch,
  ]);

  useEffect(() => {
    if (conditionTarget && isAttributeType(conditionTarget)) {
      getValueHints({
        variables: {
          attrTypeId: conditionTarget.id,
          target: conditionTarget.target,
          search: value || "",
        },
      });
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (
      value !== lastFetchedQuery &&
      data &&
      conditionTarget &&
      isAttributeType(conditionTarget)
    ) {
      setSelectedSuggestion(0);
      setLastFetchedQuery(value);
      debouncedRefetch({
        attrTypeId: conditionTarget.id,
        target: conditionTarget.target,
        search: value || "",
      });
    }
  }, [
    data,
    value,
    conditionTarget,
    debouncedRefetch,
    lastFetchedQuery,
    setLastFetchedQuery,
  ]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!data?.attributeValueHints) {
        return;
      }
      switch (e.key) {
        case "ArrowDown":
          if (selectedSuggestion < data.attributeValueHints.length - 1) {
            setSelectedSuggestion(selectedSuggestion + 1);
          }
          break;
        case "ArrowUp":
          if (selectedSuggestion > 0) {
            setSelectedSuggestion(selectedSuggestion - 1);
          }
          break;
        case "Enter":
          onChange(data.attributeValueHints[selectedSuggestion]);
      }
    },
    [selectedSuggestion, data, onChange],
  );

  const refetchLoading = networkStatus === NetworkStatus.setVariables;
  const oneResultSameAsValue =
    data?.attributeValueHints?.length === 1 &&
    data?.attributeValueHints[0] === value;

  const showHints =
    data?.attributeValueHints?.length > 0 &&
    !loading &&
    !refetchLoading &&
    isFocused &&
    !oneResultSameAsValue;

  return (
    <div className="relative">
      <input
        className="mb-2 border border-rule-color bg-grey-50 text-xs px-4 py-3 rounded-md focus:ring-2 focus:ring-blue-300 focus:ring-inset min-w-48 max-w-96"
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={onKeyDown}
        autoFocus
      />
      {showHints && (
        <div className="absolute bg-white w-full shadow-md p-2">
          {data.attributeValueHints.map((v, i) => (
            <div
              key={v}
              className={cx(
                "cursor-pointer",
                i === selectedSuggestion && "bg-slate-10 rounded-md",
              )}
              onMouseDown={() => {
                onChange(v);
              }}
              onMouseEnter={() => setSelectedSuggestion(i)}
            >
              {v}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
