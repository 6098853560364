import { CrmIntegrationType } from "../__generated__/graphql";
import Drawer from "../patterns/Drawer";
import PageHeader from "../patterns/PageHeader";

interface Props {
  close: () => void;
  activeIntegration: CrmIntegrationType;
}

export default function IntegrationDetail({ close, activeIntegration }: Props) {
  return (
    <Drawer close={close}>
      <PageHeader header={activeIntegration.crmType} subhead="" buttons={[]} />
      <div className="m-8">{activeIntegration.config}</div>
    </Drawer>
  );
}
