import { useNavigate } from "react-router-dom";
import { PersonEdge } from "../__generated__/graphql";
import Table, { TableProps } from "../patterns/tables/table";
import FilterBuilder from "../filter_builder";
import { Filter } from "../types/BackendTypes";
import SearchInput from "../patterns/forms/SearchInput";
import BehaviorButton from "../patterns/atoms/BehaviorButton";

interface Props {
  people: Omit<PersonEdge, "cursor">[] | any;
  filter: Filter;
  query: string;
  isLoading: boolean;
  updateFilter: (f: Filter) => void;
  updateQuery: (s: string) => void;
  showNewBehaviorModal: (b: boolean) => void;
  readOnlyFilters?: boolean;
  onRowClick?: (id: string) => void;
}

function toTable(
  people: Omit<PersonEdge, "cursor">[],
  rowClickFunc: Function,
): Omit<TableProps, "filterBar"> {
  return {
    header: [
      { label: "Name" },
      { label: "Email" },
      { label: "Active Campaigns" },
      { label: "Entered Campaigns" },
      { label: "Completion Rate" },
      // { label: "Events Completed" },
    ],
    rows: people.map((p) => {
      const enteredCampaigns =
        p.node.activeCampaigns.length +
        p.node.completedCampaigns.length +
        p.node.exitedCampaigns.length;
      const completionRate =
        enteredCampaigns > 0
          ? Math.round(
              (p.node.completedCampaigns.length / enteredCampaigns) * 100,
            )
          : 0;

      return {
        id: p.node.id,
        onClick: (e: any, id: string) => rowClickFunc(p.node.id),
        cells: [
          { content: p.node.name },
          { content: p.node.email },
          { content: p.node.activeCampaigns.length },
          { content: enteredCampaigns },
          { content: `${completionRate}%` },
          // { content: p.node.eventsTotal },
        ],
      };
    }),
  };
}
export default function PeopleTable({
  people,
  filter,
  query,
  isLoading,
  updateFilter,
  updateQuery,
  showNewBehaviorModal,
  readOnlyFilters = false,
  onRowClick,
}: Props) {
  const navigate = useNavigate();
  const navigateToPerson = (id: string) => navigate(`/audience/people/${id}`);
  const rowClickFunc = onRowClick || navigateToPerson;
  const tableDefinition = toTable(people, rowClickFunc);
  const hasActiveFilters = filter.filters.conditions.length > 0;
  return (
    <Table
      header={tableDefinition.header}
      rows={tableDefinition.rows}
      isLoading={isLoading}
      emptyMessage="No people found matching these filters"
      filterBar={
        <div className="flex space-x-2">
          <div className="grow">
            <FilterBuilder
              filterDoc={filter}
              onChange={updateFilter}
              readonly={readOnlyFilters}
            />
          </div>
          <div className="min-w-64">
            {hasActiveFilters ? (
              <BehaviorButton
                label="Add a Behavior using these filters"
                onClick={() => {
                  showNewBehaviorModal(true);
                }}
              />
            ) : (
              <SearchInput
                placeholder="Search People"
                value={query}
                onChange={updateQuery}
                autoFocus={false}
              />
            )}
          </div>
        </div>
      }
    />
  );
}
