import React from "react";
import { cx } from "../lib/cx";

interface Props {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  isRadio?: boolean;
  key?: string;
  clickableCards?: boolean;
}

export default function CardMenu({
  children,
  onClick,
  className,
  isRadio = false,
  clickableCards = true,
}: Props & React.PropsWithChildren<Props>) {
  const cardClassName = cx(
    "flex grow flex-col border border-transparent rounded-md bg-grey-50 opacity-80 animate",
    clickableCards
      ? "cursor-pointer hover:opacity-100 hover:shadow-md hover:bg-grey-100"
      : "",
    className,
  );

  return isRadio ? (
    <label className={cardClassName}>
      {children}
      <div className="mx-auto pb-4">
        <input
          id="default-radio-1"
          type="radio"
          value=""
          name="default-radio"
          onClick={onClick}
          className="w-3 h-3 cursor-pointer text-body-text active:ring-1 focus:ring-white animate"
        />
      </div>
    </label>
  ) : (
    <div className={cardClassName} onClick={onClick}>
      {children}
    </div>
  );
}
