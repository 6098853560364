import { Dispatch, useMemo } from "react";
import LabelAndToggle from "../label-and-toggle";
import OptionsAndDetail from "../options-and-details";
import SelectInput from "../../patterns/forms/SelectInput";
import Card from "../../patterns/Card";
import EmptyState from "../../patterns/EmptyState";
import {
  IncentiveAction,
  TreeIncentiveCampaign,
  DiscountIncentiveCampaign,
  CampaignDefinition,
  WebhookIncentiveCampaign,
  ShopifyGiftIncentiveCampaign,
} from "../../types/BackendTypes";
import {
  IncentiveTypes,
  IncentiveType,
  MessagingSenderType,
  MachineStatusChoices,
} from "../../__generated__/graphql";
import { CampaignDefinitionEditAction } from "../reducer";
import IncentiveConfig from "./incentive-config";
import Alert from "../../patterns/Alert";
import Incentive from "../../patterns/illustrations/Incentive";
import IncentiveMessageEditor from "./IncentiveMessageEditor";
import NoSender from "../../patterns/illustrations/NoSender";

interface Props {
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  campaignConfig: CampaignDefinition;
  campaignStatus: MachineStatusChoices;
  senders?: MessagingSenderType[];
  incentives: Omit<IncentiveType, "config">[];
  isEditable: boolean;
}

function defaultConfigForIncentiveType(
  id: string,
  incentiveType: IncentiveTypes,
):
  | DiscountIncentiveCampaign
  | TreeIncentiveCampaign
  | ShopifyGiftIncentiveCampaign
  | WebhookIncentiveCampaign {
  switch (incentiveType) {
    case IncentiveTypes.Trees:
      return {
        id,
        type: "trees",
        config: {
          auto_claim: false,
          amount: 1,
        },
      };
    case IncentiveTypes.ShopifyGift:
      return {
        id,
        type: "shopify_gift",
        config: {
          auto_claim: true,
          product_gid: "",
          variant_gid: "",
        },
      };
    case IncentiveTypes.DiscountSample:
      return {
        id,
        type: "discount_sample",
        config: {
          auto_claim: false,
          type: "percentage",
          value: 5,
        },
      };
    case IncentiveTypes.Webhook:
      return {
        id,
        type: "webhook",
        config: {
          auto_claim: true,
          url: "",
          headers: [],
        },
      };

    default:
      const r: never = incentiveType;
      throw new Error(r);
  }
}

export default function IncentiveActionConfig({
  campaignConfig,
  isEditable,
  incentives,
  campaignStatus,
  senders,
  dispatch,
}: Props) {
  const incentiveAction = campaignConfig.after_actions.actions.find(
    (a) => a.type === "incentive",
  ) as IncentiveAction;
  const incentiveActionType = incentiveAction?.incentives[0]?.type || "";
  const incentiveOptions = useMemo(() => {
    return incentives.map((i) => {
      return {
        value: i.id,
        label: `${i.name}`,
      };
    });
  }, [incentives]);
  const hasIncentives = incentives.length > 0;
  const hasSenders = senders?.length > 0;

  return (
    <div className="px-8 mb-8">
      <LabelAndToggle
        label="Offer an incentive"
        isOn={!!incentiveAction}
        disabled={!isEditable}
        onChange={() => {
          !!incentiveAction
            ? dispatch({
                type: "RemoveActionOfType",
                actionType: "incentive",
                actionList: "after_actions",
              })
            : dispatch({ type: "EnableIncentive" });
        }}
      />

      {!isEditable && (
        <Alert
          type="warning"
          message="You can't edit an incentive after a campaign has been set live"
          size="small"
        />
      )}

      {!!incentiveAction && !hasIncentives && (
        <Card>
          <div className="p-4">
            <EmptyState
              title="You haven't added an incentive yet"
              icon={<Incentive />}
              description={"You can do this in the Incentives section."}
              buttonLabel={"Add an incentive sender"}
            />
          </div>
        </Card>
      )}

      {!!incentiveAction && hasIncentives && (
        <OptionsAndDetail
          left={
            <>
              <SelectInput
                label={"Choose the incentive you’ll offer"}
                description={
                  "Incentives can be added in the Incentives section"
                }
                placeholder="Pick an incentive"
                required={true}
                options={incentiveOptions}
                disabled={!isEditable}
                onChange={(s) => {
                  const incentive = incentives.find((i) => i.id === s);
                  const config = defaultConfigForIncentiveType(
                    s,
                    incentive.type,
                  );

                  dispatch({
                    type: "UpdateAfterAction",
                    action: {
                      ...incentiveAction,
                      incentives: [config],
                    },
                  });
                }}
                value={incentiveAction.incentives[0]?.id}
              />
              {incentiveAction.incentives[0] && (
                <IncentiveConfig
                  action={incentiveAction}
                  dispatch={dispatch}
                  isEditable={isEditable}
                />
              )}
            </>
          }
          right={
            <>
              {incentiveActionType === IncentiveTypes.ShopifyGift && (
                <>
                  {hasSenders ? (
                    <IncentiveMessageEditor
                      dispatch={dispatch}
                      message={incentiveAction}
                      senders={senders}
                      isEditable={
                        campaignStatus !== MachineStatusChoices.Complete
                      }
                      actionType="UpdateAfterAction"
                    />
                  ) : (
                    <Card>
                      <div className="p-4">
                        <EmptyState
                          title="You haven't added a sender yet"
                          icon={<NoSender />}
                          description={
                            "You'll need to add a sender before you can send an email. You can do this in Integrations/Messaging"
                          }
                        />
                      </div>
                    </Card>
                  )}
                </>
              )}
            </>
          }
        />
      )}
    </div>
  );
}
