import { ResponsiveBar } from "@nivo/bar";

interface BarProps {
  data: any[];
  keys: string[];
  indexBy: string;
  groupMode?: "stacked" | "grouped";
}

export default function Bar({
  data,
  keys,
  indexBy,
  groupMode = "grouped",
}: BarProps) {
  const sumOfKeys = data.map((d) => keys.reduce((s, k) => (s += d[k]), 0));
  const maxY = Math.ceil(Math.max(...sumOfKeys) / 100) * 100;
  const maxTicks = 10;
  const tickInterval = Math.round(maxY / maxTicks);
  const tickValues = [];
  for (let i = 0; i <= maxY; i += tickInterval) {
    tickValues.push(i);
  }

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      groupMode={groupMode}
      margin={{ top: 25, right: 0, bottom: 70, left: 32 }}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 10,
            },
          },
        },
        legends: {
          text: {
            fontSize: 9,
          },
        },
      }}
      padding={0.1}
      valueScale={{ type: "linear" }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      colors={["#6b8afa", "#1A41CF"]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 4,
        tickPadding: 6,
        tickRotation: -45,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 4,
        tickPadding: 4,
        tickRotation: 0,
        truncateTickAt: 0,
        tickValues: tickValues,
      }}
      gridYValues={tickValues}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 3]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 70,
          itemsSpacing: 2,
          itemWidth: 75,
          itemHeight: 15,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 15,
        },
      ]}
    />
  );
}
