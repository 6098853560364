import { useState } from "react";
import { useQuery } from "@apollo/client";
import { IncentiveType } from "../__generated__/graphql";
import { IncentiveTypes } from "../__generated__/graphql";
import { GET_INCENTIVES } from "../graphql/queries";

import EmptyState from "../patterns/EmptyState";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import PageHeader from "../patterns/PageHeader";
import SkeletonScreen from "../patterns/Skeleton";
import CardMenu from "../patterns/CardMenu";
import Incentive from "../patterns/illustrations/Incentive";
import SectionHeader from "../patterns/SectionHeader";
import IncentiveDetail from "./IncentiveDetail";
import NewIncentive from "./NewIncentive";

export default function IncentivesMain() {
  const { data, loading } = useQuery(GET_INCENTIVES);
  const [isModalVisible, setModalVisible] = useState(false);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [activeIncentive, setActiveIncentive] = useState<IncentiveType>();
  const incentiveNames: {
    [key in IncentiveTypes]: string;
  } = {
    discount_sample: "Discount Sample",
    shopify_gift: "Gift",
    trees: "Trees",
    webhook: "Webhook",
  };
  if (loading) {
    return (
      <>
        <SkeletonScreen />
      </>
    );
  }
  return (
    <div>
      {isModalVisible && (
        <NewIncentive closeModal={() => setModalVisible(false)} />
      )}
      {drawerIsVisible && activeIncentive && (
        <IncentiveDetail
          close={() => setDrawerIsVisible(false)}
          activeIncentive={activeIncentive}
        />
      )}
      <div className="flex flex-col max-w-8xl mx-auto mt-0 2xl:mt-8">
        {data.incentives.length === 0 ? (
          <div className="h-full mt-8">
            <EmptyState
              title="This is where you can add and manage incentives"
              icon={<AttributesEmpty />}
              description={
                "Incentives are how you encourage users to take actions in your product"
              }
              buttonLabel={"Add an incentive"}
              onClick={() => setModalVisible(true)}
            />
          </div>
        ) : (
          <>
            <PageHeader
              header="Incentives"
              subhead={`${data.incentives.length} incentives`}
              buttons={[
                {
                  label: "Add an Incentive",
                  type: "primary",
                  action: () => setModalVisible(true),
                },
              ]}
            />
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-8">
              {data.incentives.map((incentive) => (
                <CardMenu
                  key={incentive.id}
                  onClick={() => {
                    setDrawerIsVisible(true);
                    setActiveIncentive(incentive);
                  }}
                >
                  <div className="w-auto h-40 rounded-t-lg bg-gradient-to-br from-[#E5F5F2] to-[#E4ECF6] p-6 flex items-center justify-center">
                    <Incentive />
                  </div>
                  <div className="px-4 pt-4 pb-2">
                    <SectionHeader
                      title={incentive.name}
                      description={incentiveNames[incentive.type]}
                    />
                  </div>
                </CardMenu>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
