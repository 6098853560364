import React, { useEffect, useRef } from "react";
import CampaignMain from "../campaigns";
import ReferralSignals from "../referrals/ReferralSignals";

export default function ReferralCustomerDashboard({
  scrollTo,
}: {
  scrollTo?: string;
}) {
  const signalsRef = useRef<HTMLDivElement>(null);
  const campaignsRef = useRef<HTMLDivElement>(null);

  const scrollToRef = (ref) => ref.current.scrollIntoView();

  useEffect(() => {
    const refs = {
      signals: signalsRef,
      campaigns: campaignsRef,
    };

    if (scrollTo) {
      scrollToRef(refs[scrollTo]);
    }
  }, [scrollTo]);

  return (
    <>
      <div ref={signalsRef} className="scroll-mt-8">
        <ReferralSignals />
      </div>
      <div ref={campaignsRef} className="scroll-mt-8">
        <CampaignMain />
      </div>
    </>
  );
}
