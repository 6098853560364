import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/16/solid";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { BoltIcon, LightBulbIcon } from "@heroicons/react/24/outline";
import {
  BehaviorType,
  LifecycleStageType,
  MachineStatusChoices,
  ObjectiveCohortRiskType,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";
import {
  GET_LIFECYCLE_STAGE_COHORT_RISK,
  GET_LIFECYCLE_STAGES,
} from "../graphql/queries";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import EngagementCircle from "../patterns/EngagementCircle";
import LifecycleStageStatePill from "../patterns/LifecycleStageStatePill";
import CohortAction from "./actions/CohortAction";
import CampaignAction from "./actions/CampaignAction";

export const VIEW_PARAM = "view";
export const COMPLETION_RATE_THRESHOLD = 70;

export default function AiAgentDrawer({ close }: { close: () => void }) {
  const { data, loading, error } = useQuery(GET_LIFECYCLE_STAGES, {
    fetchPolicy: "cache-and-network",
  });

  const lifecycleStages = data?.allLifecycleStages;
  const activeLifecycleStages = useMemo(() => {
    return lifecycleStages?.filter((stage) => {
      const isLive = stage.behavior.status === MachineStatusChoices.Live;
      const hasEntrants = stage.behavior.stats.entered.count > 0;
      return isLive && hasEntrants;
    });
  }, [lifecycleStages]);
  const hasActiveLifecycleStages = activeLifecycleStages?.length > 0;

  if (error) return <FullScreenError />;

  return (
    <div className="p-6">
      <div className="flex flex-row items-center">
        <span className="text-green-600 w-6 h-6 mr-1 animate-pulse">
          &#9673;
        </span>
        <div className="text-xl font-bold tracking-tighter">Pavlov</div>
      </div>
      <div className="pl-7 text-xs text-body-text-lighter font-mono animate-pulse">
        Working...
      </div>
      {loading ? (
        <SkeletonScreen />
      ) : (
        <>
          {hasActiveLifecycleStages ? (
            <>
              {activeLifecycleStages.map((lifecycleStage) => (
                <LifecycleStageBreakdown
                  lifecycleStage={lifecycleStage}
                  close={close}
                />
              ))}
            </>
          ) : (
            <div className="p-6 bg-white rounded-xl shadow-sm margin-y-auto mt-12 text-center">
              There are no events that require attention at this time!
            </div>
          )}
        </>
      )}
    </div>
  );
}

function LifecycleStageBreakdown({
  lifecycleStage,
  close,
}: {
  lifecycleStage: LifecycleStageType;
  close: () => void;
}) {
  const target =
    lifecycleStage.behavior.targetMemberType === TargetMemberTypeChoices.Org
      ? "organizations"
      : "people";
  const entered = lifecycleStage.behavior.stats.entered.count;
  const completed = lifecycleStage.behavior.stats.completed.count;
  const completedPercentage =
    entered && Math.round((completed / entered) * 100);
  const avgTimeToCompletion =
    lifecycleStage.behavior.stats.timeToCompletion.average.toFixed(2);

  const { data, loading } = useQuery(GET_LIFECYCLE_STAGE_COHORT_RISK, {
    variables: { id: lifecycleStage.id },
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <SkeletonScreen />;

  const filteredRisks = data?.lifecycleStageCohortRisk.filter(
    (risk) => risk.numberOfOrgs > 0,
  );

  return (
    <div className="flex flex-col gap-4 mt-4 mb-8">
      <div className="flex flex-row items-center ml-2">
        <div className="flex-grow">
          <div className="flex flex-col h-full py-4">
            <div className="text-lg">
              <span className="font-semibold">{lifecycleStage.name}</span>{" "}
              Engagement
            </div>
            <div className="text-xs text-body-text-lightest">
              <span className="font-semibold">{entered.toLocaleString()}</span>{" "}
              {target} are currently in this Lifecycle Stage
            </div>
            <div className="text-xs text-body-text-lightest mt-1">
              <span className="font-semibold">
                {completed.toLocaleString()}
              </span>{" "}
              {target} have completed, with an avg completion time of{" "}
              <span className="font-semibold">{avgTimeToCompletion} days</span>
            </div>
          </div>
        </div>
        <div className="w-24 h-24 mr-2">
          <EngagementCircle
            completionPercentage={completedPercentage}
            showText={true}
          />
        </div>
      </div>
      {lifecycleStage.objectiveBehaviors.map((objective) => {
        return (
          <ObjectiveCard
            objective={objective}
            risks={filteredRisks.filter(
              (risk) => risk.objectiveId === objective.id,
            )}
            close={close}
          />
        );
      })}
    </div>
  );
}

function ObjectiveCard({
  objective,
  risks,
  close,
}: {
  objective: BehaviorType;
  risks: ObjectiveCohortRiskType[];
  close: () => void;
}) {
  const navigate = useNavigate();
  const entered = objective.stats.entered.count;
  const completed = objective.stats.completed.count;
  const objectiveCompletionRate =
    entered && Math.round((completed / entered) * 100);
  const avgTimeToCompletion =
    objective.stats.timeToCompletion.average.toFixed(2);

  const hasRisks = risks.length > 0;
  const belowEngagementThreshold =
    objectiveCompletionRate < COMPLETION_RATE_THRESHOLD;

  return (
    <div className="p-4 bg-white rounded-xl shadow margin-y-auto">
      <div className="flex flex-row gap-4 text-sm">
        <div className="w-5 h-5">
          <EngagementCircle completionPercentage={objectiveCompletionRate} />
        </div>
        <div className="flex-grow">
          <div className="font-semibold flex flex-row items-center gap-2">
            {objective.name}
            <ArrowTopRightOnSquareIcon
              className="w-4 h-4 mb-0.5 cursor-pointer"
              onClick={() => {
                navigate(`/behaviors/${objective.id}?view=overview`);
                close();
              }}
            />
          </div>
          <div className="text-body-text-lightest text-xs font-mono mt-2">
            -{" "}
            <span className="font-semibold text-body-text">
              {objectiveCompletionRate}%
            </span>{" "}
            have completed, or are on-track to complete
          </div>
          <div className="text-body-text-lightest text-xs font-mono mt-2">
            - Average time to completion is{" "}
            <span className="font-semibold text-body-text">
              {avgTimeToCompletion} days
            </span>
          </div>
        </div>
        <div>
          <LifecycleStageStatePill
            completedPercentage={objectiveCompletionRate}
          />
        </div>
      </div>
      {hasRisks && belowEngagementThreshold && (
        <>
          {risks.map((risk) => (
            <CohortRisk objective={objective} risk={risk} />
          ))}
        </>
      )}
    </div>
  );
}

function CohortRisk({
  objective,
  risk,
}: {
  objective: BehaviorType;
  risk: ObjectiveCohortRiskType;
}) {
  return (
    <Disclosure
      key="p1"
      as="div"
      defaultOpen={false}
      className="rounded-lg bg-grey-50 p-4 mt-4 flex flex-col gap-4 text-sm"
    >
      <DisclosureButton className="group">
        <div className="flex flex-row gap-4">
          <div>
            <LightBulbIcon className="w-5 h-5" />
          </div>
          <div className="grow font-mono text-left">
            <span className="font-semibold">{risk.cohortName}</span> customers
            at risk
          </div>
          <div>
            <PlusCircleIcon className="w-5 h-5 group-data-[open]:hidden" />
            <MinusCircleIcon className="w-5 h-5 [.group:not([data-open])_&]:hidden" />
          </div>
        </div>
        <div className="text-body-text-lightest text-left pl-9 mt-2">
          <span className="font-semibold">{risk.numberOfOrgs}</span>{" "}
          Organizations in this cohort are currently at risk, with a combined
          revenue of:{" "}
          <span className="font-semibold">
            ${risk.totalRevenue.toLocaleString()}
          </span>
        </div>
      </DisclosureButton>
      <DisclosurePanel as="div">
        <div className="flex flex-row gap-4 mt-2">
          <div>
            <BoltIcon className="w-5 h-5" />
          </div>
          <div className="grow font-semibold text-left">Action</div>
        </div>
        <div className="text-body-text-lightest pl-9">
          Proposed plan to drive engagement:
        </div>
        <div className="flex flex-row mt-4">
          <div className="w-8 pr-6">
            <div className="h-full border-r-1 border-grey-300"></div>
          </div>
          <div className="flex flex-col w-full gap-2 font-mono">
            <CohortAction cohortId={risk.cohortId} />
            <CampaignAction objective={objective} />
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
