import { useMutation } from "@apollo/client";
import Modal from "../patterns/Modal";
import { CREATE_LOOPS_INTEGRATION } from "../graphql/mutations";
import { FormEvent, useCallback, useMemo, useRef, useState } from "react";
import { LoopsTypeChoices } from "../__generated__/graphql";
import SelectInput from "../patterns/forms/SelectInput";
import toast from "react-hot-toast";

interface Props {
  closeModal: () => void;
}

function AddToSlackButton({ onClick, disabled }) {
  return (
    <button
      onClick={onClick}
      type="button"
      style={{ pointerEvents: disabled ? "none" : "auto" }}
      className={
        " \
        w-full \
        bg-white border-1 border-rule-color animate  \
        hover:bg-grey-200 hover:border-grey-200 \
        font-medium rounded-full text-sm px-5 py-2.5 text-center \
        inline-flex items-center justify-center mr-2 mb-2 \
        "
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
        viewBox="0 0 122.8 122.8"
      >
        <path
          d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
          fill="#e01e5a"
        ></path>
        <path
          d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
          fill="#36c5f0"
        ></path>
        <path
          d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
          fill="#2eb67d"
        ></path>
        <path
          d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
          fill="#ecb22e"
        ></path>
      </svg>
      Add to Slack
    </button>
  );
}

export default function NewIntegrationModal({ closeModal }: Props) {
  const formRef = useRef<HTMLFormElement>();
  const [createLoopsIntegrationMutation] = useMutation(
    CREATE_LOOPS_INTEGRATION,
  );
  const [type, setType] = useState<LoopsTypeChoices>();
  const [isSaving, setIsSaving] = useState(false);
  const options = useMemo(
    () =>
      Object.keys(LoopsTypeChoices).map((k) => {
        return {
          label: k,
          value: LoopsTypeChoices[k],
        };
      }),
    [],
  );
  const createLoopsIntegration = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        const response = await createLoopsIntegrationMutation({
          variables: { type, config: "{}" },
          refetchQueries: ["GetLoopsIntegrations", "GetSession"],
        });
        const redirect = response.data.createLoopsIntegration.redirect;
        if (redirect) {
          if (window.location.port === "3000") {
            window.location.replace(`//localhost:8000/${redirect}`);
          } else {
            window.location.replace(`/${redirect}`);
          }
        } else {
          toast.success("Integration created");
          closeModal();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsSaving(false);
      }
    },
    [closeModal, createLoopsIntegrationMutation, type],
  );
  return (
    <Modal close={closeModal} title="Add a Loops integration">
      <form
        ref={formRef}
        className=""
        action="#"
        onSubmit={createLoopsIntegration}
      >
        <SelectInput
          label="Choose the integration you would like to use"
          placeholder="Choose an integration"
          options={options}
          required={true}
          onChange={(s) => {
            setType(s as LoopsTypeChoices);
          }}
        />

        {type === LoopsTypeChoices.Slack && (
          <>
            <p className="text-xs text-body-text-light py-3">
              You will be asked to authorize the integration with Slack, which
              will add the Pavlov-for-slack app to your workspace.
            </p>
            <AddToSlackButton
              onClick={() => formRef.current.requestSubmit()}
              disabled={isSaving}
            />
          </>
        )}
      </form>
    </Modal>
  );
}
