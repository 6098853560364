import SelectInput from "../../patterns/forms/SelectInput";
import TextInput from "../../patterns/forms/TextInput";
import {
  MixpanelAuth,
  MixpanelProps,
  MixpanelRegion,
} from "../../types/BackendTypes";

interface Props {
  props: MixpanelProps;
  setProps: (s: MixpanelProps) => void;
  auth: MixpanelAuth;
  setAuth: (s: MixpanelAuth) => void;
}

export default function MixpanelConfig({
  props,
  setProps,
  auth,
  setAuth,
}: Props) {
  return (
    <>
      <SelectInput
        label="Mixpanel Region"
        placeholder="Choose a region"
        options={[
          {
            label: "EU",
            value: "EU",
          },
          {
            label: "US",
            value: "US",
          },
        ]}
        required={true}
        value={props?.mixpanel_region || ""}
        onChange={(s: MixpanelRegion) => {
          setProps({ ...props, mixpanel_region: s });
        }}
      />
      <TextInput
        placeholder="Account name"
        label="Service account username"
        value={auth?.username || ""}
        required={true}
        onChange={(s) => {
          setAuth({ ...auth, username: s });
        }}
      />
      <TextInput
        placeholder="Secret"
        label="Service account secret"
        value={auth?.secret || ""}
        required={true}
        onChange={(s) => {
          setAuth({ ...auth, secret: s });
        }}
      />
      <TextInput
        placeholder="Project ID"
        label="Project ID"
        value={props?.project_id || ""}
        required={true}
        onChange={(s) => {
          setProps({ ...props, project_id: s });
        }}
      />
    </>
  );
}
