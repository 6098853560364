import { Dispatch } from "react";
import { Flags, MessagingSenderType } from "../../__generated__/graphql";
import { incentiveAttributes } from "../../filter_builder/lib";
import SelectInput from "../../patterns/forms/SelectInput";
import TextInput from "../../patterns/forms/TextInput";
import useIsFeatureEnabled from "../../hooks/useIsFeatureEnabled";
import {
  DelayedEmailAction,
  EmailAction,
  IncentiveAction,
} from "../../types/BackendTypes";
import { CampaignDefinitionEditAction } from "../reducer";
import Editor from "../editor";

interface Props {
  dispatch: Dispatch<CampaignDefinitionEditAction>;
  message: EmailAction | DelayedEmailAction | IncentiveAction;
  isEditable: boolean;
  senders?: MessagingSenderType[];
  actionType: "UpdateBeforeAction" | "UpdateAfterAction";
}

export default function IncentiveMessageEditor({
  dispatch,
  message,
  isEditable,
  senders,
  actionType,
}: Props) {
  const giftRedemptionUrlAttribute = incentiveAttributes[0];
  const defaultIncentiveMessage = `Click here to claim your gift: <span class="tiptap-variable" data-type="variable" data-value="${giftRedemptionUrlAttribute.slug}">${giftRedemptionUrlAttribute.name}</span>`;
  const showIncentivePicker = useIsFeatureEnabled(Flags.ShopifyGiftIncentive);

  return (
    <>
      <div className="text-sm font-semibold">Incentive message</div>
      <div className="text-xs font-normal leading-5 tracking-wide text-body-text-lighter mb-4">
        Customize the email users will receive when they claim their incentive
      </div>
      <SelectInput
        label={"Send from"}
        description={"Senders can be managed in Integrations/Messaging"}
        placeholder="Pick a sender"
        required={true}
        value={message.messages[0]?.sender || ""}
        options={senders.map((s) => ({
          label: s.displayName,
          value: s.id,
        }))}
        disabled={!isEditable}
        onChange={(s) =>
          dispatch({
            type: actionType,
            action: {
              ...message,
              messages: [
                {
                  ...message.messages[0],
                  sender: s,
                },
              ],
            },
          })
        }
      />
      <TextInput
        placeholder="Subject"
        label="Enter E-mail subject"
        value={message.messages[0]?.subject || ""}
        required={true}
        disabled={!isEditable}
        onChange={(s) =>
          dispatch({
            type: actionType,
            action: {
              ...message,
              messages: [
                {
                  ...message.messages[0],
                  subject: s,
                },
              ],
            },
          })
        }
      />
      <Editor
        label="Message to send"
        value={message.messages[0]?.body || defaultIncentiveMessage}
        editable={isEditable}
        showIncentivePicker={showIncentivePicker}
        onChange={(s) =>
          dispatch({
            type: actionType,
            action: {
              ...message,
              messages: [
                {
                  ...message.messages[0],
                  body: s,
                },
              ],
            },
          })
        }
      />
    </>
  );
}
