import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_BEHAVIOR_STATS_FOR_MEMBER } from "../../graphql/queries";
import * as Plot from "@observablehq/plot";
import Card from "../Card";
import SectionHeader from "../SectionHeader";
import { cx } from "../../lib/cx";
import { truncate } from "../../lib/string";
import { useChartDimensions } from "../../hooks/useChartDimensions";

const PillButton = ({ label, active, onClick }) => {
  return (
    <button
      className={cx(
        "px-2 py-1 rounded-full text-sm text-white",
        active
          ? "bg-slate-200 hover:bg-slate-100 text-white"
          : "bg-slate-50 hover:bg-slate-200 text-black",
      )}
      onClick={onClick}
      style={{ fontSize: "0.6rem" }}
    >
      {label}
    </button>
  );
};

interface Props {
  member;
}

export default function BehaviorTimelines({ member }: Props) {
  const initialChartSettings = {
    marginTop: 20,
    marginLeft: 150,
    marginBottom: 50,
  };

  const [ref, dims] = useChartDimensions(initialChartSettings);
  const { data: queryResult } = useQuery(GET_BEHAVIOR_STATS_FOR_MEMBER, {
    variables: { memberId: member.id },
  });

  const [timeCutoff, setTimeCutoff] = useState(null);

  const handleTimeCutoffSelect = useCallback(
    (days: number): void => {
      if (timeCutoff === days) {
        setTimeCutoff(null);
      } else {
        setTimeCutoff(days);
      }
    },
    [timeCutoff],
  );

  const names = useMemo(() => {
    if (!queryResult) return [];

    return queryResult.behaviorStatsForMember.map((b) => b.behaviorName);
  }, [queryResult]);

  const events = useMemo(() => {
    if (!queryResult) return [];

    const transformed = [];

    queryResult.behaviorStatsForMember.forEach((b, i) => {
      b.completed.forEach((c) =>
        transformed.push({
          id: b.behaviorId,
          name: b.behaviorName,
          idx: i + 1,
          happenedAt: c,
        }),
      );
    });

    return transformed;
  }, [queryResult]);

  const completions = useMemo(() => {
    if (!queryResult) return [];

    const transformed = queryResult.behaviorStatsForMember.map((b) => ({
      id: b.behaviorId,
      name: b.behaviorName,
      completed: Math.max(...b.completed),
    }));
    return transformed;
  }, [queryResult]);

  const timeLines = useMemo(() => {
    if (!queryResult) return [];

    const transformed = queryResult.behaviorStatsForMember
      .map((b, i) => [
        {
          id: b.behaviorId,
          name: b.behaviorName,
          t: timeCutoff
            ? Math.max(Math.max(...b.completed) - timeCutoff, 0)
            : 0,
        },
        {
          id: b.behaviorId,
          name: b.behaviorName,
          t: Math.max(...b.completed),
        },
      ])
      .flat();
    return transformed;
  }, [queryResult, timeCutoff]);

  const averages = useMemo(() => {
    if (!queryResult) return [];

    const transformed = queryResult.behaviorStatsForMember.map((b) => ({
      id: b.behaviorId,
      name: b.behaviorName,
      average: b.average,
    }));
    return transformed;
  }, [queryResult]);

  const maxDaysSince = useMemo(
    () =>
      Math.max(
        ...events.map((d) => d.happenedAt),
        ...averages.map((d) => d.average),
        1,
      ),
    [events, averages],
  );

  const minDaysSince = useMemo(
    () => Math.max(maxDaysSince - (timeCutoff || maxDaysSince), 0),
    [maxDaysSince, timeCutoff],
  );

  useEffect(() => {
    if (events === undefined) return;
    const plot = Plot.plot({
      width: dims.width,
      marginTop: dims.marginTop,
      marginRight: dims.marginRight,
      marginBottom: dims.marginBottom,
      marginLeft: dims.marginLeft,
      y: {
        label: null,
        ticks: names,
        tickFormat: (d) => truncate(d, 20),
      },
      x: {
        domain: [minDaysSince, maxDaysSince + 1],
        label: "Days since entered",
        labelAnchor: "center",
        labelArrow: "none",
        labelOffset: 45,
      },
      style: {
        fontSize: "10pt",
        color: "gray",
      },
      symbol: { legend: true },
      marks: [
        Plot.frame({ anchor: "bottom" }),
        Plot.frame({ anchor: "left" }),
        Plot.gridY(
          averages.map((d) => d.name),
          {
            stroke: "black",
            strokeWidth: 0.75,
            strokeDasharray: "2,3",
          },
        ),
        Plot.line(timeLines, {
          x: "t",
          y: "name",
          z: "id",
          stroke: "grey",
          filter: (d) => d.t >= minDaysSince,
        }),
        Plot.dot(events, {
          x: "happenedAt",
          y: "name",
          fill: "black",
          r: 5,
          filter: (d) => d.happenedAt >= minDaysSince,
        }),
        Plot.hexagon(completions, {
          x: "completed",
          y: "name",
          fill: "green",
          r: 7,
          filter: (d) => d.completed >= minDaysSince,
        }),
        Plot.tip(
          events,
          Plot.pointer({
            x: "happenedAt",
            y: "name",
            title: (d) => `${Math.round(d.happenedAt)} days since entered`,
            filter: (d) => d.happenedAt >= minDaysSince,
          }),
        ),
        Plot.tickX(averages, {
          x: "average",
          y: "name",
          strokeWidth: 3,
          stroke: "blue",
          strokeDasharray: "3,1",
          filter: (d) => d.average >= minDaysSince,
        }),
      ],
    });
    ref.current.append(plot);
    return () => plot.remove();
  }, [
    events,
    timeLines,
    averages,
    completions,
    minDaysSince,
    maxDaysSince,
    dims,
    names,
    ref,
  ]);

  return (
    <div className="">
      <SectionHeader title="Performance" icon="Performance"></SectionHeader>
      <Card>
        <div className="flex flex-row gap-1 mb-4 justify-end">
          <PillButton
            label="last 7 days"
            active={timeCutoff === 7}
            onClick={() => handleTimeCutoffSelect(7)}
          />
          <PillButton
            label="last 30 days"
            active={timeCutoff === 30}
            onClick={() => handleTimeCutoffSelect(30)}
          />
          <PillButton
            label="last 90 days"
            active={timeCutoff === 90}
            onClick={() => handleTimeCutoffSelect(90)}
          />
        </div>
        <div ref={ref}></div>
        <div className="flex flex-row items-center gap-6 justify-end text-gray-500">
          <div className="flex flex-row items-center">
            <span>Event</span>
            <svg height={20} width={15}>
              <circle r={5} cy={10} cx={10} fill="black"></circle>
            </svg>
          </div>
          <div className="flex flex-row items-center">
            <span>Completed</span>
            <svg height={20} width={20}>
              <path
                d="M1.5 4.5V10.5L7.5 14L13.5 10.5V4.5L7.5 1L1.5 4.5Z"
                stroke="green"
                fill="green"
                transform="translate(5, 2)"
              />
            </svg>
          </div>
          <div className="flex flex-row items-center gap-2">
            <span>Average</span>
            <svg height={20} width={10}>
              <line
                x1={5}
                y1={2}
                x2={5}
                y2={18}
                stroke="blue"
                strokeWidth={3}
                strokeDasharray="3,1"
              ></line>
            </svg>
          </div>
        </div>
      </Card>
    </div>
  );
}
