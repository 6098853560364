import React from "react";

export default function MixPanelLogo() {
  return (
    <svg viewBox="0 0 50 48" width="100%" height="100%" className="opacity-60">
      <path
        fill="#5028c0"
        d="M14.41,19.54h6.31c-1.58-0.99-2.17-2.37-2.96-4.93l-2.37-8.78C14.31,1.88,13.42,0,9.08,0H0.01v2.37h1.29
			c2.66,0,2.96,0.99,3.75,3.95l2.07,7.7C8.2,17.76,9.88,19.54,14.41,19.54L14.41,19.54z M29.01,19.54h6.31c4.54,0,6.11-1.78,7.2-5.52
			l2.07-7.7c0.79-2.96,1.18-3.95,3.75-3.95h1.29V0h-8.98c-4.44,0-5.33,1.78-6.32,5.82l-2.37,8.78
			C31.19,17.26,30.59,18.55,29.01,19.54z M20.72,27.82h8.29v-8.29h-8.29V27.82z M0.01,47.36h9.07c4.34,0,5.23-1.88,6.31-5.82
			l2.37-8.78c0.79-2.56,1.38-3.95,2.96-4.93h-6.31c-4.54,0-6.22,1.78-7.3,5.52l-2.07,7.7C4.24,44,3.95,44.99,1.29,44.99H0L0.01,47.36
			L0.01,47.36z M40.65,47.36h8.98v-2.37h-1.29c-2.56,0-2.96-0.99-3.75-3.95l-2.07-7.7c-1.08-3.75-2.66-5.52-7.2-5.52h-6.3
			c1.58,0.99,2.15,2.27,2.94,4.93l2.37,8.78C35.31,45.58,36.2,47.36,40.65,47.36L40.65,47.36z"
      ></path>
    </svg>
  );
}
