export enum LifecycleStages {
  Onboarding = "Onboarding",
  Activation = "Activation",
  Expansion = "Expansion",
  Retention = "Retention",
}

export type LifecycleStage = keyof typeof LifecycleStages;

export const ORDERED_LIFECYCLE_STAGES = [
  LifecycleStages.Onboarding,
  LifecycleStages.Activation,
  LifecycleStages.Expansion,
  LifecycleStages.Retention,
];
