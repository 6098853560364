import React, { Dispatch, useCallback, useState } from "react";
import Drawer from "../../patterns/Drawer";
import { BehaviorType } from "../../__generated__/graphql";
import AudienceBuilderSection from "./builder_sections/AudienceBuilderSection";
import AudienceDrawer from "./drawer/AudienceDrawer";
import CompletionCriteriaBuilderSection from "./builder_sections/CompletionCriteriaBuilderSection";
import FollowUpActionsBuilderSection from "./builder_sections/FollowUpActionsBuilderSection";
import CompletionCriteriaDrawer from "./drawer/CompletionCriteriaDrawer";
import FollowUpActionsDrawer from "./drawer/FollowUpActionsDrawer";
import EntryActionsBuilderSection from "./builder_sections/EntryActionsBuilderSection";
import EntryActionsDrawer from "./drawer/EntryActionsDrawer";
import { BehaviorUpdate } from "./reducer";

interface Props {
  behavior: BehaviorType;
  dispatch: Dispatch<BehaviorUpdate>;
}

type DrawerComponent = typeof AudienceDrawer | typeof CompletionCriteriaDrawer;

export default function BehaviorForm({ behavior, dispatch }: Props) {
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const [DrawerComponent, setDrawerComponent] = useState<{
    Component: DrawerComponent;
  }>();
  const closeDrawer = useCallback(() => {
    setDrawerIsVisible(false);
  }, [setDrawerIsVisible]);

  if (!behavior) {
    return;
  }

  return (
    <>
      {drawerIsVisible && (
        <Drawer close={closeDrawer}>
          <DrawerComponent.Component
            dispatch={dispatch}
            behavior={behavior}
            close={closeDrawer}
          />
        </Drawer>
      )}

      <div className="flex grow flex-col mx-8 mb-32 gap-y-6">
        <div>
          <div className="bg-white rounded-lg">
            <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
              <AudienceBuilderSection
                behavior={behavior}
                openDrawer={() => {
                  setDrawerIsVisible(true);
                  setDrawerComponent({
                    Component: AudienceDrawer,
                  });
                }}
              />
            </div>
            <div className="px-6 pt-6 pb-4 border-b-1 border-rule-color border-dashed">
              <CompletionCriteriaBuilderSection
                behavior={behavior}
                openDrawer={() => {
                  setDrawerIsVisible(true);
                  setDrawerComponent({
                    Component: CompletionCriteriaDrawer,
                  });
                }}
              />
            </div>
            <div className="px-6 pt-6 pb-4">
              <EntryActionsBuilderSection
                behavior={behavior}
                actionList={behavior.actionsOnEnter}
                openDrawer={() => {
                  setDrawerIsVisible(true);
                  setDrawerComponent({
                    Component: EntryActionsDrawer,
                  });
                }}
              />
            </div>
            <div className="px-6 pt-6 pb-4">
              <FollowUpActionsBuilderSection
                behavior={behavior}
                actionList={behavior.actionsOnCompletion}
                openDrawer={() => {
                  setDrawerIsVisible(true);
                  setDrawerComponent({
                    Component: FollowUpActionsDrawer,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
