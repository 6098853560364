import { useMemo } from "react";
import {
  OrgDetailCampaign,
  UserDetailCampaign,
} from "../__generated__/graphql";

interface FinishedCampaign {
  campaignName: string;
  campaignId: string;
  date: any;
  completed: boolean;
}

export default function useFinishedCampaigns(
  completedCampaigns: UserDetailCampaign[] | OrgDetailCampaign[],
  exitedCampaigns: UserDetailCampaign[] | OrgDetailCampaign[],
) {
  return useMemo<FinishedCampaign[]>(() => {
    const result: FinishedCampaign[] = [
      ...completedCampaigns.map((c) => ({
        campaignName: c.campaignName,
        campaignId: c.campaignId,
        date: c.date,
        completed: true,
      })),
      ...exitedCampaigns.map((c) => ({
        campaignName: c.campaignName,
        campaignId: c.campaignId,
        date: c.date,
        completed: false,
      })),
    ];
    result.sort((a, b) => {
      return new Date(a.date).valueOf() - new Date(b.date).valueOf();
    });
    return result;
  }, [completedCampaigns, exitedCampaigns]);
}
