import { ActionList, Actions } from "../types/BackendTypes";

type ElementOf<T> = T extends Array<infer U> ? U : never;
export type Action = ElementOf<Actions>;

export function updatedActionList(actionList: ActionList, newAction: Action) {
  const newList = actionList.actions.filter((a) => a.id !== newAction.id);
  newList.push(newAction);
  return newList;
}

export function removeActionOfType(
  actionList: ActionList,
  type: Action["type"],
) {
  return actionList.actions.filter((a) => a.type !== type);
}
