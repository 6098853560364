import { useLazyQuery, NetworkStatus } from "@apollo/client";
import { ReferralType, PersonType } from "../__generated__/graphql";
import { formatLong } from "../lib/date";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { GET_REFERRAL_MEMBERS } from "../graphql/queries";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import SearchInput from "../patterns/forms/SearchInput";
import Table, { TableProps } from "../patterns/tables/table";
import SecondaryButton from "../patterns/atoms/SecondaryButton";
import { downloadFile } from "../lib/file";

interface Props {
  referral: ReferralType;
}

interface ReferralNode {
  id: string;
  referredAt: string;
  referrer: {
    name: string;
    email: string;
  };
  referee: {
    name: string;
    email: string;
  };
  member: PersonType;
}

interface CellData {
  content: string;
}

const HEADERS = [
  { label: "Referred On" },
  { label: "Referrer Name" },
  { label: "Referrer Email" },
  { label: "Referee Name" },
  { label: "Referee Email" },
];

const CELLS = (n: ReferralNode): CellData[] => [
  { content: formatLong(n.referredAt) },
  { content: n.referrer.name },
  { content: n.referrer.email },
  { content: n.referee.name },
  { content: n.referee.email },
];

const PAGE_SIZE = 50;
const SEARCH_DEBOUNCE_TIMEOUT = 1000;

function toTable(data: any): Omit<TableProps, "filterBar"> {
  return {
    header: HEADERS,
    rows: data.edges.map((p) => {
      return {
        id: p.node.id,
        cells: CELLS(p.node),
        onClick: () => {},
      };
    }),
  };
}

export const MemberTable = ({ referral }: Props) => {
  const [isPaginating, setIsPaginating] = useState(false);
  const [filter, setFilter] = useState("");

  const [
    getReferralMembers,
    { error, data, fetchMore, previousData, networkStatus, refetch },
  ] = useLazyQuery(GET_REFERRAL_MEMBERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  // We don't want to debounce the initial fetch
  useEffect(() => {
    getReferralMembers({
      variables: { referralId: referral.id, filter },
    });
  }, []);

  const debouncedRefetch = useCallback(
    debounce(refetch, SEARCH_DEBOUNCE_TIMEOUT),
    [refetch],
  );
  useEffect(() => {
    debouncedRefetch({ referralId: referral.id, filter });
  }, [referral, filter, debouncedRefetch]);

  const loadNext = useCallback(() => {
    setIsPaginating(true);
    try {
      fetchMore({
        variables: {
          cursor:
            currentData.referralMembers.edges[
              currentData.referralMembers.edges.length - 1
            ].node.id,
          referralId: referral.id,
          filter,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [referral, filter, data, fetchMore]);

  const nextPageTrigger = useInfiniteScroll(loadNext);

  if (!previousData && !data) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <FullScreenError />;
  }

  const currentData = data || previousData;

  const loading = networkStatus === NetworkStatus.setVariables;
  const hasMore =
    currentData.referralMembers.total >
    currentData.referralMembers.edges.length;

  const tableData = toTable(currentData.referralMembers);

  return (
    <>
      <div className="flex flex-col items-end mx-8 mb-4">
        <SecondaryButton
          label="Download CSV"
          onClick={() => {
            downloadFile(
              `/reporting/download/membership?object_id=${referral.id}`,
              referral.name,
            );
          }}
        />
      </div>
      <Table
        header={tableData.header}
        rows={tableData.rows}
        isLoading={loading}
        filterBar={
          <div className="w-72">
            <SearchInput
              placeholder="Search referrals..."
              value={filter}
              onChange={setFilter}
            />
          </div>
        }
      />
      {hasMore && <div ref={nextPageTrigger}></div>}
      {isPaginating ? <></> : <div className="w-full min-h-96"></div>}
    </>
  );
};
