import { Direction, Unit } from "../../types/BackendTypes";
import { ValueComponentProps } from "../lib";

export default function RelativeDateInput({
  value,
  disabled = false,
  onChange,
}: ValueComponentProps) {
  return (
    <>
      <input
        type="number"
        disabled={disabled}
        className="mb-2 w-14 border-none bg-grey-50 text-xs p-4 px-2 rounded-md focus:ring-2 focus:ring-blue-300 focus:ring-inset"
        value={value.value ?? ""}
        onChange={(e) => {
          const newValue = { ...value };
          newValue.value = parseInt(e.target.value);
          onChange(newValue);
        }}
        autoFocus={true}
      />
      <select
        className="mb-2 border-none bg-grey-50 text-xs p-4 pl-2 pr-7 rounded-md focus:ring-2 focus:ring-blue-300 focus:ring-inset"
        value={value.unit}
        onChange={(e) => {
          const newValue = { ...value };
          newValue.unit = e.target.value as Unit;
          onChange(newValue);
        }}
      >
        <option value="day">{value.value === 1 ? "day" : "days"}</option>
        <option value="week">{value.value === 1 ? "week" : "weeks"}</option>
        <option value="month">{value.value === 1 ? "month" : "months"}</option>
      </select>
      <select
        className="mb-2 border-none bg-slate-5 text-sm p-4 pl-2 pr-7 rounded-md focus:ring-2 focus:ring-blue-300 focus:ring-inset"
        value={value.direction}
        onChange={(e) => {
          const newValue = { ...value };
          newValue.direction = e.target.value as Direction;
          onChange(newValue);
        }}
      >
        <option value="past">ago</option>
        <option value="future">from now</option>
      </select>
    </>
  );
}
