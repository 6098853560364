import { formatRelative } from "date-fns";
import {
  EventIntegrationTaskType,
  EventsIntegrationType,
  IntervalType,
} from "../__generated__/graphql";
import Toggle from "../patterns/Toggle";
import { useMutation } from "@apollo/client";
import {
  DISABLE_EVENT_INTEGRATION_TASK,
  ENABLE_EVENT_INTEGRATION_TASK,
} from "../graphql/mutations";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";

interface Props {
  integration: EventsIntegrationType;
}

function printInterval(interval: IntervalType) {
  return `every ${interval.every} ${interval.period.toLowerCase()}`;
}

export default function IntegrationTasks({ integration }: Props) {
  const [enableMutation] = useMutation(ENABLE_EVENT_INTEGRATION_TASK);
  const [disableMutation] = useMutation(DISABLE_EVENT_INTEGRATION_TASK);
  const [eventTaskEnabled, setEventTaskEnabled] = useState(
    integration.refreshTask.enabled,
  );
  const [personTaskEnabled, setPersonTaskEnabled] = useState(
    integration.personRefreshTask.enabled,
  );

  const enableTask = useCallback(
    async (taskType: EventIntegrationTaskType) => {
      const setter =
        taskType === EventIntegrationTaskType.Event
          ? setEventTaskEnabled
          : setPersonTaskEnabled;
      try {
        setter(true);
        await enableMutation({
          variables: { integrationId: integration.id, taskType },
        });
        toast.success("Enabled");
      } catch (e) {
        setter(false);
        toast.error(e.message);
      }
    },
    [integration, enableMutation],
  );

  const disableTask = useCallback(
    async (taskType: EventIntegrationTaskType) => {
      const setter =
        taskType === EventIntegrationTaskType.Event
          ? setEventTaskEnabled
          : setPersonTaskEnabled;
      try {
        setter(false);
        await disableMutation({
          variables: { integrationId: integration.id, taskType },
        });
        toast.success("Disabled");
      } catch (e) {
        setter(true);
        toast.error(e.message);
      }
    },
    [integration, disableMutation],
  );
  return (
    <div className="mt-6">
      <h2>Sync Status</h2>
      <div className="flex mt-2">
        <div className="grow">
          <div>Load events from mixpanel</div>
          <div className="text-xs">
            Runs {printInterval(integration.refreshTask.interval)}.{" "}
            {integration.refreshTask?.lastRunAt
              ? `Last run ${formatRelative(integration.refreshTask?.lastRunAt, new Date())}`
              : "Not run yet"}
          </div>
        </div>
        <Toggle
          isOn={eventTaskEnabled}
          onChange={(isOn) => {
            isOn
              ? enableTask(EventIntegrationTaskType.Event)
              : disableTask(EventIntegrationTaskType.Event);
          }}
        />
      </div>
      <div className="flex mt-2">
        <div className="grow">
          <div>Load users from mixpanel</div>
          <div className="text-xs">
            Runs {printInterval(integration.personRefreshTask.interval)}.{" "}
            {integration.personRefreshTask?.lastRunAt
              ? `Last run ${formatRelative(integration.personRefreshTask?.lastRunAt, new Date())}`
              : "Not run yet"}
          </div>
        </div>
        <Toggle
          isOn={personTaskEnabled}
          onChange={(isOn) => {
            isOn
              ? enableTask(EventIntegrationTaskType.Person)
              : disableTask(EventIntegrationTaskType.Person);
          }}
        />
      </div>
    </div>
  );
}
