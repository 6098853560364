import TopNav from "../patterns/TopNav";
import SettingsIcon from "../patterns/symbols/IntegrationsIcon";

export default function SettingsMain() {
  const topNavOptions = {
    title: "Settings",
    breadcrumb: <SettingsIcon strokeColor={"white"} strokeWidth={1.5} />,
  };

  return (
    <div>
      <TopNav {...topNavOptions} />
    </div>
  );
}
