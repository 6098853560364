import React from "react";
import { useMatch } from "react-router-dom";
import MainLayout from "./MainLayout";
import Onboarding from "./Onboarding";

export default function Layout() {
  const isOnboarding = useMatch("/onboarding");
  if (isOnboarding) {
    return <Onboarding />;
  }
  return <MainLayout />;
}
