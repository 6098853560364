import TextInput from "../../patterns/forms/TextInput";
import { PavlovProps } from "../../types/BackendTypes";

interface Props {
  config: PavlovProps;
  setConfig: (s: PavlovProps) => void;
}

export default function PavlovConfig({ config, setConfig }: Props) {
  return (
    <>
      <TextInput
        placeholder="company.pavlov-app.com"
        label="Domain"
        value={config.domain || ""}
        required={true}
        onChange={(s) => {
          setConfig({ ...config, domain: s });
        }}
      />
      <TextInput
        autoComplete="new-password"
        type="password"
        placeholder="api key"
        label="API key"
        value={config.api_key || ""}
        required={true}
        onChange={(s) => {
          setConfig({ ...config, api_key: s });
        }}
      />
      <TextInput
        autoComplete="new-password"
        type="password"
        placeholder="Webhook key"
        label="Webhook key"
        value={config.webhook_key || ""}
        required={true}
        onChange={(s) => {
          setConfig({ ...config, webhook_key: s });
        }}
      />
    </>
  );
}
