import FilterBuilder from "../../../filter_builder";
import PageHeader from "../../../patterns/PageHeader";
import Card from "../../../patterns/Card";
import {
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../../../__generated__/graphql";
import DrawerComponentProps from "./drawer-component-props";
import useFilteredAudience from "../../../hooks/useFilteredAudience";
import AudienceTargeting from "../../../campaign_form/drawer/AudienceTargeting";

export default function AudienceDrawer({
  dispatch,
  behavior,
}: DrawerComponentProps) {
  const targetingOrgs =
    behavior.targetMemberType === TargetMemberTypeChoices.Org;

  const entityName = targetingOrgs ? "organization" : "person";

  const isEditable = behavior.status !== MachineStatusChoices.Complete;

  const { audience, loading: audienceLoading } = useFilteredAudience(
    behavior.audience.criteria[0].filter,
    behavior.targetMemberType,
  );

  return (
    <div>
      <PageHeader
        header="Audience Targeting"
        subhead={`${targetingOrgs ? "Which organizations are" : "Who is"} being observed for this behavior?`}
      />
      <div className="px-8">
        <Card>
          <FilterBuilder
            readonly={!isEditable}
            filterDoc={behavior.audience.criteria[0].filter}
            showOrgAttrs={true}
            showPersonAttrs={!targetingOrgs}
            showEvents={true}
            onChange={(d) => {
              dispatch({
                type: "UpdateAudience",
                data: d,
              });
            }}
          />
          <div className="mt-2">
            <AudienceTargeting
              filter={behavior.audience.criteria[0].filter}
              audience={audience}
              loading={audienceLoading}
              objTargetsOrgs={targetingOrgs}
              entityName={entityName}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
