import { useMutation, useQuery } from "@apollo/client";
import Modal from "../patterns/Modal";
import { GET_USER_PROFILE } from "../graphql/queries";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import TextInput from "../patterns/forms/TextInput";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import SectionHeader from "../patterns/SectionHeader";
import { UPDATE_PASSWORD, UPDATE_USER_NAME } from "../graphql/mutations";
import toast from "react-hot-toast";
import { SessionContext } from "../SessionContext";
import Alert from "../patterns/Alert";

interface Props {
  closeModal: () => void;
}

export default function UserProfile({ closeModal }: Props) {
  const sessionContext = useContext(SessionContext);
  const { data, loading } = useQuery(GET_USER_PROFILE);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [editingUserName, setEditingUserName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef<HTMLFormElement>();
  const usernameFormRef = useRef<HTMLFormElement>();
  const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD);
  const [updateUserNameMutation] = useMutation(UPDATE_USER_NAME);

  const canChangePassword =
    !sessionContext.session.settings.passwordAuthDisabled;

  useEffect(() => {
    setEditingUserName(data.userProfile.name);
  }, [data]);

  const updatePassword = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (newPasswordConfirm !== newPassword) {
        toast.error("Password confirmation does not match.");
        return;
      }
      setIsSaving(true);
      try {
        const result = await updatePasswordMutation({
          variables: { oldPassword, newPassword },
        });
        if (result.data.updatePassword.ok) {
          toast.success("password updated");
          closeModal();
        } else {
          toast.error(result.data.updatePassword.message);
        }
      } finally {
        setIsSaving(false);
      }
    },
    [
      closeModal,
      newPassword,
      newPasswordConfirm,
      oldPassword,
      updatePasswordMutation,
    ],
  );

  const updateUsername = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setIsSaving(true);
      try {
        await updateUserNameMutation({
          variables: { newName: editingUserName },
        });
        toast.success("Name updated");
      } finally {
        setIsSaving(false);
      }
    },
    [editingUserName, updateUserNameMutation],
  );

  if (loading) {
    return <></>;
  }
  return (
    <Modal close={closeModal} title="User profile">
      <div className="space-y-12">
        <div>
          <SectionHeader title="Personal Info" />

          <form
            ref={usernameFormRef}
            className=""
            action="#"
            onSubmit={updateUsername}
          >
            <TextInput
              type="text"
              label="Name"
              value={editingUserName}
              onChange={setEditingUserName}
              placeholder="Your name"
              required={true}
            />
            <PrimaryButton
              label="Save name"
              isDisabled={isSaving}
              onClick={() => usernameFormRef.current.requestSubmit()}
            />
          </form>
        </div>
        <div>
          <SectionHeader title="Password" />
          <div className="py-2">
            <form
              ref={formRef}
              className=""
              action="#"
              onSubmit={updatePassword}
            >
              {!canChangePassword && (
                <div className="mb-4">
                  <Alert
                    type="warning"
                    message="Your company has disabled password authentication"
                  />
                </div>
              )}
              <TextInput
                type="password"
                label="Current password"
                value={oldPassword}
                onChange={setOldPassword}
                placeholder="current password"
                required={true}
                disabled={!canChangePassword}
              />
              <TextInput
                type="password"
                label="New password"
                value={newPassword}
                onChange={setNewPassword}
                placeholder="new password"
                required={true}
                disabled={!canChangePassword}
              />
              <TextInput
                type="password"
                label="Confirm new password"
                value={newPasswordConfirm}
                onChange={setNewPasswordConfirm}
                placeholder="confirm new password"
                required={true}
                disabled={!canChangePassword}
              />
              <PrimaryButton
                label="Change password"
                isDisabled={isSaving || !canChangePassword}
                onClick={() => formRef.current.requestSubmit()}
              />
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
