import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import useLocalStorageState from "../hooks/useLocalStorageState";
import EmptyState from "../patterns/EmptyState";
import PageHeader from "../patterns/PageHeader";
import SkeletonScreen from "../patterns/Skeleton";
import { GET_BEHAVIORS } from "../graphql/queries";
import BehaviorCard from "../behavior/BehaviorCard";
import BehaviorSummary from "../behavior/BehaviorSummary";
import Drawer from "../patterns/Drawer";
import toast from "react-hot-toast";
import Card from "../patterns/Card";
import CardMenu from "../patterns/CardMenu";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import BehaviorsEmpty from "../patterns/illustrations/BehaviorsEmpty";
import FullScreenError from "../patterns/Errors";
import NewBehavior from "./NewBehavior";

function SkeletonBehavior({ onClick }: { onClick?: () => void }) {
  return (
    <div className="flex flex-wrap" onClick={onClick}>
      <CardMenu>
        <Card>
          <div className="flex flex-col items-center justify-center h-full text-body-text hover:text-active-blue animate">
            <div className="h-36 pb-4">
              <BehaviorsEmpty />
            </div>
            <div className="text-medium text-sm">Add a Behavior</div>
          </div>
        </Card>
      </CardMenu>
    </div>
  );
}

export default function BehaviorsMain() {
  const [modalIsVisible, setModalVisible] = useState(false);
  const { data, loading, error } = useQuery(GET_BEHAVIORS);
  const [pinnedBehaviorIds, setPinnedBehaviorIds] = useLocalStorageState(
    [],
    "pinnedBehaviors",
  );
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);

  // Remove pinned behaviors that no longer exist
  // Note the empty dependency array, which ensures this only runs once at component mount time.
  useEffect(() => {
    if (data?.allBehaviors) {
      setPinnedBehaviorIds(
        pinnedBehaviorIds.filter((id) =>
          data.allBehaviors
            .filter((behavior) => !behavior.deleted)
            .map((b) => b.id)
            .includes(id),
        ),
      );
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <FullScreenError />;
  }

  const behaviors = data.allBehaviors
    .filter((behavior) => !behavior.deleted)
    .sort((a, b) => (a.id < b.id ? -1 : 1));
  const pinnedBehaviors = behaviors.filter((behavior) =>
    pinnedBehaviorIds.includes(behavior.id),
  );

  return (
    <div>
      {behaviors.length === 0 ? (
        <div className="bg-slate-5 p-8 mx-8 mt-8 rounded-xl">
          <EmptyState
            title="What are your customers doing?"
            icon={<AttributesEmpty />}
            description={
              "By setting up tracked behaviours , you’ll build insights over time around what actions your customers are taking"
            }
            buttonLabel={"Add a behavior"}
            onClick={() => setModalVisible(true)}
          />
        </div>
      ) : (
        <>
          <PageHeader
            header="Behaviors"
            subhead={`${behaviors.length} total`}
            buttons={[
              {
                type: "icon",
                label: "",
                icon: "cog",
                action: () => setDrawerIsVisible(true),
              },
              {
                type: "behavior",
                label: "Add a behavior",
                action: () => setModalVisible(true),
              },
            ]}
          />
          <div className="px-8 grid grid-cols-3 gap-4 w-full">
            {pinnedBehaviors.map((behavior) => (
              <BehaviorCard key={behavior.id} behavior={behavior} />
            ))}
            {pinnedBehaviors.length <= 1 && (
              <SkeletonBehavior onClick={() => setDrawerIsVisible(true)} />
            )}
          </div>
        </>
      )}
      {modalIsVisible && (
        <NewBehavior closeModal={() => setModalVisible(false)} />
      )}
      {drawerIsVisible && (
        <Drawer size="md" close={() => setDrawerIsVisible(false)}>
          <PageHeader
            header="Behaviors"
            subhead="Display up to 6 behaviors on your dashboard"
          />
          <div className="flex flex-col px-8 gap-2">
            {behaviors.map((behavior) => (
              <BehaviorSummary
                behavior={behavior}
                pinned={pinnedBehaviorIds.includes(behavior.id)}
                onClick={(id) => {
                  if (pinnedBehaviorIds.includes(id)) {
                    setPinnedBehaviorIds(
                      pinnedBehaviorIds.filter((b) => b !== id),
                    );
                  } else {
                    if (pinnedBehaviorIds.length < 6) {
                      setPinnedBehaviorIds([...pinnedBehaviorIds, id]);
                    } else {
                      toast.error("You can only display 6 behaviors");
                    }
                  }
                }}
              />
            ))}
          </div>
        </Drawer>
      )}
    </div>
  );
}
