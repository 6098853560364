import { useLazyQuery } from "@apollo/client";
import { MachineCampaignType, PersonType } from "../__generated__/graphql";
import { CampaignDefinition } from "../types/BackendTypes";
import {
  GET_CAMPAIGN_MESSAGE_STATS,
  GET_PEOPLE_FOR_MESSAGE_EVENT,
} from "../graphql/queries";
import SelectInput from "../patterns/forms/SelectInput";
import { useCallback, useEffect, useMemo, useState } from "react";
import { compact } from "lodash";
import Table from "../patterns/tables/table";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { cx } from "../lib/cx";
import SkeletonScreen from "../patterns/Skeleton";
import EmptyState from "../patterns/EmptyState";
import CampaignEmpty from "../patterns/illustrations/CampaignEmpty";
import StatsHeading from "../patterns/StatsHeading";
import { iconMap } from "../patterns/StatsHeading";
import { formatPercentage } from "../lib/number";

export interface Props {
  campaign: MachineCampaignType;
  campaignDefinition: CampaignDefinition;
  showMember: (m: PersonType) => void;
}
function StatCell({
  title,
  value,
  footer,
  isActive,
  onClick,
}: {
  title: string;
  value: number;
  footer: string;
  isActive: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={cx(
        "flex grow flex-col rounded-lg p-4 shadow-sm cursor-pointer",
        isActive ? "bg-white" : "bg-slate-5",
      )}
      onClick={onClick}
    >
      <StatsHeading
        title={title}
        stat={value.toLocaleString()}
        subtitle={footer}
        icon={title as keyof typeof iconMap} // Type assertion
      />
    </div>
  );
}

function toTable(people, showMember) {
  return {
    header: [{ label: "Name" }, { label: "Email" }],
    rows: people.map((p) => {
      return {
        id: p.node.id,
        onClick: (e: React.MouseEvent, id: string) => {
          showMember(p.node);
          e.stopPropagation();
        },
        cells: [{ content: p.node.name }, { content: p.node.email }],
      };
    }),
  };
}

const PAGE_SIZE = 10;

export default function MessageStats({
  campaign,
  campaignDefinition,
  showMember,
}: Props) {
  const [getStats, { data }] = useLazyQuery(GET_CAMPAIGN_MESSAGE_STATS);
  const [
    getPeople,
    { data: peopleData, loading: peopleLoading, fetchMore: fetchMorePeople },
  ] = useLazyQuery(GET_PEOPLE_FOR_MESSAGE_EVENT, {
    variables: { campaignId: campaign.id, eventName: "delivered" },
  });
  const [actionFilter, setActionFilter] = useState("all");
  const [selectedEvent, setSelectedEvent] = useState("delivered");
  const [isPaginating, setIsPaginating] = useState(false);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getStats({
      variables: {
        campaignId: campaign.id,
        actionId: actionFilter === "all" ? undefined : actionFilter,
      },
      fetchPolicy: "network-only",
    });
  }, [actionFilter]);

  useEffect(() => {
    getPeople({
      variables: {
        eventName: selectedEvent,
        campaignId: campaign.id,
        actionId: actionFilter === "all" ? undefined : actionFilter,
        limit: PAGE_SIZE,
      },
      fetchPolicy: "network-only",
    });
  }, [actionFilter, selectedEvent]);

  const loadNext = useCallback(async () => {
    const hasMore =
      peopleData.peopleForMessageEvent.total >
      peopleData.peopleForMessageEvent.edges.length;
    if (!hasMore) {
      return;
    }
    setIsPaginating(true);
    try {
      await fetchMorePeople({
        variables: {
          eventName: selectedEvent,
          campaignId: campaign.id,
          actionId: actionFilter === "all" ? undefined : actionFilter,
          cursor:
            peopleData &&
            peopleData.peopleForMessageEvent.edges[
              peopleData.peopleForMessageEvent.edges.length - 1
            ].node.id,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [data, peopleData, fetchMorePeople, selectedEvent, actionFilter]);

  const nextPageTrigger = useInfiniteScroll(loadNext);

  const options = useMemo(() => {
    const beforeMessage = campaignDefinition.before_actions.actions.find(
      (a) => a.type === "email",
    );
    const delayedMessage = campaignDefinition.before_actions.actions.find(
      (a) => a.type === "delayed_email",
    );
    const afterMessage = campaignDefinition.after_actions.actions.find(
      (a) => a.type === "email",
    ) as any;
    return compact([
      { label: "All messages", value: "all" },
      beforeMessage && { label: "Entry message", value: beforeMessage.id },
      delayedMessage && {
        label: "Re-engagement message",
        value: delayedMessage.id,
      },
      afterMessage && { label: "Followup message", value: afterMessage.id },
    ]);
  }, [campaignDefinition]);

  if (!data) {
    return <SkeletonScreen />;
  }
  const tableData = toTable(
    peopleData?.peopleForMessageEvent?.edges || [],
    showMember,
  );
  const hasMore =
    peopleData &&
    peopleData.peopleForMessageEvent.total >
      peopleData.peopleForMessageEvent.edges.length;
  return (
    <>
      <div className="mx-8 w-48">
        <SelectInput
          placeholder="Filter stats by message"
          options={options}
          value={actionFilter}
          required={false}
          onChange={(a) => setActionFilter(a)}
        />
      </div>
      <div className="mx-8 mb-8 flex gap-2">
        <StatCell
          title="Delivered"
          value={data.campaignMessageStats.delivered}
          footer={formatPercentage(
            data.campaignMessageStats.delivered,
            data.campaignMessageStats.sent,
          )}
          onClick={() => setSelectedEvent("delivered")}
          isActive={selectedEvent === "delivered"}
        />
        <StatCell
          title="Opened"
          value={data.campaignMessageStats.open}
          footer={formatPercentage(
            data.campaignMessageStats.open,
            data.campaignMessageStats.sent,
          )}
          onClick={() => setSelectedEvent("open")}
          isActive={selectedEvent === "open"}
        />
        <StatCell
          title="Clicked"
          value={data.campaignMessageStats.click}
          footer={formatPercentage(
            data.campaignMessageStats.click,
            data.campaignMessageStats.sent,
          )}
          onClick={() => setSelectedEvent("click")}
          isActive={selectedEvent === "click"}
        />
        <StatCell
          title="Bounced"
          value={data.campaignMessageStats.bounce}
          footer={formatPercentage(
            data.campaignMessageStats.bounce,
            data.campaignMessageStats.sent,
          )}
          onClick={() => setSelectedEvent("bounce")}
          isActive={selectedEvent === "bounce"}
        />
        <StatCell
          title="Unsubscribed"
          value={data.campaignMessageStats.unsubscribe}
          footer={formatPercentage(
            data.campaignMessageStats.unsubscribe,
            data.campaignMessageStats.sent,
          )}
          onClick={() => setSelectedEvent("unsubscribe")}
          isActive={selectedEvent === "unsubscribe"}
        />
      </div>
      {tableData.rows.length !== 0 && !peopleLoading && (
        <>
          <Table header={tableData.header} rows={tableData.rows} />
          {hasMore && <div ref={nextPageTrigger}></div>}
        </>
      )}

      {tableData.rows.length === 0 && !peopleLoading && (
        <div className="m-8">
          <EmptyState
            title="No data"
            description="Nobody found"
            icon={<CampaignEmpty />}
            fullHeight={false}
          />
        </div>
      )}
      {peopleLoading || isPaginating ? (
        <SkeletonScreen />
      ) : (
        <div className="w-full min-h-96"></div>
      )}
    </>
  );
}
