export default function Bold() {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.08331 0.166748C0.669099 0.166748 0.333313 0.502534 0.333313 0.916748V5V5.00008V9.08333C0.333313 9.49755 0.669099 9.83333 1.08331 9.83333H5.16665C5.90704 9.83333 6.61711 9.53921 7.14065 9.01567C7.66419 8.49213 7.95831 7.78206 7.95831 7.04167C7.95831 6.30127 7.66419 5.5912 7.14065 5.06766C7.01615 4.94316 6.8811 4.83163 6.7376 4.73395C7.14789 4.23617 7.37498 3.609 7.37498 2.95841C7.37498 2.21802 7.08086 1.50795 6.55732 0.984408C6.03378 0.460869 5.32371 0.166748 4.58331 0.166748H1.08331ZM4.59784 4.25H4.58331C4.57958 4.25 4.57586 4.25003 4.57214 4.25008H1.83331V1.66675H4.58331C4.92588 1.66675 5.25442 1.80283 5.49666 2.04507C5.73889 2.2873 5.87498 2.61584 5.87498 2.95841C5.87498 3.30099 5.73889 3.62953 5.49666 3.87176C5.25785 4.11057 4.93516 4.24621 4.59784 4.25ZM4.60466 5.75H5.16665C5.50922 5.75 5.83776 5.88609 6.07999 6.12832C6.32223 6.37055 6.45831 6.6991 6.45831 7.04167C6.45831 7.38424 6.32223 7.71278 6.07999 7.95501C5.83776 8.19725 5.50922 8.33333 5.16665 8.33333H1.83331V5.75008H4.58331C4.59043 5.75008 4.59755 5.75005 4.60466 5.75Z"
        fill="#5F5F5F"
      />
    </svg>
  );
}
