import {
  TagIcon,
  UserGroupIcon,
  PlayIcon,
  CursorArrowRaysIcon,
} from "@heroicons/react/24/solid"; // Import all icons

import { CheckCircleIcon } from "@heroicons/react/24/outline"; // Import all outlined icons

interface Props {
  title: string;
  description?: string;
  icon?: keyof typeof iconMap; // icon is optional and refers to a key in iconMap
}

export const iconMap = {
  "Personal Attributes": TagIcon,
  "Other members": UserGroupIcon,
  "Active Campaigns": PlayIcon,
  "Finished Campaigns": CheckCircleIcon,
  "Events Summary": CursorArrowRaysIcon,
  Performance: CursorArrowRaysIcon,
};

export default function SectionHeader({ title, description, icon }: Props) {
  // const IconComponent = icon && iconMap[icon];

  return (
    <div className="flex flex-row gap-x-2">
      {/* {IconComponent && (
        <div className="flex h-4 items-center">
          <IconComponent className="inline-block w-3.5 text-body-text" />
        </div>
      )} */}

      <div className="flex flex-col">
        <div
          className={`text-xs font-semibold text-body-text ${!description ? "mb-3" : ""}`}
        >
          {title}
        </div>
        {description && (
          <div className="text-xs font-normal tracking-wide text-body-text-lighter mb-3">
            {description}
          </div>
        )}
      </div>
    </div>
  );
}
