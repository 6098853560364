import React, { Dispatch, useState } from "react";
import Card from "../patterns/Card";
import { CampaignDefinitionEditAction } from "../campaign_form/reducer";
import { BehaviorUpdate } from "../behavior/behavior_form/reducer";
import { UpdateCrmAction } from "../types/BackendTypes";
import { ReferralUpdate } from "../referral/referral_form/reducer";
import TextInput from "../patterns/forms/TextInput";
import { uuidv7 } from "@kripod/uuidv7";
import IconButton from "../patterns/atoms/IconButton";
import { ActionListNames } from "./action_list_names";

interface UpdateEditorProps {
  dispatch: Dispatch<
    CampaignDefinitionEditAction | BehaviorUpdate | ReferralUpdate
  >;
  action: UpdateCrmAction;
  update?: {
    id: string;
    field: string;
    value: string | number | boolean;
  };
  actionSource: ActionListNames;
}

function UpdateEditor({
  dispatch,
  action,
  update,
  actionSource,
}: UpdateEditorProps) {
  const [updateId, setUpdateId] = useState(update?.id || uuidv7());
  const [updateField, setUpdateField] = useState(update?.field || "");
  const [updateValue, setUpdateValue] = useState(update?.value || "");

  const canSave =
    updateField &&
    updateValue &&
    (updateField !== update?.field || updateValue !== update?.value);
  const canRemove = updateField || updateValue;

  return (
    <div className="flex flex-row gap-2 items-center">
      <TextInput
        label=""
        value={updateField}
        placeholder="Field name"
        required={true}
        onChange={(value) => setUpdateField(value)}
      />
      <TextInput
        label=""
        value={`${updateValue}`}
        placeholder="Value"
        required={true}
        onChange={(value) => setUpdateValue(value)}
      />
      <div className="pb-4">
        <IconButton
          icon="check"
          variant="confirm"
          isDisabled={!canSave}
          onClick={() => {
            if (update) {
              const existingUpdateIndex = action.updates.findIndex(
                (h) => h.id === updateId,
              );
              const newUpdates = [...action.updates];
              newUpdates[existingUpdateIndex] = {
                id: updateId,
                field: updateField,
                value: updateValue,
              };
              dispatch({
                type: "UpdateActionOfType",
                actionType: "update_crm",
                actionList: actionSource,
                action: {
                  ...action,
                  updates: [...newUpdates],
                },
              });
            } else {
              dispatch({
                type: "UpdateActionOfType",
                actionType: "update_crm",
                actionList: actionSource,
                action: {
                  ...action,
                  updates: [
                    ...action.updates,
                    {
                      id: updateId,
                      field: updateField,
                      value: updateValue,
                    },
                  ],
                },
              });
              setUpdateId(uuidv7());
              setUpdateField("");
              setUpdateValue("");
            }
          }}
        />
      </div>
      <div className="pb-4">
        <IconButton
          icon="xMark"
          variant="destructive"
          isDisabled={!canRemove}
          onClick={() =>
            dispatch({
              type: "UpdateActionOfType",
              actionType: "update_crm",
              actionList: actionSource,
              action: {
                ...action,
                updates: action.updates.filter((h) => h.id !== updateId),
              },
            })
          }
        />
      </div>
    </div>
  );
}

export default function UpdateCrmActionEditor({
  dispatch,
  action,
  actionSource,
}: {
  dispatch: Dispatch<
    CampaignDefinitionEditAction | BehaviorUpdate | ReferralUpdate
  >;
  action: UpdateCrmAction;
  actionSource: ActionListNames;
}) {
  return (
    <div>
      <Card>
        <label>Updates</label>
        {action.updates.map((update, index) => (
          <UpdateEditor
            key={update.id}
            dispatch={dispatch}
            action={action}
            update={update}
            actionSource={actionSource}
          />
        ))}
        <UpdateEditor
          dispatch={dispatch}
          action={action}
          actionSource={actionSource}
        />
      </Card>
    </div>
  );
}
