import { SuggestionKeyDownProps, SuggestionProps } from "@tiptap/suggestion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useContext,
} from "react";
import { createPortal } from "react-dom";
import { useFloating, autoUpdate } from "@floating-ui/react";
import { cx } from "../../lib/cx";
import AttributePicker from "../../filter_builder/AttributePicker";
import { SessionContext } from "../../SessionContext";

interface MentionListProps extends SuggestionProps {}

interface MentionListActions {
  onKeyDown: (props: SuggestionKeyDownProps) => void;
}

type MentionOptions = {
  id: string;
  name: string;
  emailAddress: string;
  accountOwnerEmailAttribute?: string;
};

const ACCOUNT_OWNER_PLACEHOLDER_ID = "-account-owner";

export const MentionList = forwardRef<MentionListActions, MentionListProps>(
  ({ clientRect, command, query, decorationNode, items }, ref) => {
    const referenceEl = useMemo(
      () => (clientRect ? { getBoundingClientRect: clientRect } : null),
      [clientRect],
    );
    const mentionOptions = useMemo(
      () => [
        {
          id: ACCOUNT_OWNER_PLACEHOLDER_ID,
          name: "Account Owner",
          emailAddress: "None",
          accountOwnerEmailAttribute: "None",
        },
        ...items,
      ],
      [items],
    );
    const [people, setPeople] = useState<MentionOptions[]>(mentionOptions);
    const [choosingAttribute, setChoosingAttribute] = useState(false);
    const session = useContext(SessionContext);

    useEffect(() => {
      const regexp = new RegExp(query, "i");
      const filteredPeople = mentionOptions.filter(
        (p) => regexp.test(p.name) || regexp.test(p.emailAddress),
      );
      setPeople(filteredPeople ?? []);
    }, [query, mentionOptions, setPeople]);

    const handleCommand = (index, attribute = "None") => {
      const selectedPerson = people[index];
      command({
        id: selectedPerson.id,
        label: selectedPerson.name,
        email: selectedPerson.emailAddress,
        accountOwnerEmailAttribute: attribute,
      });
    };

    const [hoverIndex, setHoverIndex] = useState(0);
    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        const { key } = event;

        if (key === "ArrowUp") {
          setHoverIndex((prev) => {
            const beforeIndex = prev - 1;
            return beforeIndex >= 0 ? beforeIndex : 0;
          });
          return true;
        }

        if (key === "ArrowDown") {
          setHoverIndex((prev) => {
            const afterIndex = prev + 1;
            const peopleCount = people.length - 1 ?? 0;
            return afterIndex < peopleCount ? afterIndex : peopleCount;
          });
          return true;
        }

        if (key === "Enter") {
          if (people[hoverIndex].id === ACCOUNT_OWNER_PLACEHOLDER_ID) {
            setChoosingAttribute(true);
          } else {
            handleCommand(hoverIndex);
          }
          return true;
        }

        return false;
      },
    }));

    const { refs, floatingStyles } = useFloating({
      placement: "bottom-start",
      whileElementsMounted: autoUpdate,
    });

    useEffect(() => {
      refs.setReference(decorationNode ?? referenceEl);
    }, [refs, referenceEl, floatingStyles, decorationNode]);

    return createPortal(
      <div
        ref={refs.setFloating}
        className="mentions-container flex flex-col gap-2 bg-white shadow-md p-2 rounded"
        style={{ ...floatingStyles, zIndex: 100000 }}
      >
        {people.map((person, index) =>
          person.id === ACCOUNT_OWNER_PLACEHOLDER_ID ? (
            <div
              className={cx(
                "flex flex-col p-2 rounded cursor-pointer",
                hoverIndex === index ? "bg-slate-10" : "",
              )}
              key={person.id}
              onClick={() => {
                setChoosingAttribute(true);
              }}
            >
              <span className="text-sm">{person.name}</span>
              <span className="pt-2">
                <AttributePicker
                  attributePickerOpen={choosingAttribute}
                  setAttributePickerOpen={(s) => setChoosingAttribute(s)}
                  onPickerOpened={() => {}}
                  openerStyle="root"
                  openerLabel="Choose an attribute"
                  availableAttributes={session.attributes}
                  availableEventNames={[]}
                  onAttributePicked={(attribute: any) => {
                    setChoosingAttribute(false);
                    handleCommand(
                      index,
                      `${attribute.target}.${attribute.source}.${attribute.slug}`,
                    );
                  }}
                />
              </span>
            </div>
          ) : (
            <div
              className={cx(
                "flex flex-col p-2 rounded cursor-pointer",
                hoverIndex === index ? "bg-slate-10" : "",
              )}
              key={person.id}
              onClick={() => handleCommand(index)}
            >
              <span className="text-sm">{person.name}</span>
              <span className="text-xs text-slate-100">
                {person.emailAddress}
              </span>
            </div>
          ),
        )}
      </div>,
      document.body,
    );
  },
);
