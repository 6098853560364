import { ValueComponentProps } from "../lib";

export function DateTimeInput({
  value,
  disabled = false,
  onChange,
}: ValueComponentProps) {
  return (
    <input
      type="datetime-local"
      disabled={disabled}
      className="mb-2 border border-rule-color bg-grey-50 text-xs px-4 py-3 rounded-md focus:ring-2 focus:ring-blue-300 focus:ring-inset min-w-48 max-w-96"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
