import { MachineStatusChoices } from "../__generated__/graphql";

const statusStyles = {
  DRAFT: {
    pillColor: "bg-white",
    pillBorderColor: "border-1 border-grey-300",
    pillTextColor: "text-grey-600",
    pillDotColor: "border-1 border-grey-400",
  },
  LIVE: {
    pillColor: "bg-teal",
    pillBorderColor: "border-0",
    pillTextColor: "text-teal-10",
    pillDotColor: "bg-teal-10",
  },
  PAUSED: {
    pillColor: "bg-teal-10",
    pillBorderColor: "border-0",
    pillTextColor: "text-teal",
    pillDotColor: "border-1 border-teal",
  },
  COMPLETE: {
    pillColor: "bg-purple-10",
    pillBorderColor: "border-0",
    pillTextColor: "text-purple-600",
    pillDotColor: "bg-purple-400",
  },
  ARCHIVED: {
    pillColor: "bg-grey-600",
    pillBorderColor: "border-0",
    pillTextColor: "text-grey-100",
    pillDotColor: "bg-white border-1 border-grey-600",
  },
};

interface Props {
  status: MachineStatusChoices;
}

export default function CampaignStatusPill({ status }: Props) {
  const { pillColor, pillBorderColor, pillTextColor, pillDotColor } =
    statusStyles[status];

  return (
    <div
      className={`my-auto mr-2 inline-block whitespace-nowrap rounded-full px-2 text-[9px] tracking-[0.16em] leading-5 ${pillColor} ${pillBorderColor} ${pillTextColor}`}
    >
      <Dot color={pillDotColor} />
      <span className="ml-1.5">{status}</span>
    </div>
  );
}

function Dot({ color }: { color: string }) {
  return (
    <span
      style={{
        width: 7,
        height: 7,
        display: "inline-block",
        borderRadius: "50%",
      }}
      className={color}
    ></span>
  );
}
