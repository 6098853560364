import React, { Dispatch } from "react";
import TextInput from "../../../patterns/forms/TextInput";
import {
  IncentiveAction,
  WebhookIncentiveCampaign,
} from "../../../types/BackendTypes";
import { CampaignDefinitionEditAction } from "../../reducer";
import SecondaryButton from "../../../patterns/atoms/SecondaryButton";
import clone from "../../../lib/clone";
import IconButton from "../../../patterns/atoms/IconButton";
import Input from "../../../patterns/forms/Input";
import AutoClaimToggle from "./common/auto-claim";

interface Props {
  action: IncentiveAction;
  dispatch: Dispatch<CampaignDefinitionEditAction>;
}

export default function WebhookCampaignConfig({ action, dispatch }: Props) {
  const incentive = action.incentives[0] as WebhookIncentiveCampaign;
  return (
    <>
      <AutoClaimToggle action={action} dispatch={dispatch} />
      <TextInput
        type="url"
        label="Url"
        value={incentive.config.url}
        onChange={(s) =>
          dispatch({
            type: "UpdateAfterAction",
            action: {
              ...action,
              incentives: [
                {
                  ...incentive,
                  config: {
                    ...incentive.config,
                    url: s,
                  },
                },
              ],
            },
          })
        }
        placeholder="https://your-domain.com"
        required={true}
      />
      <div>
        <div className="text-sm">Headers</div>
        {incentive.config.headers.length === 0 ? (
          <div className="my-4 text-xs">No headers</div>
        ) : (
          <div
            className="grid items-center"
            style={{ gridTemplateColumns: "1fr 1fr max-content" }}
          >
            {incentive.config.headers.map((h, i) => (
              <React.Fragment key={i}>
                <Input
                  value={h.name}
                  placeholder="name"
                  onChange={(s) => {
                    const newHeaders = clone(incentive.config.headers);
                    newHeaders[i].name = s;
                    dispatch({
                      type: "UpdateAfterAction",
                      action: {
                        ...action,
                        incentives: [
                          {
                            ...incentive,
                            config: {
                              ...incentive.config,
                              headers: newHeaders,
                            },
                          },
                        ],
                      },
                    });
                  }}
                />
                <Input
                  value={h.value}
                  placeholder="value"
                  onChange={(s) => {
                    const newHeaders = clone(incentive.config.headers);
                    newHeaders[i].value = s;
                    dispatch({
                      type: "UpdateAfterAction",
                      action: {
                        ...action,
                        incentives: [
                          {
                            ...incentive,
                            config: {
                              ...incentive.config,
                              headers: newHeaders,
                            },
                          },
                        ],
                      },
                    });
                  }}
                />
                <IconButton
                  variant="destructive"
                  icon="xMark"
                  onClick={() => {
                    const newHeaders = clone(incentive.config.headers);
                    newHeaders.splice(i, 1);
                    dispatch({
                      type: "UpdateAfterAction",
                      action: {
                        ...action,
                        incentives: [
                          {
                            ...incentive,
                            config: {
                              ...incentive.config,
                              headers: newHeaders,
                            },
                          },
                        ],
                      },
                    });
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        )}
        <div className="mt-2">
          <SecondaryButton
            label="Add header"
            onClick={() => {
              dispatch({
                type: "UpdateAfterAction",
                action: {
                  ...action,
                  incentives: [
                    {
                      ...incentive,
                      config: {
                        ...incentive.config,
                        headers: [
                          ...incentive.config.headers,
                          { name: "", value: "" },
                        ],
                      },
                    },
                  ],
                },
              });
            }}
          />
        </div>
      </div>
    </>
  );
}
