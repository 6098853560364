import { OrgAttribute, PersonAttribute } from "../__generated__/graphql";
import { useCallback, useState } from "react";
import useDocumentClick from "../hooks/useDocumentClick";
import { Attribute } from "./Attribute";

interface Props {
  attrs: PersonAttribute[] | OrgAttribute[];
  entityId: string;
}

export default function AttributeCloud({ attrs, entityId }: Props) {
  const [editingAttributeIndex, setEditingAttributeIndex] = useState(-1);

  const openEditor = useCallback(
    (index: number) => {
      setEditingAttributeIndex(index);
    },
    [setEditingAttributeIndex],
  );

  const closeEditor = useCallback(() => {
    setEditingAttributeIndex(-1);
  }, [setEditingAttributeIndex]);

  useDocumentClick(() => {
    closeEditor();
  });

  return (
    <div className="flex flex-wrap gap-y-1 text-xs leading-6 tracking-wide">
      {attrs.map((attr: PersonAttribute | OrgAttribute, index) => (
        <Attribute
          key={attr.name}
          isEditing={index === editingAttributeIndex}
          openEditor={() => openEditor(index)}
          closeEditor={closeEditor}
          attr={attr}
          entityId={entityId}
        />
      ))}
    </div>
  );
}
