import Card from "../patterns/Card";
import StatsHeading from "../patterns/StatsHeading";

interface Props {
  header: string;
  indicator: string;
  footer: string;
}

export default function HeaderCard({ header, indicator, footer }: Props) {
  return (
    <Card>
      <StatsHeading title={header} stat={indicator} subtitle={footer} />
    </Card>
  );
}
