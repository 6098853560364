export default function EmptyData() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1202 147"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 57L1200.91 56.9999"
        stroke="#3B4B59"
        stroke-width="1"
        stroke-linecap="round"
      />
      <rect
        x="1201"
        y="147"
        width="1199.91"
        height="85"
        transform="rotate(-180 1201 147)"
        fill="url(#paint0_linear_130_2134)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_130_2134"
          x1="1799.14"
          y1="147"
          x2="1799.14"
          y2="232"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ffffff" />
          <stop offset="1" stop-color="#ffffff" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
