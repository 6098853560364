import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_SLACK_CHANNELS } from "../../graphql/queries";
import SelectInput from "../../patterns/forms/SelectInput";
import { SlackProps } from "../../types/BackendTypes";
import SkeletonScreen from "../../patterns/Skeleton";

interface Props {
  props: SlackProps;
  setProps: (s: SlackProps) => void;
}

export default function SlackConfig({ props, setProps }: Props) {
  const { data, loading, error } = useQuery(GET_SLACK_CHANNELS);

  const channelOptions = useMemo(
    () =>
      (data?.slackChannels || []).map((s) => ({
        label: s.name,
        value: s.id,
      })),
    [data],
  );

  return (
    <>
      {!error && loading && <SkeletonScreen />}
      {error && <p>Error loading Slack Channels.</p>}
      {!error && !loading && (
        <SelectInput
          label="Choose a slack channel to post campaign notifications to"
          placeholder="Choose a channel"
          options={channelOptions}
          required={true}
          value={props?.channel_id || null}
          onChange={(s) => {
            setProps({ ...props, channel_id: s });
          }}
        />
      )}
    </>
  );
}
