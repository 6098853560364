import { createContext } from "react";
import { AttributeContexts } from "./__generated__/graphql";

export interface FilterBuilderContextType {
  from: AttributeContexts | undefined;
  showEvents: boolean;
}

export const DEFAULT_FILTER_BUILDER_CONTEXT: FilterBuilderContextType = {
  from: undefined,
  showEvents: true,
};

export const LIFECYCLE_OBJECTIVE_CONTEXT: FilterBuilderContextType = {
  from: AttributeContexts.ObjectiveCampaign,
  showEvents: false,
};

export const getFilterBuilderContext = (context: string) => {
  switch (context) {
    case AttributeContexts.ObjectiveCampaign:
      return LIFECYCLE_OBJECTIVE_CONTEXT;
    default:
      return DEFAULT_FILTER_BUILDER_CONTEXT;
  }
};

export const FilterBuilderContext = createContext<FilterBuilderContextType>({
  from: undefined,
  showEvents: true,
});
