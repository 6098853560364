import { useCallback, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useLazyQuery } from "@apollo/client";
import {
  AttributeTarget,
  AudienceAttributeSummaryType,
} from "../__generated__/graphql";
import { GET_AUDIENCE_ATTRIBUTE_SUMMARIES } from "../graphql/queries";
import { SessionContext } from "../SessionContext";
import { filterDocIsComplete } from "../filter_builder/lib";
import SkeletonScreen from "../patterns/Skeleton";
import Card from "../patterns/Card";
import { formatNumber, formatPercentage } from "../lib/number";
import { titleCase } from "../lib/string";
import { cx } from "../lib/cx";

const SEARCH_DEBOUNCE_TIMEOUT = 1000;

function UniqueValuesAttributeSummaryCard({
  summary,
}: {
  summary: AudienceAttributeSummaryType;
}) {
  const NUM_ITEMS_TO_SEPARATE = 3;
  const uniqueValues = [...summary.countUniqueValues].sort(
    (a, b) => b.count - a.count,
  );
  const top5UniqueValues = uniqueValues.slice(0, NUM_ITEMS_TO_SEPARATE);
  const hasMoreUniqueValues = uniqueValues.length > NUM_ITEMS_TO_SEPARATE;
  const remainingValues = uniqueValues.slice(NUM_ITEMS_TO_SEPARATE);
  const sumOfRemainingValues = remainingValues.reduce((a, b) => a + b.count, 0);
  return (
    <Card>
      <div className="text-xs text-body-text leading-5">
        {summary.displayName || summary.name}
      </div>
      <div className="flex flex-col gap-1 font-normal text-l my-2 h-full justify-center items-center">
        {top5UniqueValues?.map((uv) => (
          <div>
            {titleCase(uv.value)}: {uv.count}
            <span className="text-body-text-lighter ml-1">
              ({formatPercentage(uv.count, summary.countTotal, 1)})
            </span>
          </div>
        ))}
        {hasMoreUniqueValues && (
          <div>
            Other: {sumOfRemainingValues}
            <span className="text-body-text-lighter ml-1">
              ({formatPercentage(sumOfRemainingValues, summary.countTotal, 1)})
            </span>
          </div>
        )}
        {summary.countNullValues > 0 && (
          <div>
            Null: {summary.countNullValues}
            <span className="text-body-text-lighter ml-1">
              (
              {formatPercentage(summary.countNullValues, summary.countTotal, 1)}
              )
            </span>
          </div>
        )}
      </div>
    </Card>
  );
}

function color2QueryParam(attributeName: string, color: string) {
  return btoa(
    JSON.stringify({
      version: "1",
      filters: {
        operator: "AND",
        conditions: [
          {
            source: "lifecycle_stage",
            path: attributeName,
            target: AttributeTarget.Org,
            operator: "EQ",
            value: color,
          },
        ],
      },
    }),
  );
}

function RagStatusCard({
  summary,
  orientation,
}: {
  summary: AudienceAttributeSummaryType;
  orientation?: "horizontal" | "vertical";
}) {
  const colors = ["Red", "Amber", "Green"];
  const navigate = useNavigate();
  const engagementLabels = {
    Red: "Low",
    Amber: "Medium",
    Green: "High",
  };
  const rags = colors.map((color) => {
    const rag = summary.countUniqueValues.find((uv) => uv.value === color);
    return {
      displayName: engagementLabels[color],
      color,
      count: rag?.count || 0,
    };
  });

  return (
    <div
      className={`flex ${orientation === "horizontal" ? "flex-row" : "flex-col"} gap-2 font-normal text-l mx-2 h-full text-center items-center`}
    >
      {rags?.map((rag) => (
        <div
          className={cx(
            "rounded-lg px-3 py-2 cursor-pointer",
            rag.color === "Red" && "bg-[#FFECE8] text-[#C5280C]",
            rag.color === "Amber" && "bg-[#2AACBB1A] text-[#2AACBB]",
            rag.color === "Green" && "bg-[#009FE31A] text-[#366EAA]",
          )}
          onClick={() =>
            navigate({
              pathname: "/audience/organizations",
              search: `?f=${color2QueryParam(summary.name, rag.color)}`,
            })
          }
        >
          {titleCase(rag.displayName)}: {rag.count}
          <span
            className={cx(
              rag.color === "Red" && "text-[#C5280C]",
              rag.color === "Amber" && "text-[#2AACBB]",
              rag.color === "Green" && "text-[#366EAA]",
            )}
          >
            {" "}
            ({formatPercentage(rag.count, summary.countTotal, 1)})
          </span>
        </div>
      ))}
    </div>
  );
}

function AverageValueAttributeSummaryCard({
  summary,
}: {
  summary: AudienceAttributeSummaryType;
}) {
  return (
    <Card>
      <div className="text-xs text-body-text leading-5">
        {summary.displayName || summary.name}
      </div>
      <div className="flex flex-col gap-1 font-normal text-l my-2 h-full justify-center items-center">
        <div>Average: {formatNumber(summary.average, 2)}</div>
        {summary.countNullValues > 0 && (
          <div>Null: {summary.countNullValues}</div>
        )}
      </div>
    </Card>
  );
}

interface Props {
  filter: any;
  attributeTarget: AttributeTarget;
  attributesToSummarize?: any[];
  orientation?: "horizontal" | "vertical";
}

export default function AudienceAttributeSummaries({
  filter,
  attributeTarget,
  attributesToSummarize,
  orientation = "vertical",
}: Props) {
  const sessionContext = useContext(SessionContext);
  const [getAudienceAttributeSummaries, { data, error, loading, refetch }] =
    useLazyQuery(GET_AUDIENCE_ATTRIBUTE_SUMMARIES);

  /* eslint-disable react-hooks/exhaustive-deps */
  // Initial fetch is not debounced
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      const params = {
        target: AttributeTarget.Org,
        filter: JSON.stringify(filter),
      };
      if (attributesToSummarize) {
        params["attributesToSummarize"] = JSON.stringify(attributesToSummarize);
      }
      getAudienceAttributeSummaries({
        variables: params,
      });
    }
  }, []);

  const selectedSummaries = useMemo(() => {
    if (attributesToSummarize) {
      return attributesToSummarize;
    } else {
      return (
        sessionContext?.session?.settings?.audienceAttributesToSummarize?.filter(
          (a) => a.target === attributeTarget,
        ) || []
      );
    }
  }, [sessionContext, attributeTarget]);

  const debouncedRefetch = useCallback(
    debounce(refetch, SEARCH_DEBOUNCE_TIMEOUT),
    [refetch],
  );

  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      const params = {
        target: AttributeTarget.Org,
        filter: JSON.stringify(filter),
      };
      if (attributesToSummarize) {
        params["attributesToSummarize"] = JSON.stringify(attributesToSummarize);
      }
      debouncedRefetch(params);
    }
  }, [filter, attributeTarget, attributesToSummarize, debouncedRefetch]);

  const anyAttributesForTarget = useMemo(() => {
    return selectedSummaries.length > 0;
  }, [selectedSummaries]);

  if (!anyAttributesForTarget) {
    return <></>;
  }

  if (loading) {
    return <SkeletonScreen />;
  }

  if (error) {
    return <></>; // No need to blow up the whole app if this fails
  }

  if (!data?.audienceAttributeSummaries) {
    return <></>;
  }

  return (
    <div className="flex flex-row justify-between gap-4">
      {data?.audienceAttributeSummaries.map((summary) => {
        if (
          summary.name.includes("_rag_status") &&
          summary.source === "lifecycle_stage"
        ) {
          return <RagStatusCard summary={summary} orientation={orientation} />;
        } else {
          switch (summary.aType) {
            case "s":
            case "b":
              return <UniqueValuesAttributeSummaryCard summary={summary} />;
            case "i":
              return <AverageValueAttributeSummaryCard summary={summary} />;
            default:
              return <></>;
          }
        }
      })}
    </div>
  );
}
