import { ValueComponentProps } from "../lib";

export default function BooleanInput({
  value,
  disabled = false,
  onChange,
}: ValueComponentProps) {
  return (
    <select
      defaultValue={value.toString()}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value === "true" ? true : false)}
      className="mb-2 border border-rule-color bg-grey-50 text-xs py-3 pr-10 pl-4 rounded-md min-w-48 max-w-96"
    >
      <option value={"true"}>True</option>
      <option value={"false"}>False</option>
    </select>
  );
}
