export default function PeopleEmpty() {
  return (
    <svg
      width="258"
      height="162"
      viewBox="0 0 258 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1445_14121"
        style={{ "mask-type": "alpha" }}
        maskUnits="userSpaceOnUse"
        x="37"
        y="21"
        width="187"
        height="141"
      >
        <path
          d="M210.012 21.3535H50.5836C43.2461 21.3535 37.2979 25.8307 37.2979 31.3535V151.354C37.2979 156.876 43.2461 161.354 50.5836 161.354H210.012C217.35 161.354 223.298 156.876 223.298 151.354V31.3535C223.298 25.8307 217.35 21.3535 210.012 21.3535Z"
          fill="url(#paint0_linear_1445_14121)"
        />
      </mask>
      <g mask="url(#mask0_1445_14121)">
        <circle cx="128.798" cy="110.854" r="88.5" fill="#E7ECEB" />
      </g>
      <g filter="url(#filter0_d_1445_14121)">
        <path
          d="M39.5 106.354H174.5C175.826 106.354 177.098 106.835 178.036 107.692C178.973 108.55 179.5 109.713 179.5 110.925V133.782C179.5 134.995 178.973 136.157 178.036 137.015C177.098 137.872 175.826 138.354 174.5 138.354H39.5C38.1739 138.354 36.9021 137.872 35.9645 137.015C35.0268 136.157 34.5 134.995 34.5 133.782V110.925C34.5 109.713 35.0268 108.55 35.9645 107.692C36.9021 106.835 38.1739 106.354 39.5 106.354V106.354Z"
          fill="url(#paint1_linear_1445_14121)"
        />
        <path
          d="M100 121.354L105.276 125.887C106.001 126.511 106.926 126.854 107.883 126.854H113.333H119.604C120.043 126.854 120.479 126.926 120.894 127.067L123.859 128.078C125.259 128.555 126.807 128.222 127.886 127.21L130.692 124.579C132.075 123.283 134.178 123.135 135.729 124.225L136.487 124.759C138.176 125.946 140.491 125.653 141.831 124.083L144.669 120.757C145.429 119.866 146.541 119.354 147.712 119.354H152.407C153.017 119.354 153.618 119.493 154.165 119.76L163.12 124.141C164.613 124.871 166.402 124.602 167.614 123.466L172 119.354"
          stroke="#E7222E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="81.2979"
          y="121.354"
          width="40"
          height="6"
          rx="3"
          transform="rotate(-180 81.2979 121.354)"
          fill="#91A8A3"
        />
        <rect
          x="41.5"
          y="125.354"
          width="54"
          height="4"
          rx="2"
          fill="#E3E9E8"
        />
      </g>
      <path
        d="M83.5 59.3535H218.5C219.826 59.3535 221.098 59.8351 222.036 60.6925C222.973 61.5498 223.5 62.7125 223.5 63.9249V86.7821C223.5 87.9945 222.973 89.1573 222.036 90.0146C221.098 90.8719 219.826 91.3535 218.5 91.3535H83.5C82.1739 91.3535 80.9021 90.8719 79.9645 90.0146C79.0268 89.1573 78.5 87.9945 78.5 86.7821V63.9249C78.5 62.7125 79.0268 61.5498 79.9645 60.6925C80.9021 59.8351 82.1739 59.3535 83.5 59.3535V59.3535Z"
        fill="url(#paint2_linear_1445_14121)"
      />
      <path
        d="M83.2979 59.3535H218.298C219.624 59.3535 220.896 59.8351 221.833 60.6925C222.771 61.5498 223.298 62.7125 223.298 63.9249V86.7821C223.298 87.9945 222.771 89.1573 221.833 90.0146C220.896 90.8719 219.624 91.3535 218.298 91.3535H83.2979C81.9718 91.3535 80.7 90.8719 79.7623 90.0146C78.8246 89.1573 78.2979 87.9945 78.2979 86.7821V63.9249C78.2979 62.7125 78.8246 61.5498 79.7623 60.6925C80.7 59.8351 81.9718 59.3535 83.2979 59.3535V59.3535Z"
        fill="url(#paint3_linear_1445_14121)"
      />
      <g filter="url(#filter1_d_1445_14121)">
        <path
          d="M83.2979 59.3535H218.298C219.624 59.3535 220.896 59.8351 221.833 60.6925C222.771 61.5498 223.298 62.7125 223.298 63.9249V86.7821C223.298 87.9945 222.771 89.1573 221.833 90.0146C220.896 90.8719 219.624 91.3535 218.298 91.3535H83.2979C81.9718 91.3535 80.7 90.8719 79.7623 90.0146C78.8246 89.1573 78.2979 87.9945 78.2979 86.7821V63.9249C78.2979 62.7125 78.8246 61.5498 79.7623 60.6925C80.7 59.8351 81.9718 59.3535 83.2979 59.3535V59.3535Z"
          fill="url(#paint4_linear_1445_14121)"
        />
      </g>
      <rect
        x="112.5"
        y="74.3535"
        width="27"
        height="6"
        rx="3"
        transform="rotate(-180 112.5 74.3535)"
        fill="#50746C"
      />
      <rect x="85.5" y="78.3535" width="37" height="4" rx="2" fill="#E3E8E7" />
      <path
        d="M145.5 76.0904L151.007 73.1011C151.593 72.7832 152.249 72.6167 152.916 72.6167H158.833H165.341C165.624 72.6167 165.906 72.5866 166.183 72.5271L170.102 71.6833C171.086 71.4713 172.115 71.6386 172.982 72.1518L176.791 74.4075C177.905 75.067 179.269 75.1467 180.453 74.6213L182.837 73.5625C184.153 72.9779 185.682 73.1462 186.84 74.0031L190.306 76.5687C190.995 77.0784 191.829 77.3535 192.686 77.3535H198.23C198.63 77.3535 199.028 77.2934 199.41 77.1753L209.407 74.0872C210.485 73.754 211.654 73.892 212.625 74.4671L217.5 77.3535"
        stroke="#E7222E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.546 88.3379H135.436C137.929 88.3633 140.341 87.4567 142.2 85.7959H122.782C124.641 87.4567 127.054 88.3633 129.546 88.3379Z"
        fill="#F0F0F0"
      />
      <path
        d="M134.112 156.397L129.974 156.863L128.448 147.479L132.586 147.013L134.112 156.397Z"
        fill="#8A735E"
      />
      <path
        d="M152.639 157.301H148.507L147.478 147.732H151.61L152.639 157.301Z"
        fill="#8A735E"
      />
      <path
        d="M148.166 156.82H153.203C153.285 156.82 153.365 156.848 153.429 156.9C153.493 156.951 153.538 157.022 153.555 157.102L154.371 160.775C154.391 160.865 154.389 160.957 154.368 161.046C154.346 161.135 154.304 161.218 154.246 161.289C154.188 161.359 154.115 161.416 154.032 161.454C153.949 161.493 153.858 161.512 153.766 161.51C152.145 161.482 150.966 161.387 148.922 161.387C147.666 161.387 143.878 161.517 142.144 161.517C140.444 161.517 140.183 159.802 140.893 159.647C144.077 158.947 146.476 157.99 147.493 157.074C147.679 156.909 147.918 156.819 148.166 156.82Z"
        fill="#263238"
      />
      <path
        d="M129.907 156.154L134.391 155.646C134.472 155.636 134.554 155.655 134.624 155.699C134.693 155.742 134.746 155.808 134.773 155.886L135.998 159.443C136.029 159.529 136.039 159.621 136.029 159.712C136.019 159.803 135.988 159.89 135.939 159.967C135.89 160.044 135.824 160.109 135.746 160.157C135.669 160.205 135.581 160.234 135.49 160.243C133.87 160.399 131.522 160.569 129.49 160.8C127.113 161.07 126.717 161.706 123.913 161.641C122.213 161.601 121.787 159.876 122.5 159.741C125.748 159.11 126.376 158.619 128.957 156.593C129.226 156.361 129.555 156.209 129.907 156.154Z"
        fill="#263238"
      />
      <path
        d="M133.556 35.8855C132.168 37.0315 130.802 38.0275 129.389 39.0475C127.976 40.0675 126.533 41.0145 125.048 41.9335C123.563 42.8525 122.039 43.7335 120.441 44.5335C118.808 45.3799 117.107 46.0896 115.356 46.6555C114.893 46.8035 114.443 46.9255 113.923 47.0555C113.414 47.1658 112.9 47.2482 112.382 47.3025C111.451 47.4025 110.575 47.4475 109.702 47.4875C107.962 47.5675 106.255 47.5655 104.55 47.5535C101.14 47.5125 97.7595 47.3485 94.3635 47.0485L94.3125 43.9485C97.6475 43.5195 100.997 43.1485 104.312 42.7755C105.969 42.5955 107.622 42.3935 109.237 42.1825C110.037 42.0735 110.837 41.9455 111.576 41.8085C111.893 41.7525 112.207 41.6788 112.515 41.5875C112.806 41.4875 113.163 41.3735 113.493 41.2405C114.911 40.6652 116.286 39.9922 117.61 39.2265C118.981 38.4395 120.353 37.5945 121.701 36.6965C123.049 35.7985 124.391 34.8685 125.721 33.9195C127.051 32.9705 128.38 31.9655 129.628 31.0195L133.556 35.8855Z"
        fill="#8A735E"
      />
      <path
        d="M137.595 32.3534C137.001 36.2594 128.777 42.2344 128.777 42.2344L122.877 34.9114C125.462 32.1531 128.202 29.5451 131.085 27.1004C133.74 24.9304 138.241 28.1034 137.595 32.3534Z"
        fill="#91A8A3"
      />
      <path
        d="M95.4507 44.2755L93.1047 42.6055L92.8477 47.8815C93.2023 47.8824 93.5517 47.7965 93.8655 47.6312C94.1793 47.466 94.4478 47.2264 94.6477 46.9335L95.4507 44.2755Z"
        fill="#FFC3BD"
      />
      <path
        d="M90.9849 41.0693L90.5049 46.2403L92.8489 47.8813L93.1059 42.6053L90.9849 41.0693Z"
        fill="#8A735E"
      />
      <path
        opacity="0.2"
        d="M128.45 147.482L129.236 152.319L133.375 151.853L132.589 147.016L128.45 147.482Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M151.612 147.732H147.478L148.01 152.664H152.144L151.612 147.732Z"
        fill="black"
      />
      <path
        d="M151.671 27.2252C151.978 27.2942 152.269 27.424 152.526 27.6071C152.783 27.7902 153.001 28.0228 153.166 28.2913C153.332 28.5597 153.442 28.8586 153.49 29.1703C153.539 29.482 153.524 29.8002 153.448 30.1062C152.551 33.8277 151.875 37.5989 151.424 41.4001C151.033 44.6001 150.799 47.6571 150.653 50.4281C150.31 56.9231 150.453 61.8742 150.259 63.5522C147.028 63.3282 134.852 62.4812 128.213 62.0172C125.972 44.4732 128.598 33.2771 130.073 28.7281C130.298 28.0193 130.721 27.3892 131.291 26.9112C131.861 26.4332 132.555 26.127 133.293 26.0282C134.139 25.9172 135.151 25.8101 136.219 25.7471C136.593 25.7231 136.972 25.7101 137.358 25.7021C140.492 25.7393 143.621 25.957 146.731 26.3542C147.321 26.4242 147.917 26.5112 148.497 26.6042C149.656 26.7982 150.758 27.0212 151.671 27.2252Z"
        fill="#50746C"
      />
      <path
        d="M146.539 15.7412C145.739 18.7152 144.761 24.1992 146.731 26.3592C141.621 29.7962 138.359 34.6352 137.418 33.5842C136.925 33.0342 136.711 26.6692 137.359 25.7052C140.778 25.1372 140.865 22.6362 140.443 20.2522L146.539 15.7412Z"
        fill="#8A735E"
      />
      <path
        d="M146.335 25.126C146.335 25.126 138.008 28.811 136.904 37.051C139.198 34.395 144.265 31.505 144.265 31.505L142.03 30.863C143.452 30.3235 144.923 29.9276 146.423 29.681C146.175 28.543 147.497 26.273 147.497 26.273L146.335 25.126Z"
        fill="#91A8A3"
      />
      <path
        d="M138.822 24.9932C138.822 24.9932 139.932 29.6992 136.905 37.0512C136.308 34.4851 135.49 31.9757 134.459 29.5512L136.498 30.1062C136.498 30.1062 136.398 29.1572 134.747 27.8952C135.435 26.8959 136.293 26.025 137.282 25.3222L138.822 24.9932Z"
        fill="#91A8A3"
      />
      <path
        opacity="0.2"
        d="M144.054 17.582L140.446 20.25C140.553 20.8052 140.611 21.3687 140.619 21.934C141.919 21.834 143.779 20.551 144.01 19.216C144.124 18.6785 144.139 18.1249 144.054 17.582Z"
        fill="black"
      />
      <path
        d="M135.959 7.12901C133.944 7.21601 132.832 10.69 134.877 13.551C136.922 16.412 138.674 7.01201 135.959 7.12901Z"
        fill="#263238"
      />
      <path
        d="M145.896 10.1859C146.135 14.2799 146.483 16.6629 144.681 18.9959C141.971 22.5049 136.731 21.2469 135.274 17.3069C133.963 13.7609 133.792 7.66188 137.574 5.56688C138.406 5.10048 139.343 4.85458 140.297 4.85255C141.251 4.85052 142.189 5.09243 143.023 5.5553C143.857 6.01816 144.559 6.68659 145.061 7.49707C145.564 8.30755 145.851 9.23314 145.896 10.1859Z"
        fill="#8A735E"
      />
      <path
        d="M146.496 13.3321C145.401 12.6903 144.445 11.8372 143.684 10.8224C142.922 9.80758 142.37 8.65136 142.059 7.42106C140.859 7.70506 134.878 10.4571 133.039 7.42106C131.2 4.38506 133.514 2.60406 136.955 4.47306C135.433 1.78706 136.817 0.473057 141.599 0.357057C146.381 0.241057 145.738 3.01306 145.738 3.01306C145.738 3.01306 149.358 1.43706 150.638 4.21306C152.121 7.40306 149.385 12.8501 146.496 13.3321Z"
        fill="#263238"
      />
      <ellipse
        cx="148.413"
        cy="13.9755"
        rx="2"
        ry="2.69773"
        transform="rotate(23.1986 148.413 13.9755)"
        fill="#263238"
      />
      <path
        d="M149.798 19.208C146.998 16.808 148.131 12.5413 149.298 10.708L146.798 12.708C146.798 16.708 145.798 16.708 145.298 19.208C144.798 23.208 152.598 21.608 149.798 19.208Z"
        fill="#263238"
      />
      <path
        d="M144.92 2.28025C144.964 2.28025 146.731 2.70525 148.767 0.65625C148.402 2.40425 145.24 3.56825 145.24 3.56825L144.92 2.28025Z"
        fill="#263238"
      />
      <path
        d="M147.296 8.26895C145.389 7.60995 143.08 10.435 143.933 13.846C144.786 17.257 149.865 9.15695 147.296 8.26895Z"
        fill="#263238"
      />
      <path
        d="M148.375 13.5914C148.201 14.6081 147.631 15.5142 146.789 16.1114C145.668 16.8934 144.658 15.9994 144.589 14.7054C144.531 13.5414 145.043 11.7314 146.35 11.4644C146.637 11.4108 146.933 11.4297 147.211 11.5194C147.488 11.6092 147.739 11.7669 147.94 11.9782C148.141 12.1895 148.287 12.4478 148.363 12.7295C148.439 13.0113 148.443 13.3075 148.375 13.5914Z"
        fill="#8A735E"
      />
      <path
        d="M134.426 108.571C135.419 96.4846 142.703 63.0276 142.703 63.0276L128.209 62.0166C128.209 62.0166 122.62 95.2276 122.192 107.832C121.746 120.937 127.017 150.063 127.017 150.063L133.968 149.272C133.968 149.272 133.429 120.707 134.426 108.571Z"
        fill="#91A8A3"
      />
      <path
        opacity="0.5"
        d="M134.426 108.571C135.419 96.4846 142.703 63.0276 142.703 63.0276L128.209 62.0166C128.209 62.0166 122.62 95.2276 122.192 107.832C121.746 120.937 127.017 150.063 127.017 150.063L133.968 149.272C133.968 149.272 133.429 120.707 134.426 108.571Z"
        fill="#91A8A3"
      />
      <path
        opacity="0.2"
        d="M137.243 74.3584C134.321 78.0944 134.269 91.2154 135.399 100.909C136.476 93.9164 138.173 85.0354 139.66 77.6324L137.243 74.3584Z"
        fill="black"
      />
      <path
        d="M136.274 62.5625C136.274 62.5625 137.838 97.9375 139.583 109.917C141.493 123.036 146.344 151.709 146.344 151.709H153.912C153.912 151.709 152.667 124.703 151.668 111.814C150.531 97.1335 150.5 63.5545 150.5 63.5545L136.274 62.5625Z"
        fill="#91A8A3"
      />
      <path
        opacity="0.5"
        d="M136.274 62.5625C136.274 62.5625 137.838 97.9375 139.583 109.917C141.493 123.036 146.344 151.709 146.344 151.709H153.912C153.912 151.709 152.667 124.703 151.668 111.814C150.531 97.1335 150.5 63.5545 150.5 63.5545L136.274 62.5625Z"
        fill="#91A8A3"
      />
      <path
        d="M145.191 151.87H154.102V149.208L144.141 149.035L145.191 151.87Z"
        fill="#50746C"
      />
      <path
        d="M126.714 150.846L134.998 149.907L134.698 147.23L125.421 148.139L126.714 150.846Z"
        fill="#50746C"
      />
      <path
        d="M139.868 12.0627C139.908 12.3937 139.768 12.6827 139.548 12.7087C139.328 12.7347 139.124 12.4877 139.085 12.1567C139.046 11.8257 139.185 11.5367 139.405 11.5107C139.625 11.4847 139.829 11.7317 139.868 12.0627Z"
        fill="#263238"
      />
      <path
        d="M136.129 12.5119C136.169 12.8429 136.029 13.1319 135.809 13.1579C135.589 13.1839 135.385 12.9369 135.345 12.6059C135.305 12.2749 135.445 11.9859 135.665 11.9599C135.885 11.9339 136.089 12.1849 136.129 12.5119Z"
        fill="#263238"
      />
      <path
        d="M137.337 12.5342C137.064 13.5929 136.646 14.6091 136.097 15.5542C136.342 15.6886 136.614 15.7678 136.893 15.7863C137.172 15.8048 137.452 15.7621 137.713 15.6612L137.337 12.5342Z"
        fill="#5B4E41"
      />
      <path
        d="M140.884 10.7138C140.849 10.7243 140.812 10.7251 140.777 10.7161C140.742 10.7071 140.709 10.6887 140.684 10.6628C140.518 10.4809 140.312 10.3408 140.082 10.2539C139.852 10.167 139.605 10.1358 139.361 10.1628C139.31 10.1714 139.258 10.16 139.215 10.1309C139.172 10.1018 139.143 10.0574 139.132 10.0068C139.127 9.9815 139.127 9.95539 139.132 9.93005C139.137 9.90471 139.147 9.88066 139.161 9.8593C139.176 9.83794 139.195 9.81972 139.216 9.80572C139.238 9.79173 139.262 9.78223 139.288 9.77781C139.596 9.73785 139.91 9.77258 140.202 9.87909C140.494 9.98559 140.757 10.1607 140.967 10.3898C141.003 10.4271 141.023 10.4767 141.023 10.5283C141.023 10.5799 141.003 10.6296 140.967 10.6668C140.944 10.6892 140.915 10.7053 140.884 10.7138Z"
        fill="#263238"
      />
      <path
        d="M139.447 16.9809C139.919 16.8817 140.36 16.6703 140.733 16.3646C141.106 16.059 141.4 15.6681 141.59 15.2249C141.599 15.1984 141.598 15.1693 141.586 15.144C141.573 15.1186 141.552 15.0992 141.525 15.0899C141.499 15.0807 141.469 15.0823 141.444 15.0945C141.419 15.1067 141.399 15.1284 141.39 15.1549C141.208 15.5626 140.933 15.9218 140.586 16.2035C140.24 16.4852 139.832 16.6817 139.396 16.7769C139.383 16.78 139.37 16.7857 139.359 16.7937C139.347 16.8017 139.338 16.8119 139.331 16.8236C139.323 16.8353 139.318 16.8483 139.316 16.8619C139.314 16.8755 139.314 16.8895 139.317 16.9029C139.32 16.9164 139.326 16.9291 139.334 16.9404C139.342 16.9516 139.352 16.9612 139.364 16.9685C139.375 16.9759 139.388 16.9808 139.402 16.9831C139.416 16.9854 139.43 16.985 139.443 16.9819L139.447 16.9809Z"
        fill="#263238"
      />
      <path
        d="M134.369 11.4183C134.33 11.4314 134.287 11.4314 134.248 11.4183C134.199 11.4004 134.16 11.3645 134.137 11.3181C134.115 11.2717 134.111 11.2184 134.127 11.1693C134.221 10.8727 134.385 10.603 134.605 10.3833C134.825 10.1636 135.095 10.0003 135.392 9.90728C135.442 9.89592 135.495 9.90434 135.539 9.93079C135.583 9.95723 135.615 9.99967 135.629 10.0493C135.64 10.0993 135.632 10.1519 135.606 10.196C135.579 10.2401 135.537 10.2724 135.488 10.2863C135.254 10.3641 135.043 10.4963 134.871 10.6719C134.698 10.8475 134.57 11.0615 134.497 11.2963C134.486 11.3251 134.469 11.351 134.447 11.3722C134.425 11.3934 134.398 11.4091 134.369 11.4183Z"
        fill="#263238"
      />
      <path
        d="M146.805 157.542C146.522 157.564 146.24 157.49 146.005 157.331C145.932 157.267 145.875 157.187 145.84 157.097C145.806 157.007 145.793 156.91 145.805 156.814C145.805 156.756 145.821 156.7 145.85 156.652C145.88 156.603 145.922 156.563 145.973 156.537C146.432 156.301 147.763 157.121 147.913 157.215C147.93 157.225 147.942 157.24 147.95 157.258C147.958 157.276 147.961 157.295 147.958 157.315C147.955 157.334 147.946 157.351 147.933 157.366C147.92 157.38 147.903 157.39 147.884 157.395C147.531 157.484 147.169 157.533 146.805 157.542ZM146.191 156.687C146.147 156.685 146.104 156.693 146.064 156.711C146.044 156.722 146.027 156.739 146.016 156.759C146.005 156.779 146 156.803 146.002 156.826C145.993 156.891 146.001 156.958 146.024 157.02C146.047 157.082 146.085 157.137 146.135 157.181C146.597 157.391 147.122 157.418 147.602 157.255C147.171 156.975 146.69 156.782 146.186 156.687H146.191Z"
        fill="#50746C"
      />
      <path
        d="M147.858 157.397C147.842 157.397 147.826 157.393 147.811 157.385C147.384 157.153 146.557 156.247 146.644 155.785C146.658 155.713 146.697 155.648 146.754 155.601C146.81 155.555 146.882 155.529 146.955 155.528C147.03 155.519 147.105 155.526 147.177 155.548C147.249 155.569 147.315 155.606 147.372 155.654C147.852 156.047 147.951 157.239 147.954 157.289C147.955 157.307 147.952 157.324 147.944 157.34C147.936 157.356 147.925 157.37 147.91 157.379C147.895 157.39 147.877 157.396 147.858 157.397ZM147.024 155.722H146.979C146.852 155.738 146.841 155.796 146.836 155.822C146.784 156.094 147.311 156.778 147.736 157.101C147.708 156.629 147.538 156.176 147.248 155.801C147.185 155.749 147.106 155.721 147.024 155.722Z"
        fill="#50746C"
      />
      <path
        d="M129.57 156.765L129.554 156.772C128.907 156.979 127.749 157.272 127.254 156.926C127.181 156.875 127.122 156.808 127.081 156.729C127.039 156.65 127.018 156.562 127.018 156.474C127.014 156.42 127.024 156.366 127.047 156.318C127.07 156.269 127.106 156.228 127.149 156.197C127.614 155.87 129.361 156.513 129.559 156.588C129.576 156.595 129.591 156.607 129.602 156.622C129.612 156.637 129.618 156.655 129.62 156.673C129.621 156.692 129.617 156.71 129.608 156.726C129.599 156.743 129.586 156.756 129.57 156.766V156.765ZM127.287 156.341L127.264 156.355C127.247 156.367 127.232 156.383 127.224 156.403C127.215 156.423 127.212 156.444 127.214 156.466C127.213 156.524 127.226 156.582 127.253 156.635C127.279 156.687 127.318 156.732 127.366 156.766C127.974 156.955 128.63 156.92 129.214 156.666C128.609 156.397 127.946 156.285 127.286 156.341H127.287Z"
        fill="#50746C"
      />
      <path
        d="M129.57 156.765C129.547 156.777 129.519 156.779 129.494 156.771C128.974 156.605 127.894 155.828 127.914 155.356C127.919 155.245 127.982 155.1 128.246 155.043C128.342 155.021 128.44 155.02 128.536 155.038C128.632 155.056 128.723 155.094 128.804 155.149C129.228 155.554 129.514 156.082 129.62 156.659C129.624 156.675 129.623 156.693 129.617 156.709C129.612 156.725 129.603 156.74 129.59 156.752C129.584 156.757 129.577 156.761 129.57 156.765ZM128.179 155.275C128.112 155.311 128.11 155.353 128.109 155.369C128.095 155.653 128.841 156.275 129.382 156.521C129.282 156.06 129.043 155.641 128.697 155.321C128.638 155.28 128.571 155.252 128.501 155.238C128.43 155.224 128.357 155.226 128.287 155.242C128.25 155.248 128.214 155.259 128.179 155.275Z"
        fill="#50746C"
      />
      <path
        d="M155.157 33.1856C155.052 34.7996 154.862 36.3026 154.665 37.8546C154.468 39.4066 154.203 40.9276 153.912 42.4626C153.336 45.5978 152.486 48.6763 151.371 51.6626L150.88 52.8196L150.756 53.1086L150.725 53.1806L150.674 53.2906L150.53 53.5756C150.339 53.9219 150.111 54.2459 149.849 54.5416C149.417 55.0268 148.913 55.4417 148.355 55.7716C147.902 56.041 147.427 56.2715 146.936 56.4606C145.298 57.0514 143.585 57.4089 141.848 57.5226C138.693 57.7583 135.522 57.6399 132.394 57.1696L132.507 54.0696L134.693 53.8226C135.426 53.7356 136.155 53.6226 136.88 53.5226C138.327 53.3096 139.759 53.0716 141.124 52.7546C142.386 52.4976 143.613 52.0907 144.78 51.5426C145.094 51.4105 145.369 51.2002 145.58 50.9316C145.593 50.8606 145.435 51.1716 145.621 50.6746L145.948 49.7186C146.748 46.978 147.361 44.1858 147.782 41.3616C148.017 39.9236 148.218 38.4726 148.412 37.0176C148.606 35.5626 148.766 34.0776 148.919 32.6826L155.157 33.1856Z"
        fill="#8A735E"
      />
      <path
        d="M155.516 30.1893C157.539 33.5823 155.816 44.2463 155.816 44.2463L145.216 42.2873C145.216 42.2873 144.642 36.1873 146.66 31.4933C148.851 26.3843 153.065 26.0783 155.516 30.1893Z"
        fill="#91A8A3"
      />
      <path
        d="M129.538 63.6603L123.913 37.4963C123.643 36.379 123.018 35.3798 122.13 34.6493C121.243 33.9188 120.142 33.4965 118.993 33.4463H97.4404C98.5889 33.4963 99.6901 33.9185 100.578 34.649C101.465 35.3795 102.091 36.3789 102.361 37.4963L107.986 63.6603C108.256 64.7776 108.882 65.7768 109.77 66.5073C110.657 67.2378 111.758 67.66 112.906 67.7103H134.458C133.31 67.66 132.209 67.2378 131.322 66.5073C130.434 65.7768 129.808 64.7776 129.538 63.6603Z"
        fill="#263238"
      />
      <path
        opacity="0.7"
        d="M129.538 63.6603L123.913 37.4963C123.643 36.379 123.018 35.3798 122.13 34.6493C121.243 33.9188 120.142 33.4965 118.993 33.4463H97.4404C98.5889 33.4963 99.6901 33.9185 100.578 34.649C101.465 35.3795 102.091 36.3789 102.361 37.4963L107.986 63.6603C108.256 64.7776 108.882 65.7768 109.77 66.5073C110.657 67.2378 111.758 67.66 112.906 67.7103H134.458C133.31 67.66 132.209 67.2378 131.322 66.5073C130.434 65.7768 129.808 64.7776 129.538 63.6603Z"
        fill="#50746C"
      />
      <path
        d="M97.4407 33.4463H118.993C117.889 33.467 116.831 33.8899 116.018 34.6357C115.204 35.3815 114.691 36.3988 114.575 37.4963L108.984 98.2963C108.867 99.3937 108.354 100.411 107.54 101.157C106.727 101.902 105.669 102.325 104.566 102.346H91.1137C90.5955 102.356 90.0813 102.255 89.6054 102.049C89.1295 101.844 88.703 101.54 88.3544 101.156C88.0057 100.773 87.7431 100.319 87.5839 99.826C87.4248 99.3327 87.3729 98.8112 87.4317 98.2963L93.0237 37.4963C93.14 36.3989 93.653 35.3818 94.4663 34.6361C95.2797 33.8903 96.3374 33.4672 97.4407 33.4463Z"
        fill="#91A8A3"
      />
      <path
        d="M100.883 98.2949C100.824 98.8098 100.876 99.3314 101.035 99.8246C101.195 100.318 101.457 100.771 101.806 101.155C102.155 101.538 102.581 101.843 103.057 102.048C103.533 102.253 104.047 102.355 104.565 102.345H83.0132C82.495 102.355 81.9807 102.254 81.5047 102.048C81.0288 101.843 80.6022 101.539 80.2535 101.155C79.9049 100.772 79.6422 100.318 79.4831 99.8247C79.3241 99.3314 79.2723 98.8099 79.3312 98.2949H100.883Z"
        fill="#263238"
      />
      <path
        opacity="0.7"
        d="M100.883 98.2949C100.824 98.8098 100.876 99.3314 101.035 99.8246C101.195 100.318 101.457 100.771 101.806 101.155C102.155 101.538 102.581 101.843 103.057 102.048C103.533 102.253 104.047 102.355 104.565 102.345H83.0132C82.495 102.355 81.9807 102.254 81.5047 102.048C81.0288 101.843 80.6022 101.539 80.2535 101.155C79.9049 100.772 79.6422 100.318 79.4831 99.8247C79.3241 99.3314 79.2723 98.8099 79.3312 98.2949H100.883Z"
        fill="#43605C"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M110.84 40.2582H96.2561C96.1435 40.2598 96.0318 40.2373 95.9285 40.1924C95.8253 40.1475 95.7327 40.0811 95.657 39.9977C95.5814 39.9143 95.5243 39.8158 95.4897 39.7086C95.455 39.6014 95.4436 39.4881 95.4561 39.3762C95.482 39.1375 95.5939 38.9163 95.7709 38.754C95.9479 38.5917 96.178 38.4994 96.4181 38.4942H111C111.113 38.4927 111.224 38.5151 111.328 38.56C111.431 38.6049 111.523 38.6713 111.599 38.7547C111.675 38.8381 111.732 38.9367 111.766 39.0438C111.801 39.151 111.813 39.2643 111.8 39.3762C111.774 39.6146 111.663 39.8355 111.486 39.9978C111.309 40.16 111.08 40.2526 110.84 40.2582Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M110.406 45.0297H95.8215C95.7089 45.0312 95.5973 45.0088 95.494 44.9639C95.3907 44.919 95.2982 44.8526 95.2225 44.7692C95.1468 44.6858 95.0898 44.5872 95.0551 44.4801C95.0205 44.3729 95.009 44.2596 95.0215 44.1477C95.0474 43.9089 95.1594 43.6878 95.3364 43.5255C95.5134 43.3632 95.7434 43.2709 95.9835 43.2657H110.568C110.68 43.2642 110.792 43.2866 110.895 43.3315C110.998 43.3764 111.091 43.4428 111.167 43.5262C111.242 43.6096 111.299 43.7082 111.334 43.8153C111.369 43.9225 111.38 44.0358 111.368 44.1477C111.342 44.3865 111.23 44.6078 111.053 44.7702C110.876 44.9325 110.646 45.0248 110.406 45.0297Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M106.397 49.8002H95.3879C95.2753 49.8017 95.1637 49.7793 95.0604 49.7344C94.9571 49.6895 94.8646 49.6231 94.7889 49.5397C94.7132 49.4563 94.6562 49.3577 94.6215 49.2506C94.5869 49.1434 94.5754 49.0301 94.5879 48.9182C94.6138 48.6794 94.7258 48.4583 94.9028 48.296C95.0798 48.1337 95.3098 48.0414 95.5499 48.0362H106.559C106.672 48.0347 106.783 48.0571 106.886 48.102C106.99 48.1469 107.082 48.2133 107.158 48.2967C107.234 48.3801 107.291 48.4787 107.325 48.5858C107.36 48.693 107.371 48.8063 107.359 48.9182C107.333 49.1569 107.221 49.3781 107.044 49.5404C106.867 49.7027 106.637 49.795 106.397 49.8002Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M98.6873 54.5697H94.9543C94.8417 54.5713 94.7301 54.5489 94.6268 54.504C94.5235 54.459 94.431 54.3927 94.3553 54.3092C94.2796 54.2258 94.2226 54.1273 94.1879 54.0201C94.1533 53.913 94.1418 53.7997 94.1543 53.6877C94.18 53.4491 94.2917 53.2279 94.4686 53.0656C94.6454 52.9033 94.8753 52.8109 95.1153 52.8057H98.8493C98.9619 52.8042 99.0736 52.8266 99.1769 52.8715C99.2801 52.9164 99.3727 52.9828 99.4483 53.0662C99.524 53.1497 99.581 53.2482 99.6157 53.3554C99.6503 53.4625 99.6618 53.5758 99.6493 53.6877C99.6234 53.9265 99.5115 54.1477 99.3345 54.31C99.1574 54.4722 98.9274 54.5646 98.6873 54.5697Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M109.104 59.3412H94.5158C94.4032 59.3428 94.2916 59.3203 94.1883 59.2754C94.085 59.2305 93.9925 59.1641 93.9168 59.0807C93.8412 58.9973 93.7841 58.8988 93.7495 58.7916C93.7148 58.6845 93.7033 58.5712 93.7158 58.4592C93.7418 58.2205 93.8537 57.9993 94.0307 57.837C94.2077 57.6747 94.4377 57.5824 94.6778 57.5772H109.262C109.374 57.5757 109.486 57.5981 109.589 57.643C109.693 57.6879 109.785 57.7543 109.861 57.8377C109.937 57.9211 109.994 58.0197 110.028 58.1268C110.063 58.234 110.074 58.3473 110.062 58.4592C110.036 58.6973 109.925 58.9179 109.748 59.0801C109.572 59.2423 109.343 59.3351 109.104 59.3412Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M102.926 64.1117H94.0861C93.9735 64.1133 93.8619 64.0909 93.7586 64.0459C93.6553 64.001 93.5628 63.9347 93.4871 63.8513C93.4115 63.7678 93.3544 63.6693 93.3198 63.5621C93.2851 63.455 93.2737 63.3417 93.2862 63.2297C93.3118 62.9911 93.4236 62.7699 93.6004 62.6076C93.7773 62.4453 94.0072 62.3529 94.2471 62.3477H103.087C103.2 62.3462 103.311 62.3686 103.415 62.4135C103.518 62.4584 103.61 62.5248 103.686 62.6082C103.762 62.6916 103.819 62.7902 103.854 62.8974C103.888 63.0045 103.9 63.1178 103.887 63.2297C103.861 63.4683 103.749 63.6893 103.573 63.8516C103.396 64.0139 103.166 64.1063 102.926 64.1117Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M108.237 68.8822H93.6526C93.54 68.8838 93.4283 68.8614 93.325 68.8165C93.2217 68.7715 93.1292 68.7052 93.0535 68.6217C92.9779 68.5383 92.9208 68.4398 92.8862 68.3326C92.8515 68.2255 92.8401 68.1122 92.8526 68.0002C92.8785 67.7615 92.9904 67.5403 93.1674 67.378C93.3444 67.2157 93.5745 67.1234 93.8146 67.1182H108.4C108.512 67.1167 108.624 67.1391 108.727 67.184C108.83 67.2289 108.923 67.2953 108.999 67.3787C109.074 67.4621 109.131 67.5607 109.166 67.6679C109.201 67.775 109.212 67.8883 109.2 68.0002C109.174 68.2392 109.062 68.4605 108.884 68.6228C108.707 68.7851 108.477 68.8773 108.237 68.8822Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M104.228 73.6528H93.219C93.1064 73.6543 92.9947 73.6319 92.8914 73.587C92.7881 73.542 92.6956 73.4757 92.62 73.3923C92.5443 73.3089 92.4872 73.2103 92.4526 73.1031C92.4179 72.996 92.4065 72.8827 92.419 72.7708C92.4449 72.532 92.5568 72.3108 92.7338 72.1485C92.9108 71.9862 93.1409 71.8939 93.381 71.8887H104.39C104.503 71.8872 104.614 71.9096 104.718 71.9545C104.821 71.9995 104.913 72.0658 104.989 72.1492C105.065 72.2327 105.122 72.3312 105.156 72.4384C105.191 72.5455 105.202 72.6588 105.19 72.7708C105.164 73.0095 105.052 73.2307 104.875 73.393C104.698 73.5553 104.468 73.6476 104.228 73.6528Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M96.5184 78.4232H92.7824C92.6698 78.4248 92.5582 78.4024 92.4549 78.3575C92.3516 78.3126 92.2591 78.2462 92.1834 78.1628C92.1078 78.0793 92.0507 77.9808 92.0161 77.8736C91.9814 77.7665 91.9699 77.6532 91.9824 77.5413C92.0084 77.3025 92.1203 77.0813 92.2973 76.9191C92.4743 76.7568 92.7043 76.6644 92.9444 76.6593H96.6824C96.7951 76.6577 96.9067 76.6801 97.01 76.7251C97.1133 76.77 97.2058 76.8363 97.2815 76.9197C97.3571 77.0032 97.4142 77.1017 97.4488 77.2089C97.4835 77.316 97.4949 77.4293 97.4824 77.5413C97.4565 77.7803 97.3443 78.0018 97.1668 78.1641C96.9894 78.3264 96.7589 78.4186 96.5184 78.4232Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M106.935 83.1947H92.3508C92.2382 83.1963 92.1265 83.1739 92.0233 83.129C91.92 83.084 91.8274 83.0177 91.7518 82.9342C91.6761 82.8508 91.6191 82.7523 91.5844 82.6451C91.5498 82.538 91.5383 82.4247 91.5508 82.3127C91.5767 82.074 91.6886 81.8528 91.8657 81.6905C92.0427 81.5282 92.2727 81.4359 92.5128 81.4307H107.097C107.209 81.4292 107.321 81.4516 107.424 81.4965C107.528 81.5414 107.62 81.6078 107.696 81.6912C107.771 81.7746 107.829 81.8732 107.863 81.9804C107.898 82.0875 107.909 82.2008 107.897 82.3127C107.871 82.5515 107.759 82.7727 107.582 82.935C107.405 83.0972 107.175 83.1896 106.935 83.1947Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M100.757 87.9653H91.9172C91.8046 87.9668 91.693 87.9444 91.5897 87.8995C91.4864 87.8545 91.3939 87.7882 91.3182 87.7048C91.2425 87.6213 91.1855 87.5228 91.1508 87.4156C91.1162 87.3085 91.1047 87.1952 91.1172 87.0833C91.1429 86.8444 91.2548 86.6231 91.4318 86.4608C91.6089 86.2985 91.839 86.2062 92.0792 86.2012H100.918C101.031 86.1997 101.142 86.2221 101.246 86.267C101.349 86.312 101.442 86.3783 101.517 86.4617C101.593 86.5452 101.65 86.6437 101.685 86.7509C101.719 86.858 101.731 86.9713 101.718 87.0833C101.692 87.3219 101.581 87.5429 101.404 87.7052C101.227 87.8674 100.997 87.9599 100.757 87.9653Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M106.067 92.7358H91.4826C91.37 92.7373 91.2584 92.7149 91.1551 92.67C91.0518 92.6251 90.9593 92.5587 90.8836 92.4753C90.808 92.3918 90.7509 92.2933 90.7163 92.1861C90.6816 92.079 90.6701 91.9657 90.6826 91.8537C90.7086 91.615 90.8205 91.3938 90.9975 91.2316C91.1745 91.0693 91.4045 90.9769 91.6446 90.9718H106.227C106.339 90.9702 106.451 90.9926 106.554 91.0376C106.657 91.0825 106.75 91.1488 106.826 91.2323C106.901 91.3157 106.958 91.4142 106.993 91.5214C107.028 91.6285 107.039 91.7418 107.027 91.8537C107.001 92.0922 106.889 92.3131 106.713 92.4753C106.536 92.6376 106.306 92.7301 106.067 92.7358Z"
          fill="white"
        />
      </g>
      <path
        d="M133.777 54.4072L130.611 51.6562L129.127 56.7202C129.127 56.7202 132.343 58.4643 133.457 56.8633L133.777 54.4072Z"
        fill="#8A735E"
      />
      <path
        d="M127.162 51.458L126.132 55.702L129.124 56.722L130.608 51.657L127.162 51.458Z"
        fill="#8A735E"
      />
      <defs>
        <filter
          id="filter0_d_1445_14121"
          x="28.5"
          y="103.354"
          width="157"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1445_14121"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1445_14121"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1445_14121"
          x="72.2979"
          y="56.3535"
          width="157"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1445_14121"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1445_14121"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1445_14121"
          x1="37.2979"
          y1="21.3535"
          x2="169.967"
          y2="195.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E7E6" />
          <stop offset="1" stopColor="#F6F8F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1445_14121"
          x1="44.5"
          y1="106.354"
          x2="179.5"
          y2="138.354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6F8F7" />
          <stop offset="1" stopColor="#FAFBFB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1445_14121"
          x1="88.5"
          y1="59.3535"
          x2="223.5"
          y2="91.3535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6F8F7" />
          <stop offset="1" stopColor="#FAFBFB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1445_14121"
          x1="88.2978"
          y1="59.3535"
          x2="223.298"
          y2="91.3535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6F8F7" />
          <stop offset="1" stopColor="#FAFBFB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1445_14121"
          x1="88.2978"
          y1="59.3535"
          x2="223.298"
          y2="91.3535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6F8F7" />
          <stop offset="1" stopColor="#FAFBFB" />
        </linearGradient>
      </defs>
    </svg>
  );
}
