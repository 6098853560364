import SelectInput from "../../patterns/forms/SelectInput";
import TextInput from "../../patterns/forms/TextInput";
import { MailgunProps, Region } from "../../types/BackendTypes";

interface Props {
  config: MailgunProps;
  setConfig: (s: MailgunProps) => void;
}

export default function MailgunConfig({ config, setConfig }: Props) {
  return (
    <>
      <SelectInput
        label="Mailgun Region"
        placeholder="Choose a region"
        options={[
          {
            label: "EU",
            value: "EU",
          },
          {
            label: "US",
            value: "US",
          },
        ]}
        required={true}
        value={config.region || ""}
        onChange={(s: Region) => {
          setConfig({ ...config, region: s });
        }}
      />
      <TextInput
        placeholder="mg.your-app.com"
        label="Domain"
        value={config.domain || ""}
        required={true}
        onChange={(s) => {
          setConfig({ ...config, domain: s });
        }}
      />
      <TextInput
        placeholder="API-KEY"
        label="Sending key"
        value={config.sending_key || ""}
        required={true}
        onChange={(s) => {
          setConfig({ ...config, sending_key: s });
        }}
      />
    </>
  );
}
