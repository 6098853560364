import React from "react";
import PrimaryButton from "../patterns/atoms/PrimaryButton";

export default function DataIntegrations({ step, handleStepChange }: any) {
  const handleClick = (e: any) => {
    e.preventDefault();
    handleStepChange(step);
  };

  const [focusedIntegration, setFocusedIntegration] = React.useState(null);

  const integrations = [
    {
      id: 1,
      name: "Segment",
      logo: "segmentlogo.png",
    },
  ];

  const handleIntegrationFocus = (integration: any) => {
    setFocusedIntegration(integration);
  };

  return (
    <div className="flex flex-col items-center justify-center p-10 text-center">
      <h2>Connect your data with Pavlov</h2>
      <p className="pt-1 text-sm">
        We'll need this to incentivise specific events
      </p>

      <div className="w-2/5 pt-10 text-left">
        {focusedIntegration === null ? (
          <div>
            <label htmlFor="">Choose your data provider</label>

            {integrations.map((integration) => {
              return (
                <div
                  className="mb-4 block w-full rounded-lg border border-grey-100 bg-white px-4 py-2 text-xs font-normal tracking-wide text-body-text focus:border-blue-800 focus:ring-blue-300"
                  key={integration.id}
                  onClick={() => handleIntegrationFocus(integration)}
                >
                  <div className="flex w-full flex-row">
                    <img src={`/${integration.logo}`} alt="" className="w-6" />

                    {/* The margin on the integration name below is fixing the width - jaaaaank */}
                    <span className="my-auto ml-2 grow">
                      {integration.name}
                    </span>

                    <svg
                      width="17"
                      height="13"
                      viewBox="0 0 17 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="my-auto"
                    >
                      <g id="Chevron">
                        <path
                          id="Chevron down"
                          d="M7.98872 12.2804L7.72158 12.0156C7.42614 11.7228 7.42614 11.248 7.72158 10.9551L12.2161 6.5L7.72158 2.04488C7.42614 1.752 7.42614 1.27724 7.72158 0.984353L7.98872 0.719596C8.28425 0.426802 8.7633 0.426802 9.05882 0.719596L14.2232 5.83698C14.5923 6.2033 14.5923 6.7967 14.2232 7.16302L9.05882 12.2804C8.7633 12.5732 8.28425 12.5732 7.98872 12.2804Z"
                          fill="#A8A8A8"
                        />
                        <path
                          id="Line 641"
                          d="M13 6.5L1 6.5"
                          stroke="#A8A8A8"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div onClick={() => handleIntegrationFocus(null)}>focused</div>
        )}
      </div>

      <div className="pt-10">
        <PrimaryButton onClick={handleClick} label="Complete Step 1" />
      </div>
    </div>
  );
}
