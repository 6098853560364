import { useCallback, useContext } from "react";
import { FeatureFlagType, Flags } from "./__generated__/graphql";
import PageHeader from "./patterns/PageHeader";
import { SessionContext } from "./SessionContext";
import { useMutation } from "@apollo/client";
import { DISABLE_FEATURE_FLAG, ENABLE_FEATURE_FLAG } from "./graphql/mutations";
import toast from "react-hot-toast";

const isEnabled = (flag: Flags, list: FeatureFlagType[]) => {
  return !!list.find((f) => f.name === flag);
};

export default function FeatureFlags() {
  const sessionContext = useContext(SessionContext);
  const [enableMutation] = useMutation(ENABLE_FEATURE_FLAG);
  const [disableMutation] = useMutation(DISABLE_FEATURE_FLAG);

  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, f: Flags) => {
      if (e.target.checked) {
        try {
          await enableMutation({
            variables: { flag: f },
            refetchQueries: ["GetSession"],
          });
          toast.success("Feature flag enabled");
        } catch (e) {
          toast.error(e.message);
        }
      } else {
        try {
          await disableMutation({
            variables: { flag: f },
            refetchQueries: ["GetSession"],
          });
          toast.success("Feature flag disabled");
        } catch (e) {
          toast.error(e.message);
        }
      }
    },
    [disableMutation, enableMutation],
  );
  return (
    <>
      <PageHeader header="Feature flags" subhead="" />
      <div className="p-10">
        {(Object.keys(Flags) as Array<keyof typeof Flags>).map((f) => (
          <div>
            <label>
              <input
                type="checkbox"
                className="mr-2"
                defaultChecked={isEnabled(
                  Flags[f],
                  sessionContext.featureFlags,
                )}
                onChange={(e) => onChange(e, Flags[f])}
              />
              {f}
            </label>
          </div>
        ))}
      </div>
    </>
  );
}
