import React, { useCallback, useRef } from "react";

interface Props {
  close: () => void;
  title: string;
  size?: "default" | "large";
}

export default function Modal({
  close,
  title,
  size = "default",
  children,
}: Props & React.PropsWithChildren) {
  const modalContents = useRef<HTMLDivElement>();
  const onBgClick = useCallback(
    (e: React.MouseEvent) => {
      if (!modalContents.current.contains(e.target as HTMLElement)) {
        close();
      }
    },
    [close, modalContents],
  );
  const sizeClasses = size === "large" ? "max-w-lg" : "max-w-md"; // Default size is "max-w-md"
  return (
    <div
      tabIndex={-1}
      aria-hidden="true"
      className="fixed left-0 right-0 top-0 z-50 grid max-h-full w-full place-items-center overflow-y-auto overflow-x-hidden rounded-xl md:inset-0"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      onClick={onBgClick}
    >
      <div
        className={`relative max-h-full w-full ${sizeClasses}`}
        ref={modalContents}
      >
        <div className="relative rounded-xl bg-white shadow-sm">
          <div className="border-b border-rule-color px-8 py-5">
            <span className="text-sm font-bold tracking-tight text-body-text dark:text-white">
              {title}
            </span>
            <button
              type="button"
              className="absolute right-5 top-4 bottom-4 ml-auto inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-sm text-body-text bg-grey-10 hover:text-active-blue hover:bg-blue-10 animate cursor-pointer"
              data-modal-hide="event-modal"
              onClick={close}
            >
              <svg
                className="h-3 w-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="px-8 pt-6 pb-8">{children}</div>
        </div>
      </div>
    </div>
  );
}
