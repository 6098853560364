import React, { Dispatch, useState } from "react";
import Card from "../patterns/Card";
import { BehaviorUpdate } from "../behavior/behavior_form/reducer";
import { WebhookAction } from "../types/BackendTypes";
import { ReferralUpdate } from "../referral/referral_form/reducer";
import TextInput from "../patterns/forms/TextInput";
import { uuidv7 } from "@kripod/uuidv7";
import IconButton from "../patterns/atoms/IconButton";
import { ActionListNames } from "./action_list_names";

interface HeaderEditorProps {
  dispatch: Dispatch<BehaviorUpdate | ReferralUpdate>;
  action: WebhookAction;
  header?: {
    id: string;
    key: string;
    value: string;
  };
  actionSource: ActionListNames;
}

function HeaderEditor({
  dispatch,
  action,
  header,
  actionSource,
}: HeaderEditorProps) {
  const [headerId, setHeaderId] = useState(header?.id || uuidv7());
  const [headerKey, setHeaderKey] = useState(header?.key || "");
  const [headerValue, setHeaderValue] = useState(header?.value || "");

  const canSave =
    headerKey &&
    headerValue &&
    (headerKey !== header?.key || headerValue !== header?.value);
  const canRemove = headerKey || headerValue;

  return (
    <div className="flex flex-row gap-2 items-center">
      <TextInput
        label=""
        value={headerKey}
        placeholder="Authorization"
        required={true}
        onChange={(value) => setHeaderKey(value)}
      />
      <TextInput
        label=""
        value={headerValue}
        placeholder="Bearer some_token"
        required={true}
        onChange={(value) => setHeaderValue(value)}
      />
      <div className="pb-4">
        <IconButton
          icon="check"
          variant="confirm"
          isDisabled={!canSave}
          onClick={() => {
            if (header) {
              const existingHeaderIndex = action.headers.findIndex(
                (h) => h.id === headerId,
              );
              const newHeaders = [...action.headers];
              newHeaders[existingHeaderIndex] = {
                id: headerId,
                key: headerKey,
                value: headerValue,
              };
              dispatch({
                type: "UpdateActionOfType",
                actionType: "webhook",
                actionList: actionSource,
                action: {
                  ...action,
                  headers: [...newHeaders],
                },
              });
            } else {
              dispatch({
                type: "UpdateActionOfType",
                actionType: "webhook",
                actionList: actionSource,
                action: {
                  ...action,
                  headers: [
                    ...action.headers,
                    {
                      id: headerId,
                      key: headerKey,
                      value: headerValue,
                    },
                  ],
                },
              });
              setHeaderId(uuidv7());
              setHeaderKey("");
              setHeaderValue("");
            }
          }}
        />
      </div>
      <div className="pb-4">
        <IconButton
          icon="xMark"
          variant="destructive"
          isDisabled={!canRemove}
          onClick={() =>
            dispatch({
              type: "UpdateActionOfType",
              actionType: "webhook",
              actionList: actionSource,
              action: {
                ...action,
                headers: action.headers.filter((h) => h.id !== headerId),
              },
            })
          }
        />
      </div>
    </div>
  );
}

export default function WebhookActionEditor({
  dispatch,
  action,
  actionSource,
}: {
  dispatch: Dispatch<BehaviorUpdate | ReferralUpdate>;
  action: WebhookAction;
  actionSource: "actionsOnCompletion" | "actionsOnReferred" | "actionsOnEnter";
}) {
  return (
    <div>
      <Card>
        <div className="px-4">
          <TextInput
            label={`Webhook URL`}
            value={action.url}
            placeholder="https://example.com/receiver"
            required={true}
            onChange={(url) =>
              dispatch({
                type: "UpdateActionOfType",
                actionType: "webhook",
                actionList: actionSource,
                action: {
                  ...action,
                  url,
                },
              })
            }
          />
        </div>
        <div className="px-4">
          <TextInput
            label={`Secret`}
            description={
              'If set, Pavlov will sign requests with an HMAC in the "X-Signature" request header. The HMAC is a hex-encoded SHA1 hash generated using this shared secret and a stringified version of the request payload.'
            }
            value={action.secret}
            placeholder="Shared secret"
            required={false}
            onChange={(secret) =>
              dispatch({
                type: "UpdateActionOfType",
                actionType: "webhook",
                actionList: actionSource,
                action: {
                  ...action,
                  secret,
                },
              })
            }
          />
        </div>
        <div className="px-4">
          <label>Headers</label>
          {action.headers.map((header, index) => (
            <HeaderEditor
              key={header.id}
              dispatch={dispatch}
              action={action}
              header={header}
              actionSource={actionSource}
            />
          ))}
          <HeaderEditor
            dispatch={dispatch}
            action={action}
            actionSource={actionSource}
          />
        </div>
      </Card>
    </div>
  );
}
