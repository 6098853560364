const CSRF_URL = "/app/csrf_token";

export async function fetchCSRFHeader() {
  await fetch(CSRF_URL, {
    method: "GET",
  });
  return getCookie("csrftoken");
}

export async function fetchCSRFFormToken() {
  const result = await fetch(CSRF_URL, {
    method: "GET",
  });
  const text = await result.text();
  return text;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
